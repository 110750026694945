import React from 'react';

function Welcome () {

    return (
        <>
            <div class="slim-mainpanel">
                <div class="container">
                   
                    <div class="initial-success col-lg-6">
                        <h2 class="success-title header2">Welcome to the Surebanka Platform</h2>
                        <div class="signup-success">
                            <img class="img-responsive" src="../assets/checkmark.gif" alt="success icon" />
                        </div>
                        <div class="">                            
                            <a href='/ptsp/dashboard' class="btn light-button blue-color" id="submit">Go to dashboard</a>                            
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Welcome