import React, { useState, useEffect } from 'react'
import Profit from '../../../components/report/Profit'
import { useFormik } from 'formik';
import { AgentService } from '../../../service/AgentService';
import * as Yup from 'yup';
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';

function Electricity ({changeApp}) {

    const [busy, setBusy] = useState(false);

    const formik = useFormik({
        initialValues: {
          meterNo: '',
          date: '',          
        },
        validate: (what) => {
            
            if(formik.errors.meterNo && formik.touched.meterNo){
                NotificationService.notifyErrorWithID(formik.errors.meterNo, "meterNo", 5000)
                return;
            }

            if(formik.errors.date && formik.touched.date){
                NotificationService.notifyErrorWithID(formik.errors.date, "date", 5000)
                return;
            }
            
        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
          meterNo: Yup.string()
            .required('Please enter the Meter Number'),
          date: Yup.string()
            .required('Please select the transaction date'),
        }),
        onSubmit: (values,{ resetForm }) => {         
            
            setBusy(true)
            
            AgentService.getPHCNSlipPDF({ meterNo: values.meterNo, date: values.date })
            .then(response => {
                NotificationService.notifyInfo("The Electricity Payment Receipt has been sent to your registered email.", 20000)
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
            
        }
      });

    return (
        <>
            { busy ? <Spinner /> :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">    
                    <div class="main-header">
                            <a href="#" onClick={() =>  changeApp("main")} class="pss-blue pd-b-10">← Go Back</a>
                    </div>
                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="meterNo" autoFocus {...formik.getFieldProps('meterNo')} />
                                                <span className="highlight"></span>
                                                <label>Meter Number</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="date" placeholder=" "  name="date" autoFocus {...formik.getFieldProps('date')} />
                                                <span className="highlight"></span>
                                                <label>Transaction Date</label>
                                            </div>
                                           
                                        </div>
                                            
                                    

                                        <button className="btn btn-primary" type="submit">Create</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
            
    )
}

export default Electricity