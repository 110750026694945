import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../../service/NotificationService';
import InfiniteScroll from "react-infinite-scroll-component";


function Agent ({aggregators, onEdit}) {
    const [localAggregators, setLocalAggregators] = useState([])
    
    let  aggregatorElements = [];
    let increment = 1

    if(localAggregators.length !== 0){
        
        // aggregatorElements = localAggregators.map(aggregator => {
        //         return (
        //             <tr key={aggregator.email} onClick={() => onEdit(aggregator)}>
        //                 <td><a href="#">{aggregator.firstname + " " + aggregator.lastname}</a></td>
        //                 <td><a href="#">{aggregator.email}</a></td>
        //                 <td><a href="#">{aggregator.phonenumber}</a></td>
        //                 <td><a href="#"><div className={aggregator.isActive ? "success" : "inactive"}>{aggregator.isActive ? "Active" : "Disabled"}</div></a></td>
        //                 <td><a href="#">{aggregator.address + ", " + aggregator.lga + ", " + aggregator.state}</a></td>            
        //             </tr>
        //         )
        //     })    
    }

    useEffect(() => {       
        setLocalAggregators(prev => {
            return aggregators.slice(0, 20)
        })

        
    },[])
    
    const formik = useFormik({
        initialValues: {
            name : '',
            email: '',
            phone: '',
            address: ''
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });

      function nameChange(e){       

        const filtered = aggregators.filter( agent => agent.firstname.toLowerCase().includes(e.target.value.toLowerCase()) || agent.lastname.toLowerCase().includes(e.target.value.toLowerCase()))

        if(filtered.length !== 0){
            setLocalAggregators(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered does not match any value", 2000);
            setLocalAggregators(prev => {
                return aggregators
            })
        }
        
  } 

      function emailChange(e){
        formik.handleChange(e)

        //const filtered = agents.filter( agent => agent.email.toLowerCase() === e.target.value.toLowerCase())
        const filtered = aggregators.filter( agent => agent.email.toLowerCase().includes(e.target.value.toLowerCase()))

        console.log(filtered)

        if(filtered.length !== 0){
            setLocalAggregators(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered does not match any value", 2000);
            setLocalAggregators(prev => {
                return aggregators
            })
        }
        
      }

      function phoneChange(e){

        formik.handleChange(e)

        const filtered = aggregators.filter( agent => agent.phonenumber.toLowerCase().includes(e.target.value.toLowerCase()))        

        if(filtered.length !== 0){
            setLocalAggregators(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered does not match any value", 2000);
            setLocalAggregators(prev => {
                return aggregators
            })
        }
      }

      function addressChange(e){
        formik.handleChange(e)

        const filtered = aggregators.filter( agent => agent.address.toLowerCase().includes(e.target.value.toLowerCase()) || agent.lga.toLowerCase().includes(e.target.value.toLowerCase()) || agent.state.toLowerCase().includes(e.target.value.toLowerCase()) ) 
        
        console.log(filtered + " : " + e.target.value)

        if(filtered.length !== 0){
            setLocalAggregators(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered does not match any value", 2000);
            setLocalAggregators(prev => {
                return aggregators
            })
        }
        
      }

    function fetchMoreData(){
        increment += 1
        
        setLocalAggregators(prev => {
            return aggregators.slice(0, 20 * increment)
        })
      }


    return (
        <>        
           
               {/* <div className="row table-overall-headings mg-b-10">
                        <div className="col-md-6">
                            <h2>Aggregators</h2>
                        </div>
                    </div> */}
               <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form >
                               
                                <div className="floating-form">
                                    <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                    <input onChange={e => {formik.handleChange(e); nameChange(e)}} className="floating-input" type="text" placeholder="" name="name"  value={formik.values.name}/>
                                    <span className="highlight"></span>
                                    <label>Name</label>
                                </div>
                                <div className="floating-label tran col-md-6 pd-b-10">
                                    <input onChange={e => emailChange(e)} className="floating-input" type="text" placeholder="" name="email"  value={formik.values.email} />
                                    <span className="highlight"></span>
                                    <label>Email</label>
                                </div>
                                    </div>
                                <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                        <input onChange={e => phoneChange(e)} className="floating-input" type="number" placeholder="" name="phone" value={formik.values.phone}/>
                                        <span className="highlight"></span>
                                        <label>Phone Number</label>
                                    </div>

                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                        <input onChange={e => addressChange(e)} className="floating-input" type="text" placeholder="" name="address" value={formik.values.address}/>
                                        <span className="highlight"></span>
                                        <label>Address</label>
                                    </div>
                                </div>
                                
                                
                                </div>
                                
                                {/* <div className="btn-filter">
                                <a href="#"><button className=" btn-fill-md majorButton">Filter</button></a>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    </div>

                  
                           {/* <thead>
                               <tr>
                                   <th className="">Agent Name</th>
                                   <th className="">Email</th>
                                   <th className="">Phone Number</th>
                                   <th className="">Status</th>
                                   <th className="">Address</th>
                                   
                               </tr>
                           </thead> */}
                           
                               
                               <InfiniteScroll
                                    dataLength={aggregators.length}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    loader={<h4>Loading...</h4>}
                                    >
                                     <div className="table-wrapper table-responsive">
                                        <table id="datatable2" className="table display responsive nowrap">
                                         <thead>
                                            <tr>
                                                <th className="">Agent Name</th>
                                                <th className="">Email</th>
                                                <th className="">Phone Number</th>
                                                <th className="">Status</th>
                                                <th className="">Address</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {aggregatorElements = localAggregators.map(aggregator => (
                                            
                                                <tr key={aggregator.email} onClick={() => onEdit(aggregator)}>
                                                    <td><a href="#">{aggregator.firstname + " " + aggregator.lastname}</a></td>
                                                    <td><a href="#">{aggregator.email}</a></td>
                                                    <td><a href="#">{aggregator.phonenumber}</a></td>
                                                    <td><a href="#"><div className={aggregator.isActive ? "success" : "inactive"}>{aggregator.isActive ? "Active" : "Disabled"}</div></a></td>
                                                    <td><a href="#">{aggregator.address + ", " + aggregator.lga + ", " + aggregator.state}</a></td>            
                                                </tr>
                                            
                                        )) }
                                        </tbody>

                                        </table>
                                     </div>
                                        
                                        
                                </InfiniteScroll>
                           
                     
        </>
    )
}

export default Agent