import React, {useEffect, useState} from 'react'
import {AggregatorService} from '../../../service/AggregatorService'
import {  toast } from 'react-toastify';
import Spinner from '../../../components/Spinner'
import { NotificationService } from '../../../service/NotificationService'

function Main ({changeApp}) {

    const [balance, setBalance] = useState(0)
    const { id } = JSON.parse(localStorage.getItem('user'))
    const  [ now ] = useState(new Date().toLocaleTimeString())
    const [busy, setBusy] = useState(true);

    useEffect(() => {
        AggregatorService.getAggregatorBalance({aggregatorId: id})
        .then(response => {
            console.log(response.data.data)
            setBalance(prev => {
                return response.data.data
            })
            NotificationService.notify("Latest data pulled without errors")
            setBusy(false)
        })
        .catch(error => {
            setBusy(false)
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
        })
    },[])

    return (
        <>
            { busy ? <Spinner /> :
                <div className="slim-mainpanel">
                    <div className="container">

                        <div class="main-header mg-b-30">
                            <h2 class="main-header-h-1 ">Wallet Transactions</h2>
                            <p class="signin-title-secondary">See how much you have earned from your agents' transactions, track to ensure you are earning correctly from all your agents' transactions.</p>
                        </div>

                        <div class="dashboard-inner pd-x-0-force">
                            <div class="row">
                                {/* <div  class="col-12 col-md-12 col-lg-6 mg-b-20">
                                   
                                    <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                        <div onClick={() => changeApp("cashout")} class="card-body">
                                            <p class="dashboard-card-title tertiary-color pd-t-10">Wallet balance :  {balance}
                                            </p>
                                            {/* <p class="main-header-p tertiary-color pd-b-20 blue-color">{balance}</p> 
                                            
                                                <button  class="btn light-button blue-color" disabled={balance > 0 ? false : true}>Cashout</button>
                                        </div>
                                    </div>
                                </div> */}

                                <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                    <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                       <a href="/aggregator/transaction" >
                                        <div class="card-body">
                                            <p class="dashboard-card-title tertiary-color pd-t-10">Wallet Transactions
                                            </p>
                                            {/* <p class="main-header-p tertiary-color pd-b-20 blue-color"></p> */}
                                            
                                            <a href="/aggregator/transaction">
                                                <button class="btn light-button blue-color" disabled={balance > 0 ? false : true}>View Wallet Transactions</button>
                                            </a>
                                            
                                        </div>
                                        </a>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                                        
                    </div>
                </div>
            }
        </>
    )
}

export default Main