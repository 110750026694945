import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import PublicHeader from "../components/PublicHeader";
import Footer from "./Footer";
import Spinner from "../components/Spinner";
import { Context } from "../contexts/MainContext";

import { UserService } from "../service/UserService";
import Confirmation from "../components/Confirmation";

function ForgotPassword() {
  const [busy, setBusy] = useState(false);
  const [info, setInfo] = useState(null);

  const { updateLoggedIn, changeProgress } = useContext(Context);

  function updateInfo(message) {
    setInfo((prev) => {
      return message;
    });
  }

  function updateBusy() {
    setBusy((prev) => {
      return !prev;
    });
  }

  const formik = useFormik({
    initialValues: {
      useremail: "",
    },
    validate: (what) => {
      //validate()
    },
    handleChange: () => {
      
      updateInfo(" ");
    },
    validationSchema: Yup.object({
      useremail: Yup.string()
        .email("Please enter a valid email")
        .required("Please enter your Username"),
    }),
    onSubmit: (values, { resetForm }) => {
      
      updateBusy();
      changeProgress(35);

      UserService.forgot(values)
        .then((response) => {
          
          updateInfo(response.data.message);
          changeProgress(100);
          updateBusy();
          resetForm();
        })
        .catch((error) => {
          
          changeProgress(100);
          updateBusy();
        });
    },
  });

  return (
    <>
      <PublicHeader />
      {busy ? (
        <Spinner />
      ) : ( info !== null ?
          <Confirmation message={info} header="Password Reset" /> :
        <div className="slim-body">
          <div className="signin-wrapper container">
            <div className="signin-box mg-b-40 col-lg-6">
              {/* <h2 className="primary-logo brand">
                    <a href="/"> <img className="text-center" src="assets/img/surebanka-logo.svg" alt=""/> </a>
                </h2> */}
              <h2 className="signin-title-primary loginHeader mg-b-0-force">
                Password Reset
              </h2>
              <p className="signin-title-secondary">
                Please enter your registered email to start the process.{" "}
              </p>
              <p className="forgot-pass">
                <a href="">
                  {" "}
                  <span className="pss-red">{info}</span>
                </a>{" "}
              </p>
              <div className="">
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                  <div className="floating-form">
                    <div className="floating-label">
                      <input
                        className="floating-input"
                        type="email"
                        placeholder=" "
                        autoComplete="none"
                        name="email"
                        autoFocus
                        {...formik.getFieldProps("useremail")}
                      />
                      <span className="highlight"></span>
                      <label>Email</label>
                    </div>
                    {formik.touched.useremail && formik.errors.useremail ? (
                      <p className="forgot-pass">
                        <a href="">
                          {" "}
                          <span className="pss-red">
                            {formik.errors.useremail}
                          </span>
                        </a>{" "}
                      </p>
                    ) : null}

                    <button className="btn-fill-md majorButton" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default ForgotPassword;
