import React, { useState } from 'react'
import useFormik from 'formik'
import Main from './Main'
import Bank from './Bank'
import Card from './Card'

function Topup ({changeApp}) {

    const [params, setParams] = useState("manage")
    const [message, setMessage] = useState('')

    function changeView(name) {
        changeApp(name)
    }

    function changeMessage(message){
        setMessage(prev => {
            return message
        })
    }

    return (
        <>
        {
            {
                'manage': <Main changeMessage={changeMessage} changeApp={changeApp} />,
                'bank': <Bank changeMessage={changeMessage} />,
                'card' : <Card changeMessage={changeMessage} />
            }[params] || <Main changeMessage={changeMessage} />
        }
            
        </>
    )
}

export default Topup