import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'
import Spinner from '../../../components/Spinner';

function Deposit ({changeApp}) {
    const [terminals, setTerminals] = useState([])
    const [banks, setBanks] = useState([])
    const [busy, setBusy] = useState(true)
    const { id } = JSON.parse(localStorage.getItem('user'));
    const [accountNumber, setAccountNumber] = useState('')
    const [bank, setBank] = useState('')

    const accountRegEx = /^\d{10}$/

    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    const bankOptions = banks.map(bank => {
        return(
            <option key={bank.bankCode} value={`${bank.bankCode}`}>{`${bank.bankName}`}</option>
        )
    })

    useEffect(() => {
        const term = AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })

        const bank = AgentService.getBankList()
        .then(response => {
            
            setBanks(prev => {
                return response.data.data.banks
            })

        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })

        Promise.all([bank,term].map(p => p.catch(e => e)))
        .then(results => {
            setBusy(false)
            
        })
        .catch(e => {
            
            setBusy(false)
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            amount : 0,
            accountName: '',
            beneficiaryAccountNumber: '',
            terminalID: '',
            beneficiaryBank: '',
            pin: '',
            agentCharge: 0,
            name: '',
            phone: '',
            message: ''
        },
        validate: (what) => {
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyErrorWithID(formik.errors.amount, 'amount', 3000)
                return;
            }
           
            if(formik.errors.beneficiaryAccountNumber && formik.touched.beneficiaryAccountNumber){
                NotificationService.notifyErrorWithID(formik.errors.beneficiaryAccountNumber, 'accountnumber',  3000)
                return;
            }
            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyErrorWithID(formik.errors.terminalID, 'terminal', 3000)
                return;
            }
            if(formik.errors.beneficiaryBank && formik.touched.beneficiaryBank){
                NotificationService.notifyErrorWithID(formik.errors.beneficiaryBank, 'beneficiaryBank',  3000)
                return;
            }
            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyErrorWithID(formik.errors.pin, 'pin',  3000)
                return;
            }
            if(formik.errors.agentCharge && formik.touched.agentCharge){
                NotificationService.notifyErrorWithID(formik.errors.agentCharge, 'charge', 3000)
                return;
            }
            if(formik.errors.message && formik.touched.message){
                NotificationService.notifyErrorWithID(formik.errors.message, 'message', 3000)
                return;
            }
            if(formik.errors.name && formik.touched.name){
                NotificationService.notifyErrorWithID(formik.errors.name, 'name',  3000)
                return;
            }
            if(formik.errors.phone && formik.touched.phone){
                NotificationService.notifyErrorWithID(formik.errors.phone, 'phone', 3000)
                return;
            }
            if(formik.errors.accountName && formik.touched.accountName){
                NotificationService.notifyErrorWithID(formik.errors.accountName, 'accountname', 3000)
                return;
            }
        },
        handleChange:() => {
          
        },
        validationSchema: Yup.object({
            amount: Yup.string()
              .required('Please enter the amount'),
            beneficiaryAccountNumber: Yup.string()
            .required('Please enter the Account Number')
            .matches(accountRegEx, 'Please enter a valid NUBAN Account Number'),
              //.required('Please enter the Account Number')
              //.test('len', 'Please use a 10 digit NUBAN Account Number', val => val.length === 10),
            accountName: Yup.string()
              .required('Please enter the Account Name'),
            terminalID: Yup.string()
                .required('Please select the Terminal ID'),
            beneficiaryBank: Yup.string()
                .required('Please select the Bank'),
            pin: Yup.string()
                .required('Please enter your PIN'),
            agentCharge: Yup.string()
                .required('Please your agent charge/fees'),
            name: Yup.string()
                .required('Please enter the Depositor Name'),
            phone: Yup.string()
                .required('Please enter the customer phone number'),
            message: Yup.string()
                .required('Please enter narration for this deposit')
          }),
        onSubmit: (values,{ resetForm }) => {  
             
            setBusy(true)         
            AgentService.depositTransaction({...values, narration: values.message, pin: values.pin})
            .then(response => {
                
                NotificationService.notify("The desposit transaction was completed successfully.")
                setBusy(false)
                changeApp('main')
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                    
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                    
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    
                }

                //NotificationService.notifyError("An Error occurred and we have been notified")
                setBusy(false)
            })
        }
      });

//----------------------------------------------------------------------------------------------------------------------------------------------------------
      function validateAccount(e){
         
          // console.log(formik.errors.beneficiaryBank + " " + formik.errors.beneficiaryAccountNumber + " in " + formik.values.accountName + "here " )
           //console.log(Object.keys(formik.touched).length)
           //console.log(accountNumber)

           if(formik.values.accountName && (formik.values.beneficiaryAccountNumber === accountNumber || formik.values.beneficiaryBank === bank )){
               //console.log("AccountName already set and nothing has changed")
               formik.handleChange(e) 
               return
           }
           else {
                formik.values.accountName = ''
                //console.log("AccountName does not exist yet")
                if(!formik.errors.beneficiaryBank && !formik.errors.beneficiaryAccountNumber && !formik.values.accountName && Object.keys(formik.touched).length !== 0  )
                {
                        //console.log("Get Account")
                        NotificationService.notifyInfo('Getting Account Details', 5000)
                        getAccountDetails(formik.values.beneficiaryAccountNumber, formik.values.beneficiaryBank, e)
                        formik.handleChange(e)
                }
                else{
                    //console.log("No need to get account page probably just loading")
                    formik.handleChange(e)   
                }
               
        }
      }

//----------------------------------------------------------------------------------------------------------------------------------------------------------
      function getAccountDetails(accountNumber, beneficiaryBank, e){
          setBusy(true)
          AgentService.getAccountDetails({accountNumber: accountNumber, beneficiaryBank: beneficiaryBank})
          .then(response => {
            
            setBusy(false)

            formik.values.accountName = response.data.data.accountName 
            formik.handleChange(e)

            setAccountNumber(prev => {
                return accountNumber
            })

            setBank(prev => {
                return beneficiaryBank
            })
            
          })
          .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
          })
      }

//----------------------------------------------------------------------------------------------------------------------------------------------------------
    return (
        <>
        { busy ? <Spinner /> :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                    </div>

                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Deposit</h2>
                            
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">

                                        <div className="row">
                                            <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10">
                                                <input  className="floating-input" type="number" placeholder=" "  name="amount" autoFocus {...formik.getFieldProps('amount')} />
                                                <span className="highlight"></span>
                                                <label>Amount</label>
                                            </div>

                                            <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10">
                                                <input  className="floating-input" type="password" placeholder=" "  name="pin"  {...formik.getFieldProps('pin')} />
                                                <span className="highlight"></span>
                                                <label>Terminal PIN</label>
                                            </div>
                                           
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input disabled={true} className="floating-input" type="text" placeholder=" "  name="accountName" {...formik.getFieldProps('accountName')}/>
                                                    <span className="highlight"></span>
                                                    <label>Account Name</label>
                                            </div>
                                            <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10">
                                                    <input onChange={e => validateAccount(e)}   className="floating-input" type="text" placeholder=" "  name="beneficiaryAccountNumber" value={formik.values.beneficiaryAccountNumber}/>
                                                    <span className="highlight"></span>
                                                    <label>Account Number</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                           <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10 ">
                                                <select onChange={e => validateAccount(e)} className="floating-input" placeholder="" name="beneficiaryBank" value={formik.values.beneficiaryBank} >
                                                <option value="" label="Select a Bank" />
                                                {bankOptions}
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Bank Name</label>
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                <option value="" label="Please select terminal for this transaction" />
                                                {terminalOptions}
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Terminal</label>                           
                                            </div>
                                        </div>

                                        <div className="row">
                                          
                                            <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10">
                                                <input  className="floating-input" type="number" placeholder=" "  name="agentCharge"  {...formik.getFieldProps('agentCharge')} />
                                                <span className="highlight"></span>
                                                <label>Agent Charge</label>
                                            </div>
                                            <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10">
                                                <input  className="floating-input" type="text" placeholder=" "  name="message"  {...formik.getFieldProps('message')} />
                                                <span className="highlight"></span>
                                                <label>Remarks</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10">
                                                <input  className="floating-input" type="text" placeholder=" "  name="name"  {...formik.getFieldProps('name')} />
                                                <span className="highlight"></span>
                                                <label>Depositor's Name</label>
                                            </div>
                                            <div onBlur={e => validateAccount(e)} className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="phone"  {...formik.getFieldProps('phone')} />
                                                <span className="highlight"></span>
                                                <label>Depositor's Phone Number</label>
                                            </div>
                                        </div>

                                        <button className="btn-fill-md majorButton" type="submit">Submit</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Deposit