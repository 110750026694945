import React, {useEffect, useState} from 'react'
import Main from './Main'
import View from './View'
import Create from './Create'
import Edit from './Edit';

function AdminAggregator  () {
    const [params, setParams] = useState("manage");
    const [aggregator, setAggregator] = useState({})

    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(aggregator){

        setParams(prev => {
            return "edit"
        })
        setAggregator(prev => {
            return aggregator
        })
    }
    

    return (
       <>
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'view': <View changeApp={changeApp}  onEdit={onEdit} />,
                'create' : <Create changeApp={changeApp} />,
                'edit' : <Edit changeApp={changeApp} aggregator={aggregator} />
            }[params] || <Main changeApp={changeApp} />
        }
       </>
    )
}

export default AdminAggregator