import React from 'react'
import {  toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Edit ({agent, changeUp}) {
      

     const formik = useFormik({
        initialValues: {
          firstname: agent.firstname,
          lastname: agent.lastname,
          email: agent.email,
          phonenumber: agent.phonenumber,
          address: agent.address,
          lga: agent.lga,
          state: agent.state,
          bankname: agent.bankname,
          accountname: agent.accountname,
          accountnumber: agent.accountnumber

        },
        validate: (what) => {
        },
        handleChange:() => {},
        validationSchema: Yup.object({
          email: Yup.string()
            .email('Please enter a valid email')
            .required('Please enter your Username'),
          password: Yup.string()
            .required('Please enter your Password')
        }),
        onSubmit: (values,{ resetForm }) => {
            
        }
      });

    return (
        <>
           <div class="slim-mainpanel">
                <div class="container">
                    
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeUp("view")} class="pss-blue pd-b-10">← Go Back</a>
                        <h2 class="main-header-h-1 ">Edit Agents</h2>
                    </div>

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <fieldset disabled={true}>
                                <div className="floating-form ">
                                
                                        <div className="row">

                                            <div className="floating-label col-md-6 ">
                                                <input className="floating-input" type="text" placeholder=" " name="firstname" autoComplete="none" autoFocus {...formik.getFieldProps('firstname')} />
                                                <span className="highlight"></span>
                                                <label>First Name</label>
                                            </div>
                                            {formik.touched.firstname && formik.errors.firstname ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.firstname}</span></a> </p>) : null}

                                            <div className="floating-label col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="lastname" autoComplete="none" {...formik.getFieldProps('lastname')} />
                                                <span className="highlight"></span>
                                                <label>Last Name</label>
                                            </div>
                                            {formik.touched.lastname && formik.errors.lastname ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.lastname}</span></a> </p>) : null}

                                        </div>

                                        <div className="row" >
                                            <div className="floating-label col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="email" autoComplete="none" {...formik.getFieldProps('email')} />
                                                <span className="highlight"></span>
                                                <label>Email Address</label>
                                            </div>
                                            {formik.touched.email && formik.errors.email ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.email}</span></a> </p>) : null}
                                        

                                            <div className="floating-label col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="phonenumber" {...formik.getFieldProps('phonenumber')} />
                                                <span className="highlight"></span>
                                                <label>Phone Number</label>
                                            </div>
                                            {formik.touched.phonenumber && formik.errors.phonenumber ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.phonenumber}</span></a> </p>) : null}
                                        </div>

                                        <div className="row">
                                        
                                        </div>
                                </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Edit