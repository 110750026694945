/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import Agent  from '../../../components/agent/Agent'
import { AdminService} from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'

function View  ({changeApp, onEdit}) {
    const { id } = JSON.parse(localStorage.getItem('user'))
    const [agents, setAgents] = useState([]);
    const [busy, setBusy] = useState(true)

     useEffect(() => {
        AdminService.getAllAgents({aggregatorId : id.toString()})
        .then(response => {
            console.log(response.data)
            setAgents(prevAgents => {
                return response.data
            });
            
            if(response.data.length > 0){
                NotificationService.notify("The number of Agents you have is " + response.data.length);
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("You do not have any Agents assigned");
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[]);

    return (
       <>
        {busy ? <Spinner/> : 
        <>
            <div className="slim-mainpanel">
                <div className="container">
                        <div className="main-header">
                            <a href="#" onClick={() =>  changeApp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                            <h2 className="main-header-h-1 ">View Agents</h2>
                        </div>
                    <Agent agents={agents} onEdit={onEdit} /> 
                </div>
            </div>
        </>
        
        }
       </>
    )
}

export default View