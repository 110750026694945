import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SuperAdminService } from '../../../service/SuperAdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import moment from 'moment'
import Details from '../../../components/transaction/Details'

function Search({ changeApp }) {
    const [transaction, setTransaction] = useState([]);
    const [found, setFound] = useState(false)
    const [busy, setBusy] = useState(false)

    useEffect(() => {

    }, [])

    const formik = useFormik({
        initialValues: {
            rrn: '',
            date: '',

        },
        validate: (what) => {

            if (formik.errors.rrn && formik.touched.rrn) {
                NotificationService.notifyErrorWithID(formik.errors.rrn, "rrn", 5000)
                return;
            }
            if (formik.errors.date && formik.touched.date) {
                NotificationService.notifyErrorWithID(formik.errors.date, "date", 5000)
                return;
            }
        },
        handleChange: () => {

        },
        validationSchema: Yup.object({
            rrn: Yup.string()
                .required('Please enter the RRN for this transaction'),
            date: Yup.string()
                .required('Please select the Transaction Date'),

        }),
        onSubmit: (values, { resetForm }) => {
            //console.log(values)  
            setBusy(true)

            const searchParams = {

                endDate: moment(values.date).add(1, 'days').format('yyyy/MM/DD'),
                startDate: moment(values.date).format('yyyy/MM/DD'),
                rrn: values.rrn
            }
            //console.log(searchParams)

            SuperAdminService.getTransactionByRRN(searchParams)
                .then(response => {
                    console.log(response.data.data)

                    if (response.data.data.length > 0) {

                        const tran = response.data.data[0]

                        setTransaction(prev => {
                            return tran
                        });

                        NotificationService.notify("The Transaction was found in the database, find details below");

                        setFound(true)
                    }
                    else {
                        NotificationService.notifyError("No transaction was found for the specified Date and RRN.", 5000);
                    }

                    setBusy(false)
                })
                .catch(error => {
                    if (error.response) {
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if (error.message) {
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else {
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)
                })
        }
    });

    return (
        <>
            {
                <div className="slim-mainpanel no-bg-img">
                    <div className="container">
                        <div class="main-header">
                            <a href="#" onClick={() => changeApp("manage")} class="pss-blue pd-b-10">← Go Back(Use this menu to go back)</a>
                        </div>
                        <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Search Transaction</h2>
                        </div>
                        {busy ? <Spinner /> :
                            found ? <Details changeApp={changeApp} transaction={transaction} /> :
                                <div className="dashboard-card-bg  pd-30">
                                    <form autoComplete="off" onSubmit={formik.handleSubmit} >
                                        <div className="floating-form">
                                            <div className="row">
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="text" placeholder=" " name="rrn" autoFocus {...formik.getFieldProps('rrn')} />
                                                    <span className="highlight"></span>
                                                    <label>RRN</label>
                                                </div>
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="date" name="date" {...formik.getFieldProps('date')} />

                                                    <span className="highlight"></span>
                                                    <label>Transaction Date</label>
                                                </div>
                                            </div>

                                            <button className="btn btn-primary" type="submit">Search</button>

                                        </div>
                                    </form>
                                </div>

                        }
                    </div>
                </div>

            }

        </>
    )
}

export default Search