import React, { useState, useEffect } from 'react'
import Tran from '../../../components/transaction/Tran'
import { SuperAdminService} from '../../../service/SuperAdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function DepositStatus ({changeApp}) {
    
    const [busy, setBusy] = useState(false)
    const [status, setStatus] = useState('')

    useEffect(() => {

    }, [])

    const formik = useFormik({
        initialValues: {
          rrn: ""
        },
        validate: (what) => {

            if(formik.errors.rrn && formik.touched.rrn){
                NotificationService.notifyErrorWithID(formik.errors.rrn, "rrn", 5000)
                return;
            } 
        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
        rrn: Yup.string()
            .required('Please enter the RRN')

        }),
        onSubmit: (values,{ resetForm }) => {         
            console.log(values)  
            setBusy(true)

            SuperAdminService.checkPendingTransactionStatusByRRN({RRN: values.rrn})
            .then(response => {
                console.log(response.data.data.responseCode)
                setBusy(false)
                NotificationService.notifyInfo(response.data.data.responseMessage)

                setStatus(prev => {
                    return "Code/Message : " + response.data.data.responseCode + " " + response.data.data.responseMessage
                })
                
                
                
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)

            })
        }
      });   

    return (
        <>
        {
             busy ? <Spinner /> :
             <div className="slim-mainpanel no-bg-img">
             <div className="container">
                 <div class="main-header">
                     <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                 </div>
                 <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Check Deposit Status</h2>
                        
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="rrn" autoFocus {...formik.getFieldProps('rrn')} />
                                                <span className="highlight"></span>
                                                <label>Deposit RRN</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input disabled='true' className="floating-input" type="text" placeholder=" "  value={status} />
                                                <span className="highlight"></span>
                                                <label>Status</label>
                                            </div>
                                           
                                        
                                        </div>

                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
             </div>
          </div>
        }

        </>
    )
}

export default DepositStatus