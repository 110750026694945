import React, { useState, useEffect } from 'react'
import Spinner from '../../../components/Spinner'
import { AdminService } from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'

function Dashboard () {
    const [busy, setBusy] = useState(true)
    const [agents, setAgents] = useState(0)
    const [aggregators, setAggregators] = useState(0)
    const [requests, setRequests] = useState(0)

//---------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        //------------------------------------
        AdminService.getAllAgents()
        .then(response => {
            
            setAgents(prev => {
                return response.data.length;
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

        //--------------------------------------
        AdminService.getAllAggregators()
        .then(response => {
            
            setAggregators(prev => {
                return response.data.length;
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
        //--------------------------------------
        AdminService.getRequests()
        .then(response => {
            console.log(response)
            setRequests(prev => {
                return response.data.data.length;
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])

    //--------------------------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            {busy ? <Spinner /> :
                <div className="slim-mainpanel">
                    <div className="container">

                        <div class="main-header mg-b-30">
                            <h2 class="main-header-h-1 ">Admin Dashboard</h2>
                            <p class="signin-title-secondary">Paying your bill does not have to be a stressful task. Please select your
                                payment category or search for your biller to make a bill payment.</p>
                        </div>

                        <div class="dashboard-inner pd-x-0-force">
                            <div class="row">

                                <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                    <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                        <a href="/admin/agent" >
                                        <div class="card-body">
                                            <p class="dashboard-card-title tertiary-color pd-t-10">Agent Total
                                            </p>
                                            <p class="main-header-p tertiary-color pd-b-20 blue-color">{agents}</p>
                                            <a href="/admin/agent" data-toggle="modal" data-target="#modaldemo4"><button
                                                    class="btn light-button blue-color">View Agents</button></a>
                                        </div>
                                        </a>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                    <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <a href="/admin/aggregator" >
                                        <div class="card-body">
                                            <p class="dashboard-card-title tertiary-color pd-t-10">Aggregator Total
                                            </p>
                                            <p class="main-header-p tertiary-color pd-b-20 blue-color">{aggregators}</p>
                                            <a href="/admin/aggregator"><button
                                                    class="btn light-button blue-color">View Aggregator</button></a>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                                
                            
                            </div>

                            <div className="row">
                                <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                        <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                        <a href="/admin/dispute">
                                            <div class="card-body">
                                                <p class="dashboard-card-title tertiary-color pd-t-10">Approval Requests Total
                                                </p>
                                                <p class="main-header-p tertiary-color pd-b-20 blue-color">{requests}</p>
                                                <a href="/admin/dispute" data-toggle="modal" data-target="#modaldemo4"><button
                                                        class="btn light-button blue-color">View Requests</button></a>
                                            </div>
                                        </a>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                        <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                        <a href="/admin/dispute" >
                                            <div class="card-body">
                                                <p class="dashboard-card-title tertiary-color pd-t-10">Issue Log Total
                                                </p>
                                                <p class="main-header-p tertiary-color pd-b-20 blue-color">{requests}</p>
                                                <a href="/admin/dispute" data-toggle="modal" data-target="#modaldemo4"><button
                                                        class="btn light-button blue-color">View Issues</button></a>
                                            </div>
                                            </a>
                                        </div>
                                </div>

                            </div>
                        </div>
                                        
                    </div>
                </div>
            }
        </>
    )
}

export default Dashboard