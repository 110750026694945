import React,{useEffect, useState} from 'react'
import View from './View'
import Edit from './Edit'

function Agent ({changeApp}) {

    const [params, setParams] = useState("view")
    const [agent, setAgent] = useState({});

    function changeUp(route){
        console.log(route)
        if(route === "manage"){
            changeApp(route)
        }
        else{
            setParams(prev => {
                return route
            })
        }
    }

    function updateAgent(agent){
        setAgent(prevAgent => {
            return agent
        })

        setParams(prev => {
            return "edit"
        })
    }
    
        return (
            <>
                {
                    {
                        'view' : <View changeUp={changeUp} updateAgent={updateAgent} />,
                        'edit' : <Edit changeUp={changeUp} agent={agent}  />
                    }[params] || <View changeUp={changeUp}  />
                }

            </>   
    
    )
}


export default Agent