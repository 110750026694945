import React, { useState, useEffect } from 'react'
import { SuperAdminService} from '../../../service/SuperAdminService'
import { AdminService} from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'
import moment from 'moment'

function Performance ({changeApp}) {

    const [busy, setBusy] = useState(true);
    const [transactions, setTransactions] = useState([]);

    let uniqueTerminals = []
    let terminalPerformances = []

    const searchParams = {
       
        endDate: moment().add(1, 'days').format('yyyy/MM/DD'),
        startDate: moment().subtract('days', 2).format('yyyy/MM/DD'),
        lastWeek: false,
        lastMonth: false
    }

    if( transactions.length > 0){
            uniqueTerminals = [...new Set(transactions.map(transaction => ( transaction.terminalID ? transaction.terminalPhysicalID : false)))];

            // console.log(uniqueTerminals)
            uniqueTerminals.map(terminal => {
                let terminalPerformance = {}
                let totalWithdrawal = 0

                //Get all transactions that qualify
                const trans = transactions.filter( transaction => (transaction.TransactionType.includes("Withdrawal") || transaction.TransactionType.includes("Deposit")) 
                                                                    && transaction.terminalPhysicalID.includes(terminal) 
                                                                    && transaction.transactionStatusID.name.includes("Successful"))

                const depositCount = trans.filter( transaction => transaction.TransactionType.includes("Deposit"))

                const withdrawals = trans.filter( transaction => transaction.TransactionType.includes("Withdrawal"))

                // console.log(depositCount.length + withdrawals.length)

                
                totalWithdrawal = withdrawals.map( transaction => {
                    // if(transaction.amount){
                    //     totalWithdrawal = totalWithdrawal + transaction.amount
                    //     console.log(totalWithdrawal)
                    // }
                    // else{
                    //     console.log("No amount, got : " + transaction.amount)
                    // }
                    //console.log(totalWithdrawal + transaction.amount)
                    // console.log(typeof transaction.amount)
                    
                    totalWithdrawal = totalWithdrawal + transaction.amount
                    
                })

                console.log(totalWithdrawal)
                // terminalPerformance.terminalPhyscialId = terminal;
                // terminalPerformance.agent = 

            })

        }
        else{
            console.log("No data yet")
        }
    

    useEffect(() => {
        console.log(searchParams)
        AdminService.getTransactionForAdmin(searchParams)
        .then(response => {
            console.log(response.data.data)

            if(response.data.data.length > 0){
                setTransactions(prev => {
                    return response.data.data
                });
                
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("No transactions for the last 3 days");
                }
            setBusy(false)
           
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
        
    }, [])

    function getReport(){
        setBusy(true)
        SuperAdminService.getTerminalReport()
        .then(response => {
                
            NotificationService.notify("The report has been sent successfully");                
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }

    return (
       <>
                
                { busy ? <Spinner /> :
                
                <div className="slim-mainpanel">
                    <div className="container">
                        <div class="main-header">
                            <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                        </div>
                </div>
            </div>
        }
    </>

)
    
}

export default Performance