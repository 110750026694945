import React,{useState} from 'react'
import CreateTerminal from '../../../components/terminal/CreateTerminal'

function Create ({changeApp}) {
    
    

    return (
           <>
           <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                    </div>
                    <CreateTerminal />
                </div>
             </div>
           </>
        )
    }

export default Create