import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SuperAdminService} from '../../../service/SuperAdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import moment from 'moment'
import Details from '../../../components/transaction/Details'
import { CSVLink, CSVDownload } from "react-csv"

function Airtime ({ changeApp }) {
    
    const [busy, setBusy] = useState(false)   

    const formik = useFormik({
        initialValues: {
            referenceID: '',

        },
        validate: (what) => {

            if(formik.errors.referenceID && formik.touched.referenceID){
                NotificationService.notifyErrorWithID(formik.errors.referenceID, "referenceID", 5000)
                return;
            }
        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
           referenceID: Yup.string()
            .required('Please enter the Reference Number')

        }),
        onSubmit: (values,{ resetForm }) => {         
            //console.log(values)  
            setBusy(true)

            SuperAdminService.getPrimeTimeTransactionStatus({refNo: values.referenceID})
            .then(response => {
                console.log(response.data.message)
                        
                    NotificationService.notifyInfo(`The airtime transaction ${values.referenceID} was found with details : ${response.data.message.message}, Operator is ${response.data.message.operator_name}, Amount is ${response.data.message.topup_amount} `, 200000);
                    setBusy(false)

            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });
    
    return (
        <>
        {
             <div className="slim-mainpanel no-bg-img">
             <div className="container">
                 <div class="main-header">
                     <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back(Use this menu to go back)</a>
                 </div>
                 <div className="pd-x-5">
                    <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Search Transaction</h2>                        
                    </div>
                { busy ? <Spinner /> : 
                    <div className="dashboard-card-bg  pd-30">
                                    <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                        <div className="floating-form">
                                           

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="text" placeholder=" "  name="referenceID" autoFocus {...formik.getFieldProps('referenceID')} />
                                                    <span className="highlight"></span>
                                                    <label>Reference Number</label>
                                            </div>

                                            <button className="btn btn-primary" type="submit">Search</button>

                                           
                                        
                                        </div>
                                    </form>


                    </div>
                    }
             </div>
          </div>


        
        }

        </>
    )
}

export default Airtime