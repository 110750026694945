import React, { useEffect, useState } from 'react'
import { AgentService } from '../../../service/AgentService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'

function Main ({changeApp}) {
    const [busy, setBusy] = useState(true)
    const [balance, setBalance] = useState("")
    const { id } = JSON.parse(localStorage.getItem('user'))

    const formatter = new Intl.NumberFormat('en-US');

    useEffect(() => {
        AgentService.getAgentBalance({agentId: id})
        .then(response => {
            
            setBusy(false)

            setBalance(prev => {
                return response.data.data
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])

    return (
      <>
      {busy ? <Spinner /> : 
        <div className="slim-mainpanel">
                <div className="container">

                    <div className="main-header mg-b-30">
                        <h2 className="main-header-h-1 ">Wallet Transactions</h2>
                        <p className="signin-title-secondary">Welcome, this is where you process Interbank transfers, fund or top-up your wallet, transfer from one wallet to another and cashout your funds.</p>
                    </div>

                    <div className="dashboard-inner pd-x-0-force">
                        <div className="row">

                            <div  className="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div className="card pssCard basic-card pd-b-30 pd-x-5">
                                    <a href="#">
                                    <div className="card-body">
                                        <p className="dashboard-card-title tertiary-color pd-t-10">Wallet balance
                                        </p>
                                        
                                        <p className="main-header-p tertiary-color pd-b-20 blue-color">{formatter.format(balance)}</p>
                                        {/* <button   onClick={() => changeApp("cashout")} className="btn light-button blue-color">Cashout</button> */}
                                    </div>
                                    </a>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    
                    <div  className="row mg-t-20">
                        <div onClick={() => changeApp("deposit")} className="col-6 col-md-6 col-lg-3">
                            
                                <div className="card pssCard bd-0 mg-b-30">
                                <a href="#">
                                    <div className="card-body ">
                                   
                                    <p className="user-brand-name">DEPOSIT</p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">Send money to other bank accounts from your wallet</p>
                                    </div>
                                </a>
                                </div>
                        
                        </div>
                   
                        {/* <div onClick={() => changeApp("topup")} className="col-6 col-md-6 col-lg-3">                            
                                <div className="card pssCard bd-0 mg-b-30">
                                    <a href="#">
                                        <div className="card-body ">
                                        
                                        <p className="user-brand-name">WALLET TOPUP</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Fund your wallet from your bank account, with your card etc</p>
                                        </div>
                                    </a>
                                </div>
                            
                        </div> */}
                        
                        <div onClick={() => changeApp("transfer")} className="col-6 col-md-6 col-lg-3">
                            
                            <div className="card pssCard bd-0 mg-b-30">
                            <a href="#">
                                <div className="card-body ">
                                
                                <p className="user-brand-name">WALLET TRANSFER</p>
                                <p className="user-brand-text-2 tx-small mg-b-10">Wallet to Wallet Transfers from your Surebanka Account</p>
                                </div>
                            </a>
                            </div>
                        
                        </div>

                        {/* <div onClick={() => changeApp("cashout")} className="col-6 col-md-6 col-lg-3">
                            
                            <div className="card pssCard bd-0 mg-b-30">
                            <a href="#">
                                <div className="card-body ">
                                
                                <p className="user-brand-name">WALLET CASHOUT</p>
                                <p className="user-brand-text-2 tx-small mg-b-10">Withdraw money to your registered bank account</p>
                                </div>
                            </a>
                            </div>
                        
                        </div> */}
                    </div>
                                    
                </div>
            </div>
    }
      </>
    )
}

export default Main