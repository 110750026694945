import React, { useState, useEffect } from 'react'
import './Transaction.css'
import {useFormik} from 'formik'
import {NotificationService} from '../../service/NotificationService'
import { CSVLink, CSVDownload } from "react-csv"
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import Pagination from '../pagination/Pagination';
import _ from 'underscore'
 


function View({transactions, changeApp,  updateTransaction}) {
    const [terminals, setTerminals] = useState([]);
    const [types, setTypes] = useState([]);
    const [transactionsLocal, setTransactionsLocal] = useState([])
    const [activePage, setActivePage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [transactionsPerPage, setTransactionsPerPage] = useState(50)
    let transactionElements

    const { usertype}  = JSON.parse(localStorage.getItem('user'))

    const formatter = new Intl.NumberFormat('en-US');

    var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    const indexOfLastTransaction = currentPage * transactionsPerPage
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage
    const currentTransactions = transactionsLocal.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const renderTransactions = currentTransactions.map((tran, index) => {
        return <li key={index}>{tran}</li>;
    });

    //Logic for displaying Page Numbers
    const pageNumbers = []
    for(let i = 1; i <= Math.ceil(transactionsLocal.length/ transactionsPerPage); i++){
        if( i <= 10){
        pageNumbers.push(i);
        }
    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
          <li className="paginate_button"
            key={number}
            id={number}
            onClick={paginationClick}
            href="#"
          >
            {number}
          </li>
        );
      });

    

    //-----------------------------------------------------------------------------------------------------------
    function paginationClick(event){
        console.log(event.target.id)
        setCurrentPage(prev => {
            return Number(event.target.id)
        })
    }

    //-----------------------------------------------------------------------------------------------------------
    const csvData = transactionsLocal.map(transaction => {
        return (
            {
                TransactionID : transaction._id,
                ReferenceNumber: transaction.rrn,
                Amount : transaction.amount,
                BalanceBefore : transaction.openingBalance,
                BalanceAfter : transaction.closingBalance,
                ResponseCode : transaction.responseCode,
                ResponseMessage : transaction.responseDescription,
                SettlementAmount : transaction.settlementAmount,
                Authcode : transaction.authCode,
                DateCreated : new Date(transaction.dateCreated).toUTCString(),
                TerminalId : transaction.terminalID ? transaction.terminalID.terminalPhysicalId : "",
                Agent: transaction.terminalID ? transaction.terminalID.terminalOwner?.firstname + " " + transaction.terminalID.terminalOwner?.lastname : "",
                TransactionType : usertype != "SuperAdmin" ? transaction.transactionTypeID.description : transaction.TransactionType,
                AgentCharge: transaction.agentCharge,
                Channel: transaction.channel,
                Narration: transaction.narration,
                STAN: transaction.stan,
                RRN: transaction.rrn,
                Type: transaction.type,
                Status: transaction.transactionStatusID ? transaction.transactionStatusID.name : "",
                PAN: transaction.pan,
                LicenseOwnerCharge: usertype === "SuperAdmin" || usertype === "LicenseOwner"  ? transaction.licenseOwnerCharge : "",
            }
        )
    })

   
    
    //-----------------------------------------------------------------------------------------------------------
    if(transactionsLocal.length !== 0 ){
        //console.log(transactions)
        transactionElements = {};
        transactionElements = transactionsLocal.slice(indexOfFirstTransaction, indexOfLastTransaction).map(transaction => {
        return (
            <tr key={transaction.dateCreated} onClick={() => {changeApp('details'); updateTransaction(transaction);}}>
                <td><a href="#">{transaction.terminalID ? transaction.terminalID.terminalPhysicalId : ""}</a></td>
                <td><a href="#">{transaction.rrn}</a></td>
                <td><a href="#">{formatter.format(transaction.amount.toFixed(2))}</a></td>
                <td><a href="#">{transaction.openingBalance ? formatter.format(transaction.openingBalance.toFixed(2)) : ""}</a></td>
                <td><a href="#">{transaction.closingBalance ? formatter.format(transaction.closingBalance.toFixed(2)) : ""}</a></td>
                { usertype === "Agent" ? <td><a href="#">{ transaction.agentCharge ? formatter.format(transaction.agentCharge.toFixed(2)): 0}</a></td> : null }
                { usertype === "Aggregator" ? <td><a href="#">{formatter.format(transaction.commission.toFixed(2))}</a></td> : null }
                { usertype === "Aggregator" ? null : <td><a href="#">{formatter.format(transaction.settlementAmount.toFixed(2))}</a></td> }
                <td><a href="#">{transaction.responseCode}</a></td>
                <td><a href="#">{transaction.responseDescription}</a></td>
                <td><a href="#">{transaction.type }</a></td>
                <td><a href="#">{transaction.transactionStatusID ? transaction.transactionStatusID.name : "" }</a></td>
                {/* <td><a href="#">{transaction.transactionStatusID.name}</a></td> */}
                {
                    usertype != "SuperAdmin" ?
                    <td><a href="#">{transaction.transactionTypeID ? transaction.transactionTypeID.description : " "}</a></td> :
                    <td><a href="#">{transaction.TransactionType }</a></td>
                }
                <td><a href="#">{transaction.channel}</a></td>
                {/* <td><a href="#">{new Date(transaction.dateCreated).toLocaleString('en-US')}</a></td>             */}
                <td><a href="#">{new Date(transaction.dateCreated).toUTCString()}</a></td>
                { usertype === "LicenseOwner" || usertype === "SuperAdmin" ? <td><a href="#">{formatter.format(transaction.licenseOwnerCharge?.toFixed(2))}</a></td> : null}
            </tr>
            
          )
        })
    }
    //-----------------------------------------------------------------------------------------------------------
    useEffect(() => {

        const sorted = _.sortBy(transactions, 'dateCreated')
        setTransactionsLocal(prev => {
            return sorted.reverse()
                
        })
    },[])

    //-----------------------------------------------------------------------------------------------------------
    const formik = useFormik({
        initialValues: {
            startDate : '',
            endDate: '',
            terminalid: '',
            status: '',
            rrn: '',
            type: '',

        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });

//Gets All Unique Terminals from the transactions sent in
    const uniqueTerminals = [...new Set(transactions.map(transaction => transaction.terminalID ? transaction.terminalID.terminalPhysicalId : false))];

    const terminalElements = uniqueTerminals.map(terminal => {
        return (
            <option key={terminal} value={`${terminal}`}>{`${terminal}`}</option>
        )
    })
    //-----------------------------------------------------------------------------------------------------------

    //Gets All Transaction Types from the Transactions sent in 
    let uniqueTypes = [];
    if(usertype !== "SuperAdmin"){
        uniqueTypes = [...new Set(transactions.map(transaction => transaction.transactionTypeID ? transaction.transactionTypeID.description : false))];
    }
    else{
        uniqueTypes = [...new Set(transactions.map(transaction => transaction.TransactionType))];
    }
    
    const typeElements = uniqueTypes.map(type => {
        return (
            <option key={type} value={`${type}`}>{`${type}`}</option>
        )
    })
    //----------------------------------------------------------------------------------------------------------
    
    //Gets All Transaction Status from the Transactions sent in
    const uniqueStatus = [...new Set(transactions.map(transaction => transaction.transactionStatusID ? transaction.transactionStatusID.name : false))];

    const statusElements = uniqueStatus.map(status => {
        return (
            <option key={status} value={`${status}`}>{`${status}`}</option>
        )
    })
    // //----------------------------------------------------------------------------------------------------------

    function dateFilter(e){
        formik.handleChange(e);
        
        let startDate = moment(document.getElementsByName('startDate')[0].value)
        let endDate = moment(document.getElementsByName('endDate')[0].value)
        
        console.log(startDate.format("yyyy/MM/DD h:mm:ss a") + " - " + endDate)

        startDate = startDate.startOf('day')
        endDate = endDate.endOf('day')

        //console.log(startDate.format("yyyy/MM/DD h:mm:ss a") + " - " + endDate)
        //console.log(moment(transactions[0].dateCreated))

        if(startDate.isAfter(endDate))
        {
            NotificationService.notifyError("Transaction Start Date cannot be greater than End Date");
            return;
        }

        const filtered = transactionsLocal.filter( transaction => moment(transaction.dateCreated).isAfter(startDate) && moment(transaction.dateCreated).isBefore(endDate))
        console.log(filtered)
        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The Start Date and End Date combination does not match any transaction", 5000);
            //clearFilters()            
        }


    }

    //Filter on RRN----------------------------------------------------------------------------------------------------------
    function rrnFilter(e){
        formik.handleChange(e);
        const filtered = transactionsLocal.filter( transaction => transaction.rrn ? transaction.rrn.toLowerCase().includes(e.target.value.toLowerCase()) : null )

        
        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered for Reference Number does not match any value", 5000);
            //clearFilters()
            
        }
    }
    //----------------------------------------------------------------------------------------------------------
    function statusFilter(e){
        formik.handleChange(e);
        const filtered = transactionsLocal.filter( transaction => transaction.transactionStatusID ? transaction.transactionStatusID.name.toLowerCase().includes(e.target.value.toLowerCase()) : false )

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search paramter does not match any value", 5000);
            //clearFilters()
           
        }
    }
     //----------------------------------------------------------------------------------------------------------
     function typeFilter(e){
        formik.handleChange(e);
        let filtered = [] 
        if( usertype !== "SuperAdmin"){
            filtered= transactionsLocal.filter( transaction => transaction.transactionTypeID.description.toLowerCase().includes(e.target.value.toLowerCase()) )
        }
        else {
            filtered= transactionsLocal.filter( transaction => transaction.TransactionType.toLowerCase().includes(e.target.value.toLowerCase()) )
        }

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search parameter does not match any value", 5000);
           // clearFilters()
        }
          
    }
    //----------------------------------------------------------------------------------------------------------
    function terminalFilter(e){
        formik.handleChange(e);
        const filtered = transactionsLocal.filter( transaction => transaction.terminalID.terminalPhysicalId.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search parameter does not match any value", 5000);
            //clearFilters()
           
        }
    }

    function clearFilters(){
            
            formik.resetForm({values: ''})
            setTransactionsLocal(prev => {
                return transactions
            })
    }

    return (
        <div className="slim-mainpanel no-bg-img">
            <div className="container">
                    <div className="row table-overall-headings mg-b-10">
                        <div className="col-md-6">
                            <h2>Transactions</h2>
                            <p>View and download all transactions easily here</p>
                        </div>
                    </div>
                    <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form >
                               
                                <div className="floating-form">
                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.startDate} name="startDate" />
                                            
                                            <span className="highlight"></span>
                                            <label>Start Date</label>
                                        </div>
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.endDate} name="endDate"  />
                                            
                                            <span className="highlight"></span>
                                            <label>End Date</label>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10">
                                        <input onChange={e => rrnFilter(e)} className="floating-input" type="text" value={formik.values.rrn} name="rrn" autoFocus />
                                        <span className="highlight"></span>
                                        <label>Reference Number</label>
                                    </div>
                                    <div className="floating-label tran col-md-6 pd-b-10">
                                        <select onChange={e => statusFilter(e)}  name="status" className="floating-select" value={formik.values.status}>
                                            <option value="" label="Select a Status" />
                                            {statusElements}
                                        </select>
                                        <span className="highlight"></span>
                                        <label>Status</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="floating-label tran  col-md-6 pd-b-10">
                                        <select onChange={e => terminalFilter(e)} name="terminalid" className="floating-select" value={formik.values.terminalid}>
                                            <option value="" label="Select a Terminal" />
                                            {terminalElements}
                                        </select>
                                        <span className="highlight"></span>
                                        <label>Select Terminal</label>
                                    </div>

                                    <div className="floating-label tran  col-md-6 pd-b-10">
                                        <select onChange={e => typeFilter(e)} name="type" className="floating-select" value={formik.values.type}>
                                            <option value="" label="Select a Transaction Type" />
                                            {typeElements}
                                        </select>
                                        <span className="highlight"></span>
                                        <label>Transaction Type</label>
                                    </div>


                                </div>
                                
                                <div className="btn-filter">
                                    {/* <a href="#"><button className=" btn-fill-md majorButton">Download Transactions</button></a> */}
                                   <button type="button" onClick={e => clearFilters()} className=" btn-fill-md majorButton">Clear Filters</button>
                                </div>

                                <div className="btn-filter">
                                    {/* <a href="#"><button className=" btn-fill-md majorButton">Download Transactions</button></a> */}
                                    <CSVLink data={csvData}><button type="button" className=" btn-fill-md majorButton">Download Transactions</button></CSVLink>
                                </div>
                                </div>
                                
                                {/* <div className="btn-filter">
                                <a href="#"><button className=" btn-fill-md majorButton">Filter</button></a>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    </div>
                    <div className="table-wrapper table-responsive">
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                    <th className="">TerminalID</th>
                                    <th className="">Reference</th>
                                    <th className="">Amount</th>
                                    <th className="">Balance Before</th>
                                    <th className="">Balance After</th>
                                    { usertype === "Agent" ? <th className="">Agent Charge</th> : null}
                                    { usertype === "Aggregator" ? <th className="">Commission</th> : null}
                                    { usertype === "Aggregator" ? null : <th className="">Settlement</th>}
                                    <th className="">Code</th>
                                    <th className="">Message</th>
                                    <th className="">TranType</th>
                                    <th className="">Status</th>
                                    {/* <th className="">Status</th> */}
                                    <th className="">Type</th>
                                    <th className="">Channel</th>
                                    <th className="">Date</th>
                                    { usertype === "LicenseOwner" || usertype === "SuperAdmin" ? <th className="">LicenseCharge</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                               {transactionElements}
                            </tbody>
                        </table>
                    </div>                    
                    
                        <div className="dataTables_wrapper form-inline dt-bootstrap no-footer" >
                            

                            <div className="col-sm-7">
                                    <div className="dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {renderPageNumbers}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    
                   
                    
                </div>
            </div>
            )
        }
        
export default View