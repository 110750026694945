import React, { useState } from 'react'
import CSVReader from 'react-csv-reader'
import { NotificationService } from '../../../service/NotificationService'
import differenceBy from 'lodash/differenceBy'
import { CSVLink, CSVDownload } from "react-csv"


function Withdrawal(props) {
    const [settlement, setSettlement] = useState([]);
    const [surebanka, setSurebanka] = useState([]);
    const [overages, setOverages] = useState([])
    const [shortages, setShortages] = useState([])
    const [ surebankaTotal, setSurebankaTotal] = useState(0)
    const [ settlementTotal, setSettlementTotal] = useState(0)
    const [ settlementFailed, setSettlementFailed] = useState(0)
    const [ surebankaFailed, setSurebankaFailed] = useState(0)
    const [ settlementSuccessful, setSettlementSuccessful] = useState(0)
    const [ surebankaSuccessful, setSurebankaSuccessful] = useState(0)
    const [ matchedTransactions, setMatchedTransactions] = useState([])
    const [ autoReversed, setAutoReversed] = useState([]);

    function computeRecon(){
    
        const shortage = differenceBy(surebanka, settlement, 'rrn'); 

        const overage = differenceBy(settlement, surebanka, 'rrn');

        const settlementFail = settlement.filter(transaction => {
            return transaction.status !== "APPROVED"
        })

        const surebankaFail = surebanka.filter(transaction => {
            return transaction.responseCode !== "00"
        })

        const settlementSuccess = settlement.filter(transaction => {
            return transaction.status === "APPROVED"
        })

        const surebankaSuccess = surebanka.filter(transaction => {
            return transaction.responseCode === "00"
        })

        let matched = surebanka.filter(transaction => !settlement.some(settle => transaction.rrn === settle.rrn) && transaction.responseCode === "00")

        const reversed = surebankaFail.map(transaction => {
            
            settlement.map(settle => {

            })
        })


        setShortages(prev => {
            return shortage
        });

        setOverages(prev => {
            return overage
        })

        setSettlementFailed(prev => {
            return settlementFail.length
        })

        setSurebankaFailed(prev => {
            return surebankaFail.length
        })

        setSettlementSuccessful(prev => {
            return settlementSuccess.length
        })

        setSurebankaSuccessful(prev => {
            return surebankaSuccess.length  
        })

        setMatchedTransactions(prev => {
            return matched
        })

        console.log(matched)
    }

    function readSettlementCSV(data, fileinfo) {
        let settlementRRNs = [];
        let amountTotal = 0
        try {

            data.map((transaction, index) => {
                
                if (index === 0) {
                    
                    if (transaction[14] !== "RRN" || !transaction[14]) {
                        throw 'The file is not properly formatted'
                    }
                }
                else {
                    if (transaction[14] !== undefined) {

                        let tran = {};
                        tran.rrn = transaction[14];
                        tran.date = transaction[9];
                        tran.terminalID = transaction[3];
                        tran.status = transaction[13];
                        tran.amount = !transaction[7] || isNaN(transaction[7])  ? 0 : parseFloat(transaction[7]);
                        tran.approvalCode = transaction[16];
                        tran.arn = transaction[15];
                        tran.tranId = transaction[13];
                        tran.cardScheme = transaction[11];
                        tran.pan = transaction[10];

                        amountTotal += tran.amount;

                        settlementRRNs.push(tran);
                    }
                    else{
                        console.log("Empty Row at " + index)
                    }
                }
            })

            setSettlement(prev => {
                return settlementRRNs
            })

            setSettlementTotal(prev => {
                return amountTotal
            })

            
        }
        catch (error) {
            console.log(error)
            NotificationService.notifyError("Please upload a properly formmated Providus Download file", 10000)
        }

    }

    function readSurebankaCSV(data, fileinfo) {
        
        let surebankaRRNs = [];
        let amountTotal = 0
        try {

            data.map((transaction, index) => {

                if (index === 0) {

                    if (transaction[17] !== "RRN" || !transaction[17]) {
                        throw 'The file is not properly formatted'
                    }
                }
                else {
                    if (transaction[17] !== undefined) {

                        
                        let tran = {};
                        tran.rrn = transaction[17];
                        tran.date = transaction[9];
                        tran.agent = transaction[11];
                        tran.terminal = transaction[10];
                        tran.amount = transaction[2];
                        tran.channel = transaction[14]
                        tran.responseCode = transaction[5];
                        tran.pan = transaction[19];
                        tran.status = transaction[18];

                        amountTotal += parseFloat(transaction[2])
                        
                        surebankaRRNs.push(tran);
                    }
                }
            })

            setSurebanka(prev => {
                return surebankaRRNs
            })

            setSurebankaTotal(prev => {
                return amountTotal
            })
        }
        catch (error) {
            console.log(error)
            NotificationService.notifyError("Please upload a properly formmated Surebanka Download file", 10000)
        }
    }

    function handleError() {
        // NotificationService.notifyError(`An error occurred, please check the CSV File and try again`, 10000)
    }


    return (
        <>
            <div className="row mg-t-20">
                <div className="col-md-12">

                    {/* <div className="pd-x-5">

                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Bulk Upload UnImpacted Transactions</h2>

                    </div> */}

                    <div className="dashboard-card-bg  pd-30">

                        <div className="floating-form">
                            <div className="row">

                                <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">

                                    <CSVReader onFileLoaded={(data, fileInfo) => readSettlementCSV(data, fileInfo)} onError={handleError()} />
                                    <p class="dashboard-card-title mg-b-10 mg-t-10 gray-100">
                                        Upload the downloaded file from NIBBS Settlement that contains the required columns in the correct order. Please note that the CSV file most be in the correct format.
                                    </p>


                                </div>

                                <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">

                                    <CSVReader onFileLoaded={(data, fileInfo) => readSurebankaCSV(data, fileInfo)} onError={handleError()} />
                                    <p class="dashboard-card-title mg-b-10 mg-t-10 gray-100">
                                        Upload the downloaded file from Surebanka Reports that contains the required columns in the correct order. Please note that the CSV file most be in the correct format.
                                    </p>


                                </div>

                            </div>

                            {settlement.length !== 0 && surebanka.length !== 0 ?
                                <button onClick={computeRecon} className="btn btn-primary" >Compute</button> :
                                null
                            }
                        </div>

                    </div>

                    <div class="dashboard-inner pd-x-0-force">
                        <div class="row">

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Settlement Transaction Count
                                        </p>
                                        <p class="main-header-p tertiary-color pd-b-20 blue-color">{settlement.length}</p>
                                        <p className="small-signifier">Settlement Amount Total {settlementTotal}</p>
                                        <p className="small-signifier">Settlement Failed Total {settlementFailed} | Settlement Successful Total {settlementSuccessful}</p>
                                        <p className="small-signifier"></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Surebanka Transaction Count
                                        </p>
                                        <p class="main-header-p tertiary-color pd-b-20 blue-color">{surebanka.length}</p>
                                        <p className="small-signifier">Surebanka Amount Total {surebankaTotal}</p>
                                        <p className="small-signifier">Surebanka Failed Total {surebankaFailed} | Surebanka Successful Total {surebankaSuccessful}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Download Overage</p>
                                        <p className="small-signifier">Overage Total {overages.length}</p>
                                        {overages.length !== 0 ?
                                            <div className="btn-filter">
                                                <CSVLink data={overages}><button type="button" className=" btn-fill-md majorButton">Download Overages</button></CSVLink>
                                            </div> : null}
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Download Shortage</p>
                                        <p className="small-signifier">Shortage Total {shortages.length}</p>
                                        {shortages.length !== 0 ?
                                            <div className="btn-filter">
                                                <CSVLink data={shortages}><button type="button" className=" btn-fill-md majorButton">Download Shortages</button></CSVLink>
                                            </div> : null}
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Download Matched Transactions</p>
                                        <p className="small-signifier">Matched Total {matchedTransactions.length}</p>
                                        {shortages.length !== 0 ?
                                            <div className="btn-filter">
                                                <CSVLink data={matchedTransactions}><button type="button" className=" btn-fill-md majorButton">Download Matched Transactions</button></CSVLink>
                                            </div> : null}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Withdrawal