import React,{useState, useEffect} from 'react'
import {  toast } from 'react-toastify';

import { AggregatorService } from '../../../../service/AggregatorService'
import Spinner from '../../../../components/Spinner'
import {NotificationService} from '../../../../service/NotificationService'
import InfiniteScroll from "react-infinite-scroll-component";

function View ({changeUp, updateAgent}) {
    
    const { id } = JSON.parse(localStorage.getItem('user'))
    const [agents, setAgents] = useState([]);
    const [selectAgents, setSelectAgents] = useState([]);
    const [busy, setBusy] = useState(true)

    let increment = 1

    useEffect(() => {
        AggregatorService.getAgentsByAggregator({aggregatorId : id.toString()})
        .then(response => {
            
            setAgents(prevAgents => {
                return response.data
            });

            setSelectAgents(prev => {
                return response.data.slice(0,50)
            })
            
            if(response.data.length > 0){
                NotificationService.notify("The number of Agents you have is " + response.data.length);
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("You do not have any Agents assigned");
                }
                
            setBusy(false)
        })
        .catch(error => {
            
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[]);

    function fetchMoreData(){

        increment += 1
        console.log(increment)
        console.log(selectAgents)

        setSelectAgents(prev => {
            return prev.concat(agents.slice(50 * (increment - 1), 50 * increment))
        })
        
        return true; 
      }
    
    return ( 
        <>
            { busy ? <Spinner /> : 
             <div  className="slim-mainpanel">
                <div id="tableDiv" className="container">
                    
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeUp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Manage Agents</h2>
                    </div>

                    <InfiniteScroll
                                    dataLength={agents.length}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    loader={<h4>Loading...</h4>}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                          <b>Yay! You have seen it all</b>
                                        </p>
                                      }
                                    
                                    >
                                <div className="table-wrapper table-responsive">
                                    <table id="datatable2" className="table display responsive nowrap">
                                        <thead>
                                            <tr>
                                                <th className="">Agent Name</th>
                                                <th className="">Email</th>
                                                <th className="">Phone Number</th>
                                                <th className="">Status</th>
                                                <th className="">Address</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectAgents.map(agent => (
                                                
                                                    <tr key={agent.email} onClick={() => updateAgent(agent)}>
                                                        <td>{agent.firstname + " " + agent.lastname}</td>
                                                        <td>{agent.email}</td>
                                                        <td>{agent.phonenumber}</td>
                                                        <td><div className={agent.isActive ? "success" : "inactive"}>{agent.isActive ? "Active" : "Disabled"}</div></td>
                                                        <td>{agent.address + ", " + agent.lga + ", " + agent.state}</td>            
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                    </InfiniteScroll>

                </div>
            </div>
            }   
        </>
    
    
    )
}

export default View