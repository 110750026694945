import React, { useEffect, useState } from 'react'
import  Performance from '../../../components/report/Performance'
import {AggregatorService} from '../../../service/AggregatorService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'

function Report () {
    const [busy, setBusy] = useState(true)
    const {id}  = JSON.parse(localStorage.getItem('user'))
    const [agents, setAgents] = useState([])
    

    useEffect(() => {
        AggregatorService.getPerformanceReport({aggregatorId: id})
        .then(response => {
            console.log(response)
            if(response.data.data.length > 0){
                    NotificationService.notify("Transactions pulled successfully ");

                    setAgents(prev => {
                        return response.data.data
                    })
                }
                else if(response.data.data.length === 0){
                    NotificationService.notifyInfo("You do not have any transaction for this period");
                    
                } 
            setBusy(false)

        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])


    return (
        <>
            {busy ? <Spinner /> : <Performance agents={agents} /> }
        </>
    )
}

export default Report