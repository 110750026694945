import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SuperAdminService} from '../../../service/SuperAdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import moment from 'moment'
import Details from '../../../components/transaction/Details'
import { CSVLink, CSVDownload } from "react-csv"

function Download ({ changeApp }) {
    const [transactions, setTransactions] = useState([]);
    const [done, setDone] = useState(false)
    const [busy, setBusy] = useState(false)

    useEffect(() => {

    }, [])    

    const formik = useFormik({
        initialValues: {
          rrn: '',
          startDate: '',
          endDate: ''

        },
        validate: (what) => {

            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyErrorWithID(formik.errors.terminalID, "terminalID", 5000)
                return;
            }            
            if(formik.errors.startDate && formik.touched.startDate){
                NotificationService.notifyErrorWithID(formik.errors.startDate, "startDate", 5000)
                return;
            }
            if(formik.errors.endDate && formik.touched.endDate){
                NotificationService.notifyErrorWithID(formik.errors.endDate, "endDate", 5000)
                return;
            }
        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
        terminalID: Yup.string()
            .required('Please enter the RRN for this transaction'),
        startDate: Yup.string()
            .required('Please select the Transaction Start Date'),
        endDate: Yup.string()
            .required('Please select the Transaction End Date'),

        }),
        onSubmit: (values,{ resetForm }) => {         
            //console.log(values)  
            setBusy(true)

            const searchParams = {
       
                endDate: moment(values.endDate).format('yyyy-MM-DD'),
                startDate: moment(values.startDate).format('yyyy-MM-DD'),
                terminalPhysicalId: values.terminalID
            }
            console.log(searchParams)

            SuperAdminService.getTransactionsByTerminalPhysicalID(searchParams)
            .then(response => {
                console.log(response.data.data)

                if(response.data.data.length > 0){

                    const trans = response.data.data
        
                        setTransactions(prev => {
                            return trans
                        });

                        NotificationService.notify("Transactions pull successfully.");

                        setDone(true)
                    }
                    else{
                        NotificationService.notifyError("No transaction was found for the specified Date and RRN.", 5000);
                    }
                    
                setBusy(false)
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });
    
    return (
        <>
        {
             <div className="slim-mainpanel no-bg-img">
             <div className="container">
                 <div class="main-header">
                     <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back(Use this menu to go back)</a>
                 </div>
                 <div className="pd-x-5">
                    <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Search Transaction</h2>                        
                    </div>
                { busy ? <Spinner /> : 
                    <div className="dashboard-card-bg  pd-30">
                                    <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                        <div className="floating-form">
                                            <div className="row">
                                               
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="date" name="startDate" {...formik.getFieldProps('startDate')} />
                                                    
                                                    <span className="highlight"></span>
                                                    <label>Transaction Date</label>
                                                </div>

                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="date" name="endDate" {...formik.getFieldProps('endDate')} />
                                                    
                                                    <span className="highlight"></span>
                                                    <label>End Date</label>
                                                </div>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="text" placeholder=" "  name="terminalID" autoFocus {...formik.getFieldProps('terminalID')} />
                                                    <span className="highlight"></span>
                                                    <label>Terminal ID</label>
                                                </div>

                                            <button className="btn btn-primary" type="submit">Search</button>

                                            { done ?
                                            <div className="btn-filter">
                                                {/* <a href="#"><button className=" btn-fill-md majorButton">Download Transactions</button></a> */}
                                                <CSVLink data={transactions}><button type="button" className=" btn-fill-md majorButton">Download Transactions</button></CSVLink>
                                            </div> : "" }
                                        
                                        </div>
                                    </form>
                    </div>
                        
                    }
             </div>
          </div>
        
        }

        </>
    )
}

export default Download