import React, { useState, useContext, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PublicHeader from '../components/PublicHeader';
import Footer from './Footer';
import { useHistory } from 'react-router-dom'
import Register from '../components/Register'


function Registration() {
  const [busy, setBusy] = useState(false);

  const history = useHistory();

  function completeReg(){
    history.push('/confirm');
  }
  return (
    <>
    
      <PublicHeader />
      <div className="centered-form col-lg-6">
      <div className="signin-box pd-0-force">
        <Register completeReg={completeReg} />
      </div>
      </div>
      <Footer />
        </>
  )
}

export default Registration