import React from 'react'

function Footer () {
    return (
        <>
           <footer className="footer-area blue-10-bg">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="footer-widget about_widget">
                                    <a href=" " className="footer-logo"><img src="/assets/img/surebanka-logo.svg" alt=""/></a>
                                    <p className="col-md-10">Surebanka is a product of Transolve Technologies LTD. which is a suite of financial inclusion services. Our understanding
                                        of market
                                        realities have ensured we build one of the best products that meet the needs of agency
                                        banking in Africa</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget nav_menus_widget">
                                    <h6>Get started</h6>
                                    <li><a href="/login">Login</a></li>
                                    <li><a href="/register"> Sign up</a></li>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget nav_menus_widget">
                                    <h6>Connect</h6>
                                    <li><a href="#">info@surebanka.com</a></li>
                                    <li><a href="#">+23480 639 43564</a></li>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-60">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="footer-widget about_widget">
                                    <h5>Plot 234 Jumoke Apara Street, Gbagada GRA Phase 2, Lagos, Nigeria</h5>
                                    <p className="col-md-10 footnote-muted">Surebanka™ is a product of Transolve Technologies Limited. We are on a mission to
                                     developing and maintaining a robust dispute management system that instills confidence of 
                                    customers in transaction channels that cut across POS ATM, USSD, Internet and Mobile banking..To get a quote, please contact us today.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer