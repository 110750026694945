import React from 'react'
import CreateDispute from '../../../components/dispute/CreateDispute'

function Create ({changeApp}) {

    function changeUp(name){
        changeApp(name)
    }

    return (
       <>
        <div className="slim-mainpanel">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeUp("main")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Requests</h2>
                    </div>
                    <CreateDispute /> 
                </div>
            </div>
       </>
    )
}

export default Create