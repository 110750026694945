import React, { useState} from 'react'
import CSVReader from 'react-csv-reader'
import { NotificationService } from '../../../service/NotificationService'
import differenceBy from 'lodash/differenceBy'
import { CSVLink, CSVDownload } from "react-csv"


function Deposit(props) {
    const [providus, setProvidus] = useState([]);
    const [surebanka, setSurebanka] = useState([]);
    const [overages, setOverages] = useState([])
    const [shortages, setShortages] = useState([])
    const [ surebankaTotal, setSurebankaTotal] = useState(0)
    const [ providusTotal, setProvidusTotal] = useState(0)
    const [ providusFailed, setProvidusFailed] = useState(0)
    const [ surebankaFailed, setSurebankaFailed] = useState(0)
    const [ providusSuccessful, setProvidusSuccessful] = useState(0)
    const [ surebankaSuccessful, setSurebankaSuccessful] = useState(0)

    let overageElements = [];
    let shortageElements = [];

    if(shortages.length !== 0){
        shortageElements = shortages.map(transaction => {
            return (
                
                <tr key={transaction.rrn}>
                    <td><a href="#">{transaction.rrn}</a></td>
                    <td><a href="#">{transaction.amount}</a></td> 
                    <td><a href="#">{transaction.date}</a></td>
                    <td><a href="#">{transaction.accountName}</a></td>
                    <td><a href="#">{transaction.accountNumber}</a></td>                                   
                </tr>
            )
        })
    }

    if(overages.length !== 0){
        overageElements = overages.map(transaction => {
            return (
                
                <tr key={transaction.rrn}>
                    <td><a href="#">{transaction.rrn}</a></td>
                    <td><a href="#">{transaction.amount}</a></td> 
                    <td><a href="#">{transaction.date}</a></td>
                    <td><a href="#">{transaction.terminal}</a></td>
                    <td><a href="#">{transaction.agent}</a></td>
                    <td><a href="#">{transaction.channel}</a></td>                                 
                </tr>
            )
        })
    }
   

    function computeRecon(){
    
        const overage = differenceBy(surebanka, providus, 'rrn'); 

        const shortage = differenceBy(providus, surebanka, 'rrn');

        const providusFail = providus.filter(transaction => {
            return transaction.responseCode !== "0"
        })

        const surebankaFail = surebanka.filter(transaction => {
            return transaction.responseCode !== "00"
        })

        const providusSuccess = providus.filter(transaction => {
            return transaction.responseCode === "0"
        })

        const surebankaSuccess = surebanka.filter(transaction => {
            return transaction.responseCode === "00"
        })

        setShortages(prev => {
            return shortage
        });

        setOverages(prev => {
            return overage
        })

        setProvidusFailed(prev => {
            return providusFail.length
        })

        setSurebankaFailed(prev => {
            return surebankaFail.length
        })

        setProvidusSuccessful(prev => {
            return providusSuccess.length
        })

        setSurebankaSuccessful(prev => {
            return surebankaSuccess.length  
        })

        console.log(surebankaFail, surebankaSuccess)
    }


    function readProvidusCSV(data, fileinfo) {
        let providusRRNs = [];
        let amountTotal = 0
        try {

            data.map((transaction, index) => {
                
                if (index === 0) {

                    if (transaction[13] !== "Pymt. Ref.") {
                        throw 'The file is not properly formatted'
                    }
                }
                else {
                    if (transaction[13] !== undefined) {

                        let tran = {};
                        tran.rrn = transaction[13].split('-')[1];
                        tran.date = transaction[12];
                        tran.accountNumber = transaction[3];
                        tran.accountName = transaction[4];
                        tran.amount = transaction[8]
                        tran.responseCode = transaction[9];

                        amountTotal += parseFloat(transaction[8])

                        providusRRNs.push(tran);
                    }
                    else{
                        console.log("Empty Row at " + index)
                    }
                }
            })

            setProvidus(prev => {
                return providusRRNs
            })

            setProvidusTotal(prev => {
                return amountTotal
            })
        }
        catch (error) {
            console.log(error)
            NotificationService.notifyError("Please upload a properly formmated Providus Download file", 10000)
        }

    }

    function readSurebankaCSV(data, fileinfo) {
        

        let surebankaRRNs = [];
        let amountTotal = 0
        try {

            data.map((transaction, index) => {

                if (index === 0) {

                    if (transaction[17] !== "RRN" || !transaction[17]) {
                        throw 'The file is not properly formatted'
                    }
                }
                else {
                    if (transaction[17] !== undefined) {

                        
                        let tran = {};
                        tran.rrn = transaction[17];
                        tran.date = transaction[9];
                        tran.agent = transaction[11];
                        tran.terminal = transaction[10];
                        tran.amount = transaction[2];
                        tran.channel = transaction[14]
                        tran.responseCode = transaction[5];

                        amountTotal += parseFloat(transaction[2])
                        
                        surebankaRRNs.push(tran);
                    }
                }
            })

            setSurebanka(prev => {
                return surebankaRRNs
            })

            setSurebankaTotal(prev => {
                return amountTotal
            })
        }
        catch (error) {
            console.log(error)
            NotificationService.notifyError("Please upload a properly formmated Surebanka Download file", 10000)
        }
    }

    function handleError() {
        // NotificationService.notifyError(`An error occurred, please check the CSV File and try again`, 10000)
    }

    return (
        <>
            <div className="row mg-t-20">
                <div className="col-md-12">

                    {/* <div className="pd-x-5">

                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Bulk Upload UnImpacted Transactions</h2>

                    </div> */}

                    <div className="dashboard-card-bg  pd-30">
                        
                            <div className="floating-form">
                                <div className="row">

                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">

                                        <CSVReader onFileLoaded={(data, fileInfo) => readProvidusCSV(data, fileInfo)} onError={handleError()} />
                                        <p class="dashboard-card-title mg-b-10 mg-t-10 gray-100">
                                            Upload the downloaded file from NIP that contains the required columns in the correct order. Please note that the CSV file most be in the correct format.
                                        </p>


                                    </div>

                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">

                                        <CSVReader onFileLoaded={(data, fileInfo) => readSurebankaCSV(data, fileInfo)} onError={handleError()} />
                                        <p class="dashboard-card-title mg-b-10 mg-t-10 gray-100">
                                            Upload the downloaded file from Surebanka Reports that contains the required columns in the correct order. Please note that the CSV file most be in the correct format.
                                        </p>


                                    </div>

                                </div>

                                { providus.length !== 0 && surebanka.length !== 0 ?
                                    <button onClick={computeRecon} className="btn btn-primary" >Compute</button> :
                                    null
                                }
                            </div>
                        
                    </div>

                    <div class="dashboard-inner pd-x-0-force">
                        <div class="row">

                        <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">NIP Transaction Count
                                        </p>
                                        <p class="main-header-p tertiary-color pd-b-20 blue-color">{providus.length}</p>
                                        <p className="small-signifier">NIP Amount Total {providusTotal}</p>
                                        <p className="small-signifier">NIP Failed Total {providusFailed} | NIP Successful Total {providusSuccessful}</p>
                                        <p className="small-signifier"></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Surebanka Transaction Count
                                        </p>
                                        <p class="main-header-p tertiary-color pd-b-20 blue-color">{surebanka.length}</p>
                                        <p className="small-signifier">Surebanka Amount Total {surebankaTotal}</p>
                                        <p className="small-signifier">Surebanka Failed Total {surebankaFailed} | Surebanka Successful Total {surebankaSuccessful}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Download Overage(In Surebanka not in NIP)</p>
                                        <p className="small-signifier">Overage Total {overages.length}</p>
                                        { overages.length !== 0 ?
                                        <div className="btn-filter">
                                            <CSVLink data={overages}><button type="button" className=" btn-fill-md majorButton">Download Overages</button></CSVLink>
                                        </div> : null }
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Download Shortage(In NIP not in Surebanka)</p>
                                        <p className="small-signifier">Shortage Total {shortages.length}</p>
                                        { shortages.length !== 0 ?
                                        <div className="btn-filter">
                                            <CSVLink data={shortages}><button type="button" className=" btn-fill-md majorButton">Download Shortages</button></CSVLink>
                                        </div> : null }
                                    </div>
                                </div>
                            </div>

                           
                        </div>
                    </div>

                     {/* <div className="pd-x-5">

                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Depsoit Shortage</h2>

                    </div>


                    <div className="table-wrapper table-responsive">
                       <table id="datatable2" className="table display responsive nowrap">
                           <thead>
                               <tr>
                               <th className="">RRN</th>
                                <th className="">Amount</th>
                                <th className="">Date</th>
                                <th className="">Account Name</th>
                                <th className="">Account Number</th>                                 
                               </tr>
                           </thead>
                           <tbody>
                               {shortageElements}
                           </tbody>
                       </table>
                   </div>

                   <div className="pd-x-5">

                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Depsoit Overage</h2>

                    </div>


                    <div className="table-wrapper table-responsive">
                       <table id="datatable2" className="table display responsive nowrap">
                           <thead>
                               <tr>
                               <th className="">RRN</th>
                                   <th className="">Amount</th>
                                   <th className="">Date</th>
                                   <th className="">Terminal</th>
                                   <th className="">Agent</th> 
                                   <th className="">Channel</th>                                    
                               </tr>
                           </thead>
                           <tbody>
                               {overageElements}
                           </tbody>
                       </table>
                   </div> */}


                </div>
            </div>
        </>
    )
}

export default Deposit