import React,{useEffect, useState} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../../service/NotificationService'
import { AdminService } from '../../service/AdminService'
import Spinner from '../Spinner';
import { SuperAdminService } from '../../service/SuperAdminService';
import DebitAggregator from '../../pages/superadmin/Transaction/DebitAggregator';

function DebitAggregators () {
   
    const [aggregators, setAggregators] = useState([]);
    const [busy, setBusy] = useState(true)

    const aggregatorOption = aggregators.map(aggregator => {
        return (<option key={aggregator._id} value={`${aggregator._id}`}>{`${aggregator.firstname} ${aggregator.lastname}`}</option>)
    })

   

   

    useEffect(() => {  
        AdminService.getAllAggregators()
        .then(response => {
            console.log(response)
            setAggregators(prevAgents => {
                return response.data
            });
            
            if(response.data.length > 0){
                NotificationService.notify("The number of Aggregator is " + response.data.length);
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("You do not have any Agents assigned");
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

      },[])


    const formik = useFormik({
        initialValues: {
          amount: 0,
          aggregatorId: '',

        },
        validate: (what) => {

            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyErrorWithID(formik.errors.amount, "amount", 5000)
                return;
            }            
            if(formik.errors.aggregatorId && formik.touched.aggregatorId){
                NotificationService.notifyErrorWithID(formik.errors.aggregatorId, "aggregatorId", 5000)
                return;
            }
           
            

        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
        amount: Yup.string()
            .required('Please enter the Amount'),
        aggregatorId: Yup.string()
            .required('Please select the Aggregator'),

        }),
        onSubmit: (values,{ resetForm }) => {         
            console.log(values)  
            setBusy(true)

            SuperAdminService.DebitAggregator(values)
            .then(response => {
                console.log(response)
                setBusy(false)
                NotificationService.notifyInfo("Aggregator was debited successfully")
                resetForm()
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)

            })
        }
      });

    return (
        <>
            { busy ? <Spinner /> :
            
                <>
                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Debit Aggregator</h2>
                        
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="number" placeholder=" "  name="amount" autoFocus {...formik.getFieldProps('amount')} />
                                                <span className="highlight"></span>
                                                <label>Debit Amount</label>
                                            </div>                         
                                            
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="aggregatorId" {...formik.getFieldProps('aggregatorId')} >
                                                <option value="" label="Please select the Aggregator" />
                                                {aggregatorOption}                              
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Aggregators</label>                           
                                            </div>
                                           
                                        
                                        </div>

                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            
        }
        </>
    )
}

export default DebitAggregators