import React,  { useState, useEffect } from 'react'
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'
import moment from 'moment'
import Spinner from '../../../components/Spinner';


function View ({changeApp}) {
    const [disputes, setDisputes] = useState([])
    const [busy, setBusy] = useState(true)

    const user = JSON.parse(localStorage.getItem('user'))

    let disputeElements= [];

    if(disputes.length !== 0){
         disputeElements = disputes.map(dispute => {
            return (
                <tr key={dispute.TransactionId} onClick={() => console.log(dispute)}>
                        <td><a href="#">{dispute.CustomerAccountNumber}</a></td>
                        <td><a href="#">{dispute.CustomerBank}</a></td>
                        <td><a href="#">{dispute.CustomerEmail}</a></td>
                        <td><a href="#">{dispute.CustomerPhoneNumber}</a></td>
                        <td><a href="#">{dispute.Description}</a></td>                        
                        <td><a href="#"><div className={dispute.Status == "Open" ? "success" : "inactive"}>{dispute.Status == "Open" ? "Open" : "Closed"}</div></a></td>                    
                </tr>
            )
        })
    }

   


    useEffect(() => {
        AgentService.getDisputeByAgent({agentId: user.id})
        .then(response => {
            console.log(response.data.data)

            setDisputes(prev => {
                return response.data.data
            })
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])

    return (
       <>
            {
                busy ? <Spinner /> 
            : 
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="table-wrapper table-responsive">
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                    <th className="">Customer Account Number</th>
                                    <th className="">Customer Bank</th>
                                    <th className="">Customer Email</th>
                                    <th className="">Customer Phone Number</th>
                                    <th className="">Description</th>
                                    <th className="">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {disputeElements}
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
            }
       </>
    )
}

export default View