import React, { useState, useEffect } from 'react'
import Spinner from '../Spinner'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../../service/NotificationService'
import { AdminService } from '../../service/AdminService';
import moment from 'moment'
import { AgentService } from '../../service/AgentService';

function CreateDispute () {

    const [busy, setBusy] = useState(true);
    const [requestTypes, setRequestTypes] = useState([])
    const { id, usertype } = JSON.parse(localStorage.getItem('user'))
    const [isDispute, setIsDispute] = useState(false);
    const [ failed, setFailed] = useState([])
    const [banks, setBanks] = useState([])
    let failedOptions

    //----------------------------------------------------------------------------------------------------
    const types = requestTypes.map(type => {
         return (<option key={type.name} value={`${type._id}`}>{`${type.name}`}</option>)
    })

    //----------------------------------------------------------------------------------------------------
    if(failed.length > 0){
        failedOptions = failed.map(transaction => {
            const tranDate = new Date(transaction.dateCreated).toLocaleString('en-US')
            return (<option key={transaction._id} value={`${transaction._id}`}>{`Dispute on terminal ${transaction.terminalID.terminalPhysicalId} of ${transaction.amount} Naira with type ${transaction.transactionTypeID.description} on ${tranDate}`}</option>)   
        })
    }

    const bankOptions = banks.map(bank => {
        return(
            <option key={bank.bankCode} value={`${bank.bankCode}`}>{`${bank.bankName}`}</option>
        )
    })

    //----------------------------------------------------------------------------------------------------
    const searchParams = {
        agentId: id,
        startDate: moment().format('DD/MM/yyyy'),
        endDate: moment().subtract('months', 1).format('DD/MM/yyyy')
    }

    useEffect(() => {
        //----------------------------------------------------------------------------------------------------
        AdminService.getRequestTypes()
        .then(response => {

            setRequestTypes(prev => {
                return response.data.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network ", 5000)
            }
            setBusy(false)
        })

        AgentService.getBankList()
        .then(response => {
            console.log(response.data.data.banks)
            setBanks(prev => {
                return response.data.data.banks
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

        //--TODO: Replace with failed transactions--------------------------------------------------------------------------------------------------
        if( usertype !== "Aggregator"){
                AgentService.getFailedTransaction({agentId: id})
                .then(response => {
                    console.log(response.data.data)

                    if(response.data.data.length > 0){
                        
                        setFailed(prev => {
                                return response.data.data
                            })
                        }
                        else if(response.data.data.length === 0){
                            
                        }
                        
                    setBusy(false)
                })
                .catch(error => {
                    if(error.response){
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if(error.message){
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else{
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)
                })
        }
        
    },[])

    const formik = useFormik({
        initialValues: {
          title: '',
          requestTypeID: '',
          details: '',
          accountNumber: '',
          accountName: "",
          bankCode: "",
          transactionID: ""
          
        },
        validate: (what) => {
            
            if(formik.errors.title && formik.touched.title){
                NotificationService.notifyErrorWithID(formik.errors.title, "title", 5000)
                return;
            }
            if(formik.errors.requestTypeID && formik.touched.requestTypeID){
                NotificationService.notifyErrorWithID(formik.errors.requestTypeID, "request", 5000)
                return
            }
            if(formik.errors.details && formik.touched.details){
                NotificationService.notifyErrorWithID(formik.errors.details, "details", 5000)
            }
        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
          title: Yup.string()
            .required('Please enter the Title'),
          requestTypeID: Yup.string()
            .required('Please select your Request Type'),
          details: Yup.string()
            .required('Please enter the details of your request')
        }),
        onSubmit: (values,{ resetForm }) => {         
            
            setBusy(true)

            AdminService.createRequest({...values, requester: id})
            .then(response => {
                NotificationService.notify("The request was created successfully.")
                setBusy(false)
                resetForm()
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

      function typeChange(e) {
            
            formik.handleChange(e);

            const requestSelect = document.getElementsByName('requestTypeID')[0]
            const requestTypeName = requestSelect.options[requestSelect.selectedIndex].text

            console.log()

            if(requestTypeName === "DisputeRequest"){
                setIsDispute(prev => {
                    return true
                })
            }
            else(
                setIsDispute(prev => {
                    return false
                })
            )

           
      }

    return (
       <>
            { busy ? <Spinner /> :
            
            <>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="title" autoFocus {...formik.getFieldProps('title')} />
                                                <span className="highlight"></span>
                                                <label>Title</label>
                                            </div>
                                            
                                            
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select onChange={e => typeChange(e)}  className="floating-input" name="requestTypeID" value={formik.values.requestTypeID} >
                                                <option value="" label="Please select the Request Type" />
                                                {types}                              
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Request Type</label>                           
                                            </div>
                                           
                                        </div>

                                        

                                        
                                            <div className="row">
                                                {isDispute ? <div className="floating-label tran col-md-12 pd-b-10">
                                                    <select onChange={e => formik.handleChange(e)}  className="floating-input" placeholder="" name="transactionID" value={formik.values.transactionID} >
                                                    <option value="" label="Please select the Dispute Transaction" />
                                                    {failedOptions}                              
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Transaction</label>                           
                                                </div>                                           
                                               : null }
                                            </div>

                                           
                                                { isDispute ? 
                                                    <>
                                                     <div className="row">
                                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                                <input className="floating-input" type="text" placeholder=" "  name="accountName" autoFocus {...formik.getFieldProps('accountName')} />
                                                                <span className="highlight"></span>
                                                                <label>Customer Account Name</label>
                                                            </div>

                                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                                <input className="floating-input" type="text" placeholder=" "  name="accountNumber" autoFocus {...formik.getFieldProps('accountNumber')} />
                                                                <span className="highlight"></span>
                                                                <label>Customer Account Number</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="floating-label tran col-md-12 pd-b-10">
                                                                <select className="floating-input" placeholder=" " name="bankCode" {...formik.getFieldProps('bankCode')} >
                                                                <option value="" label="Select a Bank" />
                                                                {bankOptions}
                                                                </select>
                                                                <span className="highlight"></span>
                                                                <label>Bank Name</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                : null}
                                           
                                            
                                            <div className="row">
                                                <div className="floating-label tran col-md-12 pd-b-10" >
                                                        <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" placeholder=" "  name="details" {...formik.getFieldProps('details')}/>
                                                        <span className="highlight"></span>
                                                        <label>Request Details</label>
                                                </div>
                                        </div>

                                        <button className="btn btn-primary" type="submit">Create</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
        
    }
       </> 
    )
}

export default CreateDispute