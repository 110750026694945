import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import AgentChart from '../../../components/charts/AgentChart'
import './Dashboard.css'
import {AgentService} from '../../../service/AgentService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'
import moment from 'moment'

function Dashboard () {
    const { id } = JSON.parse(localStorage.getItem('user'))
    const [busy, setBusy] = useState(true)
    const [balance, setBalance] = useState("")
    const [ terminals, setTerminals] = useState([])

    const {aggregator} = JSON.parse(localStorage.getItem('user'))

    const formatter = new Intl.NumberFormat('en-US');

    const terminalCards = terminals.map(terminal => {
        return (
            
            <div className=" col-md-12 col-lg-6 mg-b-20">
            <div className="card pssCard basic-card pd-b-30 pd-x-15">
            <a href="#">
                <div className="card-body ">
                    <p className="dashboard-card-title tertiary-color mg-b-0">Balance for Terminal {" : " + terminal.TerminalPhysicalId}</p>
                    <p className="dashboard-card-title tertiary-color mg-b-0">Terminal Name {" : " + terminal.TerminalFriendlyName}</p>
                    <p className="dashboard-card-title tertiary-color blue-color" style={{fontSize: "24px"}}>Balance : {formatter.format(terminal.Amount.toFixed(2))}</p>
                    <p className="dashboard-card-title tertiary-color mg-b-0">Balance B/F {" : " + formatter.format(terminal.BalanceBF.toFixed(2))}</p>
                    <p className="small-signifier">{moment().format('MMMM Do YYYY, h:mm:ss a')}</p>
                </div>
                </a>
            </div>
        </div>
        )
    })

     useEffect(() => {
        const bal = AgentService.getAgentBalance({agentId: id})
        .then(response => {            
            
            setBalance(prev => {
                return response.data.data
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })

        const req = AgentService.getTerminalWithBalance({agentId: id})
        .then(response => {
            
            setTerminals(prev => {
                return response.data.data
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
        })

        Promise.all([bal,req].map(p => p.catch(e => e)))
        .then(results => {
            setBusy(false)
           
        })
        .catch(e => {
            
            setBusy(false)
        })
    },[])

    return (
        <>
        {busy ? <Spinner /> :
            <div className="slim-mainpanel no-bg-img">
            <div className="container">

                <div className="main-header mg-b-30">
                    <h2 className="main-header-h-1">Your Dashboard</h2>
                </div>

                <div className="dashboard-inner pd-x-0-force">
                    <div className="row">
                        <div className=" col-md-12 col-lg-6 mg-b-20">
                                <div className="card pssCard basic-card pd-b-30 pd-x-5">
                                    <a href="#">
                                        <div className="card-body">
                                            <p className="dashboard-card-title tertiary-color ">Wallet balance
                                            </p>
                                            <p className="main-header-p tertiary-color pd-b-20 blue-color">{formatter.format(balance)}</p>
                                            <p className="small-signifier">Aggregator Name & Phone  : {aggregator.firstname + " " + aggregator.lastname + " - " + aggregator.phonenumber}</p>
                                        </div>
                                    </a>
                                </div>
                        </div>
                       {terminalCards}
                        {/* <div className=" col-md-12 col-lg-6 mg-b-20">
                            <div className="card pssCard basic-card pd-b-30 pd-x-15">
                            <a href="#">
                                <div className="card-body ">
                                    <p className="dashboard-card-title tertiary-color mg-b-0">Balance for Terminal 99999000
                                    </p>
                                    <p className="dashboard-card-title tertiary-color blue-color" style={{fontSize: "24px"}}>Balance : 1,245,876</p>
                                    <p className="small-signifier">Last updated: Mar 21, 2020</p>
                                </div>
                                </a>
                            </div>
                        </div> */}

                        {/* <div className=" col-md-12 col-lg-6 mg-b-20">
                            <div className="card pssCard basic-card pd-b-30 pd-x-15">
                                <a href="#">
                                    <div className="card-body ">
                                        <p className="dashboard-card-title tertiary-color mg-b-0">Balance for Terminal 343555667
                                        </p>
                                        <p className="dashboard-card-title tertiary-color blue-color" style={{fontSize: "24px"}}>Balance : 346,876</p>
                                        <p className="small-signifier">Last updated: Mar 21, 2020</p>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                    </div>

                    {/* <div className="row">
                        <div className="col-lg-12 mg-b-20 ">
                            <div className="card pssCard basic-card pd-b-30 pd-x-15 graph">
                                <AgentChart />
                            </div>
                        </div>
                    </div> */}
                </div>
              
              
                </div>
                </div>
            }
        </>
    )
}

export default Dashboard