import axiosConfig from '../helpers/AxiosSubConfig';

const getTransactionsByAgent = (values) => {
   
   return axiosConfig.get('/transaction/transactionsByAgent/' + values.agentId, {params: values})

}

const getTerminalsByAgent = (values) => {
    return axiosConfig.post('/terminal/terminalByAgent/', values) 
}

const getRequestByUser = (values) => {
    return axiosConfig.get('/request/user/' + values.userid)
}

const getFailedTransaction = (values) => {
    return axiosConfig.get('/transaction/failedTransaction/' + values.agentId)
 }

 const depositTransaction = (values) => {
     return axiosConfig.post('/transaction/deposit', values)
 }

 const buyAirtime = (values) => {
     return axiosConfig.post('/virtual-airtime/buyPrimeAirtime', values)
 }

 const buyPower = (values) => {
     return axiosConfig.post('/virtual-airtime/payPowerBills', values)
 }

 const getAllDiscos = (values) => {
     return axiosConfig.get('/virtual-airtime/getallDiscos')
 }

 const getTVBouquet = (values) => {
     return axiosConfig.post('/virtual-airtime/getTvBouquet', values)
 }

 const payTvSubscriptions = (values) => {
     return axiosConfig.post('/virtual-airtime/payTvSubscriptions', values);
 }

 const getAgentBalance = (values) => {
     return axiosConfig.get('/wallet/agentBalance/' + values.agentId)
 }

 const getAccountDetails = (values) => {
     return axiosConfig.post('/wallet/accountEnquiry', values)
 }

 const createAgentPIN = (values) => {
     return axiosConfig.post('/terminal/createagentpin', values)
 }

 const updateUser = (values) => {
     return axiosConfig.put('/manage-user/updateUser', values)
 }

 const getTerminalWithBalance = (values) => {
     return axiosConfig.get('/transaction/getAgentEachTerminalBalance/' + values.agentId)
 }

 const getTerminalTransactions = (values) => {
     return axiosConfig.get('/transaction/terminal/' + values.terminalID)
 }

 const getTelcoData = (values) => {
     return axiosConfig.post('/virtual-airtime/telcosDataList', values)
 }
  
 const buyDataSub = (values) => {
     return axiosConfig.post('virtual-airtime/buyDataSub', values)
 }

 const getBankList = () => {
     return axiosConfig.get('/wallet/bankList')
 }

 const agentCashOut = (values) => {
     return axiosConfig.post('/transaction/agent/cashout', values)
 }

 const walletTransfer = (values) => {
     return axiosConfig.post('/transaction/walletToWalletTransfer', values)
 }

 const managePIN = (values) => {
     return axiosConfig.post('/manage-user/pin', values)
 }

 const updateFriendlyName = (values) => {
    return axiosConfig.post('/terminal/friendlyName/' + values.terminalId, values)
 }

 const createDispute = (values) => {
    return axiosConfig.post('/dispute-resolution/', values)
 }

 const resetTerminalPIN = (values) => {
    return axiosConfig.post('/terminal/ResetAgentTerminalPinEmail', values)
 }

 const getPHCNSlipPDF = (values) => {
     return axiosConfig.get('/pdf/getPHCNSlipPDF', {params: values} )
 }

 const getFailedTransactionsByAgent = (values) => {
    return axiosConfig.get('/dispute-resolution/getFailedTransactionsByAgent', {params: values})
 }

const getDisputeByAgent = (values) => {
    return axiosConfig.get('/dispute-resolution/getDisputeByAgent', {params: values})
 }

 const reprintVoucher = (values) => {
    return axiosConfig.get('/pdf/reprintrecharge', {params: values})
 }

 const getRechargeCardHistoryByAgent = (values) => {
    return axiosConfig.get('/virtual-airtime-pos/getRechargeCardHistoryByAgent', {params: values})
 }

 const PrintRechargeCardPos = (values) => {
    return axiosConfig.post('/virtual-airtime/PrintRechargeCard', values)
 }

export const AgentService = {
    getTransactionsByAgent,
    getTerminalsByAgent,
    getRequestByUser,
    getFailedTransaction,
    depositTransaction,
    buyAirtime,
    getAllDiscos,
    buyPower,
    getTVBouquet,
    payTvSubscriptions,
    getAgentBalance,
    createAgentPIN,
    updateUser,
    getTerminalWithBalance,
    getTerminalTransactions,
    getTelcoData,
    buyDataSub,
    getAccountDetails,
    getBankList,
    agentCashOut,
    walletTransfer,
    managePIN,
    updateFriendlyName,
    resetTerminalPIN,
    getPHCNSlipPDF,
    getFailedTransactionsByAgent,
    createDispute,
    getDisputeByAgent,
    reprintVoucher,
    PrintRechargeCardPos,
    getRechargeCardHistoryByAgent
}