import React,{Component, useState} from 'react'
import ManageAgent from './ManageAgent/ManageAgent'
import Main from './Main'
import Terminal from './Terminal/Terminal';
import Create from './Create';


function Manage () {
    const [params, setParams] = useState("manage")
    
    function changeApp(name) {
        console.log(name)
        
        setParams(prev => {
            return name
        })
    }   
    
    return (
        <>          
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'agent': <ManageAgent changeApp={changeApp} />,
                'terminal': <Terminal changeApp={changeApp} />,
                'create' : <Create changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
            
        </>
    )
}


export default Manage