import React from "react";
import Confirmation from "../components/Confirmation";
import PublicHeader from "../components/PublicHeader";
import Footer from "./Footer";

function RegisterConfirm() {
    const header = "Successful Registration on Surebanka!!!"
    const message = `Please check your mailbox for a confirmation email and click on the link to confirm your email.`
  return (
    <>
      <PublicHeader />
      <Confirmation header={header} message={message} />
      <Footer />
    </>
  );
}

export default RegisterConfirm;
