import axiosConfig from '../helpers/AxiosSubConfig';

const getAllAgents = () => {   
   return axiosConfig.get('/manage-user/getallAgents')
}

const getAllAggregators = () => {   
    return axiosConfig.get('/manage-user/getallAggregators')
 }

 const getAllTerminals = () => {   
    return axiosConfig.get('/terminal/getallterminals')
 }

 const createTerminal = (values) => {
    return axiosConfig.post('/terminal/create', values)
 }

 const activateAgent = (values) => {
   return axiosConfig.post('/manage-user/activateAgent', values)
}

 const getTerminalTypes = () => {   
   return axiosConfig.get('/terminal-type/getallterminaltypes')
}



const getTransactions = (values) => {  
   return axiosConfig.get('/transaction/filtertransactions', {params:values})
}

const getTransactionForAdmin = (values) => {   
   return axiosConfig.get('/transaction/getTransactionForAdmin', {params: values})
}

const getWithdrawalTransactions = (values) => {   
   return axiosConfig.get('/transaction/withdrawal', {params: values})
}

const createRequest = (values) => {
   return axiosConfig.post('/request', values)
}

const assignTerminal = (values) => {
   return axiosConfig.post('/terminal/assignterminal', values)
}

const getRequestTypes = (values) => {
   return axiosConfig.get('/requestType')
}

const getRequests = () => {
   return axiosConfig.get('/request')
}
 
const getUserRequests = (values) => {
   return axiosConfig.get('/request/user/' + values.userID)
}

const getNewRequests = () => {
   return axiosConfig.get('/request/newRewuests')
}

const getPendingRequests = () => {
   return axiosConfig.get('/request/pendingRequests')
}

const aggregatorEffectiveBalance = () => {
   return axiosConfig.get('/transaction/aggregatorEffectiveBalance')
}
 
const updateRequestStatus = (values) => {
   return axiosConfig.put('/request/updateStatus/' + values.requestID,{
      "status": values.status
   })
}

const updateUPSLTerminalPhysicalId = (values) => {
   return axiosConfig.put('/terminal/' + values.terminalId,{
      "UPSLTerminalPhysicalId": values.UPSLTerminalPhysicalId
   })
}

const closeRequest = (values) => {
   return axiosConfig.put('/request/' + values.requestID, values)
}

export const AdminService = {
    getAllAgents,
    getAllAggregators,
    getAllTerminals,
    getTerminalTypes,
    createTerminal,
    createRequest,
    assignTerminal, 
    getRequestTypes,
    getTransactions,
    getRequests,
    activateAgent,
    getUserRequests,
    getNewRequests,
    getPendingRequests,
    updateRequestStatus,
    closeRequest,
    getWithdrawalTransactions,
    getTransactionForAdmin,
    aggregatorEffectiveBalance,
    updateUPSLTerminalPhysicalId
}