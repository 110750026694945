import React, {useState, useContext, useEffect} from 'react'
import {Context} from '../contexts/MainContext'

function PublicHeader () {
    const [menu, setMenu] = useState(false);

    const {isLoggedIn } = useContext(Context);

    

    const [ userType, setUserType ] = useState({})

    

    function toggleMenu(){
        setMenu(prev => {
            return !prev;
        })
      }

      useEffect(() => {
        if(JSON.parse(localStorage.getItem('user'))){
            const {usertype} = JSON.parse(localStorage.getItem('user'))
            setUserType(prev => {
                return usertype
            })
        }
         
      },[])

      const show = (menu) ? "show" : "" ;

    return (
        <>
        <div>
            <nav className="navbar navbar-area navbar-expand-lg nav-absolute white nav-style-01">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <div className="logo-wrapper">
                                <a href="/" className="logo">
                                    <img src="/assets/img/surebanka-logo.svg" alt="Surebanka logo"/>
                                </a>
                            </div>
                            <button className="navbar-toggler" type="button" onClick={ toggleMenu }   aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className={"collapse navbar-collapse " + show} id="side_main_menu">
                            <ul className="navbar-nav">
                                { isLoggedIn ? <li><a href={`/${userType}/dashboard`} >Dashboard</a></li> : <li><a href="/login">Log in</a></li>}
                               { menu ? <li><a href="/register">Sign up</a></li> : ""}
                               {/* { menu ? isLoggedIn ? <li><a href="/client/dashboard">Dashboard</a></li> : <li><a href="/login">Log in</a></li> : ""} */}
                            </ul>
                        </div>
                        <div className="nav-right-content">
                            <ul>
                                <li className="button-wrapper">
                                    <a className="boxed-btn button" href="/register" data-mode="popup" >Sign up</a>
                                </li>

                                {/* <li className="button-wrapper">
                                    <a className="boxed-btn button" href="webcal://sqlva3y2s7kdxjfdhc.blob.core.windows.net/calendar/Resource Assigned - Central Bank of Nigeria WorkshopPLUS - Azure DevOps Server.ics" >Calendar</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
            </nav>
    </div>
        </>
    )
}

export default PublicHeader