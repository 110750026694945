import React from 'react'
import {useFormik} from 'formik'
import AuditComponent from '../../../components/audit/Audit'


function Audit () {


    return (
        <div className="slim-mainpanel">
            <div className="container">   
                <AuditComponent />
            </div>
        </div>
    )
}

export default Audit