import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup';

import Spinner from '../../../components/Spinner'
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'


function TV ({changeApp, tv}) {
    const [busy, setBusy] = useState(true)
    const [terminals, setTerminals] = useState([])
    const [bouquets, setBouquets] = useState([])
    const { id } = JSON.parse(localStorage.getItem('user'));
    

    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    const bouquetOptions = bouquets.map(bouquet => {
        return (
            <option key={bouquet.code} init={`${bouquet.price}`} value={`${bouquet.code}`}>{`${bouquet.title}`}</option>
        ) 
    })

    useEffect(() => {
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })

        AgentService.getTVBouquet({tv_network: tv})
        .then(response => {
            console.table(response.data.bundles)
        if(response.data.bundles){
            setBouquets(prev => {
                return response.data.bundles
            })
        }
        else{
            NotificationService.notifyError(response.data.message, 5000)
        }
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

    }, [])

    const formik = useFormik({
        initialValues: {
            amount: 0,
            tv_network: tv,
              
        },
        validate: (what) => {
            
            if(formik.errors.smartcard_number && formik.touched.smartcard_number){
                NotificationService.notifyError(formik.errors.smartcard_number, 3000)
                return;
            }
            if(formik.errors.email && formik.touched.email){
                NotificationService.notifyError(formik.errors.email, 3000)
                return;
            }
            if(formik.errors.agent_charge && formik.touched.agent_charge){
                NotificationService.notifyError(formik.errors.agent_charge, 3000)
                return;
            }
            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyError(formik.errors.terminalID, 3000)
                return;
            }
            if(formik.errors.phone && formik.touched.phone){
                NotificationService.notifyError(formik.errors.phone, 3000)
                return;
            }
           
            if(formik.errors.bouquet && formik.touched.bouquet){
                NotificationService.notifyError(formik.errors.bouquet, 3000)
                return;
            }

            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyError(formik.errors.pin, 3000)
                return;
            }
            
        },
        handleChange:() => {
          
        },
        validationSchema: Yup.object({
            smartcard_number: Yup.string()
                .required('Please enter the Cable SmartCard Number')
                .min(8,"The smart card number should be at least 8 digits"),
                //.test('len', 'Please enter a valid smart card number', val => val.length < 11),
            email: Yup.string()
                .email('Please enter a valid email')
                .required('Please enter the customer email'),
            terminalID: Yup.string()
                .required('Please select the terminal for this transaction'),
            agent_charge: Yup.string()
                .required('Please enter your agent charges for this transaction'),
            phone: Yup.string()
                .required('Please enter the customer phone number '),
                //.test('len', 'Please enter a valid phone number in the format 2347062067512', val => val.length === 13),
            bouquet: Yup.string()
                .required('Please select  the Cable TV Bundle'),
            pin: Yup.string()
                .required('Please enter the PIN for the selected Terminal'),
          }),
        onSubmit: (values,{ resetForm }) => {  
             
            setBusy(true)        
            
            AgentService.payTvSubscriptions({...values,pin: values.pin ,amount: parseInt(values.amount), service_code: parseInt(values.bouquet)})
            .then(response => {
                
                setBusy(false)
                NotificationService.notify("The Subscription was completed successfully.")
                changeApp('Main')
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

      function setValue(e){        
        
        const bouquetSelect = document.getElementsByName('bouquet')[0]
        const bouquet = bouquetSelect.options[bouquetSelect.selectedIndex].getAttribute('init')

        
        
         formik.values.amount = parseFloat(bouquet).toFixed(2)
         //console.log(typeof formik.values.amount + ' ' + formik.values.amount)
         formik.handleChange(e);
         //formik.values.amount = parseInt(formik.values.amount)
         //console.log(typeof formik.values.amount + ' ' + formik.values.amount)
        //const num = bouquets.find(bouq => bouq.code === bouquet)
        //formik.values.amount = num.price;
      }
    
    return (
        <>
        { busy ? <Spinner /> :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("main")} className="pss-blue pd-b-10">← Go Back</a>
                    </div>

                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Pay {tv} Subscription</h2>
                            
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input disabled={true} className="floating-input" type="number" placeholder=" "  name="amount" {...formik.getFieldProps('amount')} />
                                                <span className="highlight"></span>
                                                <label>Amount(in Naira)</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input disabled={true} className="floating-input" type="text"  name="tv_network"  {...formik.getFieldProps('tv_network')} />
                                                <span className="highlight"></span>
                                                <label>TV Network</label>
                                            </div>
                                           
                                        </div>

                                      

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                    <option value="" label="Please select terminal for this transaction" />
                                                    {terminalOptions}
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Terminal</label>                           
                                                </div>
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select onChange={e => setValue(e)}  className="floating-input" placeholder="" name="bouquet" value={formik.values.bouquet} >
                                                    <option value="" label="Please select a the Subscription Type" />
                                                    {bouquetOptions}
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Subscriptions</label>                           
                                                </div>
                                        
                                        </div>
                                        <div className="row">
                                            
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="number" placeholder=" "  name="agent_charge" {...formik.getFieldProps('agent_charge')}/>
                                                    <span className="highlight"></span>
                                                    <label>Agent Charge</label>
                                                </div>
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="number" placeholder="2348023195555"  name="phone" {...formik.getFieldProps('phone')}/>
                                                    <span className="highlight"></span>
                                                    <label>Phone Number</label>
                                            </div>
                                        
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                        <input className="floating-input" type="number" placeholder=""  name="smartcard_number" {...formik.getFieldProps('smartcard_number')}/>
                                                        <span className="highlight"></span>
                                                        <label>Smart Card Number</label>
                                                </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="text" placeholder=""  name="email" {...formik.getFieldProps('email')}/>
                                                    <span className="highlight"></span>
                                                    <label>Email Address</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="password" placeholder=""  name="pin" {...formik.getFieldProps('pin')}/>
                                                    <span className="highlight"></span>
                                                    <label>Terminal PIN</label>
                                            </div>
                                        </div>

                                        <button className="btn-fill-md majorButton" type="submit">Purchase</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default TV