import React from 'react'
import {Switch, Route } from 'react-router-dom'

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Airtime from './Airtime'
import Dashboard from './agent/Dashboard/Dashboard'
import Profile from './Profile'
import Wallet from './agent/Wallet/Wallet'
import Transaction from './agent/Transaction/Transaction'
import Dispute from './agent/Dispute/Dispute'
import Report from './agent/Report/Report'
import Device from './agent/Device/Device'
import Bill from './agent/Bill/Bill'
import Request from './agent/Request/Request'
import Welcome from './agent/Welcome'
import SetPIN from './SetPIN'



function AgentArea () {
    return (
        <>
         <Header />
        <div className="slim-body">
       
        <Sidebar />

        
        <Switch>
            <Route path='/agent/airtime' >
                <Airtime />
            </Route>
            <Route path='/agent/dashboard' >
                <Dashboard />
            </Route>
            <Route path='/agent/welcome' >
                <Welcome />
            </Route>
            <Route path='/agent/profile'>
                <Profile />
            </Route>
            <Route path='/agent/pin'>
                <SetPIN />
            </Route>
            <Route path='/agent/wallet'>
                <Wallet />
            </Route>
            <Route path='/agent/transactions'>
                <Transaction />
            </Route>
            <Route path='/agent/dispute'>
                <Dispute />
            </Route>
            <Route path='/agent/report'>
                <Report />
            </Route>
            <Route path='/agent/device'>
                <Device />
            </Route>
            <Route path='/agent/bill'>
                <Bill />
            </Route>
            <Route path='/agent/request'>
                <Request />
            </Route>
            <Route path='*' component={() => "404 NOT FOUND"} />
        </Switch>
       
        </div>
        </>
    )
}

export default AgentArea