import React from 'react'
import useFormik from 'formik'
import LienViewComponent from '../../../components/lien/View'

function View ({changeApp}) {
    return (
        <>
           <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                    </div>
                   <LienViewComponent />
                </div>
             </div>
           </>
    )
}

export default View