import React, { useEffect, useState } from 'react'
import Main from './Main'
import Create from './Create'
import Details from './Details'
import View from './View'


function Dispute () {
    const [params, setParams] = useState("manage")

    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    return (
        <>
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'create': <Create changeApp={changeApp} />,
                'details' : <Details changeApp={changeApp} />,
                'view' : <View changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
            
        </>
    )
}

export default Dispute