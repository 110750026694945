import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup';

import Spinner from '../../../components/Spinner'
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'


function Airtime ({changeApp, airtime}) {
    const [busy, setBusy] = useState(true)
    const [terminals, setTerminals] = useState([])
    const { id } = JSON.parse(localStorage.getItem('user'));

    const phoneRegEx = /^\d{13}$/

    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })
    

    useEffect(() => {
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            telcosNetWork : airtime,
            amount: 0,
            number: '',
            // email: '',
            agent_charge: '',
            terminalID: '',
        },
        validate: (what) => {
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyErrorWithID(formik.errors.amount,"amount", 3000)
                return;
            }
            if(formik.errors.number && formik.touched.number){
                NotificationService.notifyErrorWithID(formik.errors.number,"number", 3000)
                return;
            }
            // if(formik.errors.email && formik.touched.email){
            //     NotificationService.notifyErrorWithID(formik.errors.email,"email", 3000)
            //     return;
            // }
            if(formik.errors.agent_charge && formik.touched.agent_charge){
                NotificationService.notifyErrorWithID(formik.errors.agent_charge,"charge", 3000)
                return;
            }
            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyErrorWithID(formik.errors.terminalID,"terminal",  3000)
                return;
            }
            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyErrorWithID(formik.errors.pin,"pin", 3000)
                return;
            }
            
        },
        handleChange:() => {
          
        },
        validationSchema: Yup.object({
            amount: Yup.string()
                .required('Please enter the amount'),
            number: Yup.string()
                .required('Please enter the Phone Number')
                .matches(phoneRegEx, 'Please enter a valid Phone Number in the format 2347062067512'),
            // email: Yup.string()
            //     .email('Please enter a valid email')
            //     .required('Please enter the customer email'),
            terminalID: Yup.string()
                .required('Please select the terminal for this transaction'),
            agent_charge: Yup.string()
                .required('Please enter the your charge for this transaction'),
            pin: Yup.string()
                .required('Please enter the PIN for the selected terminal'),
          }),
        onSubmit: (values,{ resetForm }) => {  
             
            setBusy(true)         
            AgentService.buyAirtime({...values, pin: values.pin})
                .then(response => {
                    
                    NotificationService.notify("Airtime Purchase was successful. ")
                    setBusy(false)
                    changeApp('main')
                })
                .catch(error => {
                    if(error.response){
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if(error.message){
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else{
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)
                })
            }
      });
    
    return (
        <>
        { busy ? <Spinner /> :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("main")} className="pss-blue pd-b-10">← Go Back</a>
                    </div>

                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Buy Airtime</h2>
                            
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="number" placeholder=" "  name="amount" autoFocus {...formik.getFieldProps('amount')} />
                                                <span className="highlight"></span>
                                                <label>Amount</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input disabled={true} className="floating-input" type="text"  name="telcosNetWork"  {...formik.getFieldProps('telcosNetWork')} />
                                                <span className="highlight"></span>
                                                <label>Network</label>
                                            </div>
                                           
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="number" placeholder="2348023195555"  name="number" {...formik.getFieldProps('number')}/>
                                                    <span className="highlight"></span>
                                                    <label>Phone Number</label>
                                            </div>
                                            {/* <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="text" placeholder=" "  name="email" {...formik.getFieldProps('email')}/>
                                                    <span className="highlight"></span>
                                                    <label>Email Address</label>
                                            </div> */}
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="number" placeholder=" "  name="agent_charge" {...formik.getFieldProps('agent_charge')}/>
                                                    <span className="highlight"></span>
                                                    <label>Agent Charge</label>
                                                </div>
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                    <option value="" label="Please select terminal for this transaction" />
                                                    {terminalOptions}
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Terminal</label>                           
                                                </div>
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                        <input className="floating-input" type="password" placeholder=" "  name="pin" {...formik.getFieldProps('pin')}/>
                                                        <span className="highlight"></span>
                                                        <label>Terminal PIN</label>
                                                </div>                                                
                                        
                                        </div>
                                        <div className="row">
                                            
                                        </div>

                                        <button className="btn-fill-md majorButton" type="submit">Purchase</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Airtime