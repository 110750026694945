/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState, useEffect, useCallback} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {AdminService} from '../../../service/AdminService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import { SuperAdminService } from '../../../service/SuperAdminService';
import ImageViewerComponent from '../../../components/ImageViewer';



function Edit ({changeApp, aggregator}) {
    const [busy, setBusy] = useState(true)
    const [map, setMap] = useState(false)
    useEffect(() => {
        setBusy(false)
    }, [])

    const sas = process.env.REACT_APP_SAS;
      
      const images = [
        `${aggregator.passportphoto}${sas}`,
        `${aggregator.proofofaddress}${sas}`,
        `${aggregator.identification}${sas}`
    ];
    

    const formik = useFormik({
        initialValues: {
            firstname: aggregator.firstname,
            lastname: aggregator.lastname,
            email: aggregator.email,
            address: aggregator.address,
            lga: aggregator.lga,
            state: aggregator.state,
            activateEmail: aggregator.activateEmail,
            active: aggregator.isActive
        },
        validate: (what) => {
            
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            
        }),
        onSubmit: (values,{ resetForm }) => {
            console.log(values)            
        }
      });

      function Activate(e){
          console.log(formik.values.email)
          setBusy(true)

          AdminService.activateAgent({email:formik.values.email})
          .then(response => {
            NotificationService.notify("The Aggregator was successfully activated")
            setBusy(false)
            changeApp('view')
          })
          .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
          })
      }

      function Deactivate(e){
        setBusy(true)
        SuperAdminService.deactivateAgent({email: formik.values.email})
        .then(response => {
            NotificationService.notify("The Aggregator was successfully De-activated")
            setBusy(false)
            changeApp('view')
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

    return (
       <>
       { busy ? <Spinner /> :
        <div className="slim-mainpanel">
                <div className="container">
                    
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("view")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Edit Aggregator</h2>
                    </div>

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <fieldset >
                                <div className="floating-form ">
                                
                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" name="firstname" autoComplete="none" {...formik.getFieldProps('firstname')} />
                                                <span className="highlight"></span>
                                                <label>First Name</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="lastname" {...formik.getFieldProps('lastname')} />
                                                <span className="highlight"></span>
                                                <label>Last Name</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" name="email" {...formik.getFieldProps('email')} />
                                                <span className="highlight"></span>
                                                <label>Email Address</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="address" {...formik.getFieldProps('address')} />
                                                <span className="highlight"></span>
                                                <label>Address</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" name="lga" {...formik.getFieldProps('lga')} />
                                                <span className="highlight"></span>
                                                <label>Local Govt. Area</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="state" {...formik.getFieldProps('state')} />
                                                <span className="highlight"></span>
                                                <label>State</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" name="active" {...formik.getFieldProps('active')} />
                                                <span className="highlight"></span>
                                                <label>Active</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="activateEmail" {...formik.getFieldProps('activateEmail')} />
                                                <span className="highlight"></span>
                                                <label>activateEmail</label>
                                            </div>

                                        </div>

                                        <div className="row">
                                            { !formik.values.active ?  
                                                    <button onClick={(e) => Activate(e) } className="btn btn-outline-danger">Activate</button> : 
                                                    <button onClick={(e) => Deactivate(e) } className="btn btn-outline-danger">De-Activate</button>
                                            }
                                        </div>

                                </div>
                                </fieldset>
                            </form>
                            
                            
                        </div>
                    </div>
                    
                    <ImageViewerComponent images={images} />


                </div>
            </div>
            }
       </>
    )
}

export default Edit