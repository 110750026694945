import React from 'react'
import PublicHeader from '../components/PublicHeader'
import Footer from './Footer'

function Home () {
    return (
        <>
        
            <PublicHeader />
    
                <header className="header-area header-bg sure-style">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="header-inner">
                                    <h1 className="title">Surebanka is a suite of financial inclusion services.</h1>
                                    <p>Our understanding of market realities have ensured we build one of the best products that
                                        meet the needs of agency banking in Africa</p>
                                    <div className="btn-wrapper">
                                        <a className="boxed-btn button" href="/register" data-mode="popup" >Sign up</a>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="">
                                    <img className="img-center" src="assets/img/hero-surebanka.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
    

    
    <section className="main-content-area white-bg">
            <div className="container">
                <div className="row text-center">
                    <div className="col-lg-12">
                        <h1 className="center-title pt-120">With surebanka, you can process</h1>
                    </div>
                </div>
                <div className="row align-items-center parent-content-area">
                    <div className="col-lg-5">
                        <div className="child-content-area">
                            <h2 className="title">Cash withdrawals
                            </h2>
                            <p>We provide cash vending services to customers who can’t withstand the long queues at the
                                nearest financial institution or automated teller machine. Our product will reside with
                                an
                                agent that will debit the customer’s card and give cash. </p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="child-content-area child-content-area-img">
                            <div className="img-wrapper">
                                <img src="assets/img/cash-withdrawals.png" alt="" width=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center parent-content-area">
                <div className="col-lg-4">
                        <div className="child-content-area">
                            <h2 className="title">Cash deposit
                            </h2>
                            <p>Send money instantly to any bank account using our platform</p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="child-content-area child-content-area-img">
                            <div className="img-wrapper">
                                <img src="assets/img/cash-deposit.png" alt="" width=""/>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="row align-items-center parent-content-area">
                    <div className="col-lg-5">
                        <div className="child-content-area">
                            <h2 className="title">Bills payment
                            </h2>
                            <p>Pay your Dstv, Gotv, startimes subscription, as well as your electricity bills.</p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="child-content-area child-content-area-img">
                            <div className="img-wrapper">
                                <img src="assets/img/bills-payment.png" alt="" width=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center parent-content-area">
                <div className="col-lg-4">
                        <div className="child-content-area">
                            <h2 className="title">Card transfers
                            </h2>
                            <p>No cash for transfer, no problem..transfer money directly from your account to another bank
                                account</p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="child-content-area child-content-area-img">
                            <div className="img-wrapper">
                                <img src="assets/img/card-transfers.png" alt="" width=""/>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="row align-items-center parent-content-area">
                    <div className="col-lg-5">
                        <div className="child-content-area">
                            <h2 className="title">Balance inquiry
                            </h2>
                            <p>Skip the 'insufficient balance' problems by providing balance inquiry service to your
                                customers</p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="child-content-area child-content-area-img">
                            <div className="img-wrapper">
                                <img src="assets/img/balance-inquiry.png" alt="" width=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>    

    
    <section className="compact-area area-spaced">
        <div className="container">
            <div className="row text-center">
                <div className="col-lg-12">
                    <h1 className="center-title">How we stand out</h1>
                    <p>We have differentiated our product from others with the following features</p>
                </div>
            </div>
            <div className="row pt-60">
                <div className="col-lg-12 col-md-12">
                    <div className="feature-area">
                        <ul className="feature-list">
                            <li className="single-feature-list col-lg-4 col-md-6">
                                <div className="icon">
                                    <img src="assets/img/uptime.svg" alt=""/>
                                </div>
                                <div className="content feature-details">
                                    <h4 className="title">99.9% uptime
                                    </h4>
                                    <p>99.9% uptime enhanced by inbuilt failover technologies</p>
                                </div>
                            </li>
                            <li className="single-feature-list col-lg-4 col-md-6">
                                <div className="icon">
                                    <img src="assets/img/dispute-resolution.svg" alt=" "/>
                                </div>
                                <div className="content feature-details">
                                    <h4 className="title">Robust dispute resolution</h4>
                                    <p>Your customers will never again be
                                        dissatisfied at your outlet</p>
                                </div>
                            </li>
                            <li className="single-feature-list col-lg-4 col-md-6">
                                <div className="icon">
                                    <img src="assets/img/competitive-pricing.svg" alt=" "/>
                                </div>
                                <div className="content feature-details">
                                    <h4 className="title">Competitive pricing</h4>
                                    <p>To boost the profits you make as an agent by using our platform</p>
                                </div>
                            </li>
                            <li className="single-feature-list col-lg-4 col-md-6">
                                <div className="icon">
                                    <img src="assets/img/float-support.svg" alt=""/>
                                </div>
                                <div className="content feature-details">
                                    <h4 className="title">Float support
                                    </h4>
                                    <p>For establishment and expansion of your business. We will also empower you to own
                                        your terminal immediately you enroll with
                                        us as an agent</p>
                                </div>
                            </li>
                            <li className="single-feature-list col-lg-4 col-md-6">
                                <div className="icon">
                                    <img src="assets/img/cash-profit.svg" alt=" "/>
                                </div>
                                <div className="content feature-details">
                                    <h4 className="title">Real-time cash and profit report</h4>
                                    <p>Realtime cash and profit analysis to keep track of activities at
                                        your outlet while you are away and at end of day</p>
                                </div>
                            </li>
                            <li className="single-feature-list col-lg-4 col-md-6">
                                <div className="icon">
                                    <img src="assets/img/contact-center.svg" alt=" "/>
                                </div>
                                <div className="content feature-details">
                                    <h4 className="title">24hour contact center</h4>
                                    <p>24hour contact center with skilled support personnel to handle your
                                        issues and requests on the spot</p>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
   

  
     <section className="compact-area area-spaced cta-space">
        <div className="container">
            <div className="row text-center">
                <div className="col-lg-12">
                    <h1 className="center-title">Sign up today</h1>
                    <p>to become one of our agents</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="nav-right-content">
                        <ul>
                            <li className="button-wrapper">
                                <a className="boxed-btn button" href="/register" data-mode="popup">Sign up</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>


    
    <Footer />
        </>
    )
}

export default Home