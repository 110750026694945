import React, { useState, useEffect } from 'react'
import { NotificationService } from '../../../service/NotificationService'
import Terminal from '../../../components/terminal/Terminal';
import Spinner from '../../../components/Spinner'
import Main from './Main'
import View from './View'
import Create from './Create'

function Lien () {
     const [params, setParams] = useState("manage")
     const [terminal, setTerminal] = useState({})
    
    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(terminal){

        setParams(prev => {
            return "edit"
        })
        setTerminal(prev => {
            return terminal
        })
    }
    
    
    
    return (
        <>          
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'view': <View changeApp={changeApp}  onEdit={onEdit} />,
                'create' : <Create changeApp={changeApp} />,
            }[params] || <Main changeApp={changeApp} />
        }
            
        </>
    )
}

export default Lien