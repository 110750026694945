import React, { useState, useEffect } from 'react'
import Terminal from '../../../components/terminal/Terminal'
import Spinner from '../../../components/Spinner'
import {AgentService} from '../../../service/AgentService'
import { NotificationService} from '../../../service/NotificationService'

function View ({changeApp, onEdit}) {
    const [busy, setBusy] = useState(true)
    const [terminals, setTerminals] = useState([])
    const {id} = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            
            setTerminals(prev => {
                return response.data
            })
            
            if(response.data.length > 0){
                
                setBusy(false)
                NotificationService.notify("The number of Terminal(s) you have assigned to you is " + response.data.length)
            }
            else if(response.data.length === 0){
                NotificationService.notifyError("You do not have any Terminal/Device assigned", 5000)
            }
            setBusy(false)
           
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

    },[])

    return (
        <>
        {busy ? <Spinner /> :
        <div className="slim-mainpanel">
        <div className="container">      
            
            <Terminal terminals={terminals} onTerminalClick={onEdit} />
        </div>
    </div> }
        </>
    )
}

export default View