import React, { useEffect, useState } from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup';
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'
import Spinner from '../../../components/Spinner'

function Cashout ({changeApp}) {
    const [terminals, setTerminals] = useState([])
    const [busy, setBusy] = useState(true)

    const { id, usertype } = JSON.parse(localStorage.getItem('user'));

    
    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })


    useEffect(() => {
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            amount : '',
            pin: '',
            terminalID: '',
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        validationSchema: Yup.object({
            amount: Yup.string()
              .required('Please enter the amount'),
            amount: Yup.string()
              .required('Please enter the amount'),
        }),
        onSubmit: (values,{ resetForm }) => { 
            setBusy(true)

            AgentService.agentCashOut({...values, pin: values.pin})
            .then(response => {
                setBusy(false)
                
                NotificationService.notify("Cashout Completed Successfully")
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });
    return (
        <> { busy ? <Spinner /> :
        <div className="slim-mainpanel no-bg-img">
        <div className="container">
                <div class="main-header">
                    <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                </div>

                <div className="pd-x-5">
                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Cashout Details</h2>
                        
                </div>

                <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                <div className="floating-form">
                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="number" placeholder=" "  name="amount" autoFocus {...formik.getFieldProps('amount')} />
                                            <span className="highlight"></span>
                                            <label>Amount</label>
                                        </div>
                                       
                                        
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="password" placeholder=" "  name="pin" {...formik.getFieldProps('pin')}/>
                                            <span className="highlight"></span>
                                            <label>PIN</label>
                                        </div>
                                       
                                    </div>

                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                            <option value="" label="Please select a terminal" />
                                            {terminalOptions}                              
                                            </select>
                                            <span className="highlight"></span>
                                            <label>Ternminal</label>                           
                                        </div>
                                       
                                    </div>

                                    <button className="btn-fill-md majorButton" type="submit">Cashout</button>
                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
    )
}

export default Cashout