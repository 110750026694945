import React from 'react'
import { toast } from 'react-toastify';


import Register from '../../../components/Register'
import './Create.css'

function Create ({changeApp}) {
    const { id }  = JSON.parse(localStorage.getItem('user'));

    
    
    function completeReg() {
        changeApp("manage")
    }

    return (
        <>
            <div class="slim-mainpanel">
                <div class="container">
                    
                    <div class="main-header">
                        <a href="#"  onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>                        
                    </div>

                   
                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-0-force create-agg">           
                            <Register aggregator={id} completeReg={completeReg} />
                        </div>
                    </div>
                </div>

                
            </div>
            
        </>
    )
}

export default Create