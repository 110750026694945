import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { NotificationService } from '../../service/NotificationService';
import moment from 'moment'
import { SuperAdminService } from '../../service/SuperAdminService';
import Spinner from '../../components/Spinner'

function LienViewComponent () {
    const [busy, setBusy] = useState(true)
    const [liens, setLiens] = useState([])
    let lienElements

 //-----------------------------------------------------------------------------------------------------------
 lienElements = liens.slice(0,20).map(lien => {
    return (
        <tr key={lien._id} onClick={() => console.log(lien)}>
        <td><a href="#">{lien.terminalID.terminalPhysicalId + " " + lien.terminalID.terminalOwner.firstname}</a></td>
        <td><a href="#">{lien.amount}</a></td>
        <td><a href="#">{lien.startDate}</a></td>
        <td><a href="#">{lien.endDate}</a></td>
        <td><a href="#">{lien.status}</a></td>
        <td><a href="#">{new Date(lien.dateCreated).toDateString()}</a></td>            
    </tr>
    
    )
})

//------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        SuperAdminService.getLien()
        .then(response => {
            console.log(response)
            setBusy(false)

            setLiens(prev => {
                return response.data.data
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)  
        })
    }, [])

//------------------------------------------------------------------------------------------------------------------
    const formik = useFormik({
        initialValues: {
            owner : '',
            serial: '',
            terminalid: '',
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });

//------------------------------------------------------------------------------------------------------------------------------
      function dateFilter(e){
        formik.handleChange(e);
        
        let startDate = moment(document.getElementsByName('startDate')[0].value)
        let endDate = moment(document.getElementsByName('endDate')[0].value)
        

        startDate = startDate.startOf('day')
        endDate = endDate.endOf('day')

        //console.log(startDate + " - " + endDate)
        //console.log(moment(transactions[0].dateCreated))


    }
 
//------------------------------------------------------------------------------------------------------------------------------
    return (
        <> { busy ? <Spinner /> :
            <>
            <div className="row mg-t-20">
            <div className="col-md-12">
                <div className="dashboard-card-bg  pd-30">
                    <form >                               
                        <div className="floating-form">
                        <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.startDate} name="startDate" />
                                            
                                            <span className="highlight"></span>
                                            <label>Start Date</label>
                                        </div>
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.endDate} name="endDate"  />
                                            
                                            <span className="highlight"></span>
                                            <label>End Date</label>
                                        </div>
                        </div>
                                
                            <div className="row">
                                <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                    <input onChange={e => console.log()} className="floating-input" type="text" placeholder="" name="owner"  value={formik.values.owner}/>
                                    <span className="highlight"></span>
                                    <label>Terminal ID</label>
                                </div>
                                
                        
                            </div>
                       
                        
                        </div>
                        
                    </form>
                </div>
            </div>
            </div>
            
            <div className="table-wrapper table-responsive">
                <table id="datatable2" className="table display responsive nowrap">
                    <thead>
                        <tr >
                            <th className="">Terminal Details</th>
                            <th className="">Amount</th>
                            <th className="">Start date</th>
                            <th className="">End Date</th>
                            <th className="">Status</th>
                            <th className="">Date Created</th>                        
                        </tr>
                    </thead>
                    <tbody>
                        {lienElements}
                    
                    </tbody>
                </table>
            </div> 
            </>    
        }
    </>
    )
    
}

export default LienViewComponent