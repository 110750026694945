import React, {useState} from 'react'
import Main from './Main'
import View from './View'
import Create from './Create'
import Edit from './Edit';
import Email from './Email';

function Agent  () {
    const [params, setParams] = useState("manage");
    const [agent, setAgent] = useState({})


    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(agent){

        setParams(prev => {
            return "edit"
        })
        setAgent(prev => {
            return agent
        })
    }
    

    return (
       <>
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'view': <View changeApp={changeApp}  onEdit={onEdit} />,
                'create' : <Create changeApp={changeApp} />,
                'edit' : <Edit changeApp={changeApp} agent={agent} />,
                'email' : <Email changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
       </>
    )
}

export default Agent