import React, { useState, useEffect } from 'react'
import './Transaction.css'
import {useFormik} from 'formik'
import {NotificationService} from '../../service/NotificationService'
import { CSVLink, CSVDownload } from "react-csv"
import moment from 'moment'
 
import "react-datepicker/dist/react-datepicker.css";
import Details from './Details'
import View from './View'

function Transaction ({transactions}) {
    const [params, setParams] = useState('view')
    const [transaction, setTransaction] = useState([]);

    function changeApp(name) {
        console.log(name)
        
        setParams(prev => {
            return name
        })
    }

    function updateTransaction(transaction) {
        setTransaction(prev => {
            return transaction
        })
    }

    return (
       <>        
        {
            {
                'details': <Details changeApp={changeApp} transaction={transaction}  />,
                'view': <View changeApp={changeApp} transactions={transactions} updateTransaction={updateTransaction} />,
            }[params] || <View changeApp={changeApp} />
        }
       </> 
    )
}

export default Transaction