/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import useFormik from 'formik'
import Register from '../../../components/Register'
import { AdminService} from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'

function Balance ({changeApp}) {

    const [aggregatorBalances, setAggregatorBalances] = useState([])
    const [busy, setBusy] = useState(true)

    let aggregatorElements = []

    const formatter = new Intl.NumberFormat('en-US');

    function completeReg() {
        changeApp("manage")
    }

    //-----------------------------------------------------------------------------------------------------------
    if(aggregatorBalances.length !== 0 ){
        //console.log(transactions)
       
        aggregatorElements = aggregatorBalances.map(aggregator => {
        return (
            <tr key={aggregator.dateCreated} onClick={() => {}}>
                <td><a href="#">{aggregator.firstname + ' ' + aggregator.lastname}</a></td>
                <td><a href="#">{aggregator.phonenumber}</a></td>
                <td><a href="#">{formatter.format(aggregator.balance.toFixed(2))}</a></td>
                <td><a href="#">{aggregator.lga}</a></td>
                <td><a href="#">{aggregator.state}</a></td>
                <td><a href="#"><div className={aggregator.isActive ? "success" : "inactive"}>{aggregator.isActive ? "Enabled" : "Disabled"}</div></a></td>
                
            </tr>
            
          )
        })
    }


    useEffect(() => {
        AdminService.aggregatorEffectiveBalance()
        .then(response => {
            console.log(response)
            setAggregatorBalances(prevAgents => {
                return response.data.data
            });               
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[]);

    return (
       <>
       { busy ? 
            <Spinner /> 
                :
            <div class="slim-mainpanel">
                    <div class="container">
                        
                        <div class="main-header">
                            <a href="#"  onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>                        
                        </div>

                        <div className="table-wrapper table-responsive">
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                    <th className="">Aggregator Name</th>
                                    <th className="">Phone</th>
                                    <th className="">Balance</th>                                    
                                    <th className="">LGA</th>
                                    <th className="">State</th>
                                    <th className="">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {aggregatorElements} 
                            </tbody>
                        </table>
                    </div> 

                    
                        
                    </div>

                    
                </div>
        }
       </>
    )
}

export default Balance