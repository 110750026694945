import React, {useState, useEffect} from 'react';

import axiosConfig from '../helpers/AxiosMainConfig';



const Context = React.createContext();

function MainContextProvider({children}) {

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [progress, setProgress] = useState(0)
    const [ user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
      
   
    function updateUser(currentUser){
        
        setUser(prev => {
            return currentUser;
        })
    }

    function changeProgress(value) {
        setProgress(prev => {
          return value;
        })
      }

    function updateLoggedIn(status, token, user){

        localStorage.setItem('token', token);
        localStorage.setItem('user', user)
        setIsLoggedIn(prev =>{
            return status
        });

        setUser(prev => {
            return JSON.parse(user);
        })
    }

    function Logout(){
        
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        setIsLoggedIn(false)
        
    }

    useEffect(() => {

      setIsLoggedIn(prev => {
          return localStorage.getItem("token") ? true : false
      })

      setUser(prev => {
        return localStorage.getItem("user")  ;
    })

    },[])

    return (
        <Context.Provider value={{isLoggedIn, Logout, updateLoggedIn, progress, changeProgress, user}}>
            {children}
        </Context.Provider>
    )

}

export {MainContextProvider, Context};

