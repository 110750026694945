/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useFormik from 'formik'
import Register from '../../../components/Register'

function Create ({changeApp}) {

    function completeReg() {
        changeApp("manage")
    }

    return (
       <>
         <div class="slim-mainpanel">
                <div class="container">
                    
                    <div class="main-header">
                        <a href="#"  onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>                        
                    </div>

                   
                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-0-force create-agg">           
                            <Register completeReg={completeReg} />
                        </div>
                    </div>
                </div>

                
            </div>
       </>
    )
}

export default Create