import axiosConfig from '../helpers/AxiosSubConfig';

const getTerminalsByAggregator = (values) => {
   
   return axiosConfig.post('/terminal/GetTerminalsByAggregator', values)

}

const getAgentsByAggregator = (values) => {
    
    return axiosConfig.post('/manage-user/GetAgentsByAggregator', values)
}

const getAggregatorBalance = (values) => {
    
    return axiosConfig.get('/wallet/aggregatorBalance/' + values.aggregatorId,)
}

const getTransactionsByAggregator = (values) => {
    
    return axiosConfig.get('/transaction/transactionsByAggregator/' + values.aggregatorId,{params: values})
}

const getPerformanceReport = (values) => {
    
    return axiosConfig.get('/pdf/performanceReport', {params : values} )
}

const aggregatorCashOut = (values) => {
    return axiosConfig.post('/transaction/aggregator/cashout', values)
}


export const AggregatorService = {
    getTerminalsByAggregator,
    getAgentsByAggregator,
    getAggregatorBalance,
    getTransactionsByAggregator,
    aggregatorCashOut,
    getPerformanceReport
}