import React, { useState, useEffect } from 'react'
import {useFormik} from 'formik'
import {AdminService} from '../../service/AdminService'
import { SuperAdminService} from '../../service/SuperAdminService'
import Spinner from '../../components/Spinner'
import { NotificationService } from '../../service/NotificationService'
import moment from 'moment'
import * as Yup from 'yup';

function CreateLienComponent ({changeApp}) {
    const [busy, setBusy] = useState(true)
    const [agents, setAgents] = useState([])
    const [terminals, setTerminals] = useState([])
    const [duration, setDuration] = useState(0)

//------------------------------------------------------------------------------------------------------------------------------
const agentOptions = agents.map(agent => {
    return (
        <option key={agent._id} value={`${agent._id}`}>{`${agent.firstname + " " + agent.lastname}`}</option>
    )
})

//------------------------------------------------------------------------------------------------------------------------------
const terminalOptions = terminals.map(terminal => {
    return (
        <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
    )
})

//------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {

    AdminService.getAllAgents()
        .then(response => {
            setBusy(false)
            console.log(response)
            setAgents(prev => {
                return response.data
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])
//------------------------------------------------------------------------------------------------------------------------------
    const formik = useFormik({
        initialValues: {
            amount : '',
            pin: '',
            terminalid: '',
        },
        validate: (what) => {
            if(formik.errors.agent && formik.touched.agent){
                NotificationService.notifyError(formik.errors.agent, 3000)
                return;
            }
            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyError(formik.errors.terminalID, 3000)
                return;
            }
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyError(formik.errors.amount, 3000)
                return;
            }
        },
        validationSchema: Yup.object({
            agent: Yup.string()
                .required('Please select the Agent.'),
            terminalID: Yup.string()
                .required('Please select the Terminal for the Lien'),
            amount: Yup.number()
                .required('Please enter the Lien Amount')
        }),
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             
            setBusy(true)
            SuperAdminService.createLien({terminalID: values.terminalID, amount: values.amount, startDate: values.startDate, endDate: values.endDate, type: "Loan", status: "Active"})
                .then(response => {
                    console.log(response)
                    setBusy(false)
                    changeApp('manage')
                })
                .catch(error => {
                    if(error.response){
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if(error.message){
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else{
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)  
            })
        }
      });
//------------------------------------------------------------------------------------------------------------------------------
      function getAgentTerminals(e){
        formik.handleChange(e)
          console.log(e.target.value)
          setBusy(true)

          SuperAdminService.getTerminalsByAgent({agentId : e.target.value})
          .then(response => {
              console.log(response.data)
              if(response.data.length === 0){
                  NotificationService.notifyError("The Agent does not have any Terminal Assigned")
              }
              setTerminals(prev => {
                  return response.data
              })
              setBusy(false)
            
          })
          .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
            
          })

      }

//------------------------------------------------------------------------------------------------------------------------------
function dateFilter(e){
    formik.handleChange(e);
    
    let startDate = moment(document.getElementsByName('startDate')[0].value)
    let endDate = moment(document.getElementsByName('endDate')[0].value)
    

    startDate = startDate.startOf('day')
    endDate = endDate.endOf('day')

    if(startDate.isAfter(endDate))
    {
        NotificationService.notifyError("Transaction Start Date cannot be greater than End Date");
        return;
    }

    console.log(startDate.format() + " - " + endDate.format())
    //console.log(moment(transactions[0].dateCreated))

    const diff = endDate.diff(startDate, 'days')+1

    setDuration(prev => {
        return diff
    })

}


//------------------------------------------------------------------------------------------------------------------------------
    return (
        <> { busy ? <Spinner /> :
               <>

                <div className="pd-x-5">
                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Setup Lien Terminal</h2>
                        
                </div>

                <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                <div className="floating-form">

                                <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <select onChange={e => getAgentTerminals(e)}  className="floating-input" placeholder="" name="agent" value={formik.values.agent}  >
                                            <option value="" label="Please select the Agent" />
                                                {agentOptions}
                                            </select>
                                            <span className="highlight"></span>
                                            <label>Agent</label>                           
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                            <option value="" label="Please select the Terminal" />
                                             {terminalOptions}
                                            </select>
                                            <span className="highlight"></span>
                                            <label>Ternminal</label>                           
                                        </div>
                                       
                                    </div>

                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.startDate} name="startDate" />
                                            
                                            <span className="highlight"></span>
                                            <label>Start Date</label>
                                        </div>
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.endDate} name="endDate"  />
                                            
                                            <span className="highlight"></span>
                                            <label>End Date</label>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="number" placeholder=" "  name="amount" autoFocus {...formik.getFieldProps('amount')} />
                                            <span className="highlight"></span>
                                            <label>Amount</label>
                                        </div>
                                        
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" placeholder=" "  name="deduction" value={formik.values.amount ? formik.values.amount/duration : 0}/>
                                            <span className="highlight"></span>
                                            <label>Daily Deduction</label>
                                        </div>
                                       
                                    </div>

                                    <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" placeholder=" "  name="duration" value={duration ? duration : 0}/>
                                            <span className="highlight"></span>
                                            <label>Lien Duration in Days</label>
                                        </div>
                                       
                                    </div>

                                    <button className="btn-fill-md majorButton" type="submit">Place Lien</button>
                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </>
    }
    </> 
        
    )
}

export default CreateLienComponent