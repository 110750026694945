import React from 'react';

function Welcome () {

    return (
        <>
            <div className="slim-mainpanel">
                <div className="container">
                   
                    <div className="initial-success col-lg-6">
                        <h2 className="success-title header2">Welcome to the Surebanka Platform</h2>
                        <div className="signup-success">
                            <img className="img-responsive" src="../assets/checkmark.gif" alt="success icon" />
                        </div>
                        <div className="">                            
                            <a href='/admin/dashboard' className="btn light-button blue-color" id="submit">Go to dashboard</a>                            
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Welcome