import React, { useEffect, useState } from 'react'
import { AgentService } from '../../../service/AgentService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'

function Main ({changeApp}) {
    const [busy, setBusy] = useState(true)
    const [balance, setBalance] = useState("")
    const { id } = JSON.parse(localStorage.getItem('user'))

    const formatter = new Intl.NumberFormat('en-US');

    useEffect(() => {
        AgentService.getAgentBalance({agentId: id})
        .then(response => {
            
            setBusy(false)

            setBalance(prev => {
                return response.data.data
            })
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])

    return (
      <>
      {busy ? <Spinner /> : 
        <div className="slim-mainpanel">
                <div className="container">

                    <div className="main-header mg-b-30">
                        <h2 className="main-header-h-1 ">Dispute Management</h2>
                        <p className="signin-title-secondary">Welcome, find features here to log new disputes, view the  status of logged disputes and view all logged disputes.</p>
                    </div>
                    
                    <div  className="row mg-t-20">
                        <div onClick={() => changeApp("create")} className="col-6 col-md-6 col-lg-3">
                            
                                <div className="card pssCard bd-0 mg-b-30">
                                <a href="#">
                                    <div className="card-body ">
                                   
                                    <p className="user-brand-name">CREATE DISPUTE</p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">Log Dispute on failed/pending withdrawal transactions here</p>
                                    </div>
                                </a>
                                </div>
                        
                        </div>
                        
                        <div onClick={() => changeApp("view")} className="col-6 col-md-6 col-lg-3">
                            
                            <div className="card pssCard bd-0 mg-b-30">
                            <a href="#">
                                <div className="card-body ">
                                
                                <p className="user-brand-name">VIEW DISPUTE</p>
                                <p className="user-brand-text-2 tx-small mg-b-10">Wallet to Wallet Transfers from your Surebanka Account</p>
                                </div>
                            </a>
                            </div>
                        
                        </div>

                        

                    </div>
                                    
                </div>
            </div>
    }
      </>
    )
}

export default Main