import React, { useState, useContext } from "react";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

import 'react-toastify/dist/ReactToastify.css';
import "./App.css";

import { Switch, Route, useHistory, } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { ToastContainer } from 'react-toastify';

import { Context } from './contexts/MainContext'

import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AgentArea from "./pages/AgentArea";
import RegisterConfirm from "./pages/RegisterConfirm";
import EmailConfirm from "./pages/EmailConfirm";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPasword from "./pages/ResetPassword";
import AggregatorArea from "./pages/AggregatorArea";
import AdminArea from "./pages/AdminArea";
import SuperAdminArea from "./pages/SuperAdminArea";
import Registration from "./pages/Register";
import TailwindApp from "./pages/tailwind/TailwindApp";
import PTSPArea from "./pages/PTSPArea";
import ResetTerminalPin from "./pages/ResetTerminalPin";
import Terms from "./pages/Terms";
import LicenseOwner from "./pages/LicenseOwnerArea";
import TwoFactorMessage from "./pages/TwoFactorMessage";
import TwoFactorConfirm from "./pages/TwoFactorConfirm";

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();




function App() {
  let [idleTimer, setIdleTimer] = useState(null);

  const { Logout, isLoggedIn } = useContext(Context)

  const history = useHistory()

 

  function _onAction(e) {
    
  }

  function _onActive(e) {
    
  }

  function _onIdle(e) {
    if(isLoggedIn){
      Logout();
      history.push('/login')
    }
  }

  var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onActive={_onActive}
        onIdle={_onIdle}
        onAction={_onAction}
        debounce={250}
        timeout={5000 * 60}
      />
       <ToastContainer />

      <Switch >
        <Route exact path="/" component={Home} />

        <Route path="/login">
          <Login />
        </Route>

        <Route path="/terms">
          <Terms />
        </Route>

        <Route path="/register">
          <Registration />
        </Route>

        <Route path="/confirm">
          <RegisterConfirm />
        </Route>

        <Route path="/twofactor">
          <TwoFactorMessage />
        </Route>

        <Route path='/forgot'>
          <ForgotPassword />
        </Route>

        <Route path='/reset/:token'>
          <ResetPasword />
        </Route>

        <Route path="/confirmemail/:token">
          <EmailConfirm />
        </Route>

        <Route path="/confirmlogin/:token">
          <TwoFactorConfirm />
        </Route>

        <Route path="/resetTerminalPin/:token">
          <ResetTerminalPin />
        </Route>

        <Route path="/tailwind">
          <TailwindApp />
        </Route>

        <ProtectedRoute path="/agent" component={AgentArea} />

        <ProtectedRoute path="/aggregator" component={AggregatorArea} />

        <ProtectedRoute path="/admin" component={AdminArea} />

        <ProtectedRoute path="/superadmin" component={SuperAdminArea} />

        <ProtectedRoute path="/ptsp" component={PTSPArea} />

        <ProtectedRoute path="/licenseowner" component={LicenseOwner} />

        <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
    </>
  );
}

export default withAITracking( reactPlugin, App);
