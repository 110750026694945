import React, {useState} from 'react'
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import ClockLoader from 'react-spinners/ClockLoader'

function Spinner () {
   const [loading, setLoading] = useState(true)
   const [ran, setRan] = useState(Math.floor(Math.random() * 10) + 1 );


// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
        display: inline-block;
        margin: 120px auto;
        border-color: #4A90E2;
        `;

const style = {
    textAlign: 'center'
}

    return ( 
        <>
        {ran < 5 ? 
         <div className="slim-mainpanel">
                <div className="container"> 
                    <div style={style}>
                        <div className="sweet-loading">
                            <ClockLoader
                            css={override}
                            size={100}
                            color={"#4A90E2"}
                            loading={loading}
                            />
                        </div>
                    </div>
            </div>
        </div>
 :
        <div className="slim-mainpanel">
            <div className="container"> 
                <div style={style}>
                    <div className="sweet-loading">
                        <ScaleLoader
                        css={override}
                        size={100}
                        color={"#4A90E2"}
                        loading={loading}
                        />
                    </div>
                </div>
        </div>
    </div>
    }
    </>
    )    
}

export default Spinner