import React, {useContext} from 'react'


function Airtime (props) {

    return (
        <>
            <h2>Airtime</h2>
        </>
    )
}

export default Airtime