import React, { useState, useEffect } from 'react'
import { NotificationService } from '../../../service/NotificationService';
import { SuperAdminService } from '../../../service/SuperAdminService'
import moment from 'moment'
import Spinner from '../../../components/Spinner';
import _ from 'underscore'
import {useFormik} from 'formik'
import * as Yup from 'yup';


function Edit({ changeApp, dispute }) {
    const [busy, setBusy] = useState(false)

    const user = JSON.parse(localStorage.getItem('user'))

    const phoneRegExp = /^[0]\d{10}$/

    const formatter = new Intl.NumberFormat('en-US');

    console.log(dispute)

   

    const formik = useFormik({
        initialValues: {
            customerEmail : dispute.CustomerEmail,
            customerPhone: dispute.CustomerPhoneNumber,
            customerAccountName: dispute.CustomerName,
            rrn: dispute.RRN,
            customerAccountNumber: dispute.CustomerAccountNumber,
            customerBank: dispute.CustomerBank,
            details: dispute.Description,
            comment: ''

        },
        validate: (what) => {
            if(formik.errors.comment && formik.touched.comment){
                NotificationService.notifyErrorWithID(formik.errors.comment, "comment", 5000)
                return;
            }
        },
        validationSchema: Yup.object({
            comment: Yup.string()
                .required('Please enter the closure comment'),
            
        }),
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {  
            setBusy(true)           
            console.log(values)

            SuperAdminService.resolveDispute({disputeId: dispute._id, resolutionComment: values.comment})
            .then(response => {
                NotificationService.notify("The dispute request was successfully closed.")
                
                setBusy(false)
                
                changeApp('Main')
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });


    useEffect(() => {
    
    }, [])

    return (
        <>
        { busy ? <Spinner />  :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">

                    
                        <div className="dashboard-card-bg  pd-30">
                            <form onSubmit={formik.handleSubmit} >                                
                                <div className="floating-form">

                                    <div className="row">

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" name="rrn" {...formik.getFieldProps('rrn')} />
                                            <span className="highlight"></span>
                                            <label>Transaction Reference No.</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" name="customerPhone" {...formik.getFieldProps('customerPhone')} autoFocus />
                                            <span className="highlight"></span>
                                            <label>Customer Phone Number</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" name="customerEmail" {...formik.getFieldProps('customerEmail')} />
                                            <span className="highlight"></span>
                                            <label>Customer Email</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" name="customerAccountName" {...formik.getFieldProps('customerAccountName')} />
                                            <span className="highlight"></span>
                                            <label>Customer Account Name</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" name="customerAccountNumber" {...formik.getFieldProps('customerAccountNumber')} />
                                            <span className="highlight"></span>
                                            <label>Customer Account Number</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input disabled={true} className="floating-input" type="text" name="customerBank" {...formik.getFieldProps('customerBank')} />
                                            <span className="highlight"></span>
                                            <label>Customer Bank</label>
                                        </div>

                                        <div className="floating-label tran col-md-12 pd-b-10" >
                                            <textarea disabled={true} className="floating-input floating-textarea" rows="7" cols="50" type="text" placeholder=" "  name="details" {...formik.getFieldProps('details')}/>
                                            <span className="highlight"></span>
                                            <label>Request Details</label>
                                        </div>

                                        <div className="floating-label tran col-md-12 pd-b-10" >
                                            <select className="floating-input" placeholder="" name="bankname" {...formik.getFieldProps('comment')} >
                                                <option value="" label="Select a User Type" />
                                                <option value="Refunded to Customer">Refunded to Customer</option>
                                                <option value="Transaction not settled">Transaction not settled</option>
                                            </select>
                                            <span className="highlight"></span>
                                            <label>Closure Comment</label>
                                        </div>

                                    </div>

                                    <div className="btn-filter">
                                            <button type="submit" className="btn btn-primary">Close Dispute</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                       
                                               
                    
                </div>
            </div>
        }
        </>
    )
}

export default Edit