import React, { useState, useEffect } from 'react'
import { NotificationService } from '../../../service/NotificationService'
import Main from './Main'
import View from './View'
import Create from './Create'
import Edit from './Edit';
import Pending from './Pending'
import Process from './Process'

function Dispute () {
     const [params, setParams] = useState("manage")
     const [dispute, setDispute] = useState({})
    
    function changeApp(name) {
        console.log(name)
        
        setParams(prev => {
            return name
        })
    }

    function updateDispute(dispute){
        setDispute(prev => {
            return dispute
        })
    }   
    
    return (
        <>          
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'view': <View changeApp={changeApp}  updateDispute={updateDispute} />,
                'create' : <Create changeApp={changeApp} />,
                'edit' : <Edit changeApp={changeApp} dispute={dispute} />,
                'pending' : <Pending changeApp={changeApp} dispute={dispute}  updateDispute={updateDispute} />,
                'process' : <Process changeApp={changeApp} dispute={dispute}  updateDispute={updateDispute} />
            }[params] || <Main changeApp={changeApp} />
        }
            
        </>
    )
}

export default Dispute