import React from 'react'
import {Switch, Route } from 'react-router-dom'

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Dashboard from './aggregator/Dashboard/Dashboard'
import Profile from './Profile'
import Wallet from './aggregator/Wallet/Wallet'
import Transaction from './aggregator/Transaction/Transaction'
import Manage from './aggregator/Manage/Manage'
import Request from './aggregator/Request/Request'
import Welcome from './aggregator/Welcome'
import Report from './aggregator/Report/Report'
import SetPIN from './SetPIN'



function AggregatorArea () {
    return (
        <>
         <Header />
        <div className="slim-body">
       
        <Sidebar />

        
        <Switch>
            <Route path='/aggregator/welcome' >
                <Welcome />
            </Route>
            <Route path='/aggregator/dashboard' >
                <Dashboard />
            </Route>

            <Route path='/aggregator/profile'>
                <Profile />
            </Route>

            <Route path='/aggregator/pin'>
                <SetPIN />
            </Route>

            <Route path='/aggregator/wallet'>
                <Wallet />
            </Route>
            <Route path='/aggregator/manage'>
                <Manage />
            </Route>            
            <Route path='/aggregator/transaction'>
                <Transaction />
            </Route>
            <Route path='/aggregator/request'>
                <Request />
            </Route>
            <Route path='/aggregator/report'>
                <Report />
            </Route>
            <Route path='*' component={() => "404 NOT FOUND"} />
        </Switch>
       
        </div>
        </>
    )
}

export default AggregatorArea