import axiosConfig from '../helpers/AxiosMainConfig';


const register = (values) => {

   return axiosConfig.post('/user/register', values)

}

const forgot = (values) => {
    return axiosConfig.post('/user/forgotpassword', values)
}

const reset = (values) => {
    return axiosConfig.post('/user/resetpassword', values)
}


export const UserService = {
    register,
    forgot,
    reset
}