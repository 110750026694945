import React from 'react'
import {  toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Edit ({terminal, changeUp}) {
    console.log(terminal)

    function notify (message) {
        toast(message, 
         {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
        });
     }
   
     function notifyError(message, duration){
       toast.error(message, {
         position: "top-right",
         autoClose: duration,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         });
     }

     

     const formik = useFormik({
        initialValues: {
          terminalSerialNumber: terminal.terminalSerialNumber,
          terminalOwner: terminal.terminalOwner,
          terminalType: terminal.terminalType,
          datecreated: new Date(terminal.datecreated).toDateString()
        },
        validate: (what) => {
        },
        handleChange:() => {},
        validationSchema: Yup.object({
          email: Yup.string()
            .email('Please enter a valid email')
            .required('Please enter your Username'),
          password: Yup.string()
            .required('Please enter your Password')
        }),
        onSubmit: (values,{ resetForm }) => {
            
        }
      });


    return (
        <>
            <div class="slim-mainpanel">
                <div class="container">
                    
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeUp("view")} class="pss-blue pd-b-10">← Go Back</a>
                        <h2 class="main-header-h-1 ">Edit Terminal</h2>
                    </div>

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <fieldset disabled={true}>
                                <div className="floating-form ">
                                
                                        <div className="row">

                                            <div className="floating-label col-md-6 ">
                                                <input className="floating-input" type="text" placeholder=" " name="terminalSerialNumber" autoComplete="none" autoFocus {...formik.getFieldProps('terminalSerialNumber')} />
                                                <span className="highlight"></span>
                                                <label>terminalSerialNumber</label>
                                            </div>
                                            {formik.touched.terminalSerialNumber && formik.errors.terminalSerialNumber ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.terminalSerialNumber}</span></a> </p>) : null}

                                            <div className="floating-label col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="terminalOwner" autoComplete="none" {...formik.getFieldProps('terminalOwner')} />
                                                <span className="highlight"></span>
                                                <label>terminalOwner</label>
                                            </div>
                                            {formik.touched.terminalOwner && formik.errors.terminalOwner ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.terminalOwner}</span></a> </p>) : null}

                                        </div>

                                        <div className="row" >
                                            <div className="floating-label col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="terminalType" autoComplete="none" {...formik.getFieldProps('terminalType')} />
                                                <span className="highlight"></span>
                                                <label>terminalType</label>
                                            </div>
                                            {formik.touched.terminalType && formik.errors.terminalType ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.terminalType}</span></a> </p>) : null}
                                        

                                            <div className="floating-label col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="datecreated" {...formik.getFieldProps('datecreated')} />
                                                <span className="highlight"></span>
                                                <label>date Created</label>
                                            </div>
                                            {formik.touched.datecreated && formik.errors.datecreated ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.datecreated}</span></a> </p>) : null}
                                        </div>

                                        <div className="row">
                                        
                                        </div>
                                </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Edit