import React, { useState, useEffect } from 'react';
import Spinner from '../../components/Spinner';
import {AggregatorService} from '../../service/AggregatorService'

import {useHistory} from 'react-router-dom'

function Welcome () {
    
    const [busy, setBusy] = useState(true);
    const {usertype} = JSON.parse(localStorage.getItem('user'))
    const history = useHistory();

    function updateBusy(){
        setBusy(false)
    }   

    

    useEffect(() => {
        setTimeout(() => {
            setBusy(false)
        }, 5000);
 }, []);
   


    return (
        <>
            <div class="slim-mainpanel">
                <div class="container">
                   
                    <div class="initial-success col-lg-6">
                        <h2 class="success-title header2">Welcome to the Surebanka Platform</h2>
                        <div class="signup-success">
                            <img class="img-responsive" src="../assets/checkmark.gif" alt="success icon" />
                        </div>
                        <div class="">                            
                            <a href='/aggregator/dashboard' class="btn light-button blue-color" id="submit">Go to dashboard</a>                            
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Welcome