import React from 'react'

function Main ({changeApp}) {
    return (
       <>
            <div className="slim-mainpanel">
            <div className="container">
                <div className="main-header mg-b-30">
                    <h2 className="main-header-h-1 ">Bill & Airtime Payment</h2>
                    <p className="signin-title-secondary">Recharge airtime and pay bills here, easy, fast and reliable.</p>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <p className="signin-title-primary-2 tx-500">Select the operator</p>
                    </div>
                </div>

                <div className="row mg-t-20">
                    <div onClick={() => changeApp("MTN")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                        <div className="card pssCard bd-0 mg-b-30">
                            <div className="card-body ">
                            <img className="user-brands" src="../assets/logo/mtn.png" alt="" />
                            <p className="user-brand-name">MTN</p>
                            </div>

                        </div>
                        </a>
                    </div>
                    <div onClick={() => changeApp("AIRTEL")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                        <div className="card pssCard bd-0 mg-b-30">
                            <div className="card-body ">
                            <img className="user-brands" src="../assets/logo/airtel.png" alt="" />
                            <p className="user-brand-name">Airtel</p>
                            </div>
                        </div>
                        </a>
                    </div>
                    <div onClick={() => changeApp("9MOBILE")} className="col-6 col-md-6 col-lg-3">
                    <a href="#">
                        <div className="card pssCard bd-0 mg-b-30">
                        <div className="card-body ">
                            <img className="user-brands" src="../assets/logo/9mobile.png" alt="" />
                            <p className="user-brand-name">9 Mobile</p>
                        </div>

                        </div>
                        </a>
                    </div>
                    <div onClick={() => changeApp("Glo")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                            <div className="card pssCard bd-0 mg-b-30">
                                <div className="card-body">
                                    <img className="user-brands" src="../assets/logo/glo.png" alt="" />
                                    <p className="user-brand-name">Glo</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div onClick={() => changeApp("POWER")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                            <div className="card pssCard bd-0 mg-b-30">
                                <div className="card-body ">
                                    <img className="user-brands" src="../assets/logo/power.jpg" alt=""/>
                                    <p className="user-brand-name">POWER</p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">Pay your prepaid and postpaid  electricity bills with ease here</p>

                                </div>
                            </div>
                        </a>
                    </div>

                    <div onClick={() => changeApp("DsTV")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                            <div className="card pssCard bd-0 mg-b-30">
                                <div className="card-body ">
                                    <img className="user-brands" src="../assets/logo/dstv.png" alt=""/>
                                    <p className="user-brand-name">DSTV Subscriptions</p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">Renew your TV and cable subscriptions here with ease</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div onClick={() => changeApp("GOTV")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                            <div className="card pssCard bd-0 mg-b-30">
                                <div className="card-body ">
                                    <img className="user-brands" src="../assets/logo/Gotv.png" alt=""/>
                                    <p className="user-brand-name">GoTV Subscriptions</p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">Renew your TV and cable subscriptions here with ease</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div onClick={() => changeApp("StarTimes")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                            <div className="card pssCard bd-0 mg-b-30">
                                <div className="card-body ">
                                    <img className="user-brands" src="../assets/logo/startimes.png" alt=""/>
                                    <p className="user-brand-name">Startimes </p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">Renew your TV and cable subscriptions here with ease</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div onClick={() => changeApp("Data")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                            <div className="card pssCard bd-0 mg-b-30">
                                <div className="card-body ">
                                    <img className="user-brands" src="../assets/logo/data.jpg" alt=""/>
                                    <p className="user-brand-name">Buy Data </p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">Renew your data subscriptions on all networks here</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div onClick={() => changeApp("Voucher")} className="col-6 col-md-6 col-lg-3">
                        <a href="#">
                            <div className="card pssCard bd-0 mg-b-30">
                                <div className="card-body ">
                                    <img className="user-brands" src="../assets/logo/data.jpg" alt=""/>
                                    <p className="user-brand-name">Buy Airtime Vouchers</p>
                                    <p className="user-brand-text-2 tx-small mg-b-10">View voucher for successful airtime transactions </p>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>

        </div>
       </>
    )
}

export default Main