import React, { useState, useEffect } from 'react'
import Dispute from '../../../components/dispute/Dispute'
import { AgentService } from '../../../service/AgentService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'


function View ({changeApp, updateRequest}) {
    const [ busy, setBusy] = useState(true)
    const [requests, setRequests] = useState([])
    const { id } = JSON.parse(localStorage.getItem('user'))



    useEffect(() => {
        AgentService.getRequestByUser({userid: id})
        .then(response => {
            
            setRequests(prev => {
                return response.data.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])

    function changeUp(name){
        changeApp(name)
    }

    function onEdit(request){
        changeUp('edit')
        updateRequest(request)
    }

    return (
        <>
            {busy ? <Spinner /> : 
            <div class="slim-mainpanel">
                <div class="container">
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeUp("main")} class="pss-blue pd-b-10">← Go Back</a>
                        <h2 class="main-header-h-1 ">Requests</h2>
                    </div>
                    <Dispute disputes={requests} onEdit={onEdit}/>
                </div>
            </div>
            }
        </>
        
    )
}

export default View