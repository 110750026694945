import React,{useState, useEffect} from 'react'
import {AggregatorService} from '../../../service/AggregatorService'
import Spinner from '../../../components/Spinner'
import {NotificationService} from '../../../service/NotificationService'

function Dashboard () {

    const [agents, setAgents] = useState(0)
    const [terminals, setTerminals] = useState(0)
    const [balance, setBalance] = useState(0)
    const { id } = JSON.parse(localStorage.getItem('user'))
    const  [ now ] = useState(new Date().toLocaleTimeString())
    const [busy, setBusy] = useState(true);
    
    
    useEffect(() => {
           

        AggregatorService.getAgentsByAggregator({aggregatorId: id})
        .then(response => {
            
            setAgents(prev => {
                return response.data.length
            })
            AggregatorService.getTerminalsByAggregator({aggregatorId: id})
            .then(resp => {
               
                setTerminals(prev => {
                    return resp.data.length
                })
                NotificationService.notify("Dashboard fully updated with recent data")
                setBusy(false)
            })                
        })
        .catch(error => {
            if(error.message){
                NotificationService.notifyError(error.message, 5000);
            }
            else{
                NotificationService.notifyError("Please check your network connection.", 5000)
            }
            setBusy(false)
        }) 
        
    AggregatorService.getAggregatorBalance({aggregatorId: id})
    .then(response => {
        console.log(response.data.data)
        setBalance(prev => {
            return response.data.data
        })
        NotificationService.notify("Latest data pulled without errors");
        
    })
    .catch(error => {
        setBusy(false)
        if(error.response){
            NotificationService.notifyError(error.response.data.message, 5000)
        }
        else if(error.message){
            NotificationService.notifyError(error.message, 5000)
        }
        else{
            NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
        }
    })

    
},[])
    
    return (
        <>
            { busy ? <Spinner /> :
                <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header mg-b-30">
                        <h2 className="main-header-h-1">Your Dashboard</h2>
                    </div>
                    <div className="dashboard-inner pd-x-0-force">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div className="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div className="card-body">
                                        <p className="dashboard-card-title tertiary-color pd-t-10">Wallet Balance
                                        </p>
                                        <p className="main-header-p tertiary-color pd-b-20 blue-color">{balance + ' Naira'}</p>
                                        <p className="small-signifier">{now}</p>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div className="card pssCard basic-card pd-b-30 pd-x-15">
                                    <div className="card-body ">
                                        <p className="dashboard-card-title tertiary-color mg-b-0">Number of Active Agents
                                        </p>
                                        <p className="dashboard-card-title tertiary-color" style={{fontSize: "24px"}}>Agents : <br />{agents}</p>
                                        <p className="small-signifier">{now}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div className="card pssCard basic-card pd-b-30 pd-x-15">
                                    <div className="card-body ">
                                        <p className="dashboard-card-title tertiary-color mg-b-0">Number of Active Devices
                                        </p>
                                        <p className="dashboard-card-title tertiary-color" style={{fontSize: "24px"}}>Terminals/POS/Android : <br />{terminals}</p>
                                        <p className="small-signifier">{now}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Dashboard