import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup';
import Spinner from '../../../components/Spinner';
import { AgentService } from '../../../service/AgentService';
import { NotificationService } from '../../../service/NotificationService';


function Data ({changeApp}) {
    const [busy, setBusy] = useState(true)
    const [terminals, setTerminals] = useState([])
    const { id } = JSON.parse(localStorage.getItem('user'));
    const [products, setProducts] = useState([])

    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    const dataOptions = products.map( product => {
        return (
            <option key={product.product_id} init={`${product.face_value}`} value={`${product.product_id}`}>{`${product.product_id.split('-').pop()}`}</option>
        )
    })

    useEffect(() => {
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])


    const formik = useFormik({
        initialValues: {
            amount: 0,
            tv_network: 0,
              
        },
        validate: (what) => {
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyError(formik.errors.amount, 3000)
                return;
            }
            if(formik.errors.email && formik.touched.email){
                NotificationService.notifyError(formik.errors.email, 3000)
                return;
            }
            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyError(formik.errors.terminalID, 3000)
                return;
            }
            if(formik.errors.agent_charge && formik.touched.agent_charge){
                NotificationService.notifyError(formik.errors.agent_charge, 3000)
                return;
            }
            if(formik.errors.phone && formik.touched.phone){
                NotificationService.notifyError(formik.errors.phone, 3000)
                return;
            }
            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyError(formik.errors.pin, 3000)
                return;
            }
            
        },
        handleChange:() => {
          
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Please enter a valid email')
                .required('Please enter the customer email'),
            terminalID: Yup.string()
                .required('Please select the terminal for this transaction'),
            agent_charge: Yup.string()
                .required('Please enter your agent charges for this transaction'),
            phone: Yup.string()
                .required('Please enter the customer phone number '),
                //.test('len', 'Please enter a valid phone number in the format 2347062067512', val => val.length === 13),
            bouquet: Yup.string()
                .required('Please select  the Cable TV Bundle'),
            pin: Yup.string()
                .required('Please enter the PIN for the selected Terminal'),
            amount: Yup.string()
                .required('Amount not set, please try again later.'),
          }),
        onSubmit: (values,{ resetForm }) => {  
              
            setBusy(true)        
            
            AgentService.buyDataSub({product_id:  values.bouquet,number: values.phone , pin: values.pin ,amount: parseInt(values.amount), terminalID: values.terminalID, agent_charge: values.agent_charge })
            .then(response => {
                
                setBusy(false)
                NotificationService.notify("The Subscription was completed successfully.")
                changeApp("main")
                
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

      function getPlans(e){
         
          
          setBusy(true)

          AgentService.getTelcoData({telcosData: e.target.value})
          .then(response => {
            
            setProducts(prev => {
                return response.data.products
            })
            setBusy(false)
          })
          .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
          })

          formik.handleChange(e);
      }

      function setDataPrice(e){
        
        const bouquetSelect = document.getElementsByName('bouquet')[0]
        const bouquet = bouquetSelect.options[bouquetSelect.selectedIndex].getAttribute('init')

        

        formik.values.amount = parseFloat(bouquet).toFixed(2)

        formik.handleChange(e);
      }
    
    return (
        <>
         { busy ? <Spinner /> :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("main")} className="pss-blue pd-b-10">← Go Back</a>
                    </div>

                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Buy Data</h2>
                            
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input disabled={true} className="floating-input" type="number" placeholder=" "  name="amount" {...formik.getFieldProps('amount')} />
                                                <span className="highlight"></span>
                                                <label>Amount(in Naira)</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select onChange={e => getPlans(e)} className="floating-input" placeholder="" name="network" value={formik.values.network} >
                                                    <option value="" label="Please select Network Type" />
                                                    <option value="MTN" label="MTN" />
                                                    <option value="Airtel" label="Airtel" />
                                                    <option value="Glo" label="GLO" />
                                                    <option value="9Mobile" label="9Mobile" />
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Network</label>                           
                                                </div>
                                           
                                        </div>

                                      

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                    <option value="" label="Please select terminal for this transaction" />
                                                    {terminalOptions}
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Terminal</label>                           
                                                </div>
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select onChange={e => setDataPrice(e)} className="floating-input" placeholder="" name="bouquet" value={formik.values.bouquet} >
                                                    <option value="" label="Please select a the Subscription Type" />
                                                    {dataOptions}
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Data Plans</label>                           
                                                </div>
                                        
                                        </div>
                                        <div className="row">
                                            
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="number" placeholder=" "  name="agent_charge" {...formik.getFieldProps('agent_charge')}/>
                                                    <span className="highlight"></span>
                                                    <label>Agent Charge</label>
                                                </div>
                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="number" placeholder="2348023195555"  name="phone" {...formik.getFieldProps('phone')}/>
                                                    <span className="highlight"></span>
                                                    <label>Phone Number</label>
                                            </div>
                                        
                                        </div>

                                        <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="password" placeholder=""  name="pin" {...formik.getFieldProps('pin')}/>
                                                    <span className="highlight"></span>
                                                    <label>Terminal PIN</label>
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="text" placeholder=""  name="email" {...formik.getFieldProps('email')}/>
                                                    <span className="highlight"></span>
                                                    <label>Email Address</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                     
                                        </div>

                                        <button className="btn-fill-md majorButton" type="submit">Purchase</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Data