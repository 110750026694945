import React from 'react'


function Bills (props) {

    return (
        <>
            <h2>Bills</h2>
        </>
    )
}

export default Bills