import React from 'react'
import CreateDispute from '../../../components/dispute/CreateDispute'

function Create ({changeApp}) {
    return (
        <>
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                        <div class="main-header">
                            <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                        </div>
                    <CreateDispute />
                </div>
            </div>
            
        </>
    )
}

export default Create