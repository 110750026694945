import React, { useState, useEffect } from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup';
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'
import Spinner from '../../../components/Spinner';

function Transfer ({changeApp}) {
    const [terminals, setTerminals] = useState([])
    const [busy, setBusy] = useState(true)

    const { id } = JSON.parse(localStorage.getItem('user'));

    
    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    useEffect(() => {
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            amount : '',
            pin: '',
            narration: '',
            debitTerminalID: '',
            creditTerminalID: ''
        },
        validate: (what) => {
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyErrorWithID(formik.errors.amount, 'amount', 3000)
                return;
            }
            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyErrorWithID(formik.errors.pin, 'pin',  3000)
                return;
            }
           
            if(formik.errors.debitTerminalID && formik.touched.debitTerminalID){
                NotificationService.notifyErrorWithID(formik.errors.debitTerminalID, 'debit',  3000)
                return;
            }
            if(formik.errors.creditTerminalID && formik.touched.creditTerminalID){
                NotificationService.notifyErrorWithID(formik.errors.creditTerminalID, 'credit',  3000)
                return;
            }
        },
        validationSchema: Yup.object({
            amount: Yup.string()
              .required('Please enter the amount'),
            pin: Yup.string()
              .required('Please enter the amount'),
            debitTerminalID: Yup.string()
              .required('Please select the Debit Terminal'),
            creditTerminalID: Yup.string()
              .required('Please select the Credit Terminal'),
        }),
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => { 
            setBusy(true)
           
            if(values.creditTerminalID === values.debitTerminalID){
                NotificationService.notifyError("The Credit Terminal cannot be the same Debit Terminal ")
                return;
            }

            AgentService.walletTransfer({...values, pin: values.pin})
            .then(response => {
                
                setBusy(false)
                NotificationService.notify("The transfer was completed successfully.")
                changeApp('main')
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

    return (
        <>
        {busy ? <Spinner /> :
        <div className="slim-mainpanel no-bg-img">
        <div className="container">
                <div class="main-header">
                    <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                </div>

                <div className="pd-x-5">
                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Wallet Transfer</h2>
                        
                </div>

                <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                <div className="floating-form">
                                <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <select  className="floating-input" placeholder="" name="debitTerminalID" {...formik.getFieldProps('debitTerminalID')} >
                                            <option value="" label="Please select Terminal to Debit" />
                                            {terminalOptions}                              
                                            </select>
                                            <span className="highlight"></span>
                                            <label>Ternminal From</label>                           
                                        </div>
                                       
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <select  className="floating-input" placeholder="" name="creditTerminalID" {...formik.getFieldProps('creditTerminalID')} >
                                            <option value="" label="Please select Terminal to Credit" />
                                            {terminalOptions}                              
                                            </select>
                                            <span className="highlight"></span>
                                            <label>Ternminal To</label>                           
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="number" placeholder=" "  name="amount" {...formik.getFieldProps('amount')} />
                                            <span className="highlight"></span>
                                            <label>Amount</label>
                                        </div>
                                        {/* {formik.touched.terminalPhysicalId && formik.errors.terminalPhysicalId ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.terminalPhysicalId}</span></a> </p>) : null} */}
                                        
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="password" placeholder=" "  name="pin" {...formik.getFieldProps('pin')}/>
                                            <span className="highlight"></span>
                                            <label>PIN</label>
                                        </div>
                                        {/* {formik.touched.terminalSerialNumber && formik.errors.terminalSerialNumber ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.terminalSerialNumber}</span></a> </p>) : null} */}
                                    </div>

                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="text" placeholder=" "  name="narration" autoFocus {...formik.getFieldProps('narration')} />
                                            <span className="highlight"></span>
                                            <label>Narration</label>
                                        </div>
                                    </div>

                                   

                                    <button className="btn-fill-md majorButton" type="submit">Transfer</button>
                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
    )
}

export default Transfer