import React, { useState, useEffect } from 'react'
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'
import moment from 'moment'
import Spinner from '../../../components/Spinner';
import _ from 'underscore'
import {useFormik} from 'formik'
import * as Yup from 'yup';


function Create({ changeApp }) {
    const [transactions, setTransactions] = useState([])
    const [busy, setBusy] = useState(true)
    const [select, setSelect] = useState(false)
    const [tran, setTran] = useState([])

    const user = JSON.parse(localStorage.getItem('user'))

    let transactionElements = [];

    const phoneRegExp = /^[0]\d{10}$/

    const formatter = new Intl.NumberFormat('en-US');

    if (transactions.length !== 0) {
        transactionElements = transactions.map(transaction => {
            return (
                <tr key={transaction.datecreated} onClick={() => updateTran(transaction)}>
                    <td><a href="#">{transaction.rrn}</a></td>
                    <td><a href="#">{formatter.format(transaction.amount.toFixed(2))}</a></td>
                    <td><a href="#">{transaction.responseDescription}</a></td>
                    <td><a href="#">{transaction.TransactionType}</a></td>
                    <td><a href="#">{new Date(transaction.dateCreated).toDateString()}</a></td>
                    <td><a href="#"><div className="success">Select</div></a></td>
                </tr>
            )
        })
    }

    const searchParams = {

        endDate: moment().add(1, 'days').format('yyyy-MM-DD'),
        startDate: moment().subtract('days', 356).format('yyyy-MM-DD'),
        agentId: user.id
    }

    function updateTran(transaction){
        formik.values.rrn = transaction.rrn;
        setTran(prev => {
            return transaction
        })

        setSelect(true)
    }

    const formik = useFormik({
        initialValues: {
            customerEmail : '',
            customerPhone: '',
            customerAccountName: '',
            rrn: tran.rrn,
            customerAccountNumber: '',
            customerBank: '',
            details: ''

        },
        validate: (what) => {
            if(formik.errors.rrn && formik.touched.rrn){
                NotificationService.notifyErrorWithID(formik.errors.rrn, "rrn", 5000)
                return;
            }
            if(formik.errors.customerEmail && formik.touched.customerEmail){
                NotificationService.notifyErrorWithID(formik.errors.customerEmail, "customerEmail", 5000)
                return
            }
            if(formik.errors.customerPhone && formik.touched.customerPhone){
                NotificationService.notifyErrorWithID(formik.errors.customerPhone, "customerPhone", 5000)
                return;
            }
            if(formik.errors.customerAccountName && formik.touched.customerAccountName){
                NotificationService.notifyErrorWithID(formik.errors.customerAccountName, "customerAccountName", 5000)
                return
            }
            if(formik.errors.customerAccountNumber && formik.touched.customerAccountNumber){
                NotificationService.notifyErrorWithID(formik.errors.customerAccountNumber, "customerAccountNumber", 5000)
                return;
            }
            if(formik.errors.customerBank && formik.touched.customerBank){
                NotificationService.notifyErrorWithID(formik.errors.customerBank, "customerBank", 5000)
                return
            }
            if(formik.errors.details && formik.touched.details){
                NotificationService.notifyErrorWithID(formik.errors.details, "details", 5000)
                return;
            }
        },
        validationSchema: Yup.object({
            rrn: Yup.string()
                .required('RRN not set, start the process again'),
            customerAccountName: Yup.string()
                .required('Please enter the Customer Account Name'),
            customerAccountNumber: Yup.string()
                .required('Please enter the Customer Account Number'),
            customerEmail: Yup.string()
                .required('Please enter the Customer Email'),
            customerBank: Yup.string()
                .required('Please enter the Customer Account Name'),
            customerPhone: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid use for examples 07055554444 format'),
            details: Yup.string()
                .required('Please enter the Dispute Request Details.'),
        }),
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {  
            setBusy(true)           
            console.log(values)

            AgentService.createDispute({CustomerEmail: values.customerEmail, CustomerName: values.customerAccountName, TransactionId: tran._id, Description: values.details, CustomerPhoneNumber: values.customerPhone,
            CustomerBank: values.customerBank, CustomerAccountNumber: values.customerAccountNumber })
            .then(response => {
                NotificationService.notify("The dispute request was created successfully.")
                
                setBusy(false)
                
                changeApp('Main')
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });


    useEffect(() => {
        AgentService.getFailedTransactionsByAgent(searchParams)
            .then(response => {
                console.log(response)

                const sorted = _.sortBy(response.data.data, 'dateCreated')
                setTransactions(prev => {
                    return sorted.reverse()
                        
                })

                setBusy(false)
            })
            .catch(error => {
                if (error.response) {
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if (error.message) {
                    NotificationService.notifyError(error.message, 5000)
                }
                else {
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
    }, [])

    return (
        <>
        { busy ? <Spinner />  :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">

                    { select ?
                        <div className="dashboard-card-bg  pd-30">
                            <form onSubmit={formik.handleSubmit} >                                
                                <div className="floating-form">

                                    <div className="row">

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="text" name="rrn" {...formik.getFieldProps('rrn')} />
                                            <span className="highlight"></span>
                                            <label>Transaction Reference No.</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="text" name="customerPhone" {...formik.getFieldProps('customerPhone')} autoFocus />
                                            <span className="highlight"></span>
                                            <label>Customer Phone Number</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="text" name="customerEmail" {...formik.getFieldProps('customerEmail')} />
                                            <span className="highlight"></span>
                                            <label>Customer Email</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="text" name="customerAccountName" {...formik.getFieldProps('customerAccountName')} />
                                            <span className="highlight"></span>
                                            <label>Customer Account Name</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="text" name="customerAccountNumber" {...formik.getFieldProps('customerAccountNumber')} />
                                            <span className="highlight"></span>
                                            <label>Customer Account Number</label>
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="text" name="customerBank" {...formik.getFieldProps('customerBank')} />
                                            <span className="highlight"></span>
                                            <label>Customer Bank</label>
                                        </div>

                                        <div className="floating-label tran col-md-12 pd-b-10" >
                                            <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" placeholder=" "  name="details" {...formik.getFieldProps('details')}/>
                                            <span className="highlight"></span>
                                            <label>Request Details</label>
                                        </div>

                                    </div>

                                    <div className="btn-filter">
                                            <button type="submit" className="btn btn-primary">Create Dispute</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        : 
                        
                             <div className="table-wrapper table-responsive">
                                <table id="datatable2" className="table display responsive nowrap">
                                    <thead>
                                        <tr>
                                            <th className="">RRN</th>
                                            <th className="">Amount</th>
                                            <th className="">Response Message</th>
                                            <th className="">TranType</th>
                                            <th className="">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionElements}
                                    </tbody>
                                </table>
                            </div>
                                               
                    }
                </div>
            </div>
        }
        </>
    )
}

export default Create