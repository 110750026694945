import React, {useState, useEffect} from 'react'
import { AdminService } from '../../../service/AdminService'
import Terminal from '../../../components/terminal/Terminal';
import Spinner from '../../../components/Spinner'
import { NotificationService } from '../../../service/NotificationService'

function View ({changeApp, onEdit}) {
    const [terminals, setTerminals] = useState([]);
    const [busy, setBusy] = useState(true)

    function changeUp(param){
        changeApp(param)
    }

    useEffect(() => {
        AdminService.getAllTerminals()
        .then(response => {            
            
            if(response.data.length > 0){
                setTerminals(prev => {
                    return response.data
                });
                NotificationService.notify("The number of terminals on the platform is " + response.data.length);
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("You do not have any Agents assigned");
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[]);


    function updateTerminal(){
        changeApp('edit')
        onEdit()
    }
    return (
        <>
        { busy ? <Spinner /> :
            <>    
                <div className="slim-mainpanel">
                    <div className="container">      
                        <div className="main-header">
                            <a href="#" onClick={() =>  changeUp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                            <h2 className="main-header-h-1 ">View Terminals</h2>
                        </div>
                        <Terminal terminals={terminals} onTerminalClick={onEdit} />
                    </div>
                </div>
            </>
        
        }
        </>
    )
}

export default View