import React from 'react'
import Profit from '../../../components/report/Profit'

function CashProfit ({changeApp}) {
    return (
        <>
            <div className="slim-mainpanel no-bg-img">
                <div className="container">    
                    <div class="main-header">
                            <a href="#" onClick={() =>  changeApp("main")} class="pss-blue pd-b-10">← Go Back</a>
                    </div>
                    <div className="row table-overall-headings mg-b-10">
                            <div className="col-md-6">
                                <h2>Profit Report</h2>
                                {/* <p>View and download all transactions easily here</p> */}
                            </div>
                    </div>
                    <Profit />
                </div>
            </div>
        </>
    )
}

export default CashProfit