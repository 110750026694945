import React, { useState, useEffect } from 'react'
import Spinner from '../../../components/Spinner'
import { NotificationService } from '../../../service/NotificationService'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { AdminService } from '../../../service/AdminService'

function Edit ({changeApp, dispute}) {
    const [status, setStatus] = useState("")
    const [busy, setBusy] = useState(true)
    const [map, setMap] = useState(false)
    const { id } = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        AdminService.updateRequestStatus({requestID:dispute._id, status: 'Pending'})
        .then(response => {
            NotificationService.notify("The request has been assigned to you.")
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
            changeApp('pending')
        }) 
    }, [])

    const formik = useFormik({
        initialValues: {
             title: dispute.title,
             status: dispute.status,
             details: dispute.details,
             dateOpened: dispute.dateOpened,
             type: dispute.requestTypeID.description,
             user: dispute.requester.firstname + " " + dispute.requester.lastname,
             email: dispute.requester.email,
             usertype: dispute.requester.usertype,
             comment: ''
        },
        validate: (what) => {
            
            if(formik.errors.comment && formik.touched.comment){
                NotificationService.notifyError(formik.errors.comment, 5000)
            }
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            comment: Yup.string()
                .required('Please enter a closure comment for the request.'),
            
        }),
        onSubmit: (values,{ resetForm }) => {
            console.log(values)

            setBusy(true)

            console.log(status)

            AdminService.closeRequest({requestTypeID: dispute.requestTypeID._id, closedBy: id, closureComment: values.comment, status: status, requestID: dispute._id,
                "title": dispute.title, transactionID: dispute.transactionID, "approver": id, requester: dispute.requester._id })
                .then(response => {
                    console.log(response)
                    setBusy(false)
                    NotificationService.notify("The request has been closed")
                    changeApp("main")
                })
                .catch(error => {
                    if(error.response){
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if(error.message){
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else{
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)
                    
                })
        }
      });

      function returnRequest(e) {
        setBusy(true)
        console.table(e)
        AdminService.updateRequestStatus({requestID:dispute._id, status: 'New'})
        .then(response => {
            
            NotificationService.notify("The request have been returned back to pending queue.")
            setBusy(false)
            changeApp('main')
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
            changeApp('main')
        }) 
    }
    
    return (
        <>
        { busy ? <Spinner /> :
         <div className="slim-mainpanel">
                 <div className="container">
                     
                     <div className="main-header">
                         <a href="#" onClick={() =>  changeApp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                         <h2 className="main-header-h-1 ">Request Details</h2>
                     </div>
 
                     <div className="centered-form col-lg-12">
                         <div className="signin-box pd-20-force create-agg">  
                             <form autoComplete="off" onSubmit={formik.handleSubmit}>
                             
                                 <div className="floating-form ">
                                 
                                         <div className="row">
 
                                             <div className="floating-label  col-md-6">
                                                 <input disabled={!map} className="floating-input" type="text" name="title" {...formik.getFieldProps('title')} />
                                                 <span className="highlight"></span>
                                                 <label>Title</label>
                                             </div>                                        
 
                                             <div className="floating-label  col-md-6">
                                                 <input disabled={true} className="floating-input" type="text" name="status" {...formik.getFieldProps('status')} />
                                                 <span className="highlight"></span>
                                                 <label>Status</label>
                                             </div>
 
                                         </div>
 
                                         <div className="row">
 
                                         <div className="floating-label tran col-md-12 pd-b-10" >
                                                     <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" name="details" value={formik.values.details}/>
                                                     <span className="highlight"></span>
                                                     <label>Request Details</label>
                                             </div>
 
                                         </div>
 
                                         <div className="row">
 
                                             <div className="floating-label  col-md-6">
                                                 <input disabled={!map} className="floating-input" type="text" name="type" {...formik.getFieldProps('type')} />
                                                 <span className="highlight"></span>
                                                 <label>Request Type</label>
                                             </div>                                        
 
                                             <div className="floating-label  col-md-6">
                                                 <input disabled={true} className="floating-input" type="text" name="user" {...formik.getFieldProps('user')} />
                                                 <span className="highlight"></span>
                                                 <label>User</label>
                                             </div>
 
                                         </div>
 
                                         <div className="row">
 
                                             <div className="floating-label  col-md-6">
                                                 <input disabled={!map} className="floating-input" type="text" name="email" {...formik.getFieldProps('email')} />
                                                 <span className="highlight"></span>
                                                 <label>email</label>
                                             </div>                                        
 
                                             <div className="floating-label  col-md-6">
                                                 <input disabled={true} className="floating-input" type="text" name="usertypes" {...formik.getFieldProps('usertype')} />
                                                 <span className="highlight"></span>
                                                 <label>User Type</label>
                                             </div>
 
                                         </div>
 
                                         <div className="row">
                                             <div className="floating-label tran col-md-12 pd-b-10" >
                                                 <button onClick={e => returnRequest(e)} className="btn btn-outline-warning">Return</button>
                                             </div>
                                         </div>
                                         
                                         <div className="row">
 
                                         <div className="floating-label tran col-md-12 pd-b-10" >
                                                 <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" name="comment"  {...formik.getFieldProps('comment')}/>
                                                 <span className="highlight"></span>
                                                 <label>Closure Comments</label>
                                             </div>
 
                                         </div>
 
                                         <div className="row">  
                                                 <div className="floating-label tran col-md-12 pd-b-10" >                                          
                                                     <button onClick={(e) => setStatus("Approved")} type='submit' className="btn btn-outline-info">Approve Resquest</button>
                                                 </div>  
                                                                          
                                         </div>
 
                                         <div className="row">
                                             <div className="floating-label tran col-md-12 pd-b-10" >
                                                 <button onClick={(e) => setStatus("Rejected")} type='submit' className="btn btn-outline-danger">Reject Resquest</button> 
                                             </div>
                                         </div>
 
                                 </div>
                                 
                             </form>
                             
                             
                         </div>
                     </div>
                     
 
 
                 </div>
             </div>
             }
        </>
    )
}

export default Edit