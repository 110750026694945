import React, { useContext, useState, useEffect } from 'react'

import {Context} from '../contexts/MainContext'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory} from "react-router-dom"
import axiosConfig from '../helpers/AxiosMainConfig';
import PublicHeader from '../components/PublicHeader'
import Footer from './Footer';
import Spinner from '../components/Spinner';
import { NotificationService } from '../service/NotificationService'

function Terms () {
    
    return (
        <>       

       
          <PublicHeader />
            
          <div className="slim-body" > 
                <div className="container">
                    <div className="centered-form col-lg-12">
                    <div className="signin-box pd-0-force">
                        
                        <p><h2 className="title">THIS AGENCY AGREEMENT is made BETWEEN TRANSOLVE TECHNOLOGIES LIMITED</h2> a company incorporated under the Laws of the Federal Republic of Nigeria whose registered office is at Plot 234, Jumoke 
                            Apara Street, Gbagada Lagos, Nigeria (Hereinafter referred to as the “Princpal”) AND You(Hereinafter referred to as Agent). (both Principal and Agent are together referred to as “the parties”.</p>
                    

                        <p>WHEREASE: 
                            A.	The principal is in the business of providing agency banking and mobile money products and services
                            B.	The agent is interested in doing business with the principal to serve as an extension to customers who wish to use any of the principal’s product and services
                            C.	Parties agree to abide by terms and conditions under this agreement and shall work together and be bound by the geographical boundaries of the territory covered by the agreement. 
                            This agreement binds the relationship between Transolve Technologies ltd and the above named agent for the duration of which the prinicipal’s POS is with the agent and the 
                            agent maintains an active wallet with principal.
                        </p>

                        <p>NOW THEREFORE, THE PARTIES AGREE AS FOLLOWS:
                            1. RENUMERATION; The agent shall pay the sum of N20 (Twenty Naira) only to the Principal on every successful transaction carried out through the use of the Principals POS machine.
                            2. OBLIGATION OF PARTIES. </p>

                        <p>THE PRINCIPAL’S OBLIGATIONS ARE AS FOLLOWS: a.	To provide and maintain (reasonably) a unit of functional POS after payment of a non-refundable registration fee of N5000 (Five Thousand Naira) 
                            is paid to the principal.
                            b.	The Principal shall make available all necessary requirements to enable the smooth operation of the POS.
                        </p>
                    

                        <p>THE AGENT 
                            a.	The agent shall use the said POS for the following business:
                                i.	Cash deposit and withdrawal
                                ii.	Bill payment
                                iii.	Airtime and data vending
                            b. The agent shall do such business with Principal’s POS to wit:
                                i.	Minimum daily withdrawal shall be N50, 000 (Fifty Thousand Naira) Only 
                                ii.	Minimum of 5(five) Deposit per day.
                                iii.	Minimum of 2 (two_) bill Payment transaction per day.
                                iv.	Minimum of 5 (five) airtime transaction per day.
                        </p>

                        <p>OWNERSHIP: the POS remains a property of the Principal, agent will be accordingly liable for any damages.
                        4. LIABILITIES
                        4.1	Except where directly attributable to the negligent acts or omissions of the other party, both parties shall not be liable for any damages, claims, costs, expenses or losses whatsoever 
                        which may be suffered by the other party, including but not limited to any direct, indirect, special, consequential, punitive or incidental damages, 
                        or damages for loss of use, profits, data or other intangibles, or the cost of procurement of substitute goods and services, arising out of or related to the use, resale, 
                        inability to use, unauthorized use, performance or non-performance of this Service, or anything done or not done pursuant to this Agreement whether such damages arise in contract, delict,
                         under statute, at law or any other cause without limitation and whether this Agreement is cancelled or not.
                         4.2	The Principal does not authorize the use of the POS for any Criminal activity.
                         </p>

                        <p>DEFINITIONS AND INTERPRETATIONS
                            a.	“Governing Law” means any statute, law, regulation, rule, judgment, rule of common law, order, by-law, directive, guideline, policy, requirement, or other governmental 
                            restriction or any similar form of decision of, or determination by, or any interpretation or administration having the force of law, by any government authority having jurisdiction 
                            over the matter in question, whether in effect as of the date of this agreement or thereafter;
                            b.	“Business Day” means any day (including Saturdays and public holidays), except Sunday.
                            c.	Commencement Date” means the provision of this MOU shall be effective from the date the parties have the last appended signature. The date shall be known as the effective date.
                            d.	“Service” means use of the POS as agreed by parties.
                        </p>
                    

                        <p>6. DEFAULT: The Principal is at liberty to withdraw the POS from the Agent in case of default of obligation/duty contained in Paragraph 2 of this agreement.
                            7. TERMINATION: Any party shall exercise their right to terminate this Agreement by giving 2 days notice to the other.
                        </p>

                        <p>8. DISPUTE RESOLUTION	Parties shall endeavour to settle amicably any dispute arising from this AGREEMENT.  Where negotiated settlement fails, both Parties shall appoint a mediator to assist in resolving the dispute. 
                            The mediator shall be an independent technical expert Where mediation fails, parties may further seeks to resolve dispute through Arbitration and an arbitrator who shall be a Retired Judge or a person of similar 
                            qualification must be jointly agreed by the Parties and same would make all such decisions in writing which he deems to be appropriate 
                            and such decisions shall be binding and enforceable as if such decision was an order, direction or award made by an arbitrator in arbitration proceeding 
                        </p>

                        <p>9. ANTI-MONEY LAUNDERING/ COUNTER-FINANCING OF TERRORISM
                            The Parties are committed to observing the highest standards of legal and ethical behavior in the conduct of their relationship under this Agreement. The Parties hereby undertake to comply with
                             the applicable provisions of the Anti-Corruption Laws, and all laws relating to Anti-Money Laundering, terrorism financing etc.
                        </p>
                    

                       
                    </div>
                    </div>
                </div>
         </div>
        
        
        
        <Footer />
        </>
    )
}

export default Terms