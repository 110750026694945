import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../../service/NotificationService';
import InfiniteScroll from 'react-infinite-scroller';

function Terminal ({terminals, onTerminalClick}) {

    // console.log(terminals)

    const [localTerminals, setLocalTerminals] = useState([])
    const [page, setPage] = useState(1)

    let terminalElements= [];

    if(localTerminals.length !== 0){
         terminalElements = localTerminals.slice(0,page * 100).map(terminal => {
            return (
                <tr key={terminal.datecreated} onClick={() => onTerminalClick(terminal)}>
                        <td><a href="#">{terminal.terminalOwner ? terminal.terminalOwner.firstname + " " + terminal.terminalOwner.lastname : "Terminal not mapped"}</a></td>
                        <td><a href="#">{terminal.agentFriendlyName}</a></td>
                        <td><a href="#">{terminal.terminalSerialNumber}</a></td>
                        <td><a href="#">{terminal.terminalPhysicalId}</a></td>
                        <td><a href="#">{terminal.UPSLTerminalPhysicalId}</a></td>
                        <td><a href="#">{terminal.terminalType ? terminal.terminalType.name : " "}</a></td>
                        <td><a href="#"><div className="success">Active</div></a></td>
                        <td><a href="#">{new Date(terminal.datecreated).toDateString()}</a></td>                    
                </tr>
            )
        })
    }

    function loadFunc(){

        const totalPages = localTerminals.length/100

        if(page > totalPages){
            console.log("Done")
            return;
        }

        setPage(prev => {
            return prev + 1
        })
    }

    useEffect(() => {
        setLocalTerminals(prev => {
            return terminals
        })
    },[])

    const formik = useFormik({
        initialValues: {
            owner : '',
            serial: '',
            terminalid: '',
            upslterminalid: '',
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });
      //-----------------------------------------------------------------------------------------------------------------------
      function terminalidFilter(e){
        formik.handleChange(e);
        
        const filtered = terminals.filter( terminal => terminal.terminalPhysicalId.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setLocalTerminals(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered for NIBBS Terminal ID does not match any value", 5000);
            setLocalTerminals(prev => {
                return terminals
            })
        }
      }
       //-----------------------------------------------------------------------------------------------------------------------
       function upslterminalidFilter(e){
        formik.handleChange(e);
        
        const filtered = terminals.filter( terminal => terminal.UPSLTerminalPhysicalId.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setLocalTerminals(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered for UPSL Terminal ID does not match any value", 5000);
            setLocalTerminals(prev => {
                return terminals
            })
        }
      }
    //-----------------------------------------------------------------------------------------------------------------------------
    function ownerFilter(e){
        formik.handleChange(e);
        const filtered = terminals.filter( terminal => {
            if(terminal.terminalOwner == null){
                return false
            }
            else{
                return terminal.terminalOwner.firstname.toLowerCase().includes(e.target.value.toLowerCase()) 
            }
        })

        if(filtered.length !== 0){
            setLocalTerminals(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search parameter entered does not match any value", 5000);
            setLocalTerminals(prev => {
                return terminals
            })
        }
    }
    //-----------------------------------------------------------------------------------------------------------------------------
    function serialFilter(e){
        formik.handleChange(e);
        
        const filtered = terminals.filter( terminal => terminal.terminalSerialNumber.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setLocalTerminals(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered for Reference Number does not match any value", 5000);
            setLocalTerminals(prev => {
                return terminals
            })
        }
    }

    return (
        <>                   
            
                    {/* <div className="row table-overall-headings mg-b-10">
                        <div className="col-md-6">
                            <h2>Terminals/Devices</h2>
                        </div>
                    </div> */}
                    <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form >                               
                                <div className="floating-form">
                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                            <input onChange={e => ownerFilter(e)} className="floating-input" type="text" placeholder="" name="owner"  value={formik.values.owner}/>
                                            <span className="highlight"></span>
                                            <label>Agent Name</label>
                                        </div>
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => serialFilter(e)} className="floating-input" type="text" placeholder="" name="serial"  value={formik.values.serial} />
                                            <span className="highlight"></span>
                                            <label>Terminal Serial</label>
                                        </div>
                                
                                    </div>
                                <div className="row">

                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                        <input onChange={e => terminalidFilter(e)} className="floating-input" type="text" placeholder="" name="terminalid" value={formik.values.terminalid}/>
                                        <span className="highlight"></span>
                                        <label>Terminal ID(NIBBS)</label>
                                    </div>

                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                        <input onChange={e => upslterminalidFilter(e)} className="floating-input" type="text" placeholder="" name="upslterminalid" value={formik.values.upslterminalid}/>
                                        <span className="highlight"></span>
                                        <label>Terminal ID(UPSL)</label>
                                    </div>
                                   
                                </div>
                                
                                </div>
                                
                                {/* <div className="btn-filter">
                                <a href="#"><button className=" btn-fill-md majorButton">Filter</button></a>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    </div>
                    
                    <div className="table-wrapper table-responsive">
                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={loadFunc}
                                            hasMore={true || false}
                                            loader={<div className="loader" key={0}>Loading ...</div>}
                                >
                            <table id="datatable2" className="table display responsive nowrap">
                                <thead>
                                    <tr >
                                        <th className="">Agent Name</th>
                                        <th className="">Terminal Name</th>
                                        <th className="">Serial Number</th>
                                        <th className="">Terminal ID(NIBBS)</th>
                                        <th className="">Terminal ID(UPSL)</th>
                                        <th className="">Type</th>
                                        <th className="">Status</th>
                                        <th className="">Date Created</th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {terminalElements}
                                
                                </tbody>
                            </table>
                            </InfiniteScroll>
                    </div>      
            
        </>
    )
}

export default Terminal