import React, {useContext} from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import {Context} from '../contexts/MainContext'


function ProtectedRoute({component: Component, ...rest}){

    const {isLoggedIn,} = useContext(Context);
    let userType;
    const location = useLocation();


    if(localStorage.getItem('user')){
      const { usertype } = JSON.parse(localStorage.getItem('user'))
      userType = usertype;
      userType = userType.toLowerCase();
    }   

    const area = location.pathname.split('/')[1].toLowerCase();
    
    return (
        <>
       
        <Route
          {...rest}
          render={props => {
            if (isLoggedIn && (area === userType) ) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: {
                      from: props.location
                    }
                  }}
                />
              );
            }
          }}
        />
        </>
      );
}

export default ProtectedRoute