import React, { useState } from 'react'
import View from './View'
import Edit from './Edit'
import Create from './Create'
import Main from './Main'

function Request () {
    const [params, setParams] = useState("main")
    const [request, setRequest] = useState({})

    function changeApp(name){
        setParams(prev => {
            return name
        })
    }

    function updateRequest(request){
        setRequest(prev => {
            return request
        })
    }

    return (
      <>
           {
            {
                'main': <Main changeApp={changeApp}  />,
                'view': <View changeApp={changeApp} updateRequest={updateRequest} />,
                'edit': <Edit changeApp={changeApp} request={request} />,
                'create' : <Create changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
      </>
    )
}

export default Request