import React, { useContext, useState, useEffect } from 'react'

import {Context} from '../contexts/MainContext'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory} from "react-router-dom"
import axiosConfig from '../helpers/AxiosMainConfig';
import PublicHeader from '../components/PublicHeader'
import Footer from './Footer';
import Spinner from '../components/Spinner';
import { NotificationService } from '../service/NotificationService'

function Login () {
    const [info, setInfo] = useState("")
    const [busy, setBusy] = useState(false)

    const {isLoggedIn } = useContext(Context)
    
    const {updateLoggedIn, changeProgress, Logout} = useContext(Context)
    const history = useHistory();

    function updateInfo(message){
      setInfo(prev => {
        return message
      })
    }

    function updateBusy(){
      setBusy(prev => {
          return !prev
      })
    }


    useEffect(() => {  
      Logout()   
    },[])

    function validate(){
      setInfo(prev => {
        return formik.isValid ? "" : prev
      })
    }
    

    const formik = useFormik({
        initialValues: {
          email: '',
          password: ''
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
          updateInfo(" ")
        },
        validationSchema: Yup.object({
          email: Yup.string()
            .email('Please enter a valid email')
            .required('Please enter your Username'),
          password: Yup.string()
            .required('Please enter your Password')
        }),
        onSubmit: (values,{ resetForm }) => {
          
          updateBusy()
          changeProgress(35);
         
          axiosConfig.post('/user/login',values)
            .then(response => {

              changeProgress(100)
              
              
              history.push('/twofactor')            

              
            })
            .catch(error => {
              changeProgress(100); 
                               
                
                if(error.response){
                  updateInfo(error.response.data.message);
                  NotificationService.notifyError(error.response.data.message, 3000)
                }
                else {
                  updateInfo("An network/connection issue has occured, please check your internet connection");
                  NotificationService.notifyError("An network/connection issue has occured, please check your internet connection", 3000)
                }

                updateBusy()
                resetForm();    
            });              
        }
      });
    

    return (
        <>       

       
          <PublicHeader />
            
          <div className="slim-body" > 
          <div className="signin-wrapper container">
                
                <div className="signin-box mg-b-40 col-lg-6">
                {busy ? <Spinner /> :
                <>
                <h2 className="signin-title-primary loginHeader mg-b-0-force">SureBanka Login</h2>
                <p className="signin-title-secondary"></p>
            <p className="forgot-pass"><a href=""> <span className="pss-red">{info}</span></a> </p>
            <div className="">

                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                    <div className="floating-form">
                        <div className="floating-label">
                            <input className="floating-input" type="email" placeholder=" " autoComplete="none"  name="email" autoFocus {...formik.getFieldProps('email')} />
                            <span className="highlight"></span>
                            <label>Email</label>
                        </div>
                        {formik.touched.email && formik.errors.email ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.email}</span></a> </p>) : null}
                        <div className="floating-label">
                            <input className="floating-input" type="password" placeholder=" " autoComplete="none" name="password" {...formik.getFieldProps('password')}/>
                            <span className="highlight"></span>
                            <label>Password</label>
                        </div>
                        {formik.touched.password && formik.errors.password ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.password}</span></a> </p>) : null}
                        <p className="forgot-pass"><a href="/forgot"> <span className="pss-blue">Forgot password or you want to reset your password?</span></a> </p>
                        <button className="btn-fill-md majorButton" type="submit">Login</button>
                    </div>


                </form>
              
            </div>
            </>
            }
        </div>
        </div>
         </div>
        
        
        
        <Footer />
        </>
    )
}

export default Login