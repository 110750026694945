import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {AdminService} from '../../../service/AdminService'
import {SuperAdminService} from '../../../service/SuperAdminService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import { AgentService } from '../../../service/AgentService';

function Edit({changeApp, terminal}) {
    const [busy, setBusy] = useState(true)
    const [map, setMap] = useState(false)
    const { id } = JSON.parse(localStorage.getItem('user'))
   
    
    function changeUp(param){
        changeApp(param)
    }

    useEffect(() => {
        setBusy(false)
    },[])

    const formik = useFormik({
        initialValues: {
          terminalOwner: terminal.terminalOwner ? terminal.terminalOwner.email : "",
          terminalSerialNumber: terminal.terminalSerialNumber,
          terminalType: terminal.terminalType.name,
          terminalPhysicalId: terminal.terminalPhysicalId

        },
        validate: (what) => {
            
            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyError(formik.errors.pin, 3000)
                return;
            }
            if(formik.errors.old_pin && formik.touched.old_pin){
                NotificationService.notifyError(formik.errors.old_pin, 3000)
                return;
            }
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            pin: Yup.string()
            .required('Please enter a new pin'),
            
            
        }),
        onSubmit: (values,{ resetForm }) => {
            
            setBusy(true);

            AgentService.createAgentPIN({terminalId: terminal._id, agentId: id, pin: values.pin, old_pin: values.old_pin })
            .then(response => {
                
                NotificationService.notifyInfo("The PIN was updated successfully.")
                setBusy(false)
                
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
            resetForm()
        }
      });

      const aliasForm = useFormik({
        initialValues: {
          alias: terminal.friendlyname

        },
        validate: (what) => {
            
            if(aliasForm.errors.alias && aliasForm.touched.alias){
                NotificationService.notifyError(aliasForm.errors.alias, 3000)
                return;
            }
           
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            alias: Yup.string()
            .required('Please enter a new Friendly Name'),
            
            
        }),
        onSubmit: (values,{ resetForm }) => {
            
            setBusy(true);

            AgentService.updateFriendlyName({ terminalId: terminal._id, agentFriendlyName: values.alias })
            .then(response => {
                
                NotificationService.notifyInfo("The Friendly Name was updated successfully.")
                setBusy(false)
                
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
            resetForm()
        }
      });

      function resetTerminalPIN(){
        setBusy(true)

        
        
        AgentService.resetTerminalPIN({ terminalId: terminal._id })
        .then(response => {
            
            NotificationService.notifyInfo("The reset email was sent successfully.", 20000)
            setBusy(false)
            changeUp('view')
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

    return (
        <>
            {busy ? <Spinner /> :
            <div className="slim-mainpanel">
                <div className="container">
                    
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeUp("view")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Edit Terminal</h2>
                    </div>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                           
                            <fieldset >
                                <div className="floating-form ">
                                
                                        <div className="row">
                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" placeholder=" " name="terminalOwner" autoComplete="none" {...formik.getFieldProps('terminalOwner')} />
                                                <span className="highlight"></span>
                                                <label>Terminal Owner</label>
                                            </div>

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" placeholder=" " name="terminalSerialNumber" autoComplete="none" {...formik.getFieldProps('terminalSerialNumber')} />
                                                <span className="highlight"></span>
                                                <label>Terminal Serial Number</label>
                                            </div>

                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="email" placeholder=" " name="terminalPhysicalId" autoComplete="none" {...formik.getFieldProps('terminalPhysicalId')} />
                                                <span className="highlight"></span>
                                                <label>Terminal ID</label>
                                            </div>
                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" placeholder=" " name="terminalType" {...formik.getFieldProps('terminalType')} />
                                                <span className="highlight"></span>
                                                <label>Terminal Type</label>
                                            </div>
                                           
                                        </div>

                                     
                                </div>
                                </fieldset>

                                      
                             </div>
                        </div>
                        <div className="centered-form col-lg-12">

                                    <div className="signin-box pd-20-force create-agg">  
                                        <div className="floating-form ">
                                        <h2 className="signin-title-primary loginHeader mg-b-0-force">Update Terminal PIN</h2>

                                            <div className="row">

                                                <div className="floating-label  col-md-6">
                                                    <input  className="floating-input" type="password" placeholder="" name="pin" {...formik.getFieldProps('pin')} />
                                                    <span className="highlight"></span>
                                                    <label>Terminal PIN</label>
                                                </div>

                                                {terminal.isPinCreated ?
                                                    <div className="floating-label  col-md-6">
                                                        <input className="floating-input" type="password" placeholder="" name="old_pin" {...formik.getFieldProps('old_pin')} />
                                                        <span className="highlight"></span>
                                                        <label>Old Terminal PIN</label>
                                                    </div> : null
                                                }

                                            </div>

                                            <div disabled={!map} className="row pd-10">
                                                <button className="btn-fill-md majorButton" type="submit">Update PIN</button> 
                                            </div>

                                        </div>
                                    </div>
                        </div>
                        </form>


                        <form autoComplete="off" onSubmit={aliasForm.handleSubmit}>
                            <div className="centered-form col-lg-12">

                                <div className="signin-box pd-20-force create-agg">  
                                    <div className="floating-form ">
                                    <h2 className="signin-title-primary loginHeader mg-b-0-force">Update Terminal Friendly Name</h2>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input  className="floating-input" type="text" placeholder="" name="alias" {...aliasForm.getFieldProps('alias')} />
                                                <span className="highlight"></span>
                                                <label>Friendly Name</label>
                                            </div>

                                        </div>

                                        <div className="row pd-10">
                                            <button className="btn-fill-md majorButton" type="submit">Update</button> 
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="centered-form col-lg-12">

                                <div className="signin-box pd-20-force create-agg">  
                                    <div className="floating-form ">
                                    <h2 className="signin-title-primary loginHeader mg-b-0-force">Reset Terminal PIN</h2>

                                       

                                        <div className="row pd-10">
                                            <button onClick={() => resetTerminalPIN() } className="btn-fill-md majorButton" >Reset Terminal PIN</button> 
                                        </div>

                                    </div>
                                </div>
                            </div> 
                      
                   
                </div>
            </div>
        }
        </>
    )
}

export default Edit