import React, { useState } from 'react'
import { useFormik } from 'formik';
import { NotificationService } from '../../../service/NotificationService'
import * as Yup from 'yup';
import { SuperAdminService } from '../../../service/SuperAdminService';


function Email ({changeApp}) {
    const [busy, setBusy] = useState(true);

    const formik = useFormik({
        initialValues: {
          title: '',
          details: '',
        },
        validate: (what) => {
            
            if(formik.errors.title && formik.touched.title){
                NotificationService.notifyErrorWithID(formik.errors.title, "title", 5000)
                return;
            }
            if(formik.errors.details && formik.touched.details){
                NotificationService.notifyErrorWithID(formik.errors.details, "details", 5000)
            }
        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
          title: Yup.string()
            .required('Please enter the Title'),
          details: Yup.string()
            .required('Please enter the details of your request')
        }),
        onSubmit: (values,{ resetForm }) => {         
            
            setBusy(true)

            SuperAdminService.sendAgentBroadcast({values})
            .then(response => {
                NotificationService.notify("The emails were sent successfully.")
                setBusy(false)
                resetForm()
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

    return (
        <>
         <div class="slim-mainpanel">
                <div class="container">
                    
                    <div class="main-header">
                        <a href="#"  onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Send Broadcast Email to all Agents</h2>                      
                    </div>

                   
                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-0-force create-agg">           
                        <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">

                                            <div className="floating-label tran col-md-12 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="title" autoFocus {...formik.getFieldProps('title')} />
                                                <span className="highlight"></span>
                                                <label>Title</label>
                                            </div> 
                                        </div>
                                        <div className="row">
                                                <div className="floating-label tran col-md-12 pd-b-10" >
                                                        <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" placeholder=" "  name="details" {...formik.getFieldProps('details')}/>
                                                        <span className="highlight"></span>
                                                        <label>Email/Message Details</label>
                                                </div>
                                        </div>

                                        <button className="btn btn-primary" type="submit">Create</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
       </>
    )
}

export default Email