/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useFormik from 'formik'

function Main ({changeApp}) {
    return (
       <>
        <div className="slim-mainpanel">
                <div className="container">
                        

                        <div className="main-header mg-b-30">
                            <h2 className="main-header-h-1 ">Agent Management</h2>
                            <p className="signin-title-secondary">Paying your bill does not have to be a stressful task. Please select your
                                payment category or search for your biller to make a bill payment.</p>
                        </div>                    
                        <div className="row mg-t-20">
                        <div onClick={() => changeApp("create")} className="col-6 col-md-6 col-lg-3"> 
                                <a href="#">                          
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Create Agent</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Create Agents that you have acquired on the SureBanka Platform for onboarding</p>
                                        </div>
                                    </div>
                                </a>                   
                            </div>  
                            <div onClick={() => changeApp("view")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">View Agents</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">View and Manage the Agents that you have acquired on the SureBanka Platform</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div> 
                            <div onClick={() => changeApp("email")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Send Broadcast Email</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Functionality to Send Email to all Agents on the SureBanka Platform</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div>                  
                                         
                        </div>                                    
                    </div>
        </div>
       </>
    )
}

export default Main