import React, { useState, useEffect } from 'react'
import { SuperAdminService } from '../../../service/SuperAdminService'
import Terminal from '../../../components/terminal/Terminal';
import Spinner from '../../../components/Spinner'
import { NotificationService } from '../../../service/NotificationService'
import InfiniteScroll from 'react-infinite-scroller';

function EODCheck({ changeApp, onEdit }) {
    const [terminals, setTerminals] = useState([]);
    const [busy, setBusy] = useState(true)
    const [page, setPage] = useState(1)

    const formatter = new Intl.NumberFormat('en-US');

    let terminalElements = [];

    if (terminals.length !== 0) {
        terminalElements = terminals.slice(0, page * 50).map(terminal => {
            return (
                <tr key={terminal.datecreated} >
                    <td><a href="#">{terminal.terminalOwner}</a></td>
                    <td><a href="#">{terminal.terminalPhysicalID}</a></td>
                    <td><a href="#">{terminal.terminalSerial}</a></td>
                    <td><a href="#">{formatter.format(terminal.openingBalance.toFixed(2))}</a></td>
                    <td><a href="#">{formatter.format(terminal.previousOpeningBalance.toFixed(2))}</a></td>
                </tr>
            )
        })
    }

    function changeUp(param) {
        changeApp(param)
    }

    useEffect(() => {
        SuperAdminService.getDailyTerminalOpeningClosingBalance()
            .then(response => {

                console.log(response)

                setTerminals(prev => {
                    return response.data.data
                })

                setBusy(false)
            })
            .catch(error => {
                if (error.response) {
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if (error.message) {
                    NotificationService.notifyError(error.message, 5000)
                }
                else {
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
    }, []);


    function updateTerminal() {
        changeApp('edit')
        onEdit()
    }

    function loadFunc(){
        console.log("Loading....")

        const totalPages = terminals.length/50

        if(page > totalPages){
            console.log("Done")
            return;
        }

        setPage(prev => {
            return prev + 1
        })
    }
    return (
        <>
            {busy ? <Spinner /> :
                <>
                    <div className="slim-mainpanel">
                        <div className="container">
                            <div className="main-header">
                                <a href="#" onClick={() => changeUp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                                <h2 className="main-header-h-1 ">View Terminals</h2>
                            </div>

                            <div className="table-wrapper table-responsive">
                            <InfiniteScroll
                                        pageStart={0}
                                        loadMore={loadFunc}
                                        hasMore={true || false}
                                        loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                <table id="datatable2" className="table display responsive nowrap">
                                    <thead>
                                        <tr >
                                            <th className="">Terminal Owner</th>
                                            <th className="">Terminal ID</th>
                                            <th className="">Terminal Serial</th>
                                            <th className="">Opening Balance</th>
                                            <th className="">Previous Balance</th>
                                        </tr>
                                    </thead> 
                                    <tbody>
                                            {terminalElements}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </>

            }
        </>
    )
}

export default EODCheck