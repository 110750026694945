import React, { useState, useEffect } from 'react'
import Airtime from './Airtime'
import Bills from './Bills'
import Custom from './Custom'
import Withdrawal from './Withdrawal'
import Deposit from './Deposit'
import Main from './Main'

function Recon () {
     const [params, setParams] = useState("manage")
     const [terminal, setTerminal] = useState({})
    
    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(terminal){
        
    }
    
    
    
    return (
        <>          
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'bills': <Bills changeApp={changeApp} />,
                'deposit': <Deposit changeApp={changeApp}  />,
                'withdrawal': <Withdrawal changeApp={changeApp} />,
                'airtime': <Airtime changeApp={changeApp} />,
                'custom': <Custom changeApp={changeApp}  onEdit={onEdit} />,
            }[params] || <Main changeApp={changeApp} />
        }   
        </>
    )
}

export default Recon