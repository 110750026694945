import React, {useContext} from 'react'
import {Context} from '../contexts/MainContext'

function Airtime (props) {
    const {isLoggedIn} = useContext(Context)
    

    return (
        <>
            <h2>Airtime</h2>
            {isLoggedIn ? "True" : "False"}
        </>
    )
}

export default Airtime