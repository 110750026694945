import React, { useState, useEffect } from 'react'
import { NotificationService } from '../../service/NotificationService';
import { AdminService } from '../../service/AdminService';
import Spinner from '../../components/Spinner';

function Dashboard  () {
    const [busy, setBusy] = useState(true)
    const [terminals, setTerminals] = useState(0)
  

    //---------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        //------------------------------------
        const terminal = AdminService.getAllTerminals()
        .then(response => {
            
            setTerminals(prev => {
                return response.data.length;
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })
    }, [])

//---------------------------------------------------------------------------------------------------------------------------------
    return (
       <>
            {busy ? <Spinner/> :
         <div className="slim-mainpanel">
                <div className="container">

                    <div class="main-header mg-b-30">
                        <h2 class="main-header-h-1 ">PTSP Dashboard</h2>
                        <p class="signin-title-secondary">Paying your bill does not have to be a stressful task. Please select your
                            payment category or search for your biller to make a bill payment.</p>
                    </div>

                    <div class="dashboard-inner pd-x-0-force">
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Total Terminals
                                        </p>
                                        <p class="main-header-p tertiary-color pd-b-20 blue-color">{terminals}</p>
                                        {/* <a href="/admin/agent" data-toggle="modal" data-target="#modaldemo4"><button
                                                class="btn light-button blue-color">View Agents</button></a> */}
                                    </div>
                                </div>
                            </div>

                           
                            
                        
                        </div>

                      
                    </div>
                                    
                </div>
            </div>
            }
       </>
    )
}

export default Dashboard