import React from 'react'
import {Switch, Route } from 'react-router-dom'

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Dashboard from './superadmin/Dashboard'
import Profile from './Profile'
import Report from './superadmin/Report/Report'

import Welcome from './superadmin/Welcome'
import Authorize from './superadmin/Authorization/Authorize'
import Agent from './superadmin/Agent/Agent'
import Aggregator from './superadmin/Aggregator/Aggregator'
import Efloat from './superadmin/Efloat/Efloat'
import Lien from './superadmin/Lien/Lien'
import Device from './superadmin/Device/Device'
import Audit from './superadmin/Audit/Audit'
import SetPIN from './SetPIN'
import Transaction from './superadmin/Transaction/Transaction'
import Dispute from './superadmin/Dispute/Dispute'
import Recon from './superadmin/Recon/Recon'



function SuperAdminArea () {
    return (
        <>
         <Header />
        <div className="slim-body">
       
        <Sidebar />

        
        <Switch>
            <Route path='/superadmin/dashboard' >
                <Dashboard />
            </Route>
            <Route path='/superadmin/welcome' >
                <Welcome />
            </Route>
            <Route path='/superadmin/profile'>
                <Profile />
            </Route>   
            <Route path='/superadmin/pin'>
                <SetPIN />
            </Route>          
            <Route path='/superadmin/agent'>
                <Agent />
            </Route>
            <Route path='/superadmin/aggregator'>
                <Aggregator />
            </Route>
            <Route path='/superadmin/report'>
                <Report />
            </Route>
            <Route path='/superadmin/authorize'>
                <Authorize />
            </Route>
            <Route path='/superadmin/efloat'>
                <Efloat />
            </Route>
            <Route path='/superadmin/lien'>
                <Lien />
            </Route>
            <Route path='/superadmin/device'>
                <Device />
            </Route>
            <Route path='/superadmin/audit'>
                <Audit />
            </Route>
            <Route path='/superadmin/transaction'>
                <Transaction />
            </Route>
            <Route path='/superadmin/dispute'>
                <Dispute />
            </Route>
            <Route path='/superadmin/recon'>
                <Recon />
            </Route>

            <Route path='*' component={() => "404 NOT FOUND"} />

        </Switch>
       
        </div>
        </>
    )
}

export default SuperAdminArea