import React,{useEffect, useState} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../service/NotificationService'
import Spinner from './Spinner';
import { SuperAdminService } from '../service/SuperAdminService';

function BVNValidation ({bvn}) {  
    
    const [busy, setBusy] = useState(false)

    useEffect(() => {

      },[])


    const formik = useFormik({
        initialValues: {
          bvn: bvn,
          

        },
        validate: (what) => {

            if(formik.errors.bvn && formik.touched.bvn){
                NotificationService.notifyErrorWithID(formik.errors.amount, "bvn", 5000)
                return;
            }            
            

        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
        bvn: Yup.string()
            .required('Please enter the Amount'),
        

        }),
        onSubmit: (values,{ resetForm }) => {         
            console.log(values)  
            setBusy(true)

            SuperAdminService.validateBVN(values)
            .then(response => {
                console.log(response)
                setBusy(false)
                // NotificationService.notifyInfo("Aggregator was debited successfully")
                resetForm()
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)

            })
        }
      });

    return (
        <>
            { busy ? <Spinner /> :
            
                <>
                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">BVN Confirmation</h2>
                        
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">
                                            
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="number" placeholder=" "  name="bvn" autoFocus {...formik.getFieldProps('bvn')} />
                                                <span className="highlight"></span>
                                                <label>BVN</label>
                                            </div>                         
                                            
                                           
                                        
                                        </div>

                                        <button className="btn btn-primary" type="submit">Validate</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            
        }
        </>
    )
}

export default BVNValidation