import React, { useState, useEffect } from 'react'
import Tran from '../../../components/transaction/Tran'
import { AdminService} from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import moment from 'moment'

function View () {
    const [transactions, setTransactions] = useState([]);
    const [busy, setBusy] = useState(true)
    

    const searchParams = {
       
        endDate: moment().add(1, 'days').format('yyyy/MM/DD'),
        startDate: moment().subtract('days', 5).format('yyyy/MM/DD'),
        lastWeek: false,
        lastMonth: false
    }

    useEffect(() => {
        AdminService.getTransactionForAdmin(searchParams)
        .then(response => {
            console.log(response.data.data)
            if(response.data.data.length > 0){
                
                setTransactions(prev => {
                    return response.data.data
                });
                NotificationService.notify("Transactions pulled successfully");
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("No transactions");
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

    }, [])

   

    return (
        <>
        {
             busy ? <Spinner /> : <Tran transactions={transactions} />
        }

        </>
    )
}

export default View