import React, { useState, useEffect } from 'react'
import { SuperAdminService} from '../../../service/SuperAdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'

function Main ({changeApp}) {

    const [busy, setBusy] = useState(false);

    function getReport(){
        setBusy(true)
        SuperAdminService.getTerminalReport()
        .then(response => {
                
            NotificationService.notify("The report has been sent successfully");                
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }

    return (
       <>
                
                { busy ? <Spinner /> :
                
                <div className="slim-mainpanel">
                    <div className="container">
                        <div className="main-header mg-b-30">
                            <h2 className="main-header-h-1 ">Reporting System</h2>
                            <p className="signin-title-secondary">Backend Page for all reporting functionalities and management</p>
                        </div>                    
                        <div className="row mg-t-20">
                            <div onClick={ () => getReport()} className="col-6 col-md-6 col-lg-3"> 
                                    <a href="#">                          
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">Generate Terminal Report</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">Create Agents that you have acquired on the SureBanka Platform for onboarding</p>
                                            </div>
                                        </div>
                                    </a>                   
                            </div>
                            <div onClick={ () => changeApp('performance')} className="col-6 col-md-6 col-lg-3"> 
                                    <a href="#">                          
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">View Terminal Statistics</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">Create Agents that you have acquired on the SureBanka Platform for onboarding</p>
                                            </div>
                                        </div>
                                    </a>                   
                            </div>  
                        </div> 
                </div>
            </div>
        }
    </>

)
    
}

export default Main