import React from 'react'
import CashProfit from '../../../components/report/CashBalance'

function CashBalance ({changeApp}) {
    return (
        <>
             <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                    </div>
                        <div className="row table-overall-headings mg-b-10">
                            <div className="col-md-6">
                                <h2>Cash and Profit Report</h2>
                                {/* <p>View and download all transactions easily here</p> */}
                            </div>
                        </div>
                <CashProfit />
                </div>
            </div>
        </>
    )
}

export default CashBalance