import React, {useContext, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axiosConfig from '../helpers/AxiosMainConfig';
import PublicHeader from '../components/PublicHeader';
import Confirmation from '../components/Confirmation';
import Footer from './Footer';
import Spinner from '../components/Spinner';
import {Context} from '../contexts/MainContext'
import { useHistory} from "react-router-dom"

function TwoFactorConfirm () {
    const [message, setMessage] = useState("")
    const [busy, setBusy] = useState(true)
    const  { token } = useParams();
    const history = useHistory();
    
    const {updateLoggedIn, changeProgress, Logout} = useContext(Context)

    const header = "Login Validation"

    const url = '/user/confirmLogin/' + token

    function updateBusy(){
        setBusy(prev => {
            return !prev
        })
    }

    function updateMessage(newMessage) {
        setMessage(prev => {
            return newMessage
        })
    }
    
    useEffect(() => {
        
        axiosConfig.get(url, {
            params: {
                id: token
            }
        })
        .then(response => {
            console.log(response)

            updateBusy();
            const token = response.data.token;
            const User = response.data.data;
            const {usertype} = User;                          

            updateLoggedIn(true, token, JSON.stringify(User));              
              
            history.push(`/${usertype.toLowerCase()}/welcome`)
            updateMessage(response.data.message)
           
        })
        .catch(error => {
        
            updateBusy();
            updateMessage("An error occurred in an attempt to validate your login, please contact support.")           
        })            
    },[])


    return (
        <>
            <PublicHeader />
            {busy ? <Spinner /> : <Confirmation header={header} message={message} />}
            <Footer />
        </>
    )
}

export default TwoFactorConfirm