import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup';
import Spinner from '../../../components/Spinner';
import { AgentService } from '../../../service/AgentService';
import { NotificationService } from '../../../service/NotificationService';
import moment from '../../../../node_modules/moment/moment';


function Voucher ({changeApp}) {
    const [busy, setBusy] = useState(true)
    const [terminals, setTerminals] = useState([])
    const { id } = JSON.parse(localStorage.getItem('user'));
    const [purchases, setPurchases] = useState([])
    
    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    let purchaseElements = [];

    if(purchases.length !== 0){
        purchaseElements = purchases.map(purchase => {
           return (
               <tr key={purchase.TransactionId} onClick={() => reprintVoucher(purchase.reference_id)}>
                       <td><a href="#">{purchase.reference_id}</a></td>
                       <td><a href="#">{purchase.amount}</a></td>
                       <td><a href="#">{purchase.display_name}</a></td>
                       <td><a href="#">{purchase.qty_recharge}</a></td>
                       <td><a href="#">{new Date(purchase.timerequested).toUTCString()}</a></td>
                       <td><a href="#"><div className="success">Download</div></a></td>                              
               </tr>
           )
       })
   }
    useEffect(() => {
        const devices = AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })

        const searchParams = {
            agentId: id,
            startDate: moment().subtract("months", 6).format("YYYY-MM-DD"),
            endDate: moment().format("YYY-MM-DD")
        }

        const purchase = AgentService.getRechargeCardHistoryByAgent(searchParams)
        .then(response => {
            

            setPurchases(prev => {
                return response.data.data
            })
            
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })

        Promise.all([devices,purchase].map(p => p.catch(e => e)))
        .then(results => {
            setBusy(false)
           
        })
        .catch(e => {
            
            setBusy(false)
        })
       
    }, [])


    const formik = useFormik({
        initialValues: {
            rechargeQty: 0,
            amount: 0,
            agentCharge: 0,
            displayName: '',
            terminalID: '',
            pin: ''
              
        },
        validate: (what) => {
            if(formik.errors.rechargeQty && formik.touched.rechargeQty){
                NotificationService.notifyError(formik.errors.rechargeQty, 3000)
                return;
            } 
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyError(formik.errors.amount, 3000)
                return;
            }
            if(formik.errors.agentCharge && formik.touched.agentCharge){
                NotificationService.notifyError(formik.errors.agentCharge, 3000)
                return;
            }   
            if(formik.errors.displayName && formik.touched.displayName){
                NotificationService.notifyError(formik.errors.displayName, 3000)
                return;
            }   
            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyError(formik.errors.terminalID, 3000)
                return;
            }   
            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyError(formik.errors.pin, 3000)
                return;
            }      
        },
        handleChange:() => {
          
        },
        validationSchema: Yup.object({
            rechargeQty: Yup.number()
                .required('Please enter the quantity of Recharge Cards you want to purchase'),
            amount: Yup.number()
                .required('Please enter the Amount'),
            agentCharge: Yup.number()
                .required('Please enter the Agent Charge'),
            displayName: Yup.string()
                .required('Please enter the Display Name for the vouchers'),
            terminalID: Yup.string()
                .required('Please select the Terminal'),
            pin: Yup.string()
                .required('Please select the Terminal')
          }),
        onSubmit: (values,{ resetForm }) => {  
              
            setBusy(true)        

            const transactionTime = moment().format("hh:mm")
            const transactionDate = moment().format("YYYY/MM/DD")
            
            AgentService.PrintRechargeCardPos({...values, pin: values.pin, rechargeQty: values.rechargeQty.toString(), amount: values.amount.toString(), transactionTime: transactionTime, transactionDate: transactionDate })
            .then(response => {
                console.log(response.data.message.reference_id)

                reprintVoucher(response.data.message.reference_id)                
                
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

      function reprintVoucher(reference_id){
        
        setBusy(true)
        AgentService.reprintVoucher({referenceId: reference_id})
            .then(response => {

                downloadBase64File("application/pdf", response.data.data, reference_id + ".pdf")

                setBusy(false)
                NotificationService.notify("The Airtime Purchase was completed successfully.")             
                
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
      }

      function downloadBase64File(contentType, base64Data, fileName) {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
   }
    
    return (
        <>
         { busy ? <Spinner /> :
         <>
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("main")} className="pss-blue pd-b-10">← Go Back</a>
                    </div>

                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Buy Airtime Voucher</h2>
                            
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="number" placeholder=" "  name="rechargeQty" {...formik.getFieldProps('rechargeQty')} />
                                                <span className="highlight"></span>
                                                <label>Quantity of Recharge Cards</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="number" placeholder=" "  name="amount" {...formik.getFieldProps('amount')} />
                                                <span className="highlight"></span>
                                                <label>Amount</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="number" placeholder=" "  name="agentCharge" {...formik.getFieldProps('agentCharge')} />
                                                <span className="highlight"></span>
                                                <label>Agent Charge</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="displayName" {...formik.getFieldProps('displayName')} />
                                                <span className="highlight"></span>
                                                <label>Display Name</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                    <option value="" label="Please select terminal for this transaction" />
                                                    {terminalOptions}
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Terminal</label>                           
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="password" placeholder=" "  name="pin" {...formik.getFieldProps('pin')} />
                                                <span className="highlight"></span>
                                                <label>Terminal PIN</label>
                                            </div>
                                            
                                           
                                        </div>

                                        <button className="btn-fill-md majorButton" type="submit">Buy Voucher</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                

            
                    <div className="table-wrapper table-responsive">
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                    <th className="">Reference Number</th>
                                    <th className="">Amount</th>
                                    <th className="">Display Name</th>
                                    <th className="">Quantity</th>
                                    <th className="">Time Requested</th>
                                    <th className="">Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseElements}
                            </tbody>
                        </table>
                    </div>
                
            </div>
            </div>
        </>
        }
        </>
    )
}

export default Voucher