import React, {useState, useEffect} from 'react'
import Spinner from '../../../../components/Spinner'
import { AggregatorService } from '../../../../service/AggregatorService'
import {  toast } from 'react-toastify';
import  Terminal  from '../../../../components/terminal/Terminal'

function View ({changeUp, updateTerminal}) {

    const { id } = JSON.parse(localStorage.getItem('user'))
    const [terminals, setTerminals] = useState([])

    const [busy, setBusy] = useState(true)

    function notify (message) {
        toast(message, 
         {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
        });
     }
   
     function notifyError(message, duration){
       toast.error(message, {
         position: "top-right",
         autoClose: duration,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         });
     }

    useEffect(() => {
        AggregatorService.getTerminalsByAggregator({aggregatorId: id})
        .then(response => {

            setTerminals(prev => {
                return response.data
            })
            
            if(response.data.length > 0){
                
                
                notify("The number of Terminal(s) you have assigned to your Agents is " + response.data.length)
            }
            else if(response.data.length === 0){
                notify("You do not have any Terminal/Device assigned")
            }
            setBusy(false)
        })
        .catch(error => {
            console.log(error)
            setBusy(false)
            notifyError(error.message, 5000)
        })
    }, [])

    return (
        <>
            { busy ? <Spinner /> :
            <div class="slim-mainpanel">
                <div class="container">
                    
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeUp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                        <h2 class="main-header-h-1 ">View Terminals</h2>
                    </div>

                    <Terminal terminals={terminals} onTerminalClick={updateTerminal} />
                </div>
            </div>
}
        </>
    )
}

export default View