import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axiosConfig from '../helpers/AxiosMainConfig';
import PublicHeader from '../components/PublicHeader';
import Confirmation from '../components/Confirmation';
import Footer from './Footer';
import Spinner from '../components/Spinner';

function ResetTerminalPin () {
    const [message, setMessage] = useState("")
    const [busy, setBusy] = useState(true)
    const  { token } = useParams();
    

    const header = "Reset Terminal PIN Confirmation"    

    const url = '/terminal/ResetAgentTerminalPin/' + token

    function updateBusy(){
        setBusy(prev => {
            return !prev
        })
    }

    function updateMessage(newMessage) {
        setMessage(prev => {
            return newMessage
        })
    }
    
    useEffect(() => {
        
        axiosConfig.get(url, {
            params: {
                id: token
            }
        })
        .then(response => {
            
            updateBusy();
            updateMessage(response.data.message)
           
        })
        .catch(error => {
        
            updateBusy();
            updateMessage("An error occurred in an attempt to update the terminal pin, please contact support.")
           
        })

            
    },[])


    return (
        <>

            <PublicHeader />
            {busy ? <Spinner /> : <Confirmation header={header} message={message} />}
            <Footer />
        </>
    )
}

export default ResetTerminalPin