import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {AdminService} from '../../../service/AdminService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';

function Edit({changeApp, terminal}) {
    const [agents, setAgents] = useState([])
    const [busy, setBusy] = useState(true)
    const [map, setMap] = useState(false)

    const agentsOption = agents.map(user => {
        return (<option key={user.email} value={`${user.email}`}>{`${user.email}`}</option>)
    })

   
    
    function changeUp(param){
        changeApp(param)
    }

    useEffect(() => {
        AdminService.getAllAgents()
        .then(response => {

            setAgents(prev => {
                return response.data
            })

            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])

    const formik = useFormik({
        initialValues: {
          terminalOwner: terminal.terminalOwner ? terminal.terminalOwner.email : "",
          terminalSerialNumber: terminal.terminalSerialNumber,
          terminalType: terminal.terminalType.name,
          terminalPhysicalId: terminal.terminalPhysicalId

        },
        validate: (what) => {
            
            if(formik.errors.terminalOwner){
                NotificationService.notifyError(formik.errors.terminalOwner, "", 5000)
            }
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            terminalOwner: Yup.string()
            .required('Please select a new owner'),
            
        }),
        onSubmit: (values,{ resetForm }) => {
            console.log(values)

            AdminService.assignTerminal({agentemail: values.terminalOwner, terminalId: terminal._id})
            .then(response => {
                setBusy(false)
                NotificationService.notify("Terminal was successfully updated")
                switchViews(values)
               
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

      function switchViews(e){
          
        if(e.terminalOwner){
          formik.values.terminalOwner = e.terminalOwner;
        }
        else{
            formik.values.terminalOwner = ''
        }
          setMap(prev => {
              return !prev
          })
      }

    return (
        <>
            {busy ? <Spinner /> :
            <div className="slim-mainpanel">
                <div className="container">
                    
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeUp("view")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Edit Terminal</h2>
                    </div>

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <fieldset >
                                <div className="floating-form ">
                                
                                        <div className="row">
                                        {map ?
                                        <div className="floating-label tran  col-md-6 pd-b-10">
                                                <select disabled={!map} className="floating-input" placeholder="" name="terminalOwner" {...formik.getFieldProps('terminalOwner')} >
                                                <option value="" label="Please select the Terminal Owner" />
                                                {agentsOption}                              
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Terminal Owner</label>                           
                                            </div>
                                            :
                                            <div className="floating-label tran  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" placeholder=" " name="terminalOwner" autoComplete="none" {...formik.getFieldProps('terminalOwner')} />
                                                <span className="highlight"></span>
                                                <label>Terminal Owner</label>
                                            </div>

                                        }

                                            <div className="floating-label tran  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" placeholder=" " name="terminalSerialNumber" autoComplete="none" {...formik.getFieldProps('terminalSerialNumber')} />
                                                <span className="highlight"></span>
                                                <label>Terminal Serial Number</label>
                                            </div>

                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label tran  col-md-6">
                                                <input disabled={true} className="floating-input" type="email" placeholder=" " name="terminalPhysicalId" autoComplete="none" {...formik.getFieldProps('terminalPhysicalId')} />
                                                <span className="highlight"></span>
                                                <label>Terminal ID</label>
                                            </div>
                                        

                                            <div className="floating-label  tran col-md-6">
                                                <input disabled={true} className="floating-input" type="text" placeholder=" " name="terminalType" {...formik.getFieldProps('terminalType')} />
                                                <span className="highlight"></span>
                                                <label>Terminal Type</label>
                                            </div>
                                           
                                        </div>

                                        <div disabled={!map} className="row">
                                        {!map ?  <button onClick={(e) => switchViews(e) } className="btn btn-outline-primary">Map</button> 
                                                :
                                                <button className="btn btn-outline-primary" type="submit">Update Owner</button> }
                                        </div>

                                </div>
                                </fieldset>
                            </form>
                            
                            
                        </div>
                    </div>

                </div>
            </div>
        }
        </>
    )
}

export default Edit