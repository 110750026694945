import React, { useState } from 'react'


import Main from './Main'
import Airtime from './Airtime'
import Power from './Power'
import TV from './TV'
import Data from './Data'
import Voucher from './Voucher'

function Bill () {
    const [params, setParams] = useState("main")
    const [airtime, setAirtime] = useState('')
    const [tv, setTv] = useState('')
   
    

    function changeApp(name){
        
        switch (name) {
            case 'MTN':
                setParams('airtime')
                setAirtime('MTN')
                break;
            case 'Glo':
                setParams('airtime')
                setAirtime('Glo')
                break;
            case 'AIRTEL':
                setParams('airtime')
                setAirtime('Airtel')
                break;
            case '9MOBILE':
                setParams('airtime')
                setAirtime('9Mobile')
                break;
            case 'POWER':
                setParams('power')
                break;
            case 'DsTV':
                    setParams('tv')
                    setTv('DSTV')
                    break;
            case 'GOTV':
                    setParams('tv')
                    setTv('GOTV')
                    break; 
            case 'StarTimes':
                    setParams('tv')
                    setTv('StarTimes')
                    break;
            case 'Data':
                    setParams('data')
                    break;
            case "Voucher":
                    setParams('voucher')
                    break;
            default:
                setParams('main')
                break;
        }
    }

    return (
    <>
         {
            {
                'main': <Main changeApp={changeApp}  />,
                'airtime': <Airtime changeApp={changeApp} airtime={airtime} />,
                'power': <Power changeApp={changeApp} />,
                'tv': <TV changeApp={changeApp} tv={tv} />,
                'data': <Data changeApp={changeApp} />,
                'voucher': <Voucher changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
    </>
    )
}

export default Bill