import React, { useState, useEffect } from 'react'
import Dispute from '../../../components/dispute/Dispute'
import { SuperAdminService} from '../../../service/SuperAdminService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';

function Pending ({changeApp, updateDispute}) {
    const [busy, setBusy] = useState(true);
    const [disputes, setDisputes] = useState([]);

//-----------------------------------------------------------------------------------------------------------
    useEffect(() => {
        SuperAdminService.getNewRequests()
        .then(response => {

            setDisputes(prevAgents => {
                return response.data.data
            });
            
            if(response.data.length > 0){
                NotificationService.notify("The number of Requests is " + response.data.length);
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("No requests at this time");
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])

    //--------------------------------------------------------------------------------------------------------------------------------------
    function onEdit(dispute){
        updateDispute(dispute)
        changeApp('edit')
    }  

 //--------------------------------------------------------------------------------------------------------------------------------------
    return (
        <>
        {
        busy ? <Spinner /> : 
                <div className="slim-mainpanel">
                    <div className="container">
                            <div class="main-header">
                                    <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                            </div>
                        <Dispute disputes={disputes} onEdit={onEdit} />
                    </div>
            </div>
        }
     </>
    )
}

export default Pending