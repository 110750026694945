import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AgentService } from '../../service/AgentService';
import { NotificationService } from '../../service/NotificationService';
import Spinner from '../Spinner';
import moment from 'moment'

function CashBalance () {
    const [terminals, setTerminals] = useState([])
    const [busy, setBusy] = useState(true)
    const [calculating, setCalculating] = useState(false)
    const { id } = JSON.parse(localStorage.getItem('user'));
    const [balance, setBalance] = useState(0)
    
    const [ transactions, setTransactions] = useState([])
    let transactionElements
    let closing = balance

    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    const formatter = new Intl.NumberFormat('en-US');

    if( transactions.length > 0){

        let bal = balance;
        transactionElements = transactions.map(transaction => {
            let cashBalance = 0

            if(transaction.transactionTypeID.description === "Cashout" || transaction.transactionStatusID.name !== "Successful" || transaction.transactionTypeID.description === "TerminalTransfer" || transaction.transactionTypeID.description === "FundTerminal")
            {
                return;
            }

            
            switch (transaction.type) {
                case "Debit":                    
                    cashBalance = transaction.amount + transaction.agentCharge  + bal
                    bal =  transaction.amount + transaction.agentCharge  + bal
                    break;
                case "Credit":                        
                        cashBalance = bal - (transaction.amount - transaction.agentCharge)
                        bal =  bal - (transaction.amount - transaction.agentCharge)
                        break;
            
                default:
                   
                    break;
            }

            closing = bal

            return (
                <tr key={transaction.dateCreated}>
                    <td><a href="#">{transaction.transactionTypeID.description}</a></td>
                    <td><a href="#">Auto</a></td>
                    <td><a href="#">{transaction.type === "Debit" ? "Increase" : "Decrease"}</a></td>
                    <td><a href="#">{formatter.format(transaction.amount)}</a></td>
                    <td><a href="#">{formatter.format(transaction.agentCharge)}</a></td>
                    <td><a href="#">{formatter.format(transaction.amount - transaction.agentCharge)}</a></td>
                    <td><a href="#">{formatter.format(cashBalance)}</a></td>         
                </tr>
            
            )
        })
    }

    

    useEffect(() => {
        
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            console.log(response.data)
            setTerminals(prev => {
                return response.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])

    
    const formik = useFormik({
        initialValues: {         
            cash: 9000,
            terminalID: ""
        },
        validate: (what) => {
            if(formik.errors.cash && formik.touched.cash){
                NotificationService.notifyError(formik.errors.cash, 1000)
                return;
            }

            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyError(formik.errors.terminalID, 1000)
                return;
            }
        },
        validationSchema: Yup.object({
            cash: Yup.string()
              .required('Please enter the amount'),
            terminalID: Yup.string()
             .required('Please seclect the Terminal')
        }),
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {     
            console.log(values)        
            setCalculating(true)

            AgentService.getTerminalTransactions({...values})
            .then(response => {
                 console.log(response.data.data)
                filterTransactionsBydate(response.data.data)
                setCalculating(false)
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setCalculating(false)
            })

            setBalance(prev => {
                return values.cash
            })
        }
      });

     
      function filterTransactionsBydate(transactions){

        console.log(transactions)
          let startDate;
          let endDate;

        switch (formik.values.period) {
            case "today":
                startDate = moment().format('yyyy-MM-DD')
                endDate = moment().add(1, 'days').format('yyyy-MM-DD')
                break;
            case "yesterday":
                startDate = moment().add(-1, 'days').format('yyyy-MM-DD')
                endDate = moment().format('yyyy-MM-DD')
                break;
            case "week":
                startDate = moment().add(-7, 'days').format('yyyy-MM-DD')
                endDate = moment().add(1, 'days').format('yyyy-MM-DD')
                break;        
            default:
                break;
        }

        console.log(startDate + " " + endDate)
        console.log(typeof startDate)

        const transactionByDate = transactions.filter(transaction =>  transaction.dateCreated >= startDate && transaction.dateCreated <= endDate)

        console.log(transactionByDate)

        setTransactions(prev => {
            return transactionByDate
        })
        
      }


    function generateReport(transactions){

    }

    return (
        <>
         {busy ? <Spinner /> :
         <>
         <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form onSubmit={formik.handleSubmit} >

                                <div className="floating-form">

                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                            <input className="floating-input" type="number" placeholder=" " name="cash" {...formik.getFieldProps('cash')} />
                                            <span className="highlight"></span>
                                            <label>Cash Advance Amount</label>
                                        </div>
                                        
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                <option value="" label="Please select terminal for this transaction" />
                                                {terminalOptions}
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Terminal</label>                           
                                            </div>
                                
                                    </div>

                                    <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="period" {...formik.getFieldProps('period')} >
                                                <option value="" label="Please select a specific period" />
                                                <option value="today" label="Today" />
                                                <option value="yesterday" label="Yesterday" />
                                                {/* <option value="week" label="Last Week" /> */}
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Transaction Date </label>                           
                                        </div>
                                    </div>
                                
                                </div>
                                
                                {/* <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10"> */}
                                        <div className="btn-filter">
                                        <button type="submit" className="btn btn-outline-info">Generate Report</button>
                                        </div>
                                    {/* </div>
                                </div> */}

                            </form>
                        </div>
                    </div>
                    </div>
                    <div className="table-wrapper table-responsive">
                        {calculating ? <Spinner /> : 
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                    <th className="">Transaction Type</th>
                                    <th className="">Auto/Manual</th>
                                    <th className="">Cash Effect</th>
                                    <th className="">Amount</th>
                                    <th className="">Agent Charge</th>
                                    <th className="">Amount/Charge Effect</th>
                                    <th className="">Cash Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Opening Cash Balance</td>
                                    <td>Manually Inputted</td>
                                    <td>Increase</td>
                                    <td>{formatter.format(balance)}</td>
                                    <td>N/A</td>
                                    <td>{formatter.format(balance)}</td>
                                    <td>{formatter.format(balance)}</td>
                                </tr>
                              
                               {transactionElements}
                               <tr>
                                    <td>Closing Cash Balance</td>
                                    <td>Auto Computed</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>{formatter.format(closing)}</td>
                                </tr>
                            </tbody>
                        </table>
                        }
                    </div>
                    </>
            }
        </>
    )
}

export default CashBalance