import React, { useState, useEffect } from 'react'
import DebitCustomer from '../../../components/transaction/Debit'

import { AdminService} from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import moment from 'moment'
import CSVReader from 'react-csv-reader'
import { SuperAdminService } from '../../../service/SuperAdminService';


function Upload ({ changeApp }) {
    const [transactions, setTransactions] = useState([]);
    const [failedTransactions, setFailedTransactions] = useState([]);
    const [busy, setBusy] = useState(false)

    let tranElements = []
    let failedElements = []

    console.log(transactions)

    useEffect(() => {

    }, [])

    if(transactions.length !== 0){
        
        tranElements = transactions.map(transaction => {
                return (
                    
                    <tr key={transaction.rrn}>
                        
                        <td><a href="#">{transaction.description}</a></td>
                        <td><a href="#">{transaction.rrn}</a></td>
                        <td><a href="#">{transaction.terminalID}</a></td>
                        <td><a href="#">{transaction.transactionType}</a></td>   
                        <td><a href="#">{transaction.type}</a></td>
                        <td><a href="#">{transaction.amount}</a></td>
                        <td><a href="#">{transaction.narration}</a></td> 
                        <td><a href="#">{transaction.channel}</a></td> 
                        <td><a href="#">{transaction.pan}</a></td>
                        <td><a href="#">{transaction.dateCreated}</a></td>                
                                       
                    </tr>
                    

                )
            })    
    }

    
    // if(failedTransactions.length !== 0){
    //     console.log(failedTransactions)
    //     failedElements = failedTransactions.map(transaction => {
    //             return (
                    
    //                 <tr key={transaction.rrn}>
                        
    //                     <td><a href="#">{transaction.description}</a></td>
    //                     <td><a href="#">{transaction.rrn}</a></td>
    //                     <td><a href="#">{transaction.terminalID}</a></td>
    //                     <td><a href="#">{transaction.transactionType}</a></td>   
    //                     <td><a href="#">{transaction.type}</a></td>
    //                     <td><a href="#">{transaction.amount}</a></td>
    //                     <td><a href="#">{transaction.narration}</a></td> 
    //                     <td><a href="#">{transaction.channel}</a></td> 
    //                     <td><a href="#">{transaction.pan}</a></td>
    //                     <td><a href="#">{transaction.dateCreated}</a></td>                
                                       
    //                 </tr>
                    

    //             )
    //         })    
    // }

    function readCSV(data, fileInfo){
        try{
            let tran = []        

            data.some( (transaction, index) => {
                
                if( index === 0){
                    console.log(transaction[0] + " " + transaction[1])
                    if( transaction[0] !== "RRN" || transaction[1] !== "Type"){
                        
                        NotificationService.notifyError("The CSV File you uploaded is malformed");
                        throw 'The file is malformed'
                    }
                    
                }

                if(index === 0 || transaction.length < 21 ){
                    NotificationService.notifyError("The CSV file contains a malformed row, please confirm the table contains the transactions you want to create.", 5000)
                    return false;
                }

                
                let item = {
                    description: transaction[19],
                    rrn : transaction[0],
                    terminalID : transaction[9],
                    transactionType : transaction[11],
                    type : transaction[1],
                    amount : transaction[4],
                    agentCharge : transaction[15],
                    responseCode : transaction[8],
                    responseDescription : transaction[12],
                    authCode : transaction[12],
                    beneficiaryBank : "",
                    narration : transaction[19],
                    meterToken : "",
                    transactionTime : "",
                    phone : "",
                    smartCardNumber : transaction[3],
                    accountName : transaction[10],
                    meterNumber : "",
                    stan : transaction[20],
                    channel : transaction[15],
                    pan : transaction[3],
                    transactionLog : {},
                    transactiondate : "",
                    dateCreated : new Date().toLocaleString(),
                    referenceNumber : transaction[0]
                }

                tran.push(item)           
                
            })

            setTransactions( prev => {
                return tran;
            })

        }
        catch(err){
            console.log(err)
            NotificationService.notifyError("An error occured, please check the file you uploaded and try again")
        }

    }

    function uploadTransactions() {

        transactions.map( transaction => {
           ReconcileUnImpactedTransaction(transaction);
        })

        setTransactions(prev => {
            return []
        })
    }

    async function ReconcileUnImpactedTransaction(transaction){
        try{
            console.log(transaction)
            setBusy(true)

            let terminal =  await SuperAdminService.getTerminalByPhysicalId({ PhysicalId : transaction.terminalID });

            console.log(terminal.data._id)

            let res =  await SuperAdminService.ReconcileUnImpactedTransactions( {...transaction, terminalID : terminal.data._id, amount: parseInt(transaction.amount), agentCharge: parseInt(transaction.agentCharge), responseCode: parseInt(transaction.responseCode), authCode : parseInt(transaction.authCode) });
            
            // console.log(res)
            NotificationService.notifyInfo(`Transaction with Reference Number ${transaction.rrn} was successfully created`, 10000)

            setBusy(false)
            
            }
            catch(err){
                setBusy(false)
                // setFailedTransactions(prev => {
                //     const newList = failedTransactions.push(transaction)
                //     return newList;
                // })
                NotificationService.notifyError(`Transaction with Reference Number ${transaction.rrn} was not created, please try again later.`, 10000)
                console.log(err)
            }

        
    }

    function handleError(){
       console.log("An error occurred while loading the file, please format the CSV in the right format and try again.")
    }
    
    return (
        <>
        { busy ? <Spinner /> :
             <div className="slim-mainpanel no-bg-img">
             <div className="container">
             <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Bulk Upload UnImpacted Transactions</h2>
                        
                    </div>
                 <div class="main-header">
                     <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                 </div>

                 <div className="row">
                     { transactions.length == 0 ?
                    <CSVReader onFileLoaded={(data, fileInfo) => readCSV(data, fileInfo)} onError={handleError()} /> : null }
                    
                 </div>

                 <div className="table-wrapper table-responsive">
                       <table id="datatable2" className="table display responsive nowrap">
                           <thead>
                               <tr>
                                   <th className="">Description</th>
                                   <th className="">RRN</th>
                                   <th className="">Terminal ID</th>
                                   <th className="">Transaction Type</th>
                                   <th className="">Type</th>
                                   <th className="">Amount</th>
                                   <th className="">Narration</th>
                                   <th className="">Channel</th>
                                   <th className="">PAN</th>
                                   <th className="">Date Created</th>
                                   
                               </tr>
                           </thead>
                           <tbody>
                               {tranElements}
                           </tbody>
                       </table>
                   </div> 

                   
                    { transactions.length > 0 ? 
                            <button className="btn btn-primary" onClick={() => uploadTransactions()}>Upload</button> : null }

                { failedTransactions.length > 0 ?
                
                <>
                    <div className="pd-x-5">
                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Failed Transactions</h2>                        
                    </div>

                        
                    <div className="table-wrapper table-responsive">
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                    <th className="">Description</th>
                                    <th className="">RRN</th>
                                    <th className="">Terminal ID</th>
                                    <th className="">Transaction Type</th>
                                    <th className="">Type</th>
                                    <th className="">Amount</th>
                                    <th className="">Narration</th>
                                    <th className="">Channel</th>
                                    <th className="">PAN</th>
                                    <th className="">Date Created</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {failedElements}
                            </tbody>
                        </table>
                    </div> 
                </>
                : null } 
             </div>
          </div>
        
        }

        </>
    )
}

export default Upload