import React, { useState, useEffect } from 'react'
import Main from './Main'
import View from './View'

import Debit from './Debit';
import DebitAggregator from './DebitAggregator';
import DepositStatus from './DepositStatus';
import Upload from './Upload';
import Search from './Search';
import Download from './Download';
import Airtime from './Airtime';
import Filter from './Filter';

function Transaction () {
     const [params, setParams] = useState("manage")
     const [terminal, setTerminal] = useState({})
    
    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(terminal){

        setParams(prev => {
            return "edit"
        })
        setTerminal(prev => {
            return terminal
        })
    }
    
    
    
    return (
        <>          
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'view': <View changeApp={changeApp}  onEdit={onEdit} />,
                'debit' : <Debit changeApp={changeApp} />,
                'debitaggregator' : <DebitAggregator changeApp={changeApp} /> ,
                'status' : <DepositStatus changeApp={changeApp} />,
                'upload' : <Upload changeApp={changeApp} />,
                'search' : <Search changeApp={changeApp} />, 
                'download' : <Download changeApp={changeApp} />,
                'airtime' :  <Airtime changeApp={changeApp} />,
                'filter' : <Filter changeApp={changeApp} />,
            }[params] || <Main changeApp={changeApp} />
        }   
        </>
    )
}

export default Transaction