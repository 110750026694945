import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { NotificationService } from '../../service/NotificationService';
import moment from 'moment'
import { SuperAdminService } from '../../service/SuperAdminService';
import Spinner from '../Spinner';

function AuditComponent () {
    const [busy, setBusy] = useState(true)
    const [activitys, setActivitys] = useState([])
    let [ filtered, setFiltered] = useState([])
    let activityElements

 //-----------------------------------------------------------------------------------------------------------
 activityElements = filtered.slice(0,20).map(activity => {
    return (
        <tr key={activity.activityDate} onClick={() => console.log(activity)}>
            <td><a href="#">{activity.activityUser}</a></td>
            <td><a href="#">{activity.activityDetails}</a></td>
            <td><a href="#">{activity.activityEntity}</a></td>
            <td><a href="#">{activity.activityType}</a></td>
            <td><a href="#">{new Date(activity.activityDate).toDateString()}</a></td>            
        </tr>
    
    )
})

//------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        let endDate = moment().add(1, 'days').format('yyyy/MM/DD')
        let startDate =  moment().subtract('months', 1).format('yyyy/MM/DD')
        getAuditLogs(startDate, endDate);
    }, [])

//------------------------------------------------------------------------------------------------------------------------------
    function getAuditLogs(startDate, endDate){
        setBusy(true)
        SuperAdminService.getActivityLog({startDate: startDate, endDate: endDate})
            .then(response => {
                console.log(response)
                setBusy(false)

                setActivitys(prev => {
                    return response.data.data
                })

                setFiltered(prev => {
                    return response.data.data
                })
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
    }

//------------------------------------------------------------------------------------------------------------------
    const formik = useFormik({
        initialValues: {
           
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });
//------------------------------------------------------------------------------------------------------------------------------
//Gets All Unique Terminals from the transactions sent in
const uniqueType = [...new Set(activitys.map(activity => activity.activityType ? activity.activityType : null))];

const typeElements = uniqueType.map(type => {
    return (
        <option key={type} value={`${type}`}>{`${type}`}</option>
    )
})

//------------------------------------------------------------------------------------------------------------------------------
    function dateFilter(e){
        
        formik.handleChange(e);
        
        let startDate = moment(document.getElementsByName('startDate')[0].value)
        let endDate = moment(document.getElementsByName('endDate')[0].value)
        

        startDate = startDate.startOf('day')
        endDate = endDate.endOf('day')
        
        //console.log(endDate.isValid())

        if(!startDate.isValid() || !endDate.isValid()){
            NotificationService.notifyError("Please select both the Start Date and EndDate", 4000)
            return
        }

        if(startDate.isAfter(endDate))
        {
            NotificationService.notifyError("Start Date cannot be greater than End Date");
            return;
        }

        //Checking if the user has selected a date outside the default 1 month from Now
        if(startDate.isBefore(moment().subtract('months', 1)) || endDate.isAfter(moment() )){
            console.log( startDate.format('yyyy/MM/DD') + " " + moment().subtract('months', 1).format('yyyy/MM/DD') + " " + endDate.format('yyyy/MM/DD'))
            getAuditLogs(startDate, endDate)
        }
        

    }
//------------------------------------------------------------------------------------------------------------------------------
    function userFilter(e){
        formik.handleChange(e)
        console.log(e.target.value)

        const filter = activitys.filter(activity => activity.activityUser.toLowerCase().includes(e.target.value.toLowerCase()))

        if(filter.length !== 0){
            setFiltered(prev => {
                return filter
            })
        }
        else{
            NotificationService.notifyError("The name you enter does not match any of the activities available.", 2000)
            setFiltered(prev => {
                return activitys
            })
        }

        
    }
//------------------------------------------------------------------------------------------------------------------------------
function entityFilter(e){
        formik.handleChange(e)
        console.log(e.target.value)

        const filter = activitys.filter(activity => activity.activityEntity.toLowerCase().includes(e.target.value.toLowerCase()))

        if(filter.length !== 0){
            setFiltered(prev => {
                return filter
            })
        }
        else{
            NotificationService.notifyError("The name you enter does not match any of the activities available.", 2000)
            setFiltered(prev => {
                return activitys
            })
        }

    
    }
//------------------------------------------------------------------------------------------------------------------------------
function detailsFilter(e){
    formik.handleChange(e)

    const filter = activitys.filter(activity => activity.activityDetails.toLowerCase().includes(e.target.value.toLowerCase()))

    if(filter.length !== 0){
        setFiltered(prev => {
            return filter
        })
    }
    else{
        NotificationService.notifyError("The name you enter does not match any of the activities available.", 2000)
        setFiltered(prev => {
            return activitys
        })
    }


}
//----------------------------------------------------------------------------------------------------------
function typeFilter(e){
    formik.handleChange(e);

    const filter = activitys.filter(activity => activity.activityType.toLowerCase().includes(e.target.value.toLowerCase()))

    if(filter.length !== 0){
        setFiltered(prev => {
            return filter
        })
    }
    else{
        NotificationService.notifyError("The name you enter does not match any of the activities available.", 2000)
        setFiltered(prev => {
            return activitys
        })
    }

}
 
//------------------------------------------------------------------------------------------------------------------------------
    return (
        <> { busy ? <Spinner /> :
            <>
            <div className="row mg-t-20">
            <div className="col-md-12">
                <div className="dashboard-card-bg  pd-30">
                    <form >                               
                        <div className="floating-form">
                        <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.startDate} name="startDate" />
                                            
                                            <span className="highlight"></span>
                                            <label>Start Date</label>
                                        </div>
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.endDate} name="endDate"  />
                                            
                                            <span className="highlight"></span>
                                            <label>End Date</label>
                                        </div>
                        </div>
                                
                            <div className="row">
                                <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                    <input onChange={e => userFilter(e)} className="floating-input" type="text" placeholder="" name="user"  value={formik.values.user}/>
                                    <span className="highlight"></span>
                                    <label>Activity User</label>
                                </div>
                                <div className="floating-label tran col-md-6 pd-b-10">
                                    <input onChange={e => entityFilter(e)} className="floating-input" type="text" placeholder="" name="entity"  value={formik.values.entity} />
                                    <span className="highlight"></span>
                                    <label>Activity Entity</label>
                                </div>
                        
                            </div>
                        <div className="row">

                            <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                <input onChange={e => detailsFilter(e)} className="floating-input" type="text" placeholder="" name="details" value={formik.values.details}/>
                                <span className="highlight"></span>
                                <label>Activity Details</label>
                            </div>
                            <div className="floating-label tran  col-md-6 pd-b-10">
                                    <select onChange={e => typeFilter(e)} name="type" className="floating-select" value={formik.values.type}>
                                        <option value="" label="Select a Activity Type" />
                                        {typeElements}
                                    </select>
                                    <span className="highlight"></span>
                                    <label>Activity Type</label>
                            </div>
                        
                        </div>
                        
                        </div>
                        
                    </form>
                </div>
            </div>
            </div>
            
            <div className="table-wrapper table-responsive">
                <table id="datatable2" className="table display responsive nowrap">
                    <thead>
                        <tr >
                            <th className="">Activity User</th>
                            <th className="">Activity Details</th>
                            <th className="">Activity Entity</th>
                            <th className="">Activity Type</th>
                            <th className="">Activity Date</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {activityElements}
                    
                    </tbody>
                </table>
            </div>   
            </>   
        }
    </>
    )
    
}

export default AuditComponent