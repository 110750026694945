import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { NotificationService } from '../../service/NotificationService'

function Dispute ({disputes, onEdit}) {
    console.log(disputes)

    const [disputesLocal, setDisputesLocal] = useState([])
    let disputeElements

    //-----------------------------------------------------------------------------------------------------------
    disputeElements = disputesLocal.slice(0,20).map(dispute => {
        return (
            <tr key={dispute._id} onClick={() => onEdit(dispute)}>
            <td><a href="#">{dispute.title}</a></td>
            <td><a href="#">{dispute.details}</a></td>
            <td><a href="#">{dispute.status}</a></td>
            <td><a href="#">{dispute.requestTypeID.description}</a></td>
            <td><a href="#">{new Date(dispute.dateCreated).toDateString()}</a></td>            
        </tr>
        
        )
    })
    //console.log(disputeElements)
    //-----------------------------------------------------------------------------------------------------------
    useEffect(() => {
        setDisputesLocal(prev => {
           
            return disputes
        })
    }, [])

   // console.log(disputesLocal)
    //-----------------------------------------------------------------------------------------------------------
    const formik = useFormik({
        initialValues: {
            title : '',
            details: '',
            status: '',
            startDate: '',
            endDate: ''
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });

    //Gets All Status from the Disputes sent in 
    const uniqueTypes = [...new Set(disputes.map(dispute => dispute.title.description))];
    
    const statusElements = uniqueTypes.map(type => {
        return (
            <option key={type} value={`${type}`}>{`${type}`}</option>
        )
    })
    //-----------------------------------------------------------------------------------------------------------
    function detailsFilter(e){       
        formik.handleChange(e);

        const filtered = disputes.filter( dispute => dispute.details.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setDisputesLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered for Details does not match any value", 5000);
            setDisputesLocal(prev => {
                return disputes
            })
        }
      }
    //-----------------------------------------------------------------------------------------------------------
    function titleFilter(e){       
        formik.handleChange(e);

        const filtered = disputes.filter( dispute => dispute.title.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setDisputesLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered for Title does not match any value", 5000);
            setDisputesLocal(prev => {
                return disputes
            })
        }
        
      }
    //-----------------------------------------------------------------------------------------------------------
    function dateFilter(e){       
        formik.handleChange(e);
        
        const startDate = document.getElementsByName('startDate')[0].value
        const endDate = document.getElementsByName('endDate')[0].value
        console.log(startDate > endDate)

        if(startDate > endDate)
        {
            NotificationService.notifyError("Transaction Start Date cannot be greater than End Date");
            return;
        }

        const filtered = disputes.filter( dispute => dispute.dateCreated > startDate && dispute.dateCreated < endDate)

        if(filtered.length !== 0){
            setDisputesLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The Start Date and End Date combination does not match any transaction", 5000);
            setDisputesLocal(prev => {
                return filtered
            })
        }
      }
    //-----------------------------------------------------------------------------------------------------------
    function statusFilter(e){       
        formik.handleChange(e);
      }
    //-----------------------------------------------------------------------------------------------------------


    return (
            <>
        
               {/* <div className="row table-overall-headings mg-b-10">
                        <div className="col-md-6">
                            <h2>Requests</h2>
                        </div>
                    </div> */}
               <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form >
                               
                                <div className="floating-form">
                                <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.startDate} name="startDate" />
                                            
                                            <span className="highlight"></span>
                                            <label>Start Date</label>
                                        </div>
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.endDate} name="endDate"  />
                                            
                                            <span className="highlight"></span>
                                            <label>End Date</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                    <input onChange={e =>  titleFilter(e)} className="floating-input" type="text" name="title"  value={formik.values.title}/>
                                    <span className="highlight"></span>
                                    <label>Title</label>
                                </div>
                                <div className="floating-label tran col-md-6 pd-b-10">
                                    <input onChange={e => detailsFilter(e)} className="floating-input" type="text" name="details"  value={formik.values.details} />
                                    <span className="highlight"></span>
                                    <label>Details</label>
                                </div>
                                    </div>
                                <div className="row">
                                    
                                <div className="floating-label tran  col-md-6 pd-b-10">
                                        <select onChange={e => statusFilter(e)} name="status" className="floating-select" value={formik.values.status}>
                                            <option value="" label="Select Request Status" />
                                            {statusElements}
                                        </select>
                                        <span className="highlight"></span>
                                        <label>Status</label>
                                    </div>
                                </div>                                
                                
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>

                   <div className="table-wrapper table-responsive">
                       <table id="datatable2" className="table display responsive nowrap">
                           <thead>
                               <tr>
                                   <th className="">Title</th>
                                   <th className="">Details</th>
                                   <th className="">Status</th>
                                   <th className="">Type</th>
                                   <th className="">Date Created</th>
                                   
                               </tr>
                           </thead>
                           <tbody>
                               {disputeElements}
                           </tbody>
                       </table>
                   </div>
                   </>           
    )
}

export default Dispute