import React, {useState, useContext, useEffect} from "react"
import {Context} from '../contexts/MainContext'

function Header() {
  const [fullname, setFullname] = useState("");
  const [menu, setMenu] = useState(false);

  const {Logout} = useContext(Context)

  let userType;

  
  if(localStorage.getItem('user')){
    const { usertype } = JSON.parse(localStorage.getItem('user'))
    userType = usertype;
    userType = userType.toLowerCase();
  } 

  function toggleMenu(){
    setMenu(prev => {
        return !prev;
    })
  }

  const show = (menu) ? "show" : "" ;

  useEffect(() => {
    if(localStorage.getItem('user')){
    const {firstname, lastname} = JSON.parse(localStorage.getItem('user'))

    setFullname(prev => {
      return firstname + " " + lastname;
    })
  }   
  },[])

  

  function menuClickHandler(event){
    event.preventDefault()
    if (window.matchMedia('(min-width: 1200px)').matches) {
    document.getElementsByTagName("body")[0].classList.toggle('hide-sidebar')    
    } else {      
      document.getElementsByTagName("body")[0].classList.toggle('show-sidebar')
    }
  }

 
    return ( 
       <>
        <div className="primary-header with-sidebar">
          <div className="container-fluid">
            <div className="primary-header-left">
              <h2 className="primary-logo"><a href="/"> <img src="/assets/img/surebanka-logo.svg" alt="" /></a>
              </h2>
              <a  onClick={menuClickHandler} id="slimSidebarMenu" className="slim-sidebar-menu"><span></span></a>
            </div>
            <div className="primary-header-right">
              <div  className={"dropdown dropdown-c " + show}>
                <a className="logged-user" data-toggle="dropdown">
                  <div onClick={toggleMenu} className="user-initials">{fullname.split(' ').map((name) => name[0]).join('')}</div>
                  <span >{fullname}</span>
                </a>
                <div className={"dropdown-menu dropdown-menu-right " + show}>
                  <nav className="nav">
                    <a href={`/${userType.toLowerCase()}/profile`} className="nav-link">Profile</a>
                    <a href={`/${userType.toLowerCase()}/pin`} className="nav-link">Manage PIN</a>
                    <a href="" onClick={Logout} className="nav-link"> Log Out</a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
    )
}

export default Header