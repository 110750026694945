import axios from 'axios';
import { history } from './Routing'
import { NotificationService } from '../service/NotificationService'

const instance = axios.create({    
    baseURL: process.env.REACT_APP_BASE_URL
});

const token = localStorage.getItem('token')

instance.defaults.headers.common['Authorization'] = "Bearer " + token;
// instance.defaults.headers.common['ContentType'] = 'application/x-www-form-urlencoded'

instance.interceptors.request.use(request => {
    
    return request;
}, error => {
    
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    
    return response;
}, error => {
    if(!error.response){
        return Promise.reject(error); 
    }
    if(401 === error.response.status){
        
        NotificationService.notifyError("Your Session has expired, you would be logged out now, please login again", 5000)
        history.push('/login')
        setTimeout(() => {
            window.location.href = '/login'
        }, 3000);      
        
        
    }
    return Promise.reject(error);
});

export default instance; 