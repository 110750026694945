import React, { useState, useEffect } from 'react'
import DebitCustomer from '../../../components/transaction/Debit'

import { AdminService} from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import moment from 'moment'

function Debit ({ changeApp }) {
    const [transactions, setTransactions] = useState([]);
    const [busy, setBusy] = useState(true)

    const searchParams = {
       
        endDate: moment().add(1, 'days').format('yyyy/MM/DD'),
        startDate: moment().subtract('days', 3).format('yyyy/MM/DD'),
        lastWeek: false,
        lastMonth: false
    }

    useEffect(() => {

    }, [])
    
    return (
        <>
        {
             <div className="slim-mainpanel no-bg-img">
             <div className="container">
                 <div class="main-header">
                     <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                 </div>
                 <DebitCustomer /> 
             </div>
          </div>
        
        }

        </>
    )
}

export default Debit