/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../../service/NotificationService';
import InfiniteScroll from 'react-infinite-scroller';


function Performance ({agents}) {
  
    const [localAgents, setLocalAgents] = useState([])
    const [page, setPage] = useState(1)
    let  agentElements = [];
        
        if(localAgents.length !== 0){
            const take20 = localAgents.slice(0,page * 20)
            agentElements = take20.map(agent => {
                    return (
                        
                        <tr key={agent.email} onClick={() => console.log(agent)}>
                            
                            <td><a href="#">{agent.agentName}</a></td>
                            <td><a href="#">{agent.agentEmail}</a></td>
                            <td><a href="#">{agent.terminalAccruedAmount}</a></td>
                            <td><a href="#">{agent.terminalDepositCount}</a></td>
                            <td><a href="#">{agent.terminalPhysicalId}</a></td>                   
                        </tr>
                    )
                })    
            }

    useEffect(() => {       
        setLocalAgents(prev => {
            return agents
        })
    },[])

    const formik = useFormik({
        initialValues: {
            name : '',
            email: '',
            phone: '',
            address: ''
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });


      function nameChange(e){       

        const filtered = agents.filter( agent => agent.agentName.toLowerCase().includes(e.target.value.toLowerCase()))

        if(filtered.length !== 0){
            setLocalAgents(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered does not match any value", 2000);
            setLocalAgents(prev => {
                return agents
            })
        }
        
  }     

      function emailChange(e){
        formik.handleChange(e)

        //const filtered = agents.filter( agent => agent.email.toLowerCase() === e.target.value.toLowerCase())
        const filtered = agents.filter( agent => agent.agentEmail.toLowerCase().includes(e.target.value.toLowerCase()))

        console.log(filtered)

        if(filtered.length !== 0){
            setLocalAgents(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered does not match any value", 2000);
            setLocalAgents(prev => {
                return agents
            })
        }
        
      }


      function loadFunc(){
        console.log("Loading....")

        const totalPages = agents.length/20

        if(page > totalPages){
            console.log("Done")
            return;
        }

        setPage(prev => {
            return prev + 1
        })
    }



    return (
        <>
        <div className="slim-mainpanel">
                <div className="container">
               
               <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form >                               
                                <div className="floating-form">
                                <div className="row">
                                <div className="floating-label tran col-md-6 pd-b-10 pd-b-10">
                                    <input onChange={e => {formik.handleChange(e); nameChange(e)}} className="floating-input" type="text" placeholder="" name="name"  value={formik.values.name}/>
                                    <span className="highlight"></span>
                                    <label>Name</label>
                                </div>
                                <div className="floating-label tran col-md-6 pd-b-10">
                                    <input onChange={e => emailChange(e)} className="floating-input" type="text" placeholder="" name="email"  value={formik.values.email} />
                                    <span className="highlight"></span>
                                    <label>Email</label>
                                </div>
                               
                                </div>
                                
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    </div>

                   <div className="table-wrapper table-responsive">
                    <InfiniteScroll
                                            pageStart={0}
                                            loadMore={loadFunc}
                                            hasMore={true || false}
                                            loader={<div className="loader" key={0}>Loading ...</div>}
                                        >
                                        <table id="datatable2" className="table display responsive nowrap">
                                            <thead>
                                                <tr>
                                                    <th className="">Agent Name</th>
                                                    <th className="">Email</th>
                                                    <th className="">Withdrawal</th>
                                                    <th className="">Deposit Count</th>
                                                    <th className="">Terminal ID</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {agentElements}
                                            </tbody>
                                        </table>
                        </InfiniteScroll>
                   </div> 
            </div>
            </div>          
        </>
    )
}

export default Performance