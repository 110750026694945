import React, {useState, useEffect, useContext} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Spinner from '../components/Spinner';
import { NotificationService } from '../service/NotificationService';
import { AgentService } from '../service/AgentService';
import { UploadService} from '../service/UploadService';
import BVNValidation from '../components/BVNValidation';


function Profile () {
  const [busy, setBusy] = useState(true)
  const [editing, setEditing] = useState(false);
  const [isClient, setIsClient] = useState(false)
  const [isBVNValidated, setIsBVNValidated] = useState(false)

  let firstname, lastname, email, phonenumber, bvn, bankname,accountname, accountnumber, usertype, id, businessName, nextofKin, nextofKinAddress, nextofKinPhone, nextofKinRelationship = "";
  
  const [banks, setBanks] = useState([])
  const [bank, setBank] = useState('')

  const accountRegEx = /^\d{10}$/

  const bankOptions = banks.map(bank => {
    return(
        <option key={bank.bankCode} value={`${JSON.stringify(bank)}`}>{`${bank.bankName}`}</option>
    )
})


  if(JSON.parse(localStorage.getItem('user'))){
    const user = JSON.parse(localStorage.getItem('user'))
    
    firstname = user.firstname;
    lastname = user.lastname;
    email = user.email;
    //bankname = user.bankname
    phonenumber = user.phonenumber
    bvn = user.bvn
    accountname = user.accountname
    accountnumber = user.accountnumber
    usertype = user.usertype
    id = user.id
    businessName = user.businessName
    nextofKin = user.nextofKin
    nextofKinAddress = user.nextofKinAddress
    nextofKinPhone = user.nextofKinPhone
    nextofKinRelationship = user.nextofKinRelationship

    if(banks.length !== 0){
      
      const bank = banks.filter(bank => {
        
        return bank.bankName.toLowerCase().includes(user.bankname.toLowerCase().split(" ")[0])        
      })
      //console.log(JSON.stringify(bank[0]) + ": " + user.bankname + " " + bank[0].bankCode )
      bankname = bank[0] ? bank[0].bankName : ""
          
    }  
  }


  useEffect(() => {
    
    if(usertype === "Agent" || usertype ==="Aggregator"){
      setIsClient(true)
    }

    if(JSON.parse(localStorage.getItem('user')).isBVNvalidated){
      setIsBVNValidated(prev =>{
        return true
      })
    }
    
     AgentService.getBankList()
        .then(response => {
            //console.log(response.data.data.banks)
            setBanks(prev => {
                return response.data.data.banks
            })
          setBusy(false) 

         
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false) 
        })
  },[])

  const phoneRegExp = /^[0]\d{10}$/

  //----------------------------------------------------------------------------------------------------------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      firstname: firstname,
        lastname: lastname,
        email: email,
        phonenumber: phonenumber,
        bvn: bvn,
        bankname: "",
        accountname: accountname,
        accountnumber: accountnumber,
        usertype: usertype,
        businessName: businessName,
        NextofKin: "",
        NextofKinRelationship: "",
        NextofKinPhone: "",
        NextofKinAddress: "",
        signature: null,
        NextofKin: nextofKin,
        NextofKinAddress: nextofKinAddress,
        NextofKinPhone: nextofKinPhone,
        NextofKinRelationship: nextofKinRelationship
    },
    validate: ((values) => {
      if(formik.errors.bankname && formik.touched.bankname){
        NotificationService.notifyErrorWithID(formik.errors.bankname, "bankname", 3000)
        return
      }
      if(formik.errors.accountname && formik.touched.accountname){
        NotificationService.notifyErrorWithID(formik.errors.accountname, "accountname", 3000)
        return
      }
      if(formik.errors.accountnumber && formik.touched.accountnumber){
        NotificationService.notifyErrorWithID(formik.errors.accountnumber, "accountnumber", 3000)
        return
      }

      if(formik.errors.businessName && formik.touched.businessName){
        NotificationService.notifyErrorWithID(formik.errors.businessName, "businessName", 3000)
        return
      }
    }),
    validationSchema: Yup.object({
      bankname: Yup.string()
      .required('Please select a Bank from the list'),
      accountname: Yup.string()
      .required('Please enter your account name'),
      accountnumber: Yup.string()
        .required('Please enter the Account Number')
        .matches(accountRegEx, 'Please enter a valid NUBAN Account Number'),
      businessName: Yup.string()
        .required('Please enter your business name'),   
      
      }),
    onSubmit: values => {
      // console.log(values)
     updateUser(values)
             
    },
  });

  //----------------------------------------------------------------------------------------------------------------------------------------------------------
  async function updateUser(values){
    setBusy(true)

    const signature = await UploadService.uploadFile(values.signature)
    const returnURL = (signature._response.request.url.split("?")[0])

   AgentService.updateUser({...values, UserId: id, businessName: values.businessName, bank_code: JSON.parse(values.bankname).bankCode,  bank_name: JSON.parse(values.bankname).bankName, account_name: values.accountname, account_number: values.accountnumber.toString()})
   .then(response => {
     NotificationService.notify("Your user details was successfully updated.")
     setBusy(false)
     setEditing(false)
   })
   .catch(error => {
     if(error.response){
       NotificationService.notifyError(error.response.data.message, 5000)
   }
   else if(error.message){
       NotificationService.notifyError(error.message, 5000)
   }
   else{
       NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
   }
   setBusy(false)
   })
  }

  function editClicked(){
    setEditing(true)
    formik.values.bankname = '';
    formik.values.accountname = '';
    formik.values.accountnumber = ''
  }

  function validateAccount(e){
    formik.handleChange(e)
    
    if(!formik.errors.accountnumber && !formik.errors.bankname){
      console.log(formik.values.bankname.bankCode)
      NotificationService.notify("Getting Account Details")
      getAccountDetails(formik.values.accountnumber, JSON.parse(formik.values.bankname).bankCode, e)
    }
  }

//----------------------------------------------------------------------------------------------------------------------------------------------------------
function getAccountDetails(accountNumber, beneficiaryBank, e){
  setBusy(true)
  AgentService.getAccountDetails({accountNumber: accountNumber, beneficiaryBank: beneficiaryBank})
  .then(response => {
    //console.log(response.data.data)
    setBusy(false)

    formik.values.accountname = response.data.data.accountName 
    formik.handleChange(e)
    
  })
  .catch(error => {
    if(error.response){
        NotificationService.notifyError(error.response.data.message, 5000)
    }
    else if(error.message){
        NotificationService.notifyError(error.message, 5000)
    }
    else{
        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
    }
    setBusy(false)
  })
}

    return (
       <>
       {busy ? <Spinner /> :  
         
        <div className="slim-mainpanel">
            <div className="container">
                <div className="centered-form col-lg-12">
                <div className="signin-box client pd-0-force ">
                    <div className="pd-x-40">
                    <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Profile</h2>
                   
                    </div>
                   
                    <div className="">            
                   
                    
                    <form autoComplete="off"  onSubmit={formik.handleSubmit} > 
                   
                        <div className="floating-form ">
                        <div className="pd-x-40">

                       
                            <div className="floating-label ">
                              <input disabled={true} className="floating-input" type="text"  placeholder=" " name="firstname" autoComplete="none" autoFocus {...formik.getFieldProps('firstname')}/>
                              <span className="highlight"></span>
                              <label>First Name</label>
                            </div>
                                             
                        
                           
                            <div className="floating-label">
                              <input disabled={true}className="floating-input" type="text" placeholder=" " name="lastname" autoComplete="none" {...formik.getFieldProps('lastname')} />
                              <span className="highlight"></span>
                              <label>Last Name</label>
                            </div>
                           
                            
                            <div className="floating-label">
                              <input disabled={true} className="floating-input" type="email" placeholder=" " name="email" autoComplete="none" {...formik.getFieldProps('email')} />
                              <span className="highlight"></span>
                              <label>Email Address</label>
                            </div>
                            
                            
                            <div className="floating-label">
                            <input disabled={true} className="floating-input" type="text" placeholder=" " name="phonenumber" {...formik.getFieldProps('phonenumber')} />
                            <span className="highlight"></span>
                            <label>Phone Number</label>
                            </div>
                            
                            
                            { isClient ? 
                                  <>
                                      <div className="floating-label">
                                      <input disabled={true} className="floating-input" type="number" placeholder=" " name="bvn" {...formik.getFieldProps('bvn')} />
                                      <span className="highlight"></span>
                                      <label>BVN</label>
                                      </div>                       
                                    

                                      <div className="floating-label ">
                                      <select disabled={true} className="floating-input" placeholder="" name="usertype" {...formik.getFieldProps('usertype')} >
                                          <option value="" label="Select a User Type" />
                                          <option value="Agent">Agent</option>
                                          <option value="Aggregator">Aggregator</option>
                                      </select>
                                      <span className="highlight"></span>
                                      <label>Registration/User Type</label>
                                    </div>
                                  
                                    { !editing ? 
                                    <div className="floating-label">
                                        <input disabled={true} className="floating-input" type="text" placeholder="" name="bank" value={bankname} />
                                        <span className="highlight"></span>
                                        <label>Bank Name</label>
                                      </div>               
                                    :
                                    <div className="floating-label ">
                                      <select disabled={!editing}  onChange={e => validateAccount(e)} onBlur={e => validateAccount(e)} className="floating-input" placeholder="" name="bankname" value={formik.values.bankname} >
                                          <option value="" label="Select a Bank" />
                                          {bankOptions}
                                      </select>
                                      <span className="highlight"></span>
                                      <label>Bank Name</label>
                                    </div>
                                    }                    
                                
                                    <div className="floating-label">
                                      <input disabled={!editing} onChange={e => validateAccount(e)} onBlur={e => validateAccount(e)}  className="floating-input" type="text" placeholder=" " name="accountnumber" value={formik.values.accountnumber} />
                                      <span className="highlight"></span>
                                      <label>Account Number</label>
                                    </div>
                                  
                                    
                                    <div className="floating-label">
                                      <input disabled={true} className="floating-input" type="text" placeholder=" " name="accountname" {...formik.getFieldProps('accountname')} />
                                      <span className="highlight"></span>
                                      <label>Account Name</label>
                                    </div>

                                    <div className="floating-label">
                                      <input disabled={!editing} className="floating-input" type="text" placeholder=" " name="businessName" {...formik.getFieldProps('businessName')} />
                                      <span className="highlight"></span>
                                      <label>Business Name</label>
                                    </div>

                                    <div className="floating-label">
                                      <input disabled={!editing} className="floating-input" type="text" placeholder=" " name="NextofKin" {...formik.getFieldProps('NextofKin')} />
                                      <span className="highlight"></span>
                                      <label>Next of Kin Name</label>
                                    </div>

                                    <div className="floating-label">
                                      <input disabled={!editing} className="floating-input" type="text" placeholder=" " name="NextofKinRelationship" {...formik.getFieldProps('NextofKinRelationship')} />
                                      <span className="highlight"></span>
                                      <label>Next Of Kin Relationship(Father, Brother, Spouse)</label>
                                    </div>

                                    <div className="floating-label">
                                      <input disabled={!editing} className="floating-input" type="text" placeholder=" " name="NextofKinPhone" {...formik.getFieldProps('NextofKinPhone')} />
                                      <span className="highlight"></span>
                                      <label>Next Of Kin Phone Number</label>
                                    </div>

                                    <div className="floating-label">
                                      <input disabled={!editing} className="floating-input" type="text" placeholder=" " name="NextofKinAddress" {...formik.getFieldProps('NextofKinAddress')} />
                                      <span className="highlight"></span>
                                      <label>Next Of Kin Address</label>
                                    </div>

                                    <div className="floating-label">
                                      <input className="floating-input" disabled={!editing} type="file" name="signature" accept="image/*"
                                        onChange={event => {
                                          formik.setFieldValue("signature", event.currentTarget.files[0])
                                        }}
                                      />
                                      <span className="highlight"></span>
                                      <label>Upload Signature</label>
                                    </div>
                                  
                                </> : "" }

                                  <div className="floating-label">

                                      { editing ? 
                                        <button className="btn-fill-md majorButton" type="submit">Update</button>
                                        :         
                                      
                                      isClient ? <button onClick={(e) => editClicked()} type="button" className="btn-fill-md majorButton" >Edit</button> : ""                     
                                    
                          } 
                          
                          </div>
                          

                        
                          </div>
                        </div>
                        
                                      
                                               
                          
                    </form>
                    </div>
                </div> 
                </div>

              
                <div className="centered-form col-lg-12">
                  <div className="signin-box client pd-0-force ">
                  { isBVNValidated ? <><div className='pd-x-5'><h2 className='signin-title-primary tx-600 dark-color pd-t-40'>BVN is validated</h2></div></> : 
                    <BVNValidation bvn={bvn} />
                  }
                  </div>
                </div>
              
                
              
            </div>
        </div>
        }
       </>
    )
}

export default Profile