import { BlobServiceClient } from '@azure/storage-blob'

const uploadFile = (file) => {

    
    const account = process.env.REACT_APP_StorageAccount;
    const sas = process.env.REACT_APP_SAS;
    
    const containerName = process.env.REACT_APP_ContainerName;

    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net${sas}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = new Date().getTime() + file.name;

    

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    return blockBlobClient.uploadBrowserData(file)
            .then((response) => {
                
                return response;
            })
            .catch((error) => {
                console.log("Error Uploading " + blobName);
                return error;
            })
}

export const UploadService = {
    uploadFile,
}