import React, {useState, useEffect} from 'react'
import Edit from './Edit'
import View from './View'

function Terminal ({changeApp}) {
    
    const [params, setParams] = useState("view")
    const [terminal, setTerminal] = useState({})

    function changeUp(route){
        console.log(route)
        if(route === "manage"){
            changeApp(route)
        }
        else{
            setParams(prev => {
                return route
            })
        }
    }

    function updateTerminal(terminal){
        setTerminal(prev => {
            return terminal
        })

        setParams(prev => {
            return "edit"
        })
    }
    return (
        <>
            {
                    {
                        'view' : <View changeUp={changeUp} updateTerminal={updateTerminal} />,
                        'edit' : <Edit changeUp={changeUp} terminal={terminal} />
                    }[params] || <View changeUp={changeUp} updateTerminal={updateTerminal} />
                }
        </>
    )
}

export default Terminal