import React, { useState, useEffect } from 'react'
import Spinner from '../../../components/Spinner'

import { useFormik } from 'formik'
import * as Yup from 'yup';
import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'

function Power ({changeApp, power}) {
    const [busy, setBusy] = useState(true)
    const [discos, setDiscos] = useState([])
    const [terminals, setTerminals] = useState([])
    const { id } = JSON.parse(localStorage.getItem('user'));

    const discosOption = discos.map(disco => {
        return (<option key={disco.code} value={`${disco.code}`}>{`${disco.description}`}</option>)
    })

    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    useEffect(() => {
        AgentService.getAllDiscos()
        .then(response => {
            
            setDiscos(prev => {
                return response.data.bundles
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            setTerminals(prev => {
                return response.data
            })
            
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            meterno : '',
            amount: 0,
            disconame: '',
            email: '',
            phone: 0,
            pin: '',
            agent_charge: 0
        },
        validate: (what) => {
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyError(formik.errors.amount, 1000)
                return;
            }
            if(formik.errors.meterno && formik.touched.meterno){
                NotificationService.notifyError(formik.errors.meterno, 1000)
                return;
            }
            if(formik.errors.disconame && formik.touched.disconame){
                NotificationService.notifyError(formik.errors.disconame, 1000)
                return;
            }
           
            if(formik.errors.phone && formik.touched.phone){
                NotificationService.notifyError(formik.errors.phone, 1000)
                return;
            }
            if(formik.errors.pin && formik.touched.pin){
                NotificationService.notifyError(formik.errors.phone, 1000)
                return;
            }
            if(formik.errors.agent_charge && formik.touched.agent_charge){
                NotificationService.notifyError(formik.errors.phone, 1000)
                return;
            }
            
        },
        handleChange:() => {
          
        },
        validationSchema: Yup.object({
            amount: Yup.string()
              .required('Please enter the amount'),
            meterno: Yup.string()
              .required('Please enter the customer meter number'),
            phone: Yup.string()
              .required('Please enter the customer phone number')
              .test('len', 'Please enter a valid phone number', val => val.length === 13),
            disconame: Yup.string()
              .required('Please select Disco Bundle'),
            pin: Yup.string()
              .required('Please select Disco Bundle'),
          }),
        onSubmit: (values,{ resetForm }) => {  
             
            setBusy(true)         
            AgentService.buyPower({...values, pin: values.pin})
            .then(response => {
                
                NotificationService.notify("Power Purchase was successful." )
                setBusy(false)
                changeApp("main")
            })
            .catch(error => {
                
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });

    return (
       <>
       { busy ? <Spinner /> :
            <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("main")} className="pss-blue pd-b-10">← Go Back</a>
                    </div>

                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Buy Electricity</h2>
                            
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="number" placeholder=" "  name="amount" autoFocus {...formik.getFieldProps('amount')} />
                                                <span className="highlight"></span>
                                                <label>Amount</label>
                                            </div>
                                            <div className="floating-label tran  col-md-6 pd-b-10">
                                                <select className="floating-input" placeholder="" name="disconame" {...formik.getFieldProps('disconame')} >
                                                <option value="" label="Please select Disco Type" />
                                                {discosOption}                              
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Disco Bundles</label>                           
                                            </div>
                                           
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="number" placeholder="2347062067511"  name="phone" {...formik.getFieldProps('phone')}/>
                                                    <span className="highlight"></span>
                                                    <label>Phone Number</label>
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                    <input className="floating-input" type="text" placeholder=" "  name="meterno" {...formik.getFieldProps('meterno')}/>
                                                    <span className="highlight"></span>
                                                    <label>Meter Number</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                        <input className="floating-input" type="text" placeholder=" "  name="email" {...formik.getFieldProps('email')}/>
                                                        <span className="highlight"></span>
                                                        <label>Email Address</label>
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                        <input className="floating-input" type="password" placeholder=" "  name="pin" {...formik.getFieldProps('pin')}/>
                                                        <span className="highlight"></span>
                                                        <label>Terminal PIN</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                            <input className="floating-input" type="number" placeholder=" "  name="agent_charge" {...formik.getFieldProps('agent_charge')}/>
                                                            <span className="highlight"></span>
                                                            <label>Agent Charge</label>
                                                </div>

                                                <div className="floating-label tran col-md-6 pd-b-10">
                                                    <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                    <option value="" label="Please select terminal for this transaction" />
                                                    {terminalOptions}
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Terminal</label>                           
                                                </div>
                                        </div>

                                        <button className="btn-fill-md majorButton" type="submit">Purchase</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Power