import React, { useState } from 'react'
import View from './View'
import Edit from './Edit';

function Device () {
     const [params, setParams] = useState("view")
     const [terminal, setTerminal] = useState({})
    
    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(terminal){

        setParams(prev => {
            return "edit"
        })
        setTerminal(prev => {
            return terminal
        })
    }
    
    
    
    return (
        <>          
        {
            {         
                'view': <View changeApp={changeApp}  onEdit={onEdit} />,
                'edit' : <Edit changeApp={changeApp} terminal={terminal} />
            }[params] || <View changeApp={changeApp} />
        }
            
        </>
    )
}

export default Device