import React from 'react'
import useFormik from 'formik'

function Main ({changeMessage, changeApp}) {
    return (
        <>
        <div className="slim-mainpanel">
        <div className="container">

                <div class="main-header">
                    <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                </div>
                        <div class="main-header mg-b-30">
                            <h2 class="main-header-h-1 ">Fund Wallet</h2>
                            <p class="signin-title-secondary">Welcome, this is where you process Interbank transfers, fund or top-up your wallet, transfer from one wallet to another and cashout your funds.</p>
                        </div>

                       
                        
                        <div  class="row mg-t-20">
                            <div onClick={() => changeMessage("bank")} class="col-6 col-md-6 col-lg-3">
                                
                                    <div class="card pssCard bd-0 mg-b-30">
                                    <a href="#">
                                        <div class="card-body ">
                                    
                                        <p class="user-brand-name">Bank</p>
                                        <p class="user-brand-text-2 tx-small mg-b-10">Fund your wallet using your bank's internet, mobile banking or ussd code with your agent ID as account number.</p>
                                        </div>
                                    </a>
                                    </div>
                            
                            </div>
                    
                            <div onClick={() => changeMessage("card")} class="col-6 col-md-6 col-lg-3">                            
                                    <div class="card pssCard bd-0 mg-b-30">
                                        <a href="#">
                                            <div class="card-body ">
                                            
                                            <p class="user-brand-name">Card</p>
                                            <p class="user-brand-text-2 tx-small mg-b-10">Fund wallet via card</p>
                                            </div>
                                        </a>
                                    </div>
                                
                            </div>
                            
                        
                        </div>
                                        
                    </div>
                </div>
            
        </>
    )
}

export default Main