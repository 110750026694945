import React,{useEffect, useState} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../../service/NotificationService'
import { AdminService } from '../../service/AdminService'
import Spinner from '../Spinner';
import { SuperAdminService } from '../../service/SuperAdminService';

function Debit () {
    const [terminals, setTerminals] = useState([])
    const [transactionType, setTransactionType] = useState([])
    const [busy, setBusy] = useState(true)

    const terminalOption = terminals.map(terminal => {
        return (<option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>)
    })

    const typeOption = transactionType.map(type => {
        return (<option key={type._id} value={`${type.name}`}>{`${type.category} - ${type.name}`}</option>)
    })

   

    useEffect(() => {  

        AdminService.getAllTerminals()
        .then(response => {            
            
            if(response.data.length > 0){
                setTerminals(prev => {
                    return response.data
                });
                NotificationService.notify("The number of terminals on the platform is " + response.data.length);
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("You do not have any Agents assigned");
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

        SuperAdminService.getTransactionType()
        .then(response => {
            console.log(response.data.data)

            setTransactionType(prev => {
                return response.data.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

      },[])


    const formik = useFormik({
        initialValues: {
          rrn: '',
          terminalID: '',
          transactionType: '',
          type: '',
          amount: 0,
          responseCode: 0,
          responseDescription: '',
          authCode: 0,
          channel: '',
          pan: '',
          dateCreated: '',
          description: ''

        },
        validate: (what) => {
            
            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyErrorWithID(formik.errors.terminalID, "terminalID", 5000)
                return;
            }
            if(formik.errors.rrn && formik.touched.rrn){
                NotificationService.notifyErrorWithID(formik.errors.rrn, "rrn", 5000)
                return;
            }
            if(formik.errors.transactionType && formik.touched.transactionType){
                NotificationService.notifyErrorWithID(formik.errors.transactionType, "transactionType", 5000)
                return;
            }
            if(formik.errors.type && formik.touched.type){
                NotificationService.notifyErrorWithID(formik.errors.type, "type", 5000)
                return;
            }
            if(formik.errors.amount && formik.touched.amount){
                NotificationService.notifyErrorWithID(formik.errors.amount, "amount", 5000)
                return;
            }
            if(formik.errors.responseCode && formik.touched.responseCode){
                NotificationService.notifyErrorWithID(formik.errors.responseCode, "responseCode", 5000)
                return;
            }
            if(formik.errors.responseDescription && formik.touched.responseDescription){
                NotificationService.notifyErrorWithID(formik.errors.responseDescription, "responseDescription", 5000)
                return;
            }
            if(formik.errors.authCode && formik.touched.authCode){
                NotificationService.notifyErrorWithID(formik.errors.authCode, "authCode", 5000)
                return;
            }
            if(formik.errors.channel && formik.touched.channel){
                NotificationService.notifyErrorWithID(formik.errors.channel, "channel", 5000)
                return;
            }
            if(formik.errors.pan && formik.touched.pan){
                NotificationService.notifyErrorWithID(formik.errors.pan, "pan", 5000)
                return;
            }
            if(formik.errors.channel && formik.touched.channel){
                NotificationService.notifyErrorWithID(formik.errors.channel, "channel", 5000)
                return;
            }
            if(formik.errors.dateCreated && formik.touched.dateCreated){
                NotificationService.notifyErrorWithID(formik.errors.dateCreated, "dateCreated", 5000)
                return;
            }
            if(formik.errors.description && formik.touched.description){
                NotificationService.notifyErrorWithID(formik.errors.description, "description", 5000)
                return;
            }

        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
          terminalID: Yup.string()
            .required('Please enter the Terminal ID'),
          rrn: Yup.string()
            .required('Please enter the RRN'),
        transactionType: Yup.string()
            .required('Please select the Transaction Type/Category'),
        type: Yup.string()
            .required('Please select the Transaction Type'),
        amount: Yup.string()
            .required('Please enter the Transaction Amount'),
        responseCode: Yup.string()
            .required('Please enter the response code '),
        responseDescription: Yup.string()
            .required('Please enter response description'),
        authCode: Yup.string()
            .required('Please enter the Auth Code'),
        channel: Yup.string()
            .required('Please select the Channel'),
        pan: Yup.string()
            .required('Please enter the masked PAN'),
        description: Yup.string()
            .required('Please enter the  narration'),
        dateCreated: Yup.string()
            .required('Please enter the Transaction Date'),

        }),
        onSubmit: (values,{ resetForm }) => {         
            console.log(values)  
            setBusy(true)

            SuperAdminService.ReconcileUnImpactedTransactions(values)
            .then(response => {
                console.log(response)
                setBusy(false)
                NotificationService.notifyError("Transaction was successfully created")
                resetForm()
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)

            })
        }
      });

    return (
        <>
            { busy ? <Spinner /> :
            
                <>
                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Debit Customer</h2>
                        
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="rrn" autoFocus {...formik.getFieldProps('rrn')} />
                                                <span className="highlight"></span>
                                                <label>Reference Number (RRN)</label>
                                            </div>
                            
                                            
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="Number" placeholder=" "  name="amount" {...formik.getFieldProps('amount')}/>
                                                <span className="highlight"></span>
                                                <label>Amount</label>
                                            </div>
                                        
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="Number" placeholder=" "  name="responseCode" autoFocus {...formik.getFieldProps('responseCode')} />
                                                <span className="highlight"></span>
                                                <label>Response Code</label>
                                            </div>
                            
                                            
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="responseDescription" {...formik.getFieldProps('responseDescription')}/>
                                                <span className="highlight"></span>
                                                <label>Response Description</label>
                                            </div>
                                        
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                <option value="" label="Please select the Terminal" />
                                                {terminalOption}                              
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Select Terminal</label>                           
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="transactionType" {...formik.getFieldProps('transactionType')} >
                                                <option value="" label="Please select the transaction type/category" />
                                                 {typeOption}                            
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Transaction Type/Category</label>                           
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="type" {...formik.getFieldProps('type')} >
                                                <option value="" label="Please select the Transaction Type" />
                                                <option value="Debit" label="Debit" />
                                                <option value="Credit" label="Credit" />
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Select Transaction Type</label>                           
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="type" {...formik.getFieldProps('channel')} >
                                                <option value="" label="Please select the Channel" />
                                                <option value="POS" label="POS" />
                                                <option value="WEB" label="WEB" />
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Select Transaction Channel</label>                           
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="Number" placeholder=" "  name="authCode" autoFocus {...formik.getFieldProps('authCode')} />
                                                <span className="highlight"></span>
                                                <label>Auth Code</label>
                                            </div>
                            
                                            
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="pan" {...formik.getFieldProps('pan')}/>
                                                <span className="highlight"></span>
                                                <label>Masked PAN</label>
                                            </div>
                                        
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="description" autoFocus {...formik.getFieldProps('description')} />
                                                <span className="highlight"></span>
                                                <label>Narration</label>
                                            </div>

                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder="2020/11/07 00:00:00 "  name="dateCreated" autoFocus {...formik.getFieldProps('dateCreated')} />
                                                <span className="highlight"></span>
                                                <label>Transaction Date/Time</label>
                                            </div>

                                        </div>

                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </>
            
        }
        </>
    )
}

export default Debit