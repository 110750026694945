import React from "react";
import Confirmation from "../components/Confirmation";
import PublicHeader from "../components/PublicHeader";
import Footer from "./Footer";

function TwoFactorMessage() {
    const header = "Login Successful on Surebanka!!!"
    const message = `Your Login was successful to your Surebanka Account, for extra security we have sent you an email containing a secured link to grant you access to your account.`
  return (
    <>
      <PublicHeader />
      <Confirmation header={header} message={message} />
      <Footer />
    </>
  );
}

export default TwoFactorMessage;
