import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdminService } from '../../../service/AdminService'
import { SuperAdminService } from '../../../service/SuperAdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import _ from 'underscore'

const formatter = new Intl.NumberFormat('en-US');

function Edit({ changeApp, terminal }) {
    const [agents, setAgents] = useState([])
    const [busy, setBusy] = useState(true)
    const [map, setMap] = useState(false)

    const agentsOption = agents.map(user => {
        return (<option key={user.email} value={`${user.email}`}>{`${user.email}`}</option>)
    })

    console.log(terminal)

    function changeUp(param) {
        changeApp(param);
    }

    useEffect(() => {
        AdminService.getAllAgents()
            .then(response => {

                console.log(response)
                const sorted = _.sortBy(response.data, 'email')

                setAgents(prev => {
                    return sorted
                })

                setBusy(false)
            })
            .catch(error => {
                if (error.response) {
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if (error.message) {
                    NotificationService.notifyError(error.message, 5000)
                }
                else {
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
    }, [])

    const formik = useFormik({
        initialValues: {
            terminalOwner: terminal.terminalOwner ? terminal.terminalOwner.email : "",
            terminalSerialNumber: terminal.terminalSerialNumber,
            terminalType: terminal.terminalType.name,
            terminalPhysicalId: terminal.terminalPhysicalId,
            UPSLTerminalPhysicalId: terminal.UPSLTerminalPhysicalId

        },
        validate: (what) => {

            if (formik.errors.terminalOwner) {
                NotificationService.notifyError(formik.errors.terminalOwner, "", 5000)
            }
        },
        handleChange: () => { },
        validationSchema: Yup.object({
            terminalOwner: Yup.string()
                .required('Please select a new owner'),

        }),
        onSubmit: (values, { resetForm }) => {
            console.log(values)
            AdminService.assignTerminal({ agentemail: values.terminalOwner, terminalId: terminal._id })
                .then(response => {
                    setBusy(false)
                    NotificationService.notify("Terminal was successfully updated")
                    switchViews(values)
                    changeApp('view')

                })
                .catch(error => {
                    if (error.response) {
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if (error.message) {
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else {
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)
                })



        }
    });

    const update = useFormik({
        initialValues: {
            terminalId: terminal._id,
            UPSLTerminalPhysicalId: ''
        },
        validate: (what) => {

            if (update.errors.UPSLTerminalPhysicalId && update.touched.UPSLTerminalPhysicalId) {
                NotificationService.notifyError(update.errors.UPSLTerminalPhysicalId, "UPSLTerminalPhysicalId", 5000)
            }
        },
        handleChange: () => { },
        validationSchema: Yup.object({
            UPSLTerminalPhysicalId: Yup.string()
                .required('Please enter the UPSL Terminal ID'),

        }),
        onSubmit: (values, { resetForm }) => {
            console.log(values)
            setBusy(true)
            AdminService.updateUPSLTerminalPhysicalId(values)
                .then(response => {
                    setBusy(false)
                    NotificationService.notify("Terminal was successfully updated")
                    switchViews(values)
                    changeApp('view')

                })
                .catch(error => {
                    if (error.response) {
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if (error.message) {
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else {
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)
                })

        }
    });

    const fund = useFormik({
        initialValues: {
        },
        validate: (what) => {
            console.log(fund.errors)
            if (fund.errors.amount && fund.touched.amount) {
                NotificationService.notifyError(fund.errors.amount, "amount", 5000)
            }
        },
        handleChange: () => { },
        validationSchema: Yup.object({
            amount: Yup.string()
                .required('Please enter the amount'),

        }),
        onSubmit: (values, { resetForm }) => {


            confirmAlert({
                title: 'Confirmation',
                message: "Are you sure you want to fund this terminal with " + values.amount,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            setBusy(true)
                            SuperAdminService.walletTopUp({ terminalID: terminal._id, amount: values.amount, agentCharge: 0, accountName: "UnKnown", message: "Done Temporarily to Fund Terminals" })
                                .then(response => {
                                    console.log(response)
                                    setBusy(false)
                                    NotificationService.notify("Terminal Funded Successfully")
                                    changeApp('view')
                                })
                                .catch(error => {
                                    if (error.response) {
                                        NotificationService.notifyError(error.response.data.message, 5000)
                                    }
                                    else if (error.message) {
                                        NotificationService.notifyError(error.message, 5000)
                                    }
                                    else {
                                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                                    }
                                    setBusy(false)
                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {

                            setBusy(false)
                        }
                    }
                ]
            });


        }
    });


    function switchViews(e) {

        if (e.terminalOwner) {
            formik.values.terminalOwner = e.terminalOwner;
        }
        else {
            formik.values.terminalOwner = ''
        }
        setMap(prev => {
            return !prev
        })
    }

    function unAssign(e) {
        setBusy(true)
        SuperAdminService.unAssignTerminal({ terminalId: terminal._id, agentemail: terminal.terminalOwner.email })
            .then(response => {
                NotificationService.notify("Terminal was successfully unassigned")
                setBusy(false)
                changeApp('view')
            })
            .catch(error => {
                if (error.response) {
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if (error.message) {
                    NotificationService.notifyError(error.message, 5000)
                }
                else {
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
    }

    function deleteTerminal() {

        confirmAlert({
            title: 'Confirmation',
            message: "Are you sure you want to delete this terminal",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setBusy(true)

                        SuperAdminService.deleteTerminal({ terminalId: terminal._id })
                            .then(response => {
                                NotificationService.notify("Terminal was successfully deleted")
                                setBusy(false)
                                changeApp('view')
                            })
                            .catch(error => {
                                if (error.response) {
                                    NotificationService.notifyError(error.response.data.message, 5000)
                                }
                                else if (error.message) {
                                    NotificationService.notifyError(error.message, 5000)
                                }
                                else {
                                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                                }
                                setBusy(false)
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                        setBusy(false)
                    }
                }
            ]
        });
       
    }

    function pndTerminal(){
        confirmAlert({
            title: 'Confirmation',
            message: "Are you sure you want to PND this terminal",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setBusy(true)

                        SuperAdminService.pndTerminal({ terminalId: terminal._id, status: !terminal.pnd })
                            .then(response => {
                                NotificationService.notify("Action completed succesfully.")
                                setBusy(false)
                                changeApp('view')
                            })
                            .catch(error => {
                                if (error.response) {
                                    NotificationService.notifyError(error.response.data.message, 5000)
                                }
                                else if (error.message) {
                                    NotificationService.notifyError(error.message, 5000)
                                }
                                else {
                                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                                }
                                setBusy(false)
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                        setBusy(false)
                    }
                }
            ]
        });
    }

    return (
        <>
            {busy ? <Spinner /> :
                <div className="slim-mainpanel">
                    <div className="container">

                        <div className="main-header">
                            <a href="#" onClick={() => changeUp("view")} className="pss-blue pd-b-10">← Go Back</a>
                            <h2 className="main-header-h-1 ">Edit Terminal</h2>
                        </div>

                        <div className="centered-form col-lg-12">
                            <div className="signin-box pd-20-force create-agg">
                                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                    <fieldset >
                                        <div className="floating-form ">

                                            <div className="row">
                                                {map ?
                                                    <div className="floating-label  col-md-6 pd-b-10">
                                                        <select disabled={!map} className="floating-input" placeholder="" name="terminalOwner" {...formik.getFieldProps('terminalOwner')} >
                                                            <option value="" label="Please select the Terminal Owner" />
                                                            {agentsOption}
                                                        </select>
                                                        <span className="highlight"></span>
                                                        <label>Terminal Owner</label>
                                                    </div>
                                                    :
                                                    <div className="floating-label  col-md-6">
                                                        <input disabled={!map} className="floating-input" type="text" placeholder=" " name="terminalOwner" autoComplete="none" {...formik.getFieldProps('terminalOwner')} />
                                                        <span className="highlight"></span>
                                                        <label>Terminal Owner</label>
                                                    </div>

                                                }

                                                <div className="floating-label  col-md-6">
                                                    <input disabled={true} className="floating-input" type="text" placeholder=" " name="terminalSerialNumber" autoComplete="none" {...formik.getFieldProps('terminalSerialNumber')} />
                                                    <span className="highlight"></span>
                                                    <label>Terminal Serial Number</label>
                                                </div>

                                            </div>

                                            <div className="row" >
                                                <div className="floating-label  col-md-6">
                                                    <input disabled={true} className="floating-input" type="email" placeholder=" " name="terminalPhysicalId" autoComplete="none" {...formik.getFieldProps('terminalPhysicalId')} />
                                                    <span className="highlight"></span>
                                                    <label>Terminal ID</label>
                                                </div>

                                                
                                                <div className="floating-label  col-md-6">
                                                    <input disabled={true} className="floating-input" type="email" placeholder=" " name="UPSLTerminalPhysicalId" autoComplete="none" {...formik.getFieldProps('UPSLTerminalPhysicalId')} />
                                                    <span className="highlight"></span>
                                                    <label>UPSL Terminal ID</label>
                                                </div>


                                                <div className="floating-label  col-md-6">
                                                    <input disabled={true} className="floating-input" type="text" placeholder=" " name="terminalType" {...formik.getFieldProps('terminalType')} />
                                                    <span className="highlight"></span>
                                                    <label>Terminal Type</label>
                                                </div>

                                            </div>
                                            {terminal.terminalOwner ? null :
                                                <div disabled={!map} className="row pd-10">
                                                    {!map ? <button onClick={(e) => switchViews(e)} className="btn btn-primary">Map</button>
                                                        :
                                                        <button className="btn btn-warning" type="submit">Update Owner</button>}
                                                </div>
                                            }

                                            <div disabled={!map} className="row pd-10 ">
                                                {terminal.terminalOwner ?
                                                    <button onClick={(e) => unAssign(e)} className="btn btn-warning" >UnAssign</button> : null}
                                            </div>
                                        
                                            

                                        </div>
                                    </fieldset>
                                </form>
                                
                                <div disabled={!map} className="row pd-10 ">
                                                    <button onClick={(e) => pndTerminal()} className="btn btn-danger" >{ terminal.pnd ? "Remove PND" : "PND"}</button>
                                </div>

                            </div>
                        </div>

                        {terminal.terminalOwner ?
                            <div className="centered-form col-lg-12">
                                <div className="signin-box pd-20-force create-agg">
                                    <form autoComplete="off" onSubmit={fund.handleSubmit}>
                                        <fieldset >
                                            <div className="floating-form ">
                                                <div className="row" >
                                                    <div className="floating-label  col-md-6">
                                                        <input disabled={true} className="floating-input" type="email" placeholder=" " name="terminalPhysicalId" autoComplete="none" {...formik.getFieldProps('terminalPhysicalId')} />
                                                        <span className="highlight"></span>
                                                        <label>Terminal ID</label>
                                                    </div>


                                                    <div className="floating-label  col-md-6">
                                                        <input className="floating-input" type="number" placeholder=" " name="amount" {...fund.getFieldProps('amount')} />
                                                        <span className="highlight"></span>
                                                        <label>Amount</label>
                                                    </div>

                                                    <button className="btn btn-primary" type="submit">Fund Terminal</button>

                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div> : null}

                            <div className="centered-form col-lg-12">
                                <div className="signin-box pd-20-force create-agg">
                                    <form autoComplete="off" onSubmit={update.handleSubmit}>
                                        <fieldset >
                                            <div className="floating-form ">
                                                <div className="row" >
                                                    <div className="floating-label  col-md-6">
                                                        <input disabled={true} className="floating-input" type="text" placeholder=" " name="terminalPhysicalId" autoComplete="none" {...formik.getFieldProps('terminalPhysicalId')} />
                                                        <span className="highlight"></span>
                                                        <label>Terminal ID</label>
                                                    </div>


                                                    <div className="floating-label  col-md-6">
                                                        <input className="floating-input" type="text" placeholder=" " name="UPSLTerminalPhysicalId" {...update.getFieldProps('UPSLTerminalPhysicalId')} />
                                                        <span className="highlight"></span>
                                                        <label>UPSL Terminal ID</label>
                                                    </div>

                                                    <button className="btn btn-primary" type="submit">Update UPSL Terminal ID</button>

                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>


                            <div disabled={!map} className="row pd-10 ">
                                <button onClick={(e) => deleteTerminal(e)} className="btn btn-danger">Delete Terminal</button>
                            </div>

                    </div>
                </div>
            }
        </>
    )
}

export default Edit