import React, { useState, useEffect } from 'react'
import Tran from '../../../components/transaction/Tran'
import { AdminService } from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';
import moment from 'moment'
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Filter() {
    const [transactions, setTransactions] = useState([]);
    const [busy, setBusy] = useState(false);



    useEffect(() => {
    }, [])

    const formik = useFormik({
        initialValues: {
            transactionType: '',
            date: '',

        },
        validate: (what) => {

            if (formik.errors.transactionType && formik.touched.transactionType) {
                NotificationService.notifyErrorWithID(formik.errors.transactionType, "transactionType", 5000)
                return;
            }
            if (formik.errors.date && formik.touched.date) {
                NotificationService.notifyErrorWithID(formik.errors.date, "date", 5000)
                return;
            }
        },
        handleChange: () => {

        },
        validationSchema: Yup.object({
            transactionType: Yup.string()
                .required('Please select the Transaction Type'),
            date: Yup.string()
                .required('Please select the Transaction Date'),

        }),
        onSubmit: (values, { resetForm }) => {
              
            setBusy(true)

            const searchParams = {

                endDate: moment(values.date).format('yyyy-MM-DD'),
                startDate: moment(values.date).format('yyyy-MM-DD'),
                // lastWeek: false,
                // lastMonth: false
            }

            console.log(searchParams)

            AdminService.getTransactions(searchParams)
            .then(response => {
                console.log(response.data.data)
                
                if(response.data.data.length > 0){
                    
                    setTransactions(prev => {
                        return response.data.data.filter(transaction => transaction.TransactionType === values.transactionType);
                    });
                    NotificationService.notify("Transactions pulled successfully");
                    }
                    else if(response.data.length === 0){
                        NotificationService.notifyInfo("No transactions found for the speficed parameters.");
                    }
                    
                setBusy(false)
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
    })



    return (
        <>
            {
                busy ? <Spinner /> : (transactions.length === 0 ?

                    <div className="slim-mainpanel no-bg-img">
                        <div className="container">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off" onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select className="floating-input" placeholder="" name="transactionType" {...formik.getFieldProps('transactionType')} >
                                                    <option value="" label="Select a Transaction Type" />
                                                    <option value="Deposit">Deposit</option>
                                                    <option value="Withdrawal">Withdrawal</option>
                                                </select>
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="date" name="date" {...formik.getFieldProps('date')} />
                                                <span className="highlight"></span>
                                                <label>Transaction Date</label>
                                            </div>
                                        </div>

                                        <button className="btn btn-primary" type="submit">Search</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    :

                    <Tran transactions={transactions} />
                )
            }

        </>
    )
}

export default Filter