import axios from 'axios';


const instance = axios.create({
    
    baseURL: process.env.REACT_APP_BASE_URL
    //'https://nestjsapp.azurewebsites.net'
    //baseURL: 'http://localhost:3001/'
});

instance.interceptors.request.use(request => {
    
    return request;
}, error => {
    
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    
    return response;
}, error => {
    
    return Promise.reject(error);
});

export default instance; 