import React,{useEffect, useState} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../../service/NotificationService'
import { AdminService } from '../../service/AdminService'
import Spinner from '../Spinner';

function CreateTerminal () {
    const [terminalTypes, setTerminalTypes] = useState([])
    const [users, setUsers] = useState([])
    const [busy, setBusy] = useState(true)

    const usersOption = users.map(user => {
        return (<option key={user.email} value={`${user._id}`}>{`${user.email}`}</option>)
    })

    const typesOption = terminalTypes.map(type => {
        return (<option key={type.name} value={`${type._id}`}>{`${type.name}`}</option>)
    })

    useEffect(() => {  
        AdminService.getAllAgents()
        .then(response => {
            console.log(response)

            setUsers(prev => {
                return response.data
            })

            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

        AdminService.getTerminalTypes()
        .then(response => {
            console.log(response.data)

            setTerminalTypes(prev => {
                return response.data.data
            })

            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      },[])


    const formik = useFormik({
        initialValues: {
          terminalPhysicalId: '',
          terminalSerialNumber: '',
          terminalOwner: '',
          terminalType: '',
          UPSLTerminalPhysicalId: '',
        },
        validate: (what) => {
            
            if(formik.errors.terminalPhysicalId && formik.touched.terminalPhysicalId){
                NotificationService.notifyError(formik.errors.terminalPhysicalId, "terminalPhysicalId", 5000)
                return;
            }
            if(formik.errors.terminalSerialNumber && formik.touched.terminalSerialNumber){
                NotificationService.notifyError(formik.errors.terminalSerialNumber, "terminalSerialNumber", 5000)
                return
            }
            if(formik.errors.UPSLTerminalPhysicalId && formik.touched.UPSLTerminalPhysicalId){
                NotificationService.notifyError(formik.errors.UPSLTerminalPhysicalId, "UPSLTerminalPhysicalId", 5000)
                return
            }
            if(formik.errors.terminalType && formik.touched.terminalType){
                NotificationService.notifyError(formik.errors.terminalType, 5000)
            }
        },
        handleChange:() => {
            
        },
        validationSchema: Yup.object({
          terminalPhysicalId: Yup.string()
            .required('Please enter the Terminal ID'),
          terminalSerialNumber: Yup.string()
            .required('Please enter the Serial Number of the Terminal'),
          UPSLTerminalPhysicalId: Yup.string()
            .required('Please enter the UPSL Terminal ID'),
        terminalType: Yup.string()
            .required('Please select the Terminal Type')
        }),
        onSubmit: (values,{ resetForm }) => {         
            console.log(values)  
            setBusy(true)

            AdminService.createTerminal(values)
            .then(response => {
                console.log(response)
                setBusy(false)
                NotificationService.notifyInfo("Terminal was successfully created")
                resetForm()
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)

            })
        }
      });

    return (
        <>
            { busy ? <Spinner /> :
            
                <>
                    <div className="pd-x-5">
                            <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Terminal Onboarding</h2>
                        
                    </div>

                    <div className="row mg-t-20">
                        <div className="col-md-12">
                            <div className="dashboard-card-bg  pd-30">
                                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                    <div className="floating-form">
                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="terminalPhysicalId" autoFocus {...formik.getFieldProps('terminalPhysicalId')} />
                                                <span className="highlight"></span>
                                                <label>Terminal ID</label>
                                            </div>
                                            {/* {formik.touched.terminalPhysicalId && formik.errors.terminalPhysicalId ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.terminalPhysicalId}</span></a> </p>) : null} */}
                                            
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="terminalSerialNumber" {...formik.getFieldProps('terminalSerialNumber')}/>
                                                <span className="highlight"></span>
                                                <label>Terminal Serial Number</label>
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="UPSLTerminalPhysicalId" {...formik.getFieldProps('UPSLTerminalPhysicalId')}/>
                                                <span className="highlight"></span>
                                                <label>UPSL Terminal ID</label>
                                            </div>
                                            {/* {formik.touched.terminalSerialNumber && formik.errors.terminalSerialNumber ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.terminalSerialNumber}</span></a> </p>) : null} */}
                                        </div>

                                        <div className="row">
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="terminalOwner" {...formik.getFieldProps('terminalOwner')} >
                                                <option value="" label="Please select the user" />
                                                {usersOption}                              
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Ternminal Owner</label>                           
                                            </div>
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="terminalType" {...formik.getFieldProps('terminalType')} >
                                                <option value="" label="Please select the user type" />
                                                {typesOption}                              
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Terminal Type</label>                           
                                            </div>
                                        </div>

                                        <button className="btn btn-primary" type="submit">Create</button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </>
            
        }
        </>
    )
}

export default CreateTerminal