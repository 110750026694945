import React, {useState, useContext} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Footer from './Footer';
import PublicHeader from '../components/PublicHeader';
import Spinner from '../components/Spinner';
import { useHistory, useParams} from "react-router-dom"

import {Context} from '../contexts/MainContext'
import {UserService } from '../service/UserService'
import { NotificationService } from '../service/NotificationService';


function ResetPasword () {
    const [busy, setBusy] = useState(false);
    const [info, setInfo] = useState("")
    const  { token } = useParams();

    console.log(token)

    const {updateLoggedIn, changeProgress} = useContext(Context)
    const history = useHistory();

    function updateInfo(message){
        setInfo(prev => {
          return message
        })
      }

    function updateBusy(){
        setBusy(prev => {
            return !prev
        })
      }

    const formik = useFormik({
        initialValues: {
          password: '',
          token: token
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
          updateInfo(" ")
        },
        validationSchema: Yup.object({
            password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required('Please enter your password'),
            confirm: Yup.string().when("password", {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
              )
            })
        }),
        onSubmit: (values,{ resetForm }) => {
          
          setBusy(true)
          changeProgress(35);

          UserService.reset(values)
          .then(response => {
            NotificationService.notify("Your Password has been updated")
            changeProgress(100);
            setBusy(false)
            history.push(`/login`)      
          })
          .catch(error => {
            if(error.response){
              NotificationService.notifyError(error.response.data.message, 5000)
          }
          else if(error.message){
              NotificationService.notifyError(error.message, 5000)
          }
          else{
              NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
          }
          setBusy(false)
          })
          
        }
      });

    return (
        <>
        <PublicHeader />
        {busy ? <Spinner /> :  
          <div className="slim-body" > 
          <div className="signin-wrapper container">
                <div className="signin-box mg-b-40 col-lg-6">
                {/* <h2 className="primary-logo brand">
                    <a href="/"> <img className="text-center" src="assets/img/surebanka-logo.svg" alt=""/> </a>
                </h2> */}
                <h2 className="signin-title-primary loginHeader mg-b-0-force">Update Password</h2>
                <p className="signin-title-secondary">Enter and Confirm New Password</p>
            <p className="forgot-pass"><a href=""> <span className="pss-red">{info}</span></a> </p>
            <div className="">

                <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                    <div className="floating-form">
                        <div className="floating-label">
                                <input className="floating-input" type="password" placeholder=" " name="password" {...formik.getFieldProps('password')}/>
                                <span className="highlight"></span>
                                <label>Password</label>
                        </div>
                        {formik.touched.password && formik.errors.password ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.password}</span></a> </p>) : null}
                                
                        <div className="floating-label">
                            <input className="floating-input" type="password" placeholder=" " name="confirm" {...formik.getFieldProps('confirm')}/>
                            <span className="highlight"></span>
                            <label>Confirm Password</label>
                        </div>
                        {formik.touched.confirm && formik.errors.confirm ? ( <p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.confirm}</span></a> </p>) : null}
                        
                        <button className="btn-fill-md majorButton" type="submit">Submit</button>
                    </div>
                </form>
              
            </div>
        </div>
        </div>
         </div>
        }
        <Footer />
        </>
    )
}

export default ResetPasword