import React, {useState, useCallback} from 'react'
import ImageViewer from "react-simple-image-viewer";

function ImageViewerComponent ({images}) {
    
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
    
      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

    return (
        <>
            <div className="centered-form col-lg-12">
                            <div className="signin-box pd-20-force create-agg">
                                <div className="row">
                                                {images.map((src, index) => (
                                                    <img
                                                    src={src}
                                                    onClick={() => openImageViewer(index)}
                                                    width="300"
                                                    key={index}
                                                    style={{ margin: "2px" }}
                                                    alt=""
                                                    />
                                                ))}

                                                {isViewerOpen && (
                                                    <ImageViewer
                                                    src={images}
                                                    currentIndex={currentImage}
                                                    onClose={closeImageViewer}
                                                    backgroundStyle={{
                                                        backgroundColor: "rgba(0,0,0,0.9)"
                                                    }}
                                                    />
                                                )}
                                    </div>
                            </div>
                    </div>
        </>
    )
}

export default ImageViewerComponent