/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'
import { useFormik} from 'formik'
import * as Yup from 'yup';

function Edit ({changeApp, request}) {
    
    const [busy, setBusy] = useState(true)
    const [map, setMap] = useState(false)
    const { id } = JSON.parse(localStorage.getItem('user')) 


    useEffect(() => {
        setBusy(false)
    }, [])

    const formik = useFormik({
        initialValues: {
             title: request.title,
             status: request.status,
             details: request.details,
             dateOpened: request.dateOpened,
             type: request.requestTypeID.description,
             user: request.requester.firstname + " " + request.requester.lastname,
             email: request.requester.email,
             usertype: request.requester.usertype,
             comment: ''
        },
        validate: (what) => {
            
            if(formik.errors.comment && formik.touched.comment){
                NotificationService.notifyError(formik.errors.comment, 5000)
            }
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            comment: Yup.string()
                .required('Please enter a closure comment for the request.'),
            
        }),
        onSubmit: (values,{ resetForm }) => {
            
        }
      });

    
    return (
        <>
       { busy ? <Spinner /> :
        <div className="slim-mainpanel">
                <div className="container">
                    
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("view")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Request Details</h2>
                    </div>

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            
                                <div className="floating-form ">
                                
                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" name="title" {...formik.getFieldProps('title')} />
                                                <span className="highlight"></span>
                                                <label>Title</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="status" {...formik.getFieldProps('status')} />
                                                <span className="highlight"></span>
                                                <label>Status</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                        <div className="floating-label tran col-md-12 pd-b-10" >
                                                    <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" name="details" value={formik.values.details}/>
                                                    <span className="highlight"></span>
                                                    <label>Request Details</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" name="type" {...formik.getFieldProps('type')} />
                                                <span className="highlight"></span>
                                                <label>Request Type</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="user" {...formik.getFieldProps('user')} />
                                                <span className="highlight"></span>
                                                <label>User</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={!map} className="floating-input" type="text" name="email" {...formik.getFieldProps('email')} />
                                                <span className="highlight"></span>
                                                <label>email</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="usertypes" {...formik.getFieldProps('usertype')} />
                                                <span className="highlight"></span>
                                                <label>User Type</label>
                                            </div>

                                        </div>
                                        
                                        <div className="row">

                                        <div className="floating-label tran col-md-12 pd-b-10" >
                                                <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" name="comment"  {...formik.getFieldProps('comment')}/>
                                                <span className="highlight"></span>
                                                <label>Closure Comments</label>
                                            </div>

                                        </div>

                                </div>
                                
                            </form>
                            
                            
                        </div>
                    </div>
                    


                </div>
            </div>
            }
       </>
    )
}

export default Edit