import React, { useState, useEffect } from 'react'
import { NotificationService } from '../../service/NotificationService';
import { AdminService } from '../../service/AdminService';
import Spinner from '../../components/Spinner';
import moment from 'moment'

function Dashboard  () {
    const [busy, setBusy] = useState(true)
    const [total, setTotal] = useState(0)
    const [sum, setSum] = useState(0)
  
    const searchParams = {
        endDate: moment().add(1, 'days').format('yyyy/MM/DD'),
        startDate: moment().subtract('days', 1).format('yyyy/MM/DD'),
        lastWeek: false,
        lastMonth: false
    }

    //---------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        //------------------------------------
      AdminService.getTransactionForAdmin(searchParams)
        .then(response => {
            
            const result = response.data.data.filter(item => item.licenseOwnerCharge > 0 )

            let add = 0;
            result.map(item => {
                add += item.licenseOwnerCharge
            })

            setSum(prev => {
                return add
            })

            setTotal(prev => {
                return result.length;
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            
        })
    }, [])

//---------------------------------------------------------------------------------------------------------------------------------
    return (
       <>
            {busy ? <Spinner/> :
         <div className="slim-mainpanel">
                <div className="container">

                    <div class="main-header mg-b-30">
                        <h2 class="main-header-h-1 ">Dashboard</h2>
                        <p class="signin-title-secondary"></p>
                    </div>

                    <div class="dashboard-inner pd-x-0-force">
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Total Transactions Count from {searchParams.startDate} to {searchParams.endDate}</p>
                                        
                                        <p class="main-header-p tertiary-color pd-b-20 blue-color">{total}</p>
                                        {/* <a href="/admin/agent" data-toggle="modal" data-target="#modaldemo4"><button
                                                class="btn light-button blue-color">View Agents</button></a> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-12 col-lg-6 mg-b-20">
                                <div class="card pssCard basic-card pd-b-30 pd-x-5">
                                    <div class="card-body">
                                        <p class="dashboard-card-title tertiary-color pd-t-10">Total Transaction Value from {searchParams.startDate} to {searchParams.endDate}</p>
                                        
                                        <p class="main-header-p tertiary-color pd-b-20 blue-color">{sum.toFixed(2)}</p>
                                        {/* <a href="/admin/agent" data-toggle="modal" data-target="#modaldemo4"><button
                                                class="btn light-button blue-color">View Agents</button></a> */}
                                    </div>
                                </div>
                            </div>

                           
                            
                        
                        </div>

                      
                    </div>
                                    
                </div>
            </div>
            }
       </>
    )
}

export default Dashboard