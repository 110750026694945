import React from 'react'
import {Switch, Route } from 'react-router-dom'

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Dashboard from './licenseowner/Dashboard'
import Profile from './Profile'

import Welcome from './licenseowner/Welcome'
import Transaction from './licenseowner/Transaction/Transaction'




function LicenseOwner () {
    return (
        <>
         <Header />
        <div className="slim-body">
       
        <Sidebar />

        
        <Switch>
            <Route path='/licenseowner/dashboard' >
                <Dashboard />
            </Route>

            <Route path='/licenseowner/welcome' >
                <Welcome />
            </Route>

            <Route path='/licenseowner/transactions' >
                <Transaction />
            </Route> 

            <Route path='/licenseowner/profile' >
                <Profile />
            </Route>


            <Route path='*' component={() => "404 NOT FOUND"} />

        </Switch>
       
        </div>
        </>
    )
}

export default LicenseOwner