import React, {useEffect, useState} from 'react'
import Aggregator  from '../../../components/aggregator/Aggregator'
import { AdminService} from '../../../service/AdminService'
import { NotificationService } from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'

function View  ({changeApp, onEdit}) {
    const { id } = JSON.parse(localStorage.getItem('user'))
    const [aggregators, setAggregators] = useState([]);
    const [busy, setBusy] = useState(true)

     useEffect(() => {
        AdminService.getAllAggregators()
        .then(response => {
            
            setAggregators(prevAgents => {
                return response.data
            });
            
            if(response.data.length > 0){
                NotificationService.notify("The number of Aggregator is " + response.data.length);
                }
                else if(response.data.length === 0){
                    NotificationService.notifyInfo("You do not have any Agents assigned");
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[]);



    return (
       <>
        {
            busy ? <Spinner/> : 
            <div className="slim-mainpanel">
                <div className="container">
                    <div className="main-header">
                            <a href="#" onClick={() =>  changeApp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                            <h2 className="main-header-h-1 ">View Aggregators</h2>
                        </div>
                    <Aggregator aggregators={aggregators} onEdit={onEdit} /> 
                </div>
            </div>
        }
       </>
    )
}

export default View