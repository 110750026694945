import React,{useState} from 'react'

function Main ({changeApp}) { 
    

    return (
            <div className="slim-mainpanel">
                <div className="container">
                        

                        <div className="main-header mg-b-30">
                            <h2 className="main-header-h-1 ">Transaction Management</h2>
                            <p className="signin-title-secondary">Paying your bill does not have to be a stressful task. Please select your
                                payment category or search for your biller to make a bill payment.</p>
                        </div>                    
                        <div className="row mg-t-20">
                        <div onClick={() => changeApp("view")} className="col-6 col-md-6 col-lg-3"> 
                                <a href="#">                          
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">View Transactions</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Create Agents that you have acquired on the SureBanka Platform for onboarding</p>
                                        </div>
                                    </div>
                                </a>                   
                            </div>  
                            <div onClick={() => changeApp("debit")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Debit Agent Terminal</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Pass Unimpacted Transactions for Debit to Customer's Transaction History</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div>
                            <div onClick={() => changeApp("debitaggregator")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Debit Aggregator</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Pass Unimpacted Transactions for Debit to Aggregator's Transaction History</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div>
                            <div onClick={() => changeApp("status")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Get Deposit Transaction Status</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Get the Status of Transactions sent to ProvidusBank API that returned Pending Status</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div> 
                            <div onClick={() => changeApp("upload")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Bulk Upload Unimpacted Transactions</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Bulk Upload Unimpacted Transactions for unsynchronized debit and credit transactions automatic entry into the system</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div>
                            <div onClick={() => changeApp("search")} className="col-6 col-md-6 col-lg-3"> 
                                <a href="#">                          
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Search Transactions</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Search for a Transaction using the RRN and Transaction Date. Note this only returns one transaction.</p>
                                        </div>
                                    </div>
                                </a>                   
                            </div>

                             <div onClick={() => changeApp("download")} className="col-6 col-md-6 col-lg-3"> 
                                <a href="#">                          
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Download Terminal Transactions</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Search for all transactions by a terminal for a particular date range and download to csv.</p>
                                        </div>
                                    </div>
                                </a>                   
                            </div>  

                            <div onClick={() => changeApp("airtime")} className="col-6 col-md-6 col-lg-3"> 
                                <a href="#">                          
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">View Airtime Status</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">View status of airtime transactions completed on the Surebanka platform</p>
                                        </div>
                                    </div>
                                </a>                   
                            </div>

                            <div onClick={() => changeApp("filter")} className="col-6 col-md-6 col-lg-3"> 
                                <a href="#">                          
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Transaction Filters</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Search through transactions by date and type in the Reconciliation Format</p>
                                        </div>
                                    </div>
                                </a>                   
                            </div>                                             
                                         
                        </div>                                    
                    </div>
        </div>
        )
    }

export default Main