/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import useFormik from 'formik'
import Dispute from '../../../components/dispute/Dispute'
import Spinner from '../../../components/Spinner'
import {SuperAdminService} from '../../../service/SuperAdminService'
import {NotificationService} from '../../../service/NotificationService'

function Pending ({changeApp, updateDispute}) {
    const [disputes, setDisputes] = useState([])
    const [busy, setBusy] = useState(true)

    useEffect(() => {
        SuperAdminService.getNewRequests()
            .then(response => {          
                
                setDisputes(prev => {
                    return response.data.data
                })

                setBusy(false)
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
    },[])

    function onEdit(dispute){
        
        changeApp('edit')
        updateDispute(dispute)
    }

    return (
       <>
        {busy ? <Spinner /> : 
            <div className="slim-mainpanel">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("main")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">New Requests</h2>
                    </div>
                    <Dispute disputes={disputes} onEdit={onEdit} />
                </div>
            </div>}
       </>
    )
}

export default Pending