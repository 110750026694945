import React, { useState, useEffect } from 'react'
import Main from './Main'
import View from './View'
import Create from './Create'
import Edit from './Edit';


function Dispute () {
     const [params, setParams] = useState("manage")
     const [dispute, setDispute] = useState({})
    
    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(dispute){

        setDispute(prev => {
            return dispute
        })

        setParams(prev => {
            return 'edit'
        })
    }
 
    return (
        <>          
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'view': <View changeApp={changeApp}  onEdit={onEdit} />,
                'create' : <Create changeApp={changeApp} />,
                'edit' : <Edit changeApp={changeApp} dispute={dispute} />
            }[params] || <Main changeApp={changeApp} />
        }
            
        </>
    )
}

export default Dispute