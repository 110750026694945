import React,{useState} from 'react'
import CreateTerminal from '../../../components/terminal/CreateTerminal'
import CreateLienComponent from '../../../components/lien/Create'

function Create ({changeApp}) {
    
    

    return (
           <>
           <div className="slim-mainpanel no-bg-img">
                <div className="container">
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("manage")} className="pss-blue pd-b-10">← Go Back</a>
                    </div>
                    <CreateLienComponent changeApp={changeApp} />
                </div>
             </div>
           </>
        )
    }

export default Create