import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NotificationService } from '../service/NotificationService';
import { AgentService } from '../service/AgentService';
import Spinner from '../components/Spinner';


function SetPIN () {
    const [busy, setBusy] = useState(false)

    const { id, isPinCreated } = JSON.parse(localStorage.getItem('user'));

    const formik = useFormik({
        initialValues: {
         
        },
        validate: ((values) => {
          if(formik.errors.pin && formik.touched.pin){
            NotificationService.notifyError(formik.errors.pin)
          }
        }),
        validationSchema: Yup.object({
          pin: Yup.string()
          .required('Please enter your PIN'),     
          
          }),
        onSubmit: (values,{ resetForm }) => {

          

          setBusy(true)

          AgentService.managePIN({pin: values.pin, userId: id, old_pin: values.old_pin})
          .then(response => {
            setBusy(false)
            
            NotificationService.notify("The PIN was updated successfully")
            resetForm()
          })
          .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
          })
         
              
          
                 
        },
      });


    return (
        <>{ busy ? <Spinner /> : 
        <div className="slim-mainpanel no-bg-img">
        <div className="container">

                <div className="pd-x-5">
                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Change PIN</h2>
                        
                </div>

                <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                <div className="floating-form">
                                    <div className="row">
                                        
                                       
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="password" placeholder=" "  name="pin" {...formik.getFieldProps('pin')}/>
                                            <span className="highlight"></span>
                                            <label>PIN</label>
                                        </div>

                                        { isPinCreated ? 
                                            <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="password" placeholder=" "  name="old_pin" {...formik.getFieldProps('old_pin')}/>
                                                <span className="highlight"></span>
                                                <label>OLD PIN</label>
                                            </div> : null
                                        }
                                       
                                    </div>

                                    <button className="btn-fill-md majorButton" type="submit">Submit</button>
                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
        
    )
}

export default SetPIN