import React from 'react'

function Main ({changeApp}) { 
    

    return (
            <div className="slim-mainpanel">
                <div className="container">
                        

                        <div className="main-header mg-b-30">
                            <h2 className="main-header-h-1 ">Request Management</h2>
                            <p className="signin-title-secondary">Paying your bill does not have to be a stressful task. Please select your
                                payment category or search for your biller to make a bill payment.</p>
                        </div>                    
                        <div className="row mg-t-20">
                       
                            <div onClick={() => changeApp("process")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Process Pending </p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">View and Manage the Agents that you have acquired on the SureBanka Platform</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div>

                             <div onClick={() => changeApp("pending")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Pending Authorization</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">View and Manage the Agents that you have acquired on the SureBanka Platform</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div>                                     
                        </div>                                    
                    </div>
        </div>
        )
    }

export default Main