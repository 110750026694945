import React,{useState} from 'react'

function Main ({changeApp}) { 
    

    return (
            <div className="slim-mainpanel">
                <div className="container">
                        

                        <div className="main-header mg-b-30">
                            <h2 className="main-header-h-1 ">Dispute Management</h2>
                            <p className="signin-title-secondary">Page for Super Admins and Admins to review and make a decision on dispute issues opened by Agents.</p>
                        </div>                    
                        <div className="row mg-t-20">
                                <div onClick={() => changeApp("view")} className="col-6 col-md-6 col-lg-3"> 
                                    <a href="#">                          
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">View Disputes</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">Create Agents that you have acquired on the SureBanka Platform for onboarding</p>
                                            </div>
                                        </div>
                                    </a>                   
                                </div>
                                              
                                         
                        </div>                                    
                    </div>
        </div>
        )
    }

export default Main