import {  toast } from 'react-toastify';

const notify = (message) => {
    toast(message, 
     {
       position: "top-right",
       autoClose: 5000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
    });
 }

 const notifyError = (message, duration = 5000) => {
   toast.error(message, {
     position: "top-right",
     autoClose: duration,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     });
 }

 const notifyErrorWithID = (message, id, duration = 5000) => {
  toast.error(message, {
    position: "top-right",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id
    });
}

 const notifyInfo = (message, duration=5000) => {
    toast.info(message, {
        position: "top-right",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

 export const NotificationService = {
    notify,
    notifyError,
    notifyInfo,
    notifyErrorWithID
}