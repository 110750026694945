import React from 'react'
import {Switch, Route } from 'react-router-dom'

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Dashboard from './ptsp/Dashboard'
import Profile from './Profile'
import Report from './superadmin/Report/Report'

import Welcome from './ptsp/Welcome'
import Transaction from './ptsp/Transaction/Transaction'




function PTSPArea () {
    return (
        <>
         <Header />
        <div className="slim-body">
       
        <Sidebar />

        
        <Switch>
            <Route path='/ptsp/dashboard' >
                <Dashboard />
            </Route>
            <Route path='/ptsp/welcome' >
                <Welcome />
            </Route>
            <Route path='/ptsp/transaction'>
                <Transaction />
            </Route>

            <Route path='*' component={() => "404 NOT FOUND"} />

        </Switch>
       
        </div>
        </>
    )
}

export default PTSPArea