import React from 'react'
import CreateDispute from '../../../components/dispute/CreateDispute'

function Create ({changeApp}) {
    
    function changeUp(name){
        changeApp(name)
    }

    return (
       <>
        <div class="slim-mainpanel">
                <div class="container">
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeUp("main")} class="pss-blue pd-b-10">← Go Back</a>
                        <h2 class="main-header-h-1 ">Create Request</h2>
                    </div>
                    <CreateDispute />
                </div>
            </div>
       </>
    )
}

export default Create