/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState, useEffect, useCallback} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {AdminService} from '../../../service/AdminService'
import {SuperAdminService} from '../../../service/SuperAdminService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner';

import { UploadService } from '../../../service/UploadService'

import ImageViewer from "react-simple-image-viewer";
import { confirmAlert } from 'react-confirm-alert';

function Edit ({changeApp, agent}) {
    const [busy, setBusy] = useState(true)
    const [map, setMap] = useState(false)
    const [aggregators, setAggregators] = useState([])
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [uploading, setUploading] = useState(false);

    const { id } = JSON.parse(localStorage.getItem('user'))

    const sas = process.env.REACT_APP_SAS;

    console.log(agent)

    const aggregatorOption = aggregators.map(aggregator => {
        return (<option key={aggregator.email} value={`${aggregator._id}`}>{`${aggregator.email}`}</option>)
    })

    useEffect(() => {
        AdminService.getAllAggregators()
        .then(response => {
            setBusy(false)
            console.log(response.data)
            setAggregators(prev => {
                return response.data
            })
        })
        .catch(error => {

        })
        if(!agent.aggregator){
            setMap(prev => {
                return true
            })
        }
    
    }, [])

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
    
      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

    const images = [
        `${agent.passportphoto}${sas}`,
        `${agent.proofofaddress}${sas}`,
        `${agent.identification}${sas}`
    ];
    
  
    const formik = useFormik({
        initialValues: {
            firstname: agent.firstname,
            lastname: agent.lastname,
            email: agent.email,
            address: agent.address,
            lga: agent.lga,
            state: agent.state,
            activateEmail: agent.activateEmail,
            active: agent.isActive,
            aggregator: '',
            aggregatorOld: agent.aggregator ? agent.aggregator.email : " "
        },
        validate: (what) => {
            
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            
        }),
        onSubmit: (values,{ resetForm }) => {
                   
        }
      });

      const upload = useFormik({
        initialValues: {
            passportphoto: null,
            identification: null,
            proofofaddress: null
        },
        validate: (what) => {
            
            if(upload.errors.passportphoto && upload.touched.passportphoto){
                NotificationService.notifyError(upload.errors.passportphoto, "passportphoto", 5000)
                return;
            }
            if(upload.errors.identification && upload.touched.identification){
                NotificationService.notifyError(upload.errors.identification, "identification", 5000)
                return
            }
            if(upload.errors.proofofaddress && upload.touched.proofofaddress){
                NotificationService.notifyError(upload.errors.proofofaddress, "proofofaddress", 5000)
                return
            }
            
        },
        validationSchema:  Yup.object({
            passportphoto: Yup.mixed()
                .required("Please select your passport photo")
                .test(
                "fileSize",
                "Your passport photo size is too big :(",
                value => value && value.size <= 1157100
                ),
            identification: Yup.mixed()
                .required("Please select your identification document")
                .test(
                "fileSize",
                "The document size is too big :(",
                value => value && value.size <= 1157100
                ),
            proofofaddress: Yup.mixed()
                .required("Please select your proof of address")
                .test(
                "fileSize",
                "The document size is too big :(",
                value => value && value.size <= 1157100
                )
        }),
        handleChange:() => {},
        onSubmit: (values,{ resetForm }) => {
            console.log(values)
            setBusy(true) 
            UploadFileSync(values)  
            
        }
      });

      async function UploadFileSync(values) {
       
        try {
          const passportResponse = await UploadService.uploadFile(values.passportphoto)
          const passportphoto = (passportResponse._response.request.url.split("?")[0])
    
         
    
          const identificationResponse = await UploadService.uploadFile(values.identification)
          const identification = (identificationResponse._response.request.url.split("?")[0])
    
         
    
          const proofofaddressResponse = await UploadService.uploadFile(values.proofofaddress)
          const proofofaddress = (proofofaddressResponse._response.request.url.split("?")[0])

          SuperAdminService.UpdateUserUploadedFiles({passportphoto: passportphoto, identification: identification, proofofaddress: proofofaddress, UserId : agent._id})
          .then(response => {
            console.log(response.data)
            NotificationService.notify("The Agent's files were updated successfully")  
            setBusy(false)
            changeApp('manage')
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })


        }
        catch(error){
            setBusy(false)
        }
    }

      function Activate(e){
          console.log(formik.values.email)
          setBusy(true)

          AdminService.activateAgent({email:formik.values.email})
          .then(response => {
            NotificationService.notify("The Agent " + agent.firstname + " " + agent.lastname + " was successfully activated")
            setBusy(false)
            changeApp('view')
          })
          .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
          })
      }

      function Deactivate(e){
        setBusy(true)
        SuperAdminService.deactivateAgent({email: formik.values.email})
        .then(response => {
            NotificationService.notify("The Agent " + agent.firstname + " " + agent.lastname + " was successfully De-activated")
            setBusy(false)
            changeApp('view')
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

      function switchView(){
        setMap(prev => {
            return !prev
        })
      }

      function unAssignAggregator(){
        setBusy(true)
        SuperAdminService.unAssignAggregator({agentId: agent._id})
         .then(response => {
            console.log(response)
            NotificationService.notify("The Aggregator to the Agent " + agent.firstname + " " + agent.lastname + " has been removed.")
            setBusy(false)
            changeApp('view')
         }) 
         .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
         })
      }

      function updateAggregator(e) {
        console.log(formik.values.aggregator + " " + agent._id)

        setBusy(true)

        SuperAdminService.assignAgentAggregator({agentId: agent._id, aggregatorId: formik.values.aggregator})
        .then(response => {
            console.log(response)
            NotificationService.notify("The Agent " + agent.firstname + " " + agent.lastname + " Aggregator was updated." )
            setBusy(false)
            setMap(false)
            changeApp('view')
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

      function deleteAgent(){
       

        confirmAlert({
            title: 'Confirmation',
            message: "Are you sure you want to delete this Agent account?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    setBusy(true)
                    SuperAdminService.popUser({email: agent.email})
                    .then(response => {
                        console.log(response)
        
                        NotificationService.notify("The Agent " + agent.firstname + " " + agent.lastname + " has been deleted." )
        
                        setBusy(false)
                        
                        changeApp('view')
                 })
                .catch(error => {
                    if(error.response){
                        NotificationService.notifyError(error.response.data.message, 5000)
                    }
                    else if(error.message){
                        NotificationService.notifyError(error.message, 5000)
                    }
                    else{
                        NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                    }
                    setBusy(false)
                })                        
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                    setBusy(false)
                }
              }
            ]
          });
         
      }

    return (
       <>
       { busy ? <Spinner /> :
        <div className="slim-mainpanel">
                <div className="container">
                    
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("view")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Edit Agent</h2>
                    </div>

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <fieldset >
                                <div className="floating-form ">
                                
                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="firstname" autoComplete="none" {...formik.getFieldProps('firstname')} />
                                                <span className="highlight"></span>
                                                <label>First Name</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="lastname" {...formik.getFieldProps('lastname')} />
                                                <span className="highlight"></span>
                                                <label>Last Name</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="email" {...formik.getFieldProps('email')} />
                                                <span className="highlight"></span>
                                                <label>Email Address</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="address" {...formik.getFieldProps('address')} />
                                                <span className="highlight"></span>
                                                <label>Address</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="lga" {...formik.getFieldProps('lga')} />
                                                <span className="highlight"></span>
                                                <label>Local Govt. Area</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="state" {...formik.getFieldProps('state')} />
                                                <span className="highlight"></span>
                                                <label>State</label>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="active" {...formik.getFieldProps('active')} />
                                                <span className="highlight"></span>
                                                <label>Active</label>
                                            </div>                                        

                                            <div className="floating-label  col-md-6">
                                                <input disabled={true} className="floating-input" type="text" name="activateEmail" {...formik.getFieldProps('activateEmail')} />
                                                <span className="highlight"></span>
                                                <label>activateEmail</label>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="floating-label  col-md-6">
                                                    <input disabled={true} className="floating-input" type="text" name="aggregatorOld" {...formik.getFieldProps('aggregatorOld')} />
                                                    <span className="highlight"></span>
                                                    <label>Present Aggregator </label>
                                                </div>

                                            <div className="floating-label  col-md-6 pd-b-10">
                                                    <select disabled={!map} className="floating-input" placeholder="" name="aggregator" {...formik.getFieldProps('aggregator')} >
                                                    <option value="" label="Please select the Aggregator" />
                                                    {aggregatorOption}                              
                                                    </select>
                                                    <span className="highlight"></span>
                                                    <label>Aggregator</label>                           
                                                </div>
                                        </div>

                                        <div className="row pd-20-force">
                                            { 
                                                !formik.values.active ?  
                                                <button onClick={(e) => Activate(e) } className="btn btn-danger">Activate</button> : 
                                                <button onClick={(e) => Deactivate(e) } className="btn btn-danger">De-Activate</button> 
                                            }
                                        </div>
                                        <div className="row pd-20-force">
                                            {
                                                !map ? 
                                                <button onClick={(e) => unAssignAggregator(e) } className="btn btn-info">Unassign Aggregator</button> :
                                                <button onClick={(e) => updateAggregator(e) } className="btn btn-info">Update Agent's Aggregator</button>
                                                    
                                            }
                                        </div>
                                        <div className="row pd-20-force">
                                           {
                                               !uploading ?
                                                <button onClick={(e) => setUploading(true) } className="btn btn-info">Upload New Files</button> : null
                                           } 
                                        </div>

                                        <div className="row pd-20-force">
                                           {
                                                <button onClick={(e) => deleteAgent(true) } className="btn btn-danger">Delete Agent</button>
                                           } 
                                        </div>

                                </div>
                                </fieldset>
                            </form>
                            
                            
                        </div>
                    </div>
                    { uploading ? 
                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={upload.handleSubmit}>
                                <fieldset >
                                    <div className="floating-form ">
                                    
                                            <div className="row">
                                                                
                                                <div className="floating-label">
                                                    <input className="floating-input" type="file" name="passportphoto" accept="image/*"
                                                    onChange={event => {
                                                        upload.setFieldValue("passportphoto", event.currentTarget.files[0])
                                                    }}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Upload Passport</label>
                                                </div>
                                                <div className="floating-label">
                                                    <input className="floating-input" type="file" name="identification" accept="image/*"
                                                    onChange={event => {
                                                        upload.setFieldValue("identification", event.currentTarget.files[0])
                                                    }}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Upload Identification Document</label>
                                                </div>

                                                <div className="floating-label">
                                                    <input className="floating-input" type="file" name="proofofaddress" accept="image/*"
                                                    onChange={event => {
                                                        upload.setFieldValue("proofofaddress", event.currentTarget.files[0])
                                                    }}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Upload Utility Bill or Receipt</label>
                                                </div>

                                            </div>
                                            <div className="floating-label">
                                                <button className="btn-fill-md majorButton" type="submit" >Update</button>
                                            </div>
                                        </div>
                                </fieldset>
                            </form>
                        </div>
                    </div> : null }

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">
                            <div className="row">
                                            {images.map((src, index) => (
                                                <img
                                                src={src}
                                                onClick={() => openImageViewer(index)}
                                                width="300"
                                                key={index}
                                                style={{ margin: "2px" }}
                                                alt=""
                                                />
                                            ))}

                                            {isViewerOpen && (
                                                <ImageViewer
                                                src={images}
                                                currentIndex={currentImage}
                                                onClose={closeImageViewer}
                                                backgroundStyle={{
                                                    backgroundColor: "rgba(0,0,0,0.9)"
                                                }}
                                                />
                                            )}
                                </div>
                            </div>
                    </div>

                </div>
            </div>
            }
       </>
    )
}

export default Edit