import React from "react";

function Confirmation({header, message}) {
    return (
        <>
             <header className="header-area header-bg sure-style">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="header-inner">
                                    <h1 className="title">{header}</h1>
                                    <p>{message}</p>
                                    <div className="btn-wrapper">
                                        <a className="boxed-btn button" href="/login" data-mode="popup" >Login</a>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="">
                                    <img className="img-center" src="/assets/img/hero-surebanka.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
        </>
    )
}

export default Confirmation