import React, { useState, useEffect } from 'react'
import useFormik from 'formik'
import Spinner from '../../../components/Spinner'
import Dispute from '../../../components/dispute/Dispute'

import {SuperAdminService} from '../../../service/SuperAdminService'
import {NotificationService} from '../../../service/NotificationService'

function Process ({changeApp, updateDispute}) {
    const [disputes, setDisputes] = useState([])
    const [busy, setBusy] = useState(true)

    useEffect(() => {
        SuperAdminService.getPendingRequests()
        .then(response => {
            setDisputes(prev => {
                return response.data.data
            })

            setBusy(false);
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])

    function onEdit(dispute){
        
        changeApp('edit')
        updateDispute(dispute)
    }

    return (
       <>
        {busy ? <Spinner /> : 
            <div class="slim-mainpanel">
                <div class="container">
                    <div class="main-header">
                        <a href="#" onClick={() =>  changeApp("main")} class="pss-blue pd-b-10">← Go Back</a>
                        <h2 class="main-header-h-1 ">Pending Requests</h2>
                    </div>
                    <Dispute disputes={disputes} onEdit={onEdit} />
                </div>
            </div>}
       </>
    )
}

export default Process