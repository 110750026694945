import React, { useEffect, useState } from 'react'
import {useFormik} from 'formik'


import { NotificationService } from '../../../service/NotificationService';
import { AgentService } from '../../../service/AgentService'
import { AggregatorService } from '../../../service/AggregatorService';
import Spinner from '../../../components/Spinner';

function Cashout ({changeApp}) {
    const [terminals, setTerminals] = useState([])
    const [busy, setBusy] = useState(true)

    const { id } = JSON.parse(localStorage.getItem('user'));
    

    useEffect(() => {
      
        setBusy(false)
    }, [])

    const formik = useFormik({
        initialValues: {
            amount : '',
            pin: '',
            terminalid: '',
        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {  
            setBusy(true)           

            AggregatorService.aggregatorCashOut({...values, aggregatorId: id, pin: values.pin})
            .then(response => {
                console.log(response)
                NotificationService.notify("Cashout Completed Successfully")
                setBusy(false)
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setBusy(false)
            })
        }
      });
    return (
        <> { busy ? <Spinner /> :
        <div className="slim-mainpanel no-bg-img">
        <div className="container">
                <div class="main-header">
                    <a href="#" onClick={() =>  changeApp("manage")} class="pss-blue pd-b-10">← Go Back</a>
                </div>

                <div className="pd-x-5">
                        <h2 className="signin-title-primary tx-600 dark-color pd-t-40">Cashout Details</h2>
                        
                </div>

                <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form autoComplete="off"  onSubmit={formik.handleSubmit} >
                                <div className="floating-form">
                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="number" placeholder=" "  name="amount" autoFocus {...formik.getFieldProps('amount')} />
                                            <span className="highlight"></span>
                                            <label>Amount</label>
                                        </div>
                                        
                                        
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input className="floating-input" type="password" placeholder=" "  name="pin" {...formik.getFieldProps('pin')}/>
                                            <span className="highlight"></span>
                                            <label>PIN</label>
                                        </div>
                                       
                                    </div>
                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                                <input className="floating-input" type="text" placeholder=" "  name="narration" {...formik.getFieldProps('narration')} />
                                                <span className="highlight"></span>
                                                <label>Narration</label>
                                        </div>
                                       
                                    </div>

                                    <button className="btn-fill-md majorButton" type="submit">Cashout</button>
                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
    )
}

export default Cashout