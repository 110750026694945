import React from 'react'


function Custom (props) {   

    return (
        <>
            <h2>Custom</h2>
        </>
    )
}

export default Custom