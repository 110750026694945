import React,{useState} from 'react'

function Main ({changeApp}) { 
    

    return (
            <div className="slim-mainpanel">
                <div className="container">
                        

                        <div className="main-header mg-b-30">
                            <h2 className="main-header-h-1 ">Device Management</h2>
                            <p className="signin-title-secondary">Paying your bill does not have to be a stressful task. Please select your
                                payment category or search for your biller to make a bill payment.</p>
                        </div>                    
                        <div className="row mg-t-20">
                                <div onClick={() => changeApp("create")} className="col-6 col-md-6 col-lg-3"> 
                                    <a href="#">                          
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">Create Terminal</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">Create Agents that you have acquired on the SureBanka Platform for onboarding</p>
                                            </div>
                                        </div>
                                    </a>                   
                                </div>  
                                <div onClick={() => changeApp("view")} className="col-6 col-md-6 col-lg-3">
                                    <a href="#">
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">View Terminal</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">View and Manage the Agents that you have acquired on the SureBanka Platform</p>
                                            </div>
                                        </div>  
                                    </a>                                                 
                                </div> 
                                <div onClick={() => changeApp("balance")} className="col-6 col-md-6 col-lg-3">
                                    <a href="#">
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">View All Terminal Balance</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">View effective balance of all Terminals active on the Surebanka Platform online realtime.</p>
                                            </div>
                                        </div>  
                                    </a>                                                 
                                </div>
                                <div onClick={() => changeApp("eod")} className="col-6 col-md-6 col-lg-3">
                                    <a href="#">
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">Terminal Opening/Closing Balance</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">View closing and opening balance of all Terminals active on the Surebanka Platform.</p>
                                            </div>
                                        </div>  
                                    </a>                                                 
                                </div>

                                <div onClick={() => changeApp("stale")} className="col-6 col-md-6 col-lg-3">
                                    <a href="#">
                                        <div className="card pssCard bd-0 mg-b-30">
                                            <div  className="card-body ">                                   
                                            <p className="user-brand-name">Terminal Last Transaction Date</p>
                                            <p className="user-brand-text-2 tx-small mg-b-10">View the last transaction date on all Terminals on the Surebanka Platform.</p>
                                            </div>
                                        </div>  
                                    </a>                                                 
                                </div>
                                              
                                         
                        </div>                                    
                    </div>
        </div>
        )
    }

export default Main