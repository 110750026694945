import axiosConfig from '../helpers/AxiosSubConfig';

const getAllAgents = () => {   
   return axiosConfig.get('/manage-user/getallAgents')
}

const vpnTest = () => {   
   return axiosConfig.get('/virtual-airtime/vpnTest')
}

const deactivateAgent = (values) => {
   return axiosConfig.post('/manage-user/deactivateAgent', values)
}

const assignAgentAggregator = (values) => {
   return axiosConfig.post('/manage-user/AssignAgentToAggregator', values)
}

const getPendingRequests = (values) => {
   return axiosConfig.get('/request/pendingRequests')
}

const getNewRequests = () => {
   return axiosConfig.get('/request/newRequests')
}

const getTransactionType = () => {
   return axiosConfig.get('/transactionType')
}

const getAllTerminalBalances = () => {
   return axiosConfig.get('/transaction/ActiveTerminalsEffectiveBalancea')
}

const updateRequestStatus = (values) => {
   return axiosConfig.put('/request/updateStatus/' + values.requestID,{
      "status": values.status
   })
}

const closeRequest = (values) => {
   return axiosConfig.put('/request/' + values.requestID, values)
}

const unAssignTerminal = (values) => {
   return axiosConfig.post('/terminal/unassignterminal', values)
}

const unAssignAggregator = (values) => {
   return axiosConfig.post('/manage-user/unAssignAgentToAggregator', values)
}

const withdrawal = (values) => {
   return axiosConfig.post('/transaction/withdrawal', values)
}

const getTerminalsByAgent = (values) => {
   return axiosConfig.post('/terminal/terminalByAgent/', values) 
}

const createLien = (values) => {
   return axiosConfig.post('/lien', values)
}

const getLien = () => {
   return axiosConfig.get('/lien')
}

const getTerminalReport = () => {
   return axiosConfig.get('/transaction/terminalEffectiveBalanceInCSV')
}

const getActivityLog = (values) => {
   return axiosConfig.post('/activity-log', values)
}

const walletTopUp = (values) => {
   return axiosConfig.post('/transaction/walletTopup', values)
}

const sendAgentBroadcast = (values) => {
   return axiosConfig.post('/request/sendAgentBroadcastEmail', values)
}

const ReconcileUnImpactedTransactions = (values) => {
   return axiosConfig.post('/transaction/ReconcileUnImpactedTransactions', values)
}

const DebitAggregator = (values) => {
   return axiosConfig.post('/transaction/aggregator/debitWallet', values)
}

const sendReceipt = (values) => {
   return axiosConfig.post('/pdf/transactionPdf', values)
}

const sendDepositReceipt = (values) => {
   return axiosConfig.post('/pdf/depositTransactionPdf', values)
}

const sendPHCNReceipt = (values) => {
   return axiosConfig.get('/pdf/getPHCNSlipPDF', {params: values} )
}

const getTransactionByRRN  = (values) => {
   return axiosConfig.get('/transaction/getTransactionByRRN', {params: values} )
}

const getTransactionsByTerminalPhysicalID  = (values) => {
   return axiosConfig.get('/transaction/getTransactionsByTerminalPhisicalID', {params: values} )
}

const getTerminalByPhysicalId = (values) => {   
   return axiosConfig.get('/terminal/getTerminalByPhysicalId/' + values.PhysicalId)
}

const getLastTerminalTransactionDate = (values) => {   
   return axiosConfig.get('/transaction/getLastTerminalTransactionDate/', {params: values})
}

const validateBVN = (values) => {   
   return axiosConfig.get('/wallet/validateBvn/' + values.bvn)
}


const checkPendingTransactionStatusByRRN = (values) => {   
   return axiosConfig.post('/transaction/checkPendingTransactionStatusByRRN', values)
}

const UpdateUserUploadedFiles = (values) => {   
   return axiosConfig.post('/manage-user/UpdateUserUploadedFiles', values)
}

const getDailyTerminalOpeningClosingBalance = () => {   
   return axiosConfig.get('/wallet/getDailyTerminalOpeningClosingBalance')
}

const getPrimeTimeTransactionStatus = (values) => {   
   return axiosConfig.get('/virtual-airtime/checkPrimeTimeTransactionStatus',{params: values})
   //return axiosConfig.post('​/virtual-airtime/checkPrimeTimeTransactionsStatus', values)
}

const ManualRefundTransactionsSlip = (values) => {   
   return axiosConfig.post('/pdf/ManualRefundTransactionsSlip', values)
}

const downloadWithdrawalPowerSlip = (values) => {   
   return axiosConfig.post('/pdf/downloadPdf', values)
}

const downloadDepositSlip = (values) => {   
   return axiosConfig.post('/pdf/depositTransactionPdf', values)
}

const resolveDispute = (values) => {   
   return axiosConfig.post('/dispute-resolution/resolveDispute', values)
}

const popUser = (values) => {   
   return axiosConfig.get('/user/popuser/' + values.email)
}

const deleteTerminal = (values) => {
   return axiosConfig.get('/terminal/delete', {params: values})
}

const getAllDispute = (values) => {
   return axiosConfig.get('/dispute-resolution/getAllDisputeByDate', {params: values})
}

const pndTerminal = (values) => {
   return axiosConfig.get('/terminal/updateTerminalPnd/' + values.terminalId, {params: {status: values.status}})
}




export const SuperAdminService = {
    deactivateAgent,
    assignAgentAggregator,
    getPendingRequests,
    getNewRequests,
    updateRequestStatus,
    closeRequest,
    unAssignTerminal,
    unAssignAggregator,
    withdrawal,
    getTerminalsByAgent,
    createLien,
    getLien,
    getActivityLog,
    walletTopUp,
    getAllTerminalBalances,
    getTransactionType,
    ReconcileUnImpactedTransactions,
    sendAgentBroadcast,
    DebitAggregator,
    getTerminalReport,
    sendReceipt,
    vpnTest,
    getTerminalByPhysicalId,
    checkPendingTransactionStatusByRRN,
    validateBVN,
    sendDepositReceipt,
    UpdateUserUploadedFiles,
    sendPHCNReceipt,
    getTransactionByRRN,
    getTransactionsByTerminalPhysicalID,
    getDailyTerminalOpeningClosingBalance,
    ManualRefundTransactionsSlip,
    popUser,
    downloadWithdrawalPowerSlip,
    downloadDepositSlip,
    deleteTerminal,
    getPrimeTimeTransactionStatus,
    getAllDispute,
    resolveDispute,
    pndTerminal,
    getLastTerminalTransactionDate
}