import React, {useState} from 'react'
import Main from './Main'
import Deposit from './Deposit'
import Transfer from './Transfer'
import Cashout from './Cashout'
import Topup from './Topup/Topup'

function Wallet () {
    const [params, setParams] = useState("manage")

    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    return (
        <>
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'deposit': <Deposit changeApp={changeApp} />,
                'transfer' : <Transfer changeApp={changeApp} />,
                'topup' : <Topup changeApp={changeApp} />,
                'cashout' : <Cashout changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
            
        </>
    )
}

export default Wallet