import React, { useState, useEffect } from 'react'
import Main from './Main'
import Performance from './Performance'

function Report () {
     const [params, setParams] = useState("manage")
     const [terminal, setTerminal] = useState({})
    
    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    function onEdit(terminal){

        setParams(prev => {
            return "edit"
        })
        setTerminal(prev => {
            return terminal
        })
    }
    
    
    
    return (
        <>          
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'performance': <Performance changeApp={changeApp} />,
                               
            }[params] || <Main changeApp={changeApp} />
        }   
        </>
    )
}

export default Report