import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import moment from 'moment'
import { NotificationService } from '../../service/NotificationService'
import { SuperAdminService} from '../../service/SuperAdminService'
import Spinner from '../Spinner';

function Details ({changeApp, transaction}) {
    const [busy, setBusy] = useState(false)

    const { usertype}  = JSON.parse(localStorage.getItem('user'))

    let transactionLogElements

    console.log(transaction)

    const formatter = new Intl.NumberFormat('en-US');

    if(transaction.transactionLogs != undefined){

        if(transaction.transactionLogs.length !== 0 ){
            console.log(transaction)
            transactionLogElements = transaction.transactionLogs.map(transactionLog => {
            return (
                <tr key={transactionLog.value}>
                    <td><a href="#">{transactionLog.name}</a></td>
                    <td><a href="#">{transactionLog.value}</a></td>
                </tr>
                
            )
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            TransactionID : transaction._id,
            ReferenceNumber: transaction.rrn,
            Amount : transaction.amount,
            ResponseCode : transaction.responseCode,
            ResponseMessage : transaction.responseCode,
            SettlementAmount : transaction.settlementAmount,
            Authcode : transaction.authCode,
            DateCreated : moment(new Date(transaction.dateCreated)).format("DD/MM/YYYY"),
            TerminalId : transaction.terminalID.terminalPhysicalId,
            TransactionType : usertype != "SuperAdmin" ? transaction.transactionTypeID.description : transaction.TransactionType,
            Status : transaction.transactionStatusID.name,
            meterToken: transaction.meterToken ? transaction.meterToken : "N/A",
            meterNumber: transaction.meterNumber,
            Balance: transaction.balance ? formatter.format(transaction.balance.toFixed(2)) : "",
            accountName: transaction.accountName,
            beneficiaryBank: transaction.beneficiaryBank,
            narration: transaction.narration,
            actualAmount: "",
            originalDate: ""

        },
        validate: (what) => {
            
        },
        handleChange:() => {},
        validationSchema: Yup.object({
            terminalOwner: Yup.string()
            .required('Please select a new owner'),
            
        }),
        onSubmit: (values,{ resetForm }) => {
            console.log(values)
        }
      });

      function sendReceipt(){
         setBusy(true)

         const tranDate = moment(transaction.dateCreated).format(moment.HTML5_FMT.DATE);

        SuperAdminService.sendReceipt({ rrn: transaction.rrn, terminalId: transaction.terminalID._id, date: tranDate, name: transaction._id, aid: transaction._id})
        .then(response => {
            console.log(response)
            NotificationService.notify("The receipt has been sent to the Support Email");              
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })

      }

      function sendDepositReceipt(){
        setBusy(true)

        const tranDate = moment(transaction.dateCreated).format(moment.HTML5_FMT.DATE);

       SuperAdminService.sendDepositReceipt({ rrn: transaction.rrn, date: tranDate, aid: transaction._id})
       .then(response => {
           console.log(response)
           NotificationService.notify("The receipt has been sent to the Support Email");              
               
           setBusy(false)
       })
       .catch(error => {
           if(error.response){
               NotificationService.notifyError(error.response.data.message, 5000)
           }
           else if(error.message){
               NotificationService.notifyError(error.message, 5000)
           }
           else{
               NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
           }
           setBusy(false)
       })

     }

      function sendPowerReceipt(){

        setBusy(true)
        const tranDate = moment(transaction.dateCreated).format(moment.HTML5_FMT.DATE);

        SuperAdminService.sendPHCNReceipt({ rrn: transaction.rrn, date: tranDate, meterNo: transaction.meterNumber})
        .then(response => {
            console.log(response)
            NotificationService.notify("The receipt has been sent to the Support Email");              
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

      function downloadRefundReceipt(){
        setBusy(true)
        console.log("receipt")
        const tranDate = moment(formik.values.originalDate).format(moment.HTML5_FMT.DATE);

        SuperAdminService.ManualRefundTransactionsSlip({ rrn: transaction.rrn, date: tranDate, fullDate: tranDate, transactionType: "withdrawal", amount:  formik.values.actualAmount })
        .then(response => {
            console.log(response.data.data)
            NotificationService.notify("The receipt has been downloaded");
            
            //window.location.href = 'data:application/pdf;base64,' + response.data.data;

            downloadBase64File("application/pdf", response.data.data, transaction.rrn + ".pdf")
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

      function downloadReceipt(){
        setBusy(true)
        
        console.log("receipt")
        const tranDate = moment(transaction.dateCreated).format(moment.HTML5_FMT.DATE);

        const type = transaction.TransactionType == "Withdrawal" ? transaction.TransactionType : "phcn"

        SuperAdminService.downloadWithdrawalPowerSlip({ rrn: transaction.rrn, date: tranDate, type: type })
        .then(response => {
            console.log(response.data.data)
            NotificationService.notify("The receipt has been downloaded");

            downloadBase64File("application/pdf", response.data.data, transaction.rrn + ".pdf")
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

      function downloadDepositReceipt(){
        setBusy(true)
        
        console.log("receipt")
        const tranDate = moment(transaction.dateCreated).format(moment.HTML5_FMT.DATE);

        SuperAdminService.downloadDepositSlip({ rrn: transaction.rrn, date: tranDate, aid: "" })
        .then(response => {
            console.log(response.data.data)
            NotificationService.notify("The receipt has been downloaded");

            downloadBase64File("application/pdf", response.data.data, transaction.rrn + ".pdf")
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
      }

      function downloadBase64File(contentType, base64Data, fileName) {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
   }

    
    return (
        <> 
          { busy ? <Spinner /> : 
            <div className="slim-mainpanel">
                <div className="container">
                    
                    <div className="main-header">
                        <a href="#" onClick={() =>  changeApp("view")} className="pss-blue pd-b-10">← Go Back</a>
                        <h2 className="main-header-h-1 ">Transaction Details</h2>
                    </div>

                    <div className="centered-form col-lg-12">
                        <div className="signin-box pd-20-force create-agg">  
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <fieldset disabled={true}>
                                <div className="floating-form ">
                                
                                        <div className="row">
                                        
                                            <div className="floating-label  col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="TransactionID" autoComplete="none" {...formik.getFieldProps('TransactionID')} />
                                                <span className="highlight"></span>
                                                <label>Transaction ID</label>
                                            </div>

                                        

                                            <div className="floating-label  col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="ReferenceNumber" autoComplete="none" {...formik.getFieldProps('ReferenceNumber')} />
                                                <span className="highlight"></span>
                                                <label>Reference Number</label>
                                            </div>

                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="Amount" autoComplete="none" {...formik.getFieldProps('Amount')} />
                                                <span className="highlight"></span>
                                                <label>Amount</label>
                                            </div>
                                        

                                            <div className="floating-label  col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="ResponseCode" {...formik.getFieldProps('ResponseCode')} />
                                                <span className="highlight"></span>
                                                <label>Response Code</label>
                                            </div>
                                           
                                        </div>

                                        
                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="ResponseMessage" {...formik.getFieldProps('ResponseMessage')} />
                                                <span className="highlight"></span>
                                                <label>Response Message</label>
                                            </div>
                                        

                                            <div className="floating-label  col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="SettlementAmount" {...formik.getFieldProps('SettlementAmount')} />
                                                <span className="highlight"></span>
                                                <label>Settlement Amount</label>
                                            </div>
                                           
                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="Authcode" {...formik.getFieldProps('Authcode')} />
                                                <span className="highlight"></span>
                                                <label>Auth Code</label>
                                            </div>
                                        

                                            <div className="floating-label  col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="DateCreated" {...formik.getFieldProps('DateCreated')} />
                                                <span className="highlight"></span>
                                                <label>Transaction Date</label>
                                            </div>
                                           
                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="TerminalId" {...formik.getFieldProps('TerminalId')} />
                                                <span className="highlight"></span>
                                                <label>Terminal ID</label>
                                            </div>
                                        

                                            <div className="floating-label  col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="Status" {...formik.getFieldProps('Status')} />
                                                <span className="highlight"></span>
                                                <label>Status</label>
                                            </div>
                                           
                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="TransactionType" {...formik.getFieldProps('TransactionType')} />
                                                <span className="highlight"></span>
                                                <label>Transaction Type</label>
                                            </div>
                                        

                                            <div className="floating-label  col-md-6">
                                                <input className="floating-input" type="text" placeholder=" " name="Balance" {...formik.getFieldProps('Balance')} />
                                                <span className="highlight"></span>
                                                <label>Balance</label>
                                            </div>
                                           
                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="meterToken" {...formik.getFieldProps('meterToken')} />
                                                <span className="highlight"></span>
                                                <label>Meter Token</label>
                                            </div>
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="accountName" {...formik.getFieldProps('accountName')} />
                                                <span className="highlight"></span>
                                                <label>Account Name</label>
                                            </div>
                                        </div>

                                        <div className="row" >
                                            <div  className="floating-label  col-md-6">
                                                <input className="floating-input" type="email" placeholder=" " name="beneficiaryBank" {...formik.getFieldProps('beneficiaryBank')} />
                                                <span className="highlight"></span>
                                                <label>Beneficiary Bank</label>
                                            </div>

                                            <div className="floating-label col-md-6 pd-b-10" >
                                                        <textarea className="floating-input floating-textarea" rows="7" cols="50" type="text" placeholder=" "  name="narration" {...formik.getFieldProps('narration')}/>
                                                        <span className="highlight"></span>
                                                        <label>Narration/Transaction Description</label>
                                                </div>
                                        </div>
                                        

                                </div>
                                </fieldset>
                            </form>
                            
                            <div className="table-wrapper table-responsive">
                                <table id="datatable2" className="table display responsive nowrap">
                                    <thead>
                                        <tr>
                                            <th className="">Name</th>
                                            <th className="">Value</th>
                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {transactionLogElements}
                                    </tbody>
                                </table>
                            </div>

                            { (usertype === "SuperAdmin" && transaction.TransactionType === "Refund") ? 
                            <div className="centered-form col-lg-12">

                                        <div className="signin-box pd-20-force create-agg">  
                                            <div className="floating-form ">   

                                                <div className="floating-label col-md-3 pd-b-10" >
                                                            <input className="floating-input" type="text" placeholder=" "  name="actualAmount" {...formik.getFieldProps('actualAmount')}/>
                                                            <span className="highlight"></span>
                                                            <label>Transaction Amount</label>
                                                </div>
                                                <div className="floating-label col-md-3 pd-b-10" >
                                                            <input className="floating-input" type="text" placeholder=" "  name="originalDate" {...formik.getFieldProps('originalDate')}/>
                                                            <span className="highlight"></span>
                                                            <label>Original Transaction Date(2021/10/17)</label>
                                                </div>

                                                <div className="row pd-10">
                                                        <button onClick={() => downloadRefundReceipt() } className="btn-fill-md majorButton" >Download Refund Receipt</button> 
                                                </div>

                                            </div>
                                        </div>
                            </div> : "" }

                            { (usertype === "SuperAdmin" && (transaction.TransactionType === "Withdrawal" || transaction.TransactionType === "Power Bill") ) ? 
                            <div className="centered-form col-lg-12">

                                        <div className="signin-box pd-20-force create-agg">  
                                            <div className="floating-form ">                               

                                                <div className="row pd-10">
                                                    <button onClick={() => downloadReceipt() } className="btn-fill-md majorButton" >Download Withdrawal/Power Bill Receipt</button> 
                                                </div>

                                            </div>
                                        </div>
                            </div> : "" }

                            { (usertype === "SuperAdmin" && transaction.TransactionType === "Deposit"  ) ? 
                            <div className="centered-form col-lg-12">

                                        <div className="signin-box pd-20-force create-agg">  
                                            <div className="floating-form ">                               

                                                <div className="row pd-10">
                                                    <button onClick={() => downloadDepositReceipt() } className="btn-fill-md majorButton" >Download Deposit Receipt</button> 
                                                </div>

                                            </div>
                                        </div>
                            </div> : "" }

                            { (usertype === "SuperAdmin" && transaction.TransactionType === "Withdrawal" ) ? 
                                    <div className="centered-form col-lg-12">

                                        <div className="signin-box pd-20-force create-agg">  
                                            <div className="floating-form ">                               

                                                <div className="row pd-10">
                                                    <button onClick={() => sendReceipt() } className="btn-fill-md majorButton" >Send Withdrawal Receipt</button> 
                                                </div>

                                            </div>
                                        </div>
                                    </div> : ""
                            }

                            { (usertype === "SuperAdmin" && transaction.TransactionType === "Deposit" ) ? 
                                    <div className="centered-form col-lg-12">

                                        <div className="signin-box pd-20-force create-agg">  
                                            <div className="floating-form ">                               

                                                <div className="row pd-10">
                                                    <button onClick={() => sendDepositReceipt() } className="btn-fill-md majorButton">Send Deposit Receipt</button> 
                                                </div>

                                            </div>
                                        </div>
                                    </div> : ""
                            }

                            { (usertype === "SuperAdmin" && transaction.TransactionType === "Power Bill" ) ? 
                                    <div className="centered-form col-lg-12">

                                        <div className="signin-box pd-20-force create-agg">  
                                            <div className="floating-form ">                               

                                                <div className="row pd-10">
                                                    <button onClick={() => sendPowerReceipt() } className="btn-fill-md majorButton">Send Power Bill Receipt</button> 
                                                </div>

                                            </div>
                                        </div>
                                    </div> : ""
                            }

                            {/* { ( transaction.transactionTypeID.description = "Power Bill" || transaction.TransactionType === "Power Bill" ) ? 
                                    <div className="centered-form col-lg-12">

                                        <div className="signin-box pd-20-force create-agg">  
                                            <div className="floating-form ">                               

                                                <div className="row pd-10">
                                                    <button onClick={() => sendPowerBillReceipt() } className="btn-fill-md majorButton" >Send Power Bill Receipt</button> 
                                                </div>

                                            </div>
                                        </div>
                                    </div> : ""
                            } */}


                            
                        </div>
                    </div>

                </div>
            </div>
            }
        </>
    )
}

export default Details