import React,{useState} from 'react'

function Main ({changeApp}) {
    
    

    return (
            <div className="slim-mainpanel">
                <div className="container">

                        <div className="main-header mg-b-30">
                            <h2 className="main-header-h-1 ">Management</h2>
                            <p className="signin-title-secondary">Create agent profile here, view and edit their profiles and terminals as well</p>
                        </div>                    
                        <div className="row mg-t-20">
                        <div onClick={() => changeApp("create")} className="col-6 col-md-6 col-lg-3"> 
                                <a href="#">                          
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Create Agent</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">Create Agents that you have acquired on the SureBanka Platform for onboarding</p>
                                        </div>
                                    </div>
                                </a>                   
                            </div>  
                            <div onClick={() => changeApp("agent")} className="col-6 col-md-6 col-lg-3">
                                <a href="#">
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div  className="card-body ">                                   
                                        <p className="user-brand-name">Manage Agent</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">View and Manage the Agents that you have acquired on the SureBanka Platform</p>
                                        </div>
                                    </div>  
                                </a>                                                 
                            </div>                   
                            <div  onClick={()=> changeApp("terminal")}  className="col-6 col-md-6 col-lg-3">
                                <a href="#">                           
                                    <div className="card pssCard bd-0 mg-b-30">
                                        <div className="card-body ">                                    
                                        <p className="user-brand-name">Manage Terminals</p>
                                        <p className="user-brand-text-2 tx-small mg-b-10">View and Manage the terminals of the Agents you have acquired on the SureBanka Platform</p>
                                        </div>
                                    </div>     
                                </a>                       
                            </div>                 
                        </div>                                    
                    </div>
        </div>
        )
    }

export default Main