export default [
    {
        "path" : "/agent/dashboard",
        "title" : "Dashboard",
        "view" : ["Agent",]
    },
    {
        "path" : "/agent/wallet",
        "title" : "Wallet",
        "view" : ["Agent"]
    },
    {
        "path" : "/agent/transactions",
        "title" : "Transactions",
        "view" : ["Agent"]
    },
    {
        "path" : "/agent/report",
        "title" : "Reports",
        "view" : ["Agent"]
    },
    {
        "path" : "/agent/device",
        "title" : "Devices",
        "view" : ["Agent"]
    },
    {
        "path" : "/agent/bill",
        "title" : "Bills",
        "view" : ["Agent"]
    },
    {
        "path" : "/agent/request",
        "title" : "Request",
        "view" : ["Agent"]
    },
    // {
    //     "path" : "/agent/dispute",
    //     "title" : "Dispute",
    //     "view" : ["Agent"]
    // },
    {
        "path" : "/aggregator/dashboard",
        "title" : "Dashboard",
        "view" : ["Aggregator"]
    },
    {
        "path" : "/aggregator/wallet",
        "title" : "Wallet",
        "view" : ["Aggregator"]
    },
    {
        "path" : "/aggregator/report",
        "title" : "Report",
        "view" : ["Aggregator"]
    },
    {
        "path" : "/aggregator/manage",
        "title" : "Management",
        "view" : ["Aggregator"]
    },
    {
        "path" : "/aggregator/transaction",
        "title" : "Transactions",
        "view" : ["Aggregator"]
    },
    {
         "path" : "/aggregator/request",
         "title" : "Requests",
         "view" : ["Aggregator"]
     },
    {
        "path" : "/admin/dashboard",
        "title" : "Dashboard",
        "view" : ["Admin",]
    },
    {
        "path" : "/admin/agent",
        "title" : "Agents",
        "view" : ["Admin"]        
    },
    {
        "path" : "/admin/aggregator",
        "title" : "Aggregators",
        "view" : ["Admin"]        
    },
    {
        "path" : "/admin/transactions",
        "title" : "Transactions",
        "view" : ["Admin",]
    },
    {
        "path" : "/admin/dispute",
        "title" : "Requests",
        "view" : ["Admin"]        
    },
    {
        "path" : "/admin/report",
        "title" : "Report",
        "view" : ["Admin"]        
    },
    {
        "path" : "/admin/device",
        "title" : "Device",
        "view" : ["Admin"]        
    },
    {
        "path" : "/superadmin/dashboard",
        "title" : "Dashboard",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/agent",
        "title" : "Agent",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/aggregator",
        "title" : "Aggregator",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/device",
        "title" : "Device",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/report",
        "title" : "Reporting",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/transaction",
        "title" : "Transaction",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/authorize",
        "title" : "Authorization",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/lien",
        "title" : "Lien",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/efloat",
        "title" : "E-Float",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/audit",
        "title" : "Audit Logs",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/dispute",
        "title" : "Dispute",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/superadmin/recon",
        "title" : "Recon",
        "view" : ["SuperAdmin"]        
    },
    {
        "path" : "/ptsp/dashboard",
        "title" : "Dashboard",
        "view" : ["PTSP"]        
    },
    {
        "path" : "/ptsp/transaction",
        "title" : "Transactions",
        "view" : ["PTSP"]        
    },
    {
        "path" : "/licenseowner/dashboard",
        "title" : "Dashboard",
        "view" : ["LicenseOwner"]        
    },
    {
        "path" : "/licenseowner/transactions",
        "title" : "Transactions",
        "view" : ["LicenseOwner"]        
    }
    
]