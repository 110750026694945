import React, { useState } from 'react'
import Main from './Main'
import CashBalance from './CashBalance'
import Profit from './CashProfit'
import Electricity from './Electricity'

function Report () {
    const [params, setParams] = useState("main")

    function changeApp(name){
        
        setParams(prev => {
            return name
        })
    }
    return (
    <>
         {
            {
                'main': <Main changeApp={changeApp}  />,
                'cash': <CashBalance changeApp={changeApp} />,
                'profit' : <Profit changeApp={changeApp} />,
                'electricity' : <Electricity changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
    </>
    )
}

export default Report