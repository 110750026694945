import React, { useState, useEffect } from 'react'
import './Transaction.css'
import {useFormik} from 'formik'
import {NotificationService} from '../../service/NotificationService'
import { CSVLink, CSVDownload } from "react-csv"
import moment from 'moment'
 
import "react-datepicker/dist/react-datepicker.css";

function AggregatorTransaction({transactions, changeApp,  updateTransaction}) {
    const [terminals, setTerminals] = useState([]);
    const [types, setTypes] = useState([]);
    const [transactionsLocal, setTransactionsLocal] = useState([])
    let transactionElements

    const { usertype}  = JSON.parse(localStorage.getItem('user'))

    const formatter = new Intl.NumberFormat('en-US');

    var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    
    //-----------------------------------------------------------------------------------------------------------
    const csvData = transactions.map(transaction => {
        return (
            {
                TransactionID : transaction._id,
                ReferenceNumber: transaction.rrn,
                Amount : transaction.amount,
                ResponseCode : transaction.responseCode,
                ResponseMessage : transaction.responseCode,
                SettlementAmount : transaction.settlementAmount,
                Authcode : transaction.authCode,
                DateCreated : new Date(transaction.dateCreated).toUTCString(),
                TerminalId : transaction.terminalID ? transaction.terminalID.terminalPhysicalId : "",
                TransactionType : transaction.transactionTypeID ? transaction.transactionTypeID.description : " ",
                //Status : transaction.transactionStatusID.name,
                AgentCharge: transaction.agentCharge
            }
        )
    })
    
    //-----------------------------------------------------------------------------------------------------------
    if(transactionsLocal.length !== 0 ){
        console.log(transactions)
        transactionElements = transactionsLocal.slice(0,20).map(transaction => {
            return (
            <tr key={transaction.dateCreated} onClick={() => {changeApp('details'); updateTransaction(transaction);}}>
                <td><a href="#">{transaction.terminalID ? transaction.terminalID.terminalOwner.firstname + " " + transaction.terminalID.terminalOwner.lastname : ""}</a></td>
                <td><a href="#">{transaction.terminalID ? transaction.terminalID.terminalPhysicalId : ""}</a></td>
                <td><a href="#">{transaction.rrn}</a></td>
                <td><a href="#">{formatter.format(transaction.amount.toFixed(2))}</a></td>
                <td><a href="#">{transaction.transactionStatusID.name == "Successful" ?  formatter.format(transaction.commission.toFixed(2)) : null}</a></td>
                <td><a href="#">{transaction.responseCode}</a></td>
                <td><a href="#">{"Response Message"}</a></td>
                <td><a href="#">{transaction.transactionTypeID ? transaction.transactionTypeID.description : " "}</a></td>
                <td><a href="#">{transaction.channel}</a></td>
                {/* <td><a href="#">{new Date(transaction.dateCreated).toLocaleString('en-US')}</a></td>             */}
                <td><a href="#">{new Date(transaction.dateCreated).toUTCString()}</a></td>
            </tr>
            
            )
        })
    }
    //-----------------------------------------------------------------------------------------------------------
    useEffect(() => {
        setTransactionsLocal(prev => {
            return transactions
        })
    },[])

    //-----------------------------------------------------------------------------------------------------------
    const formik = useFormik({
        initialValues: {
            startDate : '',
            endDate: '',
            terminalid: '',
            status: '',
            rrn: '',
            type: '',

        },
        validate: (what) => {
          //validate()
        },
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {             

        }
      });

    //Gets All Unique Terminals from the transactions sent in
    const uniqueTerminals = [...new Set(transactions.map(transaction => transaction.terminalID ? transaction.terminalID.terminalPhysicalId : null))];

    const terminalElements = uniqueTerminals.map(terminal => {
        return (
            <option key={terminal} value={`${terminal}`}>{`${terminal}`}</option>
        )
    })
    //-----------------------------------------------------------------------------------------------------------

    //Gets All Transaction Types from the Transactions sent in 
    const uniqueTypes = [...new Set(transactions.map(transaction => transaction.transactionTypeID ? transaction.transactionTypeID.description : null))];
    
    const typeElements = uniqueTypes.map(type => {
        return (
            <option key={type} value={`${type}`}>{`${type}`}</option>
        )
    })
    //----------------------------------------------------------------------------------------------------------
    
    //Gets All Transaction Status from the Transactions sent in
    // const uniqueStatus = [...new Set(transactions.map(transaction => transaction.transactionStatusID.name))];

    // const statusElements = uniqueStatus.map(status => {
    //     return (
    //         <option key={status} value={`${status}`}>{`${status}`}</option>
    //     )
    // })
    // //----------------------------------------------------------------------------------------------------------

    function dateFilter(e){
        formik.handleChange(e);
        
        let startDate = moment(document.getElementsByName('startDate')[0].value)
        let endDate = moment(document.getElementsByName('endDate')[0].value)
        

        startDate = startDate.startOf('day')
        endDate = endDate.endOf('day')

        //console.log(startDate + " - " + endDate)
        //console.log(moment(transactions[0].dateCreated))

        if(startDate.isAfter(endDate))
        {
            NotificationService.notifyError("Transaction Start Date cannot be greater than End Date");
            return;
        }

        const filtered = transactionsLocal.filter( transaction => moment(transaction.dateCreated).isAfter(startDate) && moment(transaction.dateCreated).isBefore(endDate))

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The Start Date and End Date combination does not match any transaction", 5000);
            //clearFilters()

            
        }


    }

    //Filter on RRN----------------------------------------------------------------------------------------------------------
    function agentFilter(e){
        formik.handleChange(e);
        const filtered = transactionsLocal.filter( transaction => transaction.terminalID.terminalOwner.firstname.toLowerCase().includes(e.target.value.toLowerCase()) || 
                                                                transaction.terminalID.terminalOwner.lastname.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search text you entered for Reference Number does not match any value", 5000);
            //clearFilters()
            
        }
    }
    //----------------------------------------------------------------------------------------------------------
    function statusFilter(e){
        formik.handleChange(e);
        const filtered = transactionsLocal.filter( transaction => transaction.transactionStatusID.name.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search paramter does not match any value", 5000);
            //clearFilters()
            
        }
    }
     //----------------------------------------------------------------------------------------------------------
     function typeFilter(e){
        formik.handleChange(e);
        const filtered = transactionsLocal.filter( transaction => transaction.transactionTypeID.description.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search parameter does not match any value", 5000);
            //clearFilters()
           
        }
    }
    //----------------------------------------------------------------------------------------------------------
    function terminalFilter(e){
        formik.handleChange(e);
        const filtered = transactionsLocal.filter( transaction => transaction.terminalID.terminalPhysicalId.toLowerCase().includes(e.target.value.toLowerCase()) )

        if(filtered.length !== 0){
            setTransactionsLocal(prev => {
                    return filtered
                }
            )
        }
        else{
            NotificationService.notifyError("The search parameter does not match any value", 5000);
            //clearFilters()
           
        }
    }

    function clearFilters(){
            
            formik.resetForm({values: ''})
            setTransactionsLocal(prev => {
                return transactions
            })
    }

    return (
        <div className="slim-mainpanel no-bg-img">
            <div className="container">
                    <div className="row table-overall-headings mg-b-10">
                        <div className="col-md-6">
                            <h2>Transactions</h2>
                            <p>View and download all transactions easily here</p>
                        </div>
                    </div>
                    <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form >
                               
                                <div className="floating-form">
                                    <div className="row">
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.startDate} name="startDate" />
                                            
                                            <span className="highlight"></span>
                                            <label>Start Date</label>
                                        </div>
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                            <input onChange={e => dateFilter(e)} className="floating-input" type="date" value={formik.values.endDate} name="endDate"  />
                                            
                                            <span className="highlight"></span>
                                            <label>End Date</label>
                                        </div>
                                    </div>
                                <div className="row">
                                <div className="floating-label tran col-md-6 pd-b-10">
                                    <input onChange={e => agentFilter(e)} className="floating-input" type="text" value={formik.values.agent} name="agent" />
                                    <span className="highlight"></span>
                                    <label>Agent Name</label>
                                </div>
                                <div className="floating-label tran col-md-6 pd-b-10">
                                    <select onChange={e => statusFilter(e)}  name="status" className="floating-select" value={formik.values.status}>
                                        <option value="" label="Select a Status" />
                                        {/* {statusElements} */}
                                    </select>
                                    <span className="highlight"></span>
                                    <label>Status</label>
                                </div>
                                </div>
                                <div className="row">
                                    <div className="floating-label tran  col-md-6 pd-b-10">
                                        <select onChange={e => terminalFilter(e)} name="terminalid" className="floating-select" value={formik.values.terminalid}>
                                            <option value="" label="Select a Terminal" />
                                            {terminalElements}
                                        </select>
                                        <span className="highlight"></span>
                                        <label>Select Terminal</label>
                                    </div>

                                    <div className="floating-label tran  col-md-6 pd-b-10">
                                        <select onChange={e => typeFilter(e)} name="type" className="floating-select" value={formik.values.type}>
                                            <option value="" label="Select a Transaction Type" />
                                            {typeElements}
                                        </select>
                                        <span className="highlight"></span>
                                        <label>Transaction Type</label>
                                    </div>


                                </div>

                                 <div className="row">

                                 </div>
                                 <div className="btn-filter">
                                    {/* <a href="#"><button className=" btn-fill-md majorButton">Download Transactions</button></a> */}
                                   <button type="button" onClick={e => clearFilters()} className=" btn-fill-md majorButton">Clear Filters</button>
                                </div>
                                
                                <div className="btn-filter">
                                    {/* <a href="#"><button className=" btn-fill-md majorButton">Download Transactions</button></a> */}
                                    <CSVLink data={csvData}><button type="button" className=" btn-fill-md majorButton">Download Transactions</button></CSVLink>
                                </div>
                                </div>
                                
                                {/* <div className="btn-filter">
                                <a href="#"><button className=" btn-fill-md majorButton">Filter</button></a>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    </div>
                    <div className="table-wrapper table-responsive">
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                     <th className="">Agent Name</th>
                                    <th className="">TerminalID</th>
                                    <th className="">Reference</th>
                                    <th className="">Amount</th>
                                    <th className="">Commission</th>
                                    <th className="">Code</th>
                                    <th className="">Message</th>
                                    <th className="">Type</th>
                                    <th className="">Channel</th>
                                    <th className="">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                               {transactionElements}
                            </tbody>
                        </table>
                    </div>

                    
                </div>
            </div>
            )
        }
        
export default AggregatorTransaction