import React, {useContext, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {Context} from '../contexts/MainContext'
import pagesData from '../data/pages'

function Sidebar() {
    const location = useLocation();
    const {user} = useContext(Context)
    let  userType = ""

    if(JSON.parse(localStorage.getItem('user'))){
       const { usertype } = JSON.parse(localStorage.getItem('user'));
       userType = usertype
    }

    const menus = pagesData.map(page => {     
    
      const activeMenu = page.path === location.pathname ? "active" : ""
      
     
      if(!page.view.includes(userType)){
        return;
      } 
      
      const url = page.path;

      return(
      <li className="sidebar-nav-item" key={page.path}>
          <a href={`${url}`} className={`sidebar-nav-link ${activeMenu}`}>{page.title}</a>
      </li>)
    })


    return (
        <div className="slim-sidebar">
      <ul className="nav nav-sidebar">
        {menus}
      </ul>
    </div>
    )
}

export default Sidebar