import React, {useState} from 'react'
import Main from './Main'
import Cashout from './Cashout'


function Wallet () {
    const [params, setParams] = useState("manage")

    function changeApp(name) {
        
        setParams(prev => {
            return name
        })
    }

    return (
        <>
        {
            {
                'manage': <Main changeApp={changeApp}  />,
                'cashout' : <Cashout changeApp={changeApp} />
            }[params] || <Main changeApp={changeApp} />
        }
            
        </>
    )
}

export default Wallet