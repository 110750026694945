import React, { useState, useEffect } from 'react'
import Tran from '../../../components/transaction/Tran'
import {AgentService} from '../../../service/AgentService'
import Spinner from '../../../components/Spinner'
import {NotificationService} from '../../../service/NotificationService'
import moment from 'moment'
 
import "react-datepicker/dist/react-datepicker.css";

function Transaction() {

    const [busy, setBusy] = useState(true);
    const { id } = JSON.parse(localStorage.getItem('user'))
    const [transactions, setTransactions] = useState([])

    const today = new Date();

    const searchParams = {
        agentId: id,
        endDate: moment().add(1, 'days').format('yyyy/MM/DD'),
        startDate: moment().subtract('days', 7).format('yyyy/MM/DD'),
        lastWeek: false,
        lastMonth: false
    }

    

    useEffect(() => {
        AgentService.getTransactionsByAgent(searchParams)
        .then(response => {
            
            if(response.data.data.length > 0){
                NotificationService.notify("The number of transactions for the period specified is " + response.data.data.length);
                setTransactions(prev => {
                    return response.data.data
                })
                }
                else if(response.data.data.length === 0){
                    NotificationService.notifyInfo("You do not have any transaction for the past one week", 5000);
                }
                
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])


    return (
        <>
           {busy ? <Spinner /> : <Tran transactions={transactions} /> }
        </>
    )
        }
        
export default Transaction