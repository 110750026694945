import React from 'react'
import {Switch, Route } from 'react-router-dom'

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Dashboard from './admin/Dashboard/Dashboard'
import Profile from './Profile'
import Transaction from './admin/Transaction/Transaction'
import Dispute from './admin/Dispute/Dispute'
import Report from './admin/Report/Report'
import Device from './admin/Device/Device'

import AdminAgent from './admin/Agent/AdminAgent'
import AdminAggregator from './admin/Aggregator/AdminAggregator'
import Welcome from './admin/Welcome'
import SetPIN from './SetPIN'



function AdminArea () {
    return (
        <>
         <Header />
        <div className="slim-body">
       
        <Sidebar />

        
        <Switch>
        <Route path='/admin/welcome' >
                <Welcome />
            </Route>
            <Route path='/admin/dashboard' >
                <Dashboard />
            </Route>
            <Route path='/admin/profile'>
                <Profile />
            </Route>
            <Route path='/admin/pin'>
                <SetPIN />
            </Route>
            
            <Route path='/admin/transactions'>
                <Transaction />
            </Route>
            <Route path='/admin/dispute'>
                <Dispute />
            </Route>
            <Route path='/admin/report'>
                <Report />
            </Route>
            <Route path='/admin/device'>
                <Device />
            </Route>
            <Route path='/admin/agent'>
                <AdminAgent />
            </Route>
            <Route path='/admin/aggregator'>
                <AdminAggregator />
            </Route>

            <Route path='*' component={() => "404 NOT FOUND"} />

        </Switch>
       
        </div>
        </>
    )
}

export default AdminArea