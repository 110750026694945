import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../Spinner'
import { AgentService } from '../../service/AgentService'
import { NotificationService } from '../../service/NotificationService'

import moment from 'moment'



function Profit () {
    const [terminals, setTerminals] = useState([])
    const [busy, setBusy] = useState(true)
    const [calculating, setCalculating] = useState(false)
    const { id } = JSON.parse(localStorage.getItem('user'));
    
    const [ transactions, setTransactions] = useState([])

    let transactionElements
    let closing = 0

    const terminalOptions = terminals.map(terminal => {
        return (
            <option key={terminal._id} value={`${terminal._id}`}>{`${terminal.terminalPhysicalId}`}</option>
        )
    })

    const formatter = new Intl.NumberFormat('en-US');

    if( transactions.length > 0){

        let totalProfit = 0;
        transactionElements = transactions.map(transaction => {
            let profit = 0

            if(transaction.transactionTypeID.description === "TerminalTransfer" || transaction.transactionStatusID.name !== "Successful" || transaction.transactionTypeID.description === "FundTerminal")
            {
                return;
            }

            if(transaction.transactionTypeID.description === "Airtime"){
                profit = transaction.agentCharge + Math.abs(transaction.settlementAmount - transaction.amount)
            }
            else{
                profit = transaction.agentCharge - Math.abs(transaction.settlementAmount - transaction.amount)
            }
            
            totalProfit += profit

            return (
                <tr key={transaction.dateCreated}>
                    <td><a href="#">{transaction.transactionTypeID.description}</a></td>
                    <td><a href="#">{formatter.format(transaction.amount)}</a></td>
                    <td><a href="#">{Math.abs(formatter.format(transaction.settlementAmount - transaction.amount))}</a></td>
                    <td><a href="#">{formatter.format(transaction.agentCharge)}</a></td>
                    <td><a href="#">{formatter.format(transaction.agentCharge - Math.abs(transaction.settlementAmount - transaction.amount))}</a></td>         
                </tr>
            
            )
        })
        closing = totalProfit
    }

    //console.log(closing)

    useEffect(() => {
        
        AgentService.getTerminalsByAgent({agentId: id})
        .then(response => {
            console.log(response.data)
            setTerminals(prev => {
                return response.data
            })
            setBusy(false)
        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    }, [])

    const formik = useFormik({
        initialValues: {         
            
            terminalID: "5ec1628a3ab49d1f0032ef66"
        },
        validate: (what) => {

            if(formik.errors.terminalID && formik.touched.terminalID){
                NotificationService.notifyError(formik.errors.terminalID, 1000)
                return;
            }
        },
        validationSchema: Yup.object({
           
            terminalID: Yup.string()
             .required('Please seclect the Terminal')
        }),
        handleChange:() => {
          
        },
        onSubmit: (values,{ resetForm }) => {     
            console.log(values)        
            setCalculating(true)

            AgentService.getTerminalTransactions({...values})
            .then(response => {
                // console.log(response.data.data)
                filterTransactionsBydate(response.data.data)

                setCalculating(false)
            })
            .catch(error => {
                if(error.response){
                    NotificationService.notifyError(error.response.data.message, 5000)
                }
                else if(error.message){
                    NotificationService.notifyError(error.message, 5000)
                }
                else{
                    NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
                }
                setCalculating(false)
            })
        }
      });

      function filterTransactionsBydate(transactions){

        console.log(transactions)
          let startDate;
          let endDate;

        switch (formik.values.period) {
            case "today":
                startDate = moment().format('yyyy-MM-DD')
                endDate = moment().add(1, 'days').format('yyyy-MM-DD')
                break;
            case "yesterday":
                startDate = moment().add(-1, 'days').format('yyyy-MM-DD')
                endDate = moment().format('yyyy-MM-DD')
                break;
            case "week":
                startDate = moment().add(-7, 'days').format('yyyy-MM-DD')
                endDate = moment().add(1, 'days').format('yyyy-MM-DD')
                break;        
            default:
                break;
        }

        console.log(startDate + " " + endDate)
        console.log(typeof startDate)

        const transactionByDate = transactions.filter(transaction =>  transaction.dateCreated >= startDate && transaction.dateCreated <= endDate)

        console.log(transactionByDate)

        setTransactions(prev => {
            return transactionByDate
        })
        
      }


    return (
       <>
         {busy ? <Spinner /> :
         <>
         <div className="row mg-t-20">
                    <div className="col-md-12">
                        <div className="dashboard-card-bg  pd-30">
                            <form onSubmit={formik.handleSubmit} >                               
                                <div className="floating-form">

                                    <div className="row">
                                                                                
                                        <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="terminalID" {...formik.getFieldProps('terminalID')} >
                                                <option value="" label="Please select terminal for this transaction" />
                                                {terminalOptions}
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Terminal</label>                           
                                        </div>

                                        <div className="floating-label tran col-md-6 pd-b-10">
                                                <select  className="floating-input" placeholder="" name="period" {...formik.getFieldProps('period')} >
                                                <option value="" label="Please select a specific period" />
                                                <option value="today" label="Today" />
                                                <option value="yesterday" label="Yesterday" />
                                                <option value="week" label="Last Week" />
                                                </select>
                                                <span className="highlight"></span>
                                                <label>Transaction Date Range</label>                           
                                        </div>
                                
                                    </div>
                                
                                </div>
                                
                                {/* <div className="row">
                                    <div className="floating-label tran col-md-6 pd-b-10 pd-b-10"> */}
                                        <div className="btn-filter">
                                        <button type="submit" className="btn btn-outline-info">Generate Report</button>
                                        </div>
                                    {/* </div>
                                </div> */}

                            </form>
                        </div>
                    </div>
                    </div>
                    <div className="table-wrapper table-responsive">
                        {calculating ? <Spinner /> : 
                        <table id="datatable2" className="table display responsive nowrap">
                            <thead>
                                <tr>
                                    <th className="">Transaction Type</th>
                                    <th className="">Amount</th>
                                    <th className="">Surebanka Charge</th>
                                    <th className="">Agent Charge</th>
                                    <th className="">Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                                             
                               {transactionElements}
                               <tr>
                                    <td>Closing Cash Balance</td>
                                    <td>Auto Computed</td>
                                    <td>N/A</td>
                                    <td>Total Profit</td>
                                    
                                    <td>{formatter.format(closing)}</td>
                                </tr>
                            </tbody>
                        </table>
                        }
                    </div>
                    </>
            }
        </>
    )
}

export default Profit