import React, { useEffect, useState } from 'react'
import  AggregatorTransaction from '../../../components/transaction/AggregatorTransaction'
import {AggregatorService} from '../../../service/AggregatorService'
import {NotificationService} from '../../../service/NotificationService'
import Spinner from '../../../components/Spinner'
import moment from 'moment'

function Transaction () {
    const [busy, setBusy] = useState(true)
    const {id}  = JSON.parse(localStorage.getItem('user'))
    const [transactions, setTransactions] = useState([])
    
    const searchParams = {
       
        endDate: moment().add(1, 'days').format('yyyy/MM/DD'),
        startDate: moment().subtract('days', 1).format('yyyy/MM/DD')
    }

    useEffect(() => {
        AggregatorService.getTransactionsByAggregator({aggregatorId: id, startDate: searchParams.startDate, endDate: searchParams.endDate})
        .then(response => {
            
            if(response.data.data.length > 0){
                    NotificationService.notify("Transactions pulled successfully ");
                    setTransactions(prev => {
                        return response.data.data
                    })
                }
                else if(response.data.data.length === 0){
                    NotificationService.notifyInfo("You do not have any transaction for this period");
                    
                } 
            setBusy(false)

        })
        .catch(error => {
            if(error.response){
                NotificationService.notifyError(error.response.data.message, 5000)
            }
            else if(error.message){
                NotificationService.notifyError(error.message, 5000)
            }
            else{
                NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
            }
            setBusy(false)
        })
    },[])


    return (
        <>
            {busy ? <Spinner /> : <AggregatorTransaction transactions={transactions} /> }
        </>
    )
}

export default Transaction